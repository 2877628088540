import {Modal, ModalBody, ModalFooter, ModalHeader} from "react-bootstrap";
import React, {useEffect} from "react";
import "./ResultModal.scss"
import {QuestionData} from "../quizStepper/QuizStepper";

export interface ResultModalProps {
    show: boolean,
    onHide: () => void,
    title: string,
    onSubmit: () => void,
    questionData: QuestionData[],
}

export const ResultModal = (props: ResultModalProps) => {
    return <div>
        <Modal show={props.show} onHide={props.onHide} centered>
            <ModalHeader closeButton>
                <h6 className={'fw-bold'}>{props?.title ?? " "}</h6>
            </ModalHeader>
            <ModalBody>
                <table className="table tableContainer">
                    <thead className="table-active">
                    <tr>
                        <th scope="col">No. of Questions</th>
                        <th scope="col">Answered</th>
                        <th scope="col">Not Answered</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>{props?.questionData.length ?? 0}</td>
                        <td>{props.questionData.filter(item => (item.selectedChoice?.length && item.selectedChoice.length > 0) || item.answer).length ?? 0}</td>
                        <td>{(props?.questionData.length ?? 0) -
                            ((props.questionData.filter(item => (item.selectedChoice?.length && item.selectedChoice.length > 0) || item.answer).length ?? 0))}</td>
                    </tr>
                    </tbody>
                </table>
                    {/*text not visible when not unattempted question*/}
                {((props?.questionData.length ?? 0) -
                    ((props.questionData.filter((item) => item.selectedChoice).length ?? 0) +
                        (props.questionData.filter((item) => item.answer).length ?? 0)))>0 &&
                    <div className={'mt-2 text-center'}>
                    <span className={'text-danger text-center'} style={{fontSize:'14px'}}>{`You Still have ${((props?.questionData.length ?? 0) -
                        ((props.questionData.filter((item) => item.selectedChoice).length ?? 0) +
                            (props.questionData.filter((item) => item.answer).length ?? 0)))} unattempted Questions`}</span>
                    </div>
                }
                <h6 className={'mt-4 text-center'}>Are you sure you want to submit ?</h6>
                <div className={'mt-4 d-flex justify-content-center'}>
                    <button className={'btn btnCancel'} onClick={props.onHide}>Cancel</button>
                    <button className={'btn btnSuccess ms-3'} onClick={props.onSubmit}>Submit</button>
                </div>
            </ModalBody>
        </Modal>
    </div>
}
export default ResultModal;