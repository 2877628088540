import React, {useEffect, useState} from "react";
import {Modal, ModalBody, ModalHeader} from "react-bootstrap";
import {useStudentApiClient} from "../../../student/apiClients/StudentApiClient";



const StudentBatchDetail=(props:{show:boolean,studentId:number,onCancel:()=>void,
    onSubmit?:()=>void})=>{

    const [studentDetail, setStudentDetail] = useState<any[]>([])
    const useStudentApi = useStudentApiClient()

    useEffect(() => {
        if (props.studentId) {
            studentBatchDetail(props.studentId);
        }
    }, [props.studentId]);



    const studentBatchDetail = (StudentId: number) => {
        useStudentApi.studentBatchDetail(StudentId).then((res) => {
            setStudentDetail(res.data.batches);
            console.log('BatchDetails',res.data.batches);
        }).catch((error) => {
            console.error("Error fetching teacher data:", error);
        });
    }

    return<div>
        <Modal show={props.show} onHide={props.onCancel}>
            <ModalHeader>
                <div className={'flex-fill'}><h4>Student Batches</h4></div>
                <div className={'cursor-pointer'} onClick={props.onCancel}><i className="bi bi-x fs-4 "></i></div>
            </ModalHeader>
            <ModalBody>
                <form>
                    {studentDetail.length === 0 ? (
                            <h6 className='p-3 m-2'>Student is not enrolled in any batch.</h6>
                        ) :(
                        <div className={'table-responsive'}>
                            <table className="table mt-3">
                                <thead>
                                <tr>
                                    <th scope="col"></th>
                                    <th scope="col">Batch</th>
                                    <th scope="col">Course</th>
                                </tr>
                                </thead>
                                <tbody>
                                {studentDetail.map((Student, index) => (
                                    <tr key={Student.id}>
                                        <td>{index + 1}</td>
                                        <td>{Student.name}</td>
                                        <td>{Student.course.name}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                    )}
                    <div className={'d-flex mt-3'}>
                        <div className={'flex-fill'}></div>
                        <div>
                            <button className="btn btn-secondary me-2" onClick={props.onCancel}>Close</button>
                        </div>
                    </div>
                </form>
            </ModalBody>
        </Modal>

    </div>
}
export default StudentBatchDetail;
