import React, {useEffect, useState} from "react";
import {Modal, ModalBody, ModalHeader} from "react-bootstrap";
import {useTeacherApiClient} from "../../../modules/admin/apiClients/TeacherApiClient";
import {AllToasts} from "../../toast/AllToasts";
import Button from "../../button/Button";
import Select from "react-dropdown-select";
import {useSubjectsApiClient} from "../../../modules/admin/apiClients/SubjectsApiClient";

export type EditTeacherProps = {
    show: boolean;
    onSuccess: () => void;
    onHide: () => void;
    onCancel: () => void;
    teacherId: number | undefined;
};

export type TeachersPayload = {
    name: string | undefined;
    mobile?: string;
    email?: string;
    address?: string;
    subjectId: number[];
    createUser: boolean;
};

interface SubjectsData {
    id: number;
    name: string;
}

const AddEditTeacher = (props: EditTeacherProps) => {
    const [subjects, setSubjects] = useState<SubjectsData[]>([]);
    const subjectsApiClient = useSubjectsApiClient();
    const teacherApiClient = useTeacherApiClient();
    const [mobileError, setMobileError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedSubject, setSelectedSubject] = useState<{ label: string, value: number }[]>([]);
    const [payload, setPayload] = useState<TeachersPayload>({
        name: "",
        mobile: "",
        email: "",
        address: "",
        subjectId: [],
        createUser: true,
    });

    useEffect(() => {
        setPayload({
            name: "",
            mobile: "",
            email: "",
            address: "",
            subjectId: [],
            createUser: true,
        });
        setSelectedSubject([]);
        if (props.teacherId) {
            getTeacherById(props.teacherId);
        }
    }, [props.teacherId]);

    useEffect(() => {
        getAllSubjects();
    }, []);

    const getTeacherById = (id: number) => {
        teacherApiClient.getTeacherById(id).then((res) => {
            setPayload(res.data);
            setSelectedSubject(res.data.subjects?.map((subject: any) => ({label: subject.name, value: subject.id})));
        });
    };

    const createTeacher = (payload: TeachersPayload) => {
        setLoading(true);
        setMobileError(null);
        teacherApiClient
            .createTeacher(payload)
            .then((res) => {
                setLoading(false);
                setMobileError(null);
                props.onSuccess();
                props.onHide();
                AllToasts.success("Teacher added successfully");
                setPayload({name: "", mobile: "", email: "", address: "", subjectId: [], createUser: true,});
                clearSelectedSubjects();
            })
            .catch((error) => {
                console.error("Error creating teacher:", error);
                setLoading(false);
                if (error.response?.data?.code === 4000) {
                    setMobileError("Mobile number already exists");
                }
            });
    };


    const updateTeacher = (id: number, payload: TeachersPayload) => {
        setLoading(true);
        setMobileError(null);
        setMobileError(null)
        teacherApiClient
            .updateTeacher(id, payload)
            .then((res) => {
                setLoading(false);
                setMobileError(null);
                props.onSuccess();
                props.onHide();
                AllToasts.success("Teacher details updated successfully");
                setPayload({name: "", mobile: "", email: "", address: "", subjectId: [], createUser: true,});
                clearSelectedSubjects();
            })
            .catch((err) => {
                console.log("Update error:", err);
                setLoading(false);
                if (err.response?.data?.code === 4000) {
                    setMobileError("Mobile number already exists");
                }
            });
    };

    const getAllSubjects = () => {
        subjectsApiClient.getSubjects().then((res) => {
            setSubjects(res.data);
        });
    };

    const handleSubjectChange = (selectedItems: { label: string, value: number }[]) => {
        setSelectedSubject(selectedItems);
        const selectedSubjectIds = selectedItems.map((item: any) => item.value);
        setPayload((prevState) => ({...prevState, subjectId: selectedSubjectIds}));
    };

    const clearSelectedSubjects = () => {
        setSelectedSubject([]);
        setPayload((prevState) => ({...prevState, subjectId: [],}));
    };

    const handleCancel = () => {
        setMobileError(null);
        props.onCancel();
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();
        if (props.teacherId) {
            updateTeacher(props.teacherId, payload);
        } else {
            createTeacher(payload);
        }
    };


    return (
        <div>
            <Modal show={props.show} onHide={props.onCancel}>
                <ModalHeader closeButton className="border-bottom">
                    <div className={"flex-fill"}>
                        <h5>{props.teacherId ? "Update teacher" : "Add teacher"}</h5>
                    </div>
                </ModalHeader>
                <ModalBody>
                    <form onSubmit={(e) => handleSubmit(e)}>
                        <div className="form-group m-2">
                            <label htmlFor="exampleInputName">Name <span className='text-danger'>*</span></label>
                            <input
                                type="text"
                                className="form-control"
                                id="exampleInputName"
                                aria-describedby="nameHelp"
                                onChange={(e) =>
                                    setPayload((prevState) => ({
                                        ...prevState,
                                        name: e.target.value,
                                    }))
                                }
                                value={payload?.name}
                                placeholder="Enter name"
                                required
                            />
                        </div>
                        <div className="form-group m-2">
                            <label htmlFor="exampleInputMobile">Mobile <span className='text-danger'>*</span></label>
                            <input
                                type="text"
                                className={`form-control ${mobileError ? "is-invalid" : ""}`}
                                id="exampleInputMobile"
                                aria-describedby="mobileHelp"
                                onChange={(e) =>
                                    setPayload((prevState) => ({
                                        ...prevState,
                                        mobile: e.target.value,
                                    }))
                                }
                                value={payload?.mobile}
                                placeholder="Enter mobile"
                                required
                                maxLength={10}
                                minLength={10}
                            />
                            {mobileError && (
                                <div className="invalid-feedback">{mobileError}</div>
                            )}
                        </div>

                        <div className="form-group m-2">
                            <label htmlFor="exampleInputEmail">Email <span className='text-danger'>*</span></label>
                            <input
                                type="text"
                                className="form-control"
                                id="exampleInputEmail"
                                aria-describedby="emailHelp"
                                onChange={(e) =>
                                    setPayload((prevState) => ({
                                        ...prevState,
                                        email: e.target.value,
                                    }))
                                }
                                value={payload?.email}
                                placeholder="Enter email"
                                required
                            />
                        </div>
                        <div className="form-group m-2">
                            <label htmlFor="exampleInputAddress">Address <span className='text-danger'>*</span></label>
                            <input
                                type="text"
                                className="form-control"
                                id="exampleInputAddress"
                                aria-describedby="addressHelp"
                                onChange={(e) =>
                                    setPayload((prevState) => ({
                                        ...prevState,
                                        address: e.target.value,
                                    }))
                                }
                                value={payload?.address}
                                placeholder="Enter address"
                                required
                            />
                        </div>
                        <div className="form-group m-2">
                            <label htmlFor="exampleInputAddress">Select subject <span
                                className='text-danger'>*</span></label>
                            <Select
                                options={subjects?.map(subject => ({label: subject.name, value: subject.id}))}
                                name="Subjects"
                                multi={true}
                                placeholder="Select Subjects"
                                required
                                className={"rounded"}
                                values={selectedSubject || []}
                                onChange={handleSubjectChange}
                            />
                        </div>

                        <div className={"d-flex mt-3"}>
                            <div className={"flex-fill"}></div>
                            <div>
                                <button className="btn btn-cancel me-2" onClick={handleCancel}>
                                    Cancel
                                </button>
                                <Button label={props.teacherId ? "Update" : "Add"} loading={loading}
                                        cssName={"btn btn-add text-white"}/>
                            </div>
                        </div>
                    </form>
                </ModalBody>
            </Modal>
        </div>
    );
};

export default AddEditTeacher;
