
export interface User {
    id?: string,
    username?:string
    name?: string,
    mobile?: string,
    email?: string,
    role: Role,
    imageUrl?:string,
    imageCid?:string
    user?:User
}

export enum Role {
    Admin='Admin', Student = 'Student', Teacher='Teacher', Unknown= 'Unknown' ,DataEntryExecutive = 'DataEntryExecutive'
}

export class UserUtils {
    static parseRole(role: string): Role {
        switch (role) {
            case 'Admin':
                return Role.Admin;
            case 'Student':
                return Role.Student;
            case 'Teacher':
                return Role.Teacher;
            case 'DataEntryExecutive':
                return Role.DataEntryExecutive
            default:
                return Role.Unknown;

        }
    }
}