import React, {ChangeEvent, useEffect, useState} from 'react';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { AdminResetStudentPassword } from "../../../../dto/AdminResetStudentPassword";
import { AllToasts } from "../../../../components/toast/AllToasts";
import { useStudentApiClient } from "../../../student/apiClients/StudentApiClient";

interface ChangePasswordModalProps {
    show: boolean;
    onHide: () => void;
    onSave: (newPassword: string, confirmNewPassword: string) => void;
    studentIds: number;
}

const ChangePasswordModal: React.FC<ChangePasswordModalProps> = (props: ChangePasswordModalProps) => {
    const [password, setPassword] = useState<AdminResetStudentPassword>({
        password: '',
        confirmPassword: ''
    });

    const [error, setError] = useState<string>('');

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const name = event.currentTarget.name;
        const value = event.currentTarget.value;
        setPassword((prevState) => ({ ...prevState, [name]: value }));
    }

    const useStudentApi = useStudentApiClient();

    const handleEditPassword = (studentId:number,payload:AdminResetStudentPassword) => {
        if (password.password !== password.confirmPassword) {
            setError('Passwords do not match');
            return;
        }

        useStudentApi.resetPassword(props.studentIds, password)
            .then((response) => {
                console.log('Password changed successfully:', response.data);
                AllToasts.success("Password changed successfully");
                setPassword({
                    password: '',
                    confirmPassword: ''
                });
                setError('');
                props.onHide() ;
            })
            .catch((error) => {
                console.error('Error changing password:', error);
                AllToasts.failure("Error changing password");
                setError('');
                props.onHide() ;
            });
    }

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        handleEditPassword(props.studentIds,password);
    }
    useEffect(()=>{

    },[props.studentIds])

    return (
        <Modal show={props.show} onHide={props.onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title>Change Password</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label htmlFor="newPassword">New Password</label>
                        <input type="password" id="newPassword" className="form-control" name={'password'}
                            value={password.password} onChange={handleChange} required/>
                        <br />
                    </div>
                    <div className="form-group">
                        <label htmlFor="confirmNewPassword">Confirm New Password</label>
                        <input type="password" id="confirmNewPassword" name={'confirmPassword'}
                            className="form-control" value={password.confirmPassword} onChange={handleChange} required/>
                        <br />
                    </div>
                    {error && <p className="text-danger">{error}</p>}
                    <Button variant="primary" type="submit">
                        Save Password
                    </Button>
                </form>
            </Modal.Body>
        </Modal>
    );
};

export default ChangePasswordModal;