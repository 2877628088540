import React, {useEffect, useState} from "react";
import {SessionManager} from "../../../configs/SessionManager";
import {User} from "../../../dto/User";
import {ImageModal} from "./ImageModal";
import './Profile.scss'
import {Route, useNavigate, useParams} from "react-router-dom";
import {StudentDashboard} from "../../../modules/student/screen/dashboard/StudentDashboard";
import {useStudentApiClient} from "../../../modules/student/apiClients/StudentApiClient";
import {useAuth} from "../../../context/UserContext";
import moment from "moment/moment";

enum Tabs {
    Profile = "Profile",
    Payment = "Payment",
    Others = "Others",
}

export interface PaymentData {
    orderId: number,
    courseName: string,
    amount: number,
    purchasedDate: number
    orderStatus: string
}

const Profile = () => {
    const params = useParams();
    const studentApiClient = useStudentApiClient();
    const {userData} = useAuth()
    const [user, setUser] = useState<User | null>();
    const [id, setId] = useState<number>(0)
    const [show, setShow] = useState<boolean>(false);
    const navigate = useNavigate()
    const [imageError, setImageError] = useState(false);
    const [image, setImage] = useState<any>()
    const [uploadStatus, setUploadStatus] = useState(false)
    const [activeTab, setActiveTab] = useState<Tabs>(Tabs.Profile);
    const [paymentData, setPaymentData] = useState<PaymentData[]>([])

    useEffect(() => {
        setUser(SessionManager.getSessionUser());
    }, [params, SessionManager.getSessionUser()?.imageUrl]);

    useEffect(() => {
        getPaymentHistory()
    }, []);

    useEffect(() => {
        if (uploadStatus === true) {
            window.location.reload();
        }
    }, [uploadStatus]);

    useEffect(() => {
        setImage(userData.imageUrl)
    }, [userData]);

    const getInitials = (data: any) => {
        if (data && typeof data === 'string') {
            let initials: any = data.split('');
            if (initials.length >= 2)
                return initials[0].substring(0, 1).toUpperCase() + initials[1].substring(0, 1).toUpperCase();
        }
        return '';
    }

    const getPaymentHistory = () => {
        studentApiClient.getPaymentHistoryForStudent().then((res) => {
            setPaymentData(res.data)
        }).catch((err) => {
            console.log(err)
        })
    }

    const handleUploadStatus = (status: string) => {
        console.log(status)
        if (status === 'Upload successful') {
            setUploadStatus(true)
        } else {
            setUploadStatus(false)
        }
    }

    const handleTabClick = (tab: Tabs) => {
        setActiveTab(tab);
    }

    const handleImageError = () => {
        setImageError(true);
    };

    return <div className={'container-fluid container'}>
        {SessionManager.getSessionUser()?.role === 'Student' && params['*'] === 'profile' &&
            <div className=' mt-3 container'>
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button
                            className={`nav-link ${activeTab === Tabs.Profile ? "active" : ""}`}
                            onClick={() => handleTabClick(Tabs.Profile)}
                            type="button">
                            Profile
                        </button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button
                            className={`nav-link ${
                                activeTab === Tabs.Payment ? "active" : ""}`}
                            onClick={() => handleTabClick(Tabs.Payment)}
                            type="button">
                            Payment History
                        </button>
                    </li>
                </ul>
            </div>
        }
        <div className="m-4 p-4">
            <div>
                <h3>{activeTab}</h3>
            </div>
            {activeTab === Tabs.Profile && (
                <div className="">
                    <div className={'row d-flex justify-content-center mt-4'}>
                        <div className={'card mt-3 p-4 w-75'}>
                            <h6 onClick={() => window.history.back()} style={{cursor: 'pointer'}}>
                                <i className="bi bi-arrow-bar-left me-2"></i>Back
                            </h6>
                            <div className={'d-flex profile_wrap border-bottom'}>
                                <div className={'col-6 d-flex align-items-center border-end mb-4 me-4'}>
                                    <h4>Profile Image</h4>
                                </div>
                                <div className={'col-6'}>
                                    <div className={'mb-3 upload_avatar'}>
                                        {imageError ?
                                            <div className={'initials_avatar'} style={{cursor: "pointer"}}
                                                 onClick={() =>
                                                     navigate(`/${SessionManager.getSessionUser()?.role.toLowerCase()}/profile`)
                                                 }><p>{getInitials(SessionManager.getSessionUser()?.name)}</p></div>
                                            :
                                            <img
                                                src={
                                                    image
                                                    ?? 'https://upload.wikimedia.org/wikipedia/commons/a/ac/No_image_available.svg'
                                                }
                                                onError={handleImageError}
                                                onClick={() =>
                                                    navigate(`/${SessionManager.getSessionUser()?.role.toLowerCase()}/profile`)
                                                }
                                                className="me-2 image_dimes"
                                                alt=""
                                            />
                                        }
                                        <button className={"btn btn-dark rounded-5 px-5 py-2 mx-auto"}>
                                            {/*{user?.role !== 'Admin' && (*/}
                                            <p className={'text-white'}
                                               onClick={() => {
                                                   setShow(true);
                                                   setId(user?.id as any);
                                               }}>Upload</p>
                                            {/*)}*/}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className={'col-lg-12'}>
                                <div className={'input_Field d-flex mt-3'}>
                                    <div className={'col-6'}>
                                        <h5>Name</h5>
                                    </div>
                                    <div className={'col-6'}>
                                        <input className={'form-control'} value={user?.name}/>
                                    </div>

                                </div>
                                <div className={'input_Field d-flex mt-3'}>
                                    <div className={'col-6'}>
                                        <h5>Mobile</h5>
                                    </div>
                                    <div className={'col-6'}>
                                        <input className={'form-control'} value={user?.mobile}/>
                                    </div>
                                </div>
                                <div className={'input_Field d-flex mt-3'}>
                                    <div className={'col-6'}>
                                        <h5>Email</h5>
                                    </div>
                                    <div className={'col-6'}>
                                        <input className={'form-control'} value={user?.email}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {activeTab === Tabs.Payment && (
                <div>
                    <table className="table">
                        <thead>
                        <tr>
                            <th scope="col">Order ID</th>
                            <th scope="col">Course Name</th>
                            <th scope="col">Amount</th>
                            <th scope="col">Date</th>
                            <th scope="col">Order Status</th>
                        </tr>
                        </thead>
                        <tbody>
                        {paymentData.map((order) => (
                            <tr key={order.orderId}>
                                <td>{order.orderId}</td>
                                <td>{order.courseName}</td>
                                <td>{order.amount}</td>
                                <td>{moment(order.purchasedDate).format('DD/MM/YYYY')}</td>
                                <td>{order.orderStatus}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
        <ImageModal show={show} id={id} uploadStatus={(status) => handleUploadStatus(status)}
                    onHide={() => setShow(false)}/>
    </div>
}
export default Profile;

