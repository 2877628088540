import React, {ChangeEvent, FormEvent, useEffect, useState} from 'react';
import LoginStudentUserId from "./LoginStudentUserId";
import {useNavigate} from "react-router-dom";
import {SessionManager} from "../../../../configs/SessionManager";
import {InputUtils} from "../../../../utils/InputUtils";
import {useStudentApi} from "../../../../api/StudentApiClent";
import {LoginScope} from "../../../../dto/Payload";
import logo from "../../../../assets/images/aiDronaLogo.png";
import StudentLoginImg from "../../../../assets/images/studentLoginImg.png";
import './LoginStudent.scss';
import {GOOGLE_CLIENT_ID} from "../../../../common/AppConstants";

interface StudentLoginForInput {
    username: string,
    password: string,
    scope: LoginScope
}

const StudentLogin = () => {
    const [selectedLoginMethod, setSelectedLoginMethod] = useState<string>('userId');
    const navigate = useNavigate();
    const [otpSent, setOtpSent] = useState(false);
    const [payload, setPayload] = useState<StudentLoginForInput>({
        password: '',
        username: '',
        scope: LoginScope.Student
    });
    const studentApi = useStudentApi();
    const [error, setError] = useState<string>();
    const [otpButtonDisabled, setOtpButtonDisabled] = useState<boolean>(false);
    const [loginButtonDisabled, setLoginButtonDisabled] = useState<boolean>(false);

    const handleMethodChange = (method: string) => {
        setSelectedLoginMethod(method);
    };

    useEffect(() => {
        google.accounts.id.initialize({
            client_id: GOOGLE_CLIENT_ID,
            callback: handleCallBackResponse
        })
        google.accounts.id.renderButton(
            document.getElementById("signInDiv") as HTMLElement,
            {
                theme: "filled_blue",
                size: "large",
                type: "standard",
                text: "continue_with",
                logo_alignment: "left",
                shape: "circle"
            }
        );

        google.accounts.id.prompt();
    }, []);

    const handleCallBackResponse = (res: any) => {
        console.log("#41", res.credential);
        login({username: "$GOOGLE$", password: res.credential, scope: LoginScope.Student});
    }

    const sendOtp = () => {
        setError(undefined);
        setOtpButtonDisabled(true);
        studentApi.sendOtp(payload.username).then(res => {
            setOtpSent(true);
        }).catch(err => {
            console.error(err)
            setError("Error sending OTP.")
        }).finally(() => {
            setOtpButtonDisabled(false);
        })
    }
    const login = (payload: StudentLoginForInput) => {
        setError(undefined);
        setLoginButtonDisabled(true);
        studentApi.login(payload).then(res => {
            SessionManager.createSession(res.data.access_token, '', res.data);
            navigate("/student")
        }).catch(err => {
            console.error(err)
            setError(`Login Error: Invalid username`)
        }).finally(() => {
            setLoginButtonDisabled(false);
        })
    }

    const handleChange = (event: ChangeEvent<HTMLDivElement>) => {
        const inp = InputUtils.parseInput(event);
        setPayload((prevState) => ({...prevState, [inp.name]: inp.value}));
    }
    const submitForm = (evt: FormEvent) => {
        evt.preventDefault();
        if (otpSent) {
            login(payload);
        } else {
            sendOtp();
        }
    }

    return (
        <div id={'main_wrapper'} className="loginScreen_wrapper">
            <div className={'container-fluid'}>
                <div className={'row min-vh-100'}>
                    <div className={'col-md-6'}>
                        <div className={'login-wrap'}>
                            <div className={'form-card'}>
                                <img onClick={() => navigate('/')}
                                     style={{width: '79px', height: '65px', cursor: 'pointer'}} src={logo}/>
                                <div className={'login_info_wrap'}>
                                    <h3 className='mb-4 fw-bold'>Welcome to Ai Drona</h3>
                                    <div className={'mb-4 login_head'}>
                                        <div className={'me-auto left_c'}>
                                            <h6 className='text-black-50'>Login to your Student account</h6>
                                        </div>

                                        <div className='right_c'>
                                            <h6 className={'text-black-50 mb-0'}>Don't have an Account ? </h6>
                                            <p style={{cursor: 'pointer'}} className={'mb-0'}><a className='ms-2'
                                                                                                 onClick={() => navigate(`./register`)}> Register</a>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="app-routes w-100">
                                    <div
                                        className="d-flex flex-row align-items-center justify-content-start border-bottom">
                                        <div
                                            className={`userIdButton w-50 ${selectedLoginMethod === 'userId' ? 'text-blue border-blue' : ''}`}
                                            onClick={() => handleMethodChange('userId')}>
                                            <span className="button-label">User ID</span>
                                        </div>
                                        <div
                                            className={`phoneButton me-2 w-50 ${selectedLoginMethod === 'phone' ? 'text-blue border-blue' : ''}`}
                                            onClick={() => handleMethodChange('phone')}>
                                            <span className="button-label">Phone</span>
                                        </div>
                                    </div>
                                    {selectedLoginMethod === 'phone' && (
                                        <form className="mt-4 w-100" onSubmit={submitForm}>
                                            {error && (
                                                <div className="alert alert-danger" role="alert">
                                                    {error}
                                                </div>
                                            )}
                                            <div className="mt-3">
                                                <label className="form-label d-flex" htmlFor="phoneNumber">
                                                    Phone Number: 9279792788
                                                </label>
                                                <input id={'phoneNumber'} placeholder={`Phone number`}
                                                       className='form-control m-auto'
                                                       type='mobile' name={"username"} onChange={handleChange}
                                                       maxLength={10}
                                                       minLength={10}
                                                       pattern={`[0-9]{10}`}
                                                       onInvalid={(e) => e.currentTarget.setCustomValidity("Please enter a 10-digit phone number.")}
                                                       onInput={(e) => e.currentTarget.setCustomValidity("")}
                                                       required
                                                />
                                            </div>
                                            {otpSent && (
                                                <div className="mt-2">
                                                    <label className='form-label d-flex' htmlFor={'otp'}>
                                                        OTP <span className='text-danger'>*</span>
                                                    </label>
                                                    <input
                                                        id={'otp'} placeholder={`OTP`}
                                                        className='form-control m-auto'
                                                        type='text' name={"password"}
                                                        onChange={handleChange} maxLength={6} minLength={6}
                                                        pattern={`[0-9]{6}`}
                                                        onInvalid={(e) => e.currentTarget.setCustomValidity("Please enter a 6-digit OTP.")}
                                                        onInput={(e) => e.currentTarget.setCustomValidity("")}
                                                        required/>
                                                </div>
                                            )}
                                            <div className={'loginBtn mt-5'}>
                                                <button className='btn btn-otp w-100 text-white'
                                                        disabled={otpButtonDisabled || loginButtonDisabled}>{otpSent ? "Login Now" : "Send OTP"}</button>
                                            </div>
                                        </form>
                                    )}
                                    {selectedLoginMethod === 'userId' && <LoginStudentUserId/>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={'col-md-6 right-side_st'}>
                        <img src={StudentLoginImg} height={580}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StudentLogin;
