import React, {FormEvent, useEffect, useState} from "react";
import {Modal, ModalBody, ModalHeader} from "react-bootstrap";
import {useTeacherApiClient} from "../../../apiClients/TeacherApiClient";
import {FlagData} from "../../../../admin/components/subjects/subjectCard/subjectTopic/ReportDropdown";
import LoaderButton from "../../../../../components/button/Button";
import {AllToasts} from "../../../../../components/toast/AllToasts";

export type QuestionErrorProps = {
    show: boolean;
    onHide: () => void;
    onCancel: () => void;
    questionId?: number | undefined;
    onSuccess?: any;
};

export interface FlagPayload {
    flagReasonId: number;
    issue: string;
}

const QuestionError = (props: QuestionErrorProps) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [issueDescription, setIssueDescription] = useState<string>("");
    const teacherApiClient = useTeacherApiClient();
    const [flags, setFlags] = useState<FlagData[]>([]);
    const [selectedFlagReason, setSelectedFlagReason] = useState<number | null>(
        null
    );

    const fetchFlags = () => {
        teacherApiClient
            .getFlags()
            .then((res) => {
                setFlags(res.data);
            })
            .catch((error) => {
                console.error("Error fetching flags", error);
            });
    };

    useEffect(() => {
        if(props.show)
        fetchFlags();
    }, [props.show]);

    const handleCheckboxChange = (flagId: number) => {
        setSelectedFlagReason((prevSelectedFlag) =>
            prevSelectedFlag === flagId ? null : flagId
        );
    };

    const submitFlags = (questionId: number, payload: FlagPayload) => {
        if (selectedFlagReason !== null) {
            payload.flagReasonId = selectedFlagReason;
            payload.issue = issueDescription;
            teacherApiClient
                .submitFlag(String(questionId), payload)
                .then((res) => {
                    setSelectedFlagReason(null);
                    setIssueDescription("");
                    AllToasts.success('Question Flagged')
                })
                .catch(() => {
                    console.error("Failed to raise flag.");
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };

    const handleFormSubmit = (event: FormEvent) => {
        event.preventDefault();
        if (selectedFlagReason === null) {
            return;
        }
        setLoading(true);
        const payload: FlagPayload = {
            flagReasonId: selectedFlagReason,
            issue: issueDescription,
        };
        submitFlags(props.questionId as any, payload);
        setSelectedFlagReason(null);
        setIssueDescription("");
        props.onHide();
    };

    const handleIssueDescriptionChange = (
        event: React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        setIssueDescription(event.target.value);
    };

    const isButtonDisabled = selectedFlagReason === null || issueDescription.trim() === "";

    return (
        <div>
            <Modal
                show={props.show}
                onHide={() => {
                    setSelectedFlagReason(null);
                    setIssueDescription("");
                    props.onHide();
                }}>
                <ModalHeader>
                    <div className={"flex-fill"}>
                        <h4>Report An Issue</h4>
                    </div>
                    <div className={"cursor-pointer"} onClick={props.onCancel}>
                        <i className="bi bi-x fs-4 "></i>
                    </div>
                </ModalHeader>
                <ModalBody>
                    <form onSubmit={handleFormSubmit}>
                        <div className="mt-2">
                            <h6>
                                <b>Select issue type</b>
                            </h6>
                        </div>
                        <div className="m-2">
                            <table>
                                <thead>
                                <tr>
                                    <th></th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody>
                                {flags?.map((flag) => (
                                    <tr key={flag.id}>
                                        <td>
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                value=""
                                                id={`flexCheckDefault_${flag.id}`}
                                                checked={selectedFlagReason === flag.id}
                                                onChange={() => handleCheckboxChange(flag.id)}
                                            />
                                        </td>
                                        <td>{flag.name}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="mt-2">
                            <h6>
                                <b>Write issue</b>
                            </h6>
                        </div>
                        <div className="m-2">
                            <div className="form-group">
                <textarea
                    className="form-control"
                    id="exampleFormControlTextarea1"
                    value={issueDescription}
                    onChange={handleIssueDescriptionChange}>
                </textarea>
                            </div>
                        </div>

                        <div className="mt-2 align-items-end d-flex justify-content-end">
                            <LoaderButton
                                label={"Save"}
                                loading={loading}
                                cssName={"btn btn-sm btn-create"}
                                disabled={isButtonDisabled}/>
                        </div>
                    </form>
                </ModalBody>
            </Modal>
        </div>
    );
};

export default QuestionError;
