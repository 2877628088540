
import {Constants} from "./Constants";
import {SpringToken} from "../modals/Model";
import axios from "axios";
import {useAxios} from "./AxiosClient";

export class AccountRepo {
    axiosClient = useAxios();
    static async refreshAccessToken(refreshToken: string) {
        const data =new FormData();
        data.set("refresh_token",refreshToken);
        data.set("grant_type","refresh_token");
        return new Promise<SpringToken|any>((resolve, reject)=> {
            axios.post<SpringToken>(`${Constants.API_BASE_URL}/listingfy-auth/oauth/token`,data,{
                headers:{'Authorization': `Basic ${Constants.BASIC_TOKEN}`}
            }).then((res) => {
                resolve(res.data);
            }).catch((reason)=> {
                reject(reason);
            })
        });
    }
}
