import React, { FormEvent, useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import { UpdateBatchData, UpdateBatchProps } from "../../../modals/Model";
import {useAdminApiClient} from "../../../modules/admin/apiClients/AdminApiClient";

const UpdateBatches: React.FC<UpdateBatchProps> = (props) => {
    const [payload, setPayload] = useState<UpdateBatchData>({
        id : 0,
        name: '',
    active: false});

    useEffect(() => {
        if (props.updateData) {
            console.log('props',props.updateData)
            setPayload(props.updateData);
        }
    }, [props.updateData]);

    const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setPayload((prevPayload) => ({
            ...prevPayload,
            [name]: value,
        }));
    };
    const  batchApiClient = useAdminApiClient()

    const updateDemo = (id: number, payload: UpdateBatchData) => {
        console.log("#20", id, payload);
        batchApiClient.updateBatch(payload,id)
            .then(() => {
                props.onSuccess();
                props.onHide();
            })
            .catch((error) => {
                console.error("#40 Error:", error);
            });
    };

    const handleSubmitUpdate = (e: FormEvent) => {
        e.preventDefault();
        updateDemo(props.id ?? 0, payload);
    };

    return (
        <div>
            <Modal show={props.show} onHide={props.onHide}>
                <ModalHeader closeButton className="border-bottom">
                    <h5>Edit Batch</h5>
                </ModalHeader>
                <ModalBody>
                    <form onSubmit={handleSubmitUpdate} className="form">
                        <div className="form-group m-1">
                            {/*<label htmlFor="title">Name</label>*/}
                            <input
                                type="text"
                                className="form-control"
                                id="title"
                                name="name"
                                value={payload.name}
                                aria-describedby="title"
                                placeholder="Enter Name"
                                onChange={handleInput}
                            />
                        </div>
                        <div className="d-flex">
                            <div className="flex-fill"></div>
                            <div className="mt-2">
                                <button
                                    type="button"
                                    className="btn btn-cancel me-2"
                                    onClick={props.onHide}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="btn btn-add text-white">
                                    Update
                                </button>
                            </div>
                        </div>
                    </form>
                </ModalBody>
            </Modal>
        </div>
    );
};

export default UpdateBatches;
