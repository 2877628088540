import {Modal, ModalBody} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {useStudentApiClient} from "../../../student/apiClients/StudentApiClient";
import {AllToasts} from "../../../../components/toast/AllToasts";
import Button from "../../../../components/button/Button";

export type EditStudentProps = {
    show: boolean,
    onSuccess: () => void
    onHide: () => void;
    onCancel: () => void;
    studentId: number | undefined
};

export type StudentPayload = {
    name: string;
    mobile?: string | null;
    email: string;
    password: string;
    confirmPassword: string;
    streetAddress: string;
    classSchedule: string;
    schoolName: string;
    createUser: boolean;
    user: User;
}

interface User {
    username: string,
}


const EditStudent = (props: EditStudentProps) => {
    const useStudentApi = useStudentApiClient()
    const MIN_MOBILE_LENGTH = 10;
    const MAX_MOBILE_LENGTH = 10;
    const [mobileValid, setMobileValid] = useState<boolean>(true);
    const [mobileError, setMobileError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [mobileLoading, setMobileLoading] = useState<boolean>(false);
    const [emailLoading, setEmailLoading] = useState<boolean>(false);
    const [studentPayload, setStudentPayload] = useState<StudentPayload>({
        name: '',
        mobile: '' || null,
        email: '',
        password: '',
        confirmPassword: '',
        streetAddress: '',
        classSchedule: '',
        schoolName: '',
        createUser: true,
        user: {
            username: '',
        },

    });


    const getStudentById = (studentId: number) => {
        useStudentApi.getStudentById(props.studentId as any).then((res) => {
            setStudentPayload(res.data)
        })
    }

    useEffect(() => {
        setStudentPayload({
            name: '',
            mobile: '',
            email: '',
            password: '',
            confirmPassword: '',
            streetAddress: '',
            classSchedule: '',
            schoolName: '',
            createUser: true,
            user: {
                username: '',
            },
        })
        if (props.studentId) {
            getStudentById(props.studentId);
        }
    }, [props]);

    const updateStudent = (id: number, payload: StudentPayload) => {
        setLoading(true);
        useStudentApi.updateStudent(id as any, payload)
            .then((res) => {
                console.log('Update success:', res.data);
                props.onCancel();
                props.onSuccess();
                props.onHide();
                AllToasts.success('Student details updated successfully');
            })
            .catch((err) => {
                console.log('Update error:', err);
                AllToasts.failure(`Error ${err.response.data?.reason}`);
            })
            .finally(() => {
                setLoading(false); // Always make sure to stop loading whether it's success or failure
            });
    };


    const handleMobileNumber = (studentId: number, mobile: string) => {
        setMobileLoading(true);
        useStudentApi.updateStudentMobile(studentId, mobile)
            .then((res) => {
                console.log('Mobile Update success:', res.data);
                AllToasts.success('Mobile Updated Successfully');
            })
            .catch((err) => {
                console.log('Mobile Update error:', err);
                AllToasts.failure(`Error ${err.response.data?.detail}`);
            })
            .finally(() => {
                setMobileLoading(false);
            });
    };


    const handleEmail = (studentId: number, email: string) => {
        setEmailLoading(true);
        useStudentApi.updateEmail(studentId, email)
            .then((res) => {
                console.log('Email Update success:', res.data);
                AllToasts.success('Email Updated successfully');
            })
            .catch((err) => {
                console.log('Email Update error:', err);
                AllToasts.failure(`Error ${err.response.data?.detail}`);
            })
            .finally(() => {
                setEmailLoading(false);
            });
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();
        if (studentPayload.mobile && studentPayload.mobile.length > MAX_MOBILE_LENGTH) {
            setMobileValid(false);
            return;
        }
        updateStudent(props.studentId as any, studentPayload)
    }

    return <div>
        <Modal show={props.show} onHide={() => props.onHide()} size="lg">
            <Modal.Header closeButton className="border-bottom">
                <h5>Update student</h5>

            </Modal.Header>
            <ModalBody className="modal_body mx-2">
                <form onSubmit={(e) => handleSubmit(e)}>
                    <div className='ms-2 '>
                        <h5>Login details</h5>
                    </div>
                    <hr/>
                    <div className="form-group m-2  ">
                        <label>UserID</label>
                        <input
                            type="text"
                            className="form-control"
                            value={studentPayload.user?.username}
                            readOnly
                            disabled={true}
                        />
                        <hr/>
                    </div>
                    <div>
                        <div className="form-group m-2 ">
                            <label>Mobile</label>
                            <input
                                type="text"
                                className={`form-control `}
                                placeholder="Enter mobile"
                                onChange={(e) =>
                                    setStudentPayload((prevState) => ({
                                        ...prevState,
                                        mobile: e.target.value,
                                    }))
                                }
                                value={studentPayload.mobile ?? ''}
                            />
                            {!mobileValid && (
                                <div className="invalid-feedback">
                                    Mobile number must be between {MIN_MOBILE_LENGTH} characters.
                                </div>
                            )}
                            {mobileError && (
                                <div className="invalid-feedback">{mobileError}</div>
                            )}
                        </div>
                        <div className='d-flex align-items-center justify-content-end me-2'>
                            <button type='button' className='btn btn-add text-white'
                                    onClick={() => handleMobileNumber(Number(props.studentId), studentPayload.mobile ?? '')}>
                                {mobileLoading ? <div className={'d-flex align-items-center justify-content-between'}>
                                    <span className="spinner-border spinner-border-sm me-1" role="status"
                                          aria-hidden="true"></span>
                                        <div>Update</div>
                                    </div>
                                    : 'Update'}
                            </button>
                        </div>
                        <hr/>
                    </div>
                    <div className="form-group m-2 ">
                        <label>Email</label>
                        <input
                            type="email"
                            className="form-control"
                            placeholder="Enter email"
                            onChange={(e) =>
                                setStudentPayload((prevState) => ({...prevState, email: e.target.value}))}
                            value={studentPayload.email}
                        />
                        <div className='d-flex align-items-center justify-content-end mt-2'>
                            <button type='button' className='btn btn-add text-white'
                                    onClick={() => handleEmail(Number(props.studentId), studentPayload.email)}>
                                {emailLoading ? (
                                    <div className={'d-flex align-items-center justify-content-between'}>
                                        <span className="spinner-border spinner-border-sm me-1" role="status"
                                              aria-hidden="true"></span>
                                        <div>Update</div>
                                    </div>
                                ) : 'Update'}
                            </button>
                        </div>
                        <hr/>
                    </div>
                    <div className="form-group m-2  ">
                        <label>UserID</label>
                        <input
                            type="text"
                            className="form-control"
                            value={studentPayload.user?.username}
                            readOnly
                            disabled={true}
                        />
                        <hr/>
                    </div>

                    <div className='ms-2 mt-3 '>
                        <h5>Personal details</h5>
                    </div>
                    <hr/>
                    <div className="form-group m-2  ">
                        <label>Name <i className="bi bi-asterisk" style={{color: 'red', fontSize: '10px'}}></i></label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Enter name"
                            onChange={(e) => setStudentPayload((prevState) => ({...prevState, name: e.target.value}))}
                            value={studentPayload.name}
                            required
                        />
                    </div>

                    <div className="form-group m-2  ">
                        <label>Address </label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Enter location"
                            onChange={(e) => setStudentPayload((prevState) => ({
                                ...prevState,
                                streetAddress: e.target.value
                            }))}
                            value={studentPayload.streetAddress}

                        />
                    </div>
                    <div className="form-group m-2  ">
                        <label>School name </label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Enter School name"
                            onChange={(e) => setStudentPayload((prevState) => ({
                                ...prevState,
                                schoolName: e.target.value
                            }))}
                            value={studentPayload.schoolName}

                        />
                    </div>
                    <div className="form-group m-2  ">
                        <label>Class </label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Enter class"
                            onChange={(e) => setStudentPayload((prevState) => ({
                                ...prevState,
                                classSchedule: e.target.value
                            }))}
                            value={studentPayload.classSchedule}

                        />
                    </div>

                    <div className='m-3 d-flex justify-content-end'>
                        <Button label={props.studentId ? 'Update' : 'Add'} loading={loading}
                                cssName={'btn btn-add text-white'}/>
                    </div>
                </form>
            </ModalBody>
        </Modal>
    </div>
}
export default EditStudent;