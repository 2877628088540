import React, {useEffect, useState} from "react";
import {Modal, ModalBody, ModalHeader} from "react-bootstrap";
import {useSubjectsApiClient} from "../../../../apiClients/SubjectsApiClient";
import {AllToasts} from "../../../../../../components/toast/AllToasts";
import Button from "../../../../../../components/button/Button";


export type EditTopicProps = {
    subjectId: string,
    show:boolean,
    onSuccess: ()=> void
    onHide: () => void;
    onCancel: ()=> void;
    topicId:number | string | undefined
};

export type TopicPayload = {
    name?: string,
    subjectId?: string,
}

const CreateEditDeleteTopic=(props: EditTopicProps) => {

    const [payload, setPayload] = useState<TopicPayload>({
        name: '',
        subjectId: '',
    });
    const [loading,setLoading]=useState<boolean>(false);

    const handleSubmit = (event: any) => {
        event.preventDefault();
        if (props.topicId) {
            updateTopic(props.topicId, payload);
        } else {
            payload.subjectId = props.subjectId;

            createTopic(props.subjectId, payload);
            console.log('data', props.subjectId);
        }
    };


    const topicApiClient = useSubjectsApiClient() ;

    const createTopic = (subjectId: string, payload: TopicPayload) => {
        setLoading(true);
        topicApiClient.createTopic(subjectId , payload)
            .then((res) => {
                setLoading(false);
                props.onSuccess();
                props.onHide();
                AllToasts.success('Topic added successfully');
                console.log('Success #29');
                console.log(res.data);
            })
            .catch((error) => {
                console.error('Error creating topic:', error);
                setLoading(false);
            });
    };

    const updateTopic = (id: number | string, payload: TopicPayload) => {
        setLoading(true);
        topicApiClient.updateTopic(id, payload)
            .then((res) => {
                setLoading(false);
                console.log('Update success:', res.data);
                props.onSuccess();
                props.onHide();
                AllToasts.success('Topic updated successfully');
            })
            .catch((err) => {
                console.log('Update error:', err);
                setLoading(false);
            });
    };

    const getTopicById = (id: number | string) => {
        topicApiClient.getTopicById(id)
            .then((res) => {
                setPayload(res.data);
            })
            .catch((error) => {
                console.error('Error getting topic by ID:', error);
            });
    };


    useEffect(() => {
        setPayload({
            name: '',
            subjectId : ''
        })
        if (props.topicId) {
            getTopicById(props.topicId);
        }
    }, [props]);

    const handleCancel = () => {
        setPayload({
            name: undefined,
            subjectId: undefined
        })
        props.onCancel();
    };


    return<div>
        <Modal show={props.show} onHide={handleCancel}>
            <ModalHeader>
                <div className={'flex-fill'}><h4>{props.topicId ? 'Update topic' : 'Add topic'}</h4></div>
                <div className={'cursor-pointer'} onClick={handleCancel}><i className="bi bi-x fs-4 "></i></div>
            </ModalHeader>
            <ModalBody>
                <form onSubmit={(e) => handleSubmit(e)}>
                    <div className="form-group m-2">
                        <label htmlFor="exampleInputName">Name</label>
                        <input type="text" className="form-control"
                               id="exampleInputName"
                               aria-describedby="nameHelp"
                               onChange={(e) => setPayload((prevState) => ({...prevState,name: e.target.value}))} value={payload?.name}
                               placeholder="Enter name" required/>
                    </div>
                    <div className={'d-flex mt-3'}>
                        <div className={'flex-fill'}></div>
                        <div>
                            <button  className="btn btn-sm me-2 " onClick={handleCancel}>Cancel</button>
                            <Button label={props.topicId ? 'Update' : 'Add'} loading={loading} cssName={'btn btn-sm btn-create'}/>
                        </div>
                    </div>

                </form>
            </ModalBody>
        </Modal>

    </div>
}
export default CreateEditDeleteTopic ;