import React, {useEffect, useState} from "react";
import CreateUpdateQuestions from "../../createQuestion/CreateUpdateQuestions";
import {Comprehension} from "../../../../../../dto/comprehensionFlaggedQuestions";
import {CreateAssignmentExpandedQuestionListing} from "./CreateAssignmentExpandedQuestionListing";
import {Passage} from "../../../../../../dto/CreateAssignmentQuestionListing";
import {useLocation} from "react-router-dom";
import {VerificationData} from "../../../verification/Verification";
import {useTeacherApiClient} from "../../../../apiClients/TeacherApiClient";
import {Question} from "../../../modals/CreateiUpdateQuestion";
import {CreateVerificationtExpandedQuestionListing} from "./CreateVerificationtExpandedQuestionListing";
import ReadMore from "../../../../../admin/components/studentEvauationScreen/ReadMore";

interface QuestionListProps {
    flaggedQuestion?: Comprehension[];
    comprehensionQue?: Passage[];
    getAllFilteredCompQuestions?: (page?: number, size?: number, sort?: [], assignmentId?: number[]) => void;
    getAllQuestionsForComprehensions?: (topicIds?: number[], subjectId?: number, verified?: VerificationData, query?: string, page?: number, size?: number) => void;
    checkedSelect?: (selectQuestionId: number) => void;
    handleQuestionCheckboxChange?: (selectedId: number, questionText: string[]) => void;
    getAllComprehensions?: (topicIds?: number[], subjectId?: number, verified?: VerificationData, query?: string, page?: number, size?: number) => void;
    selectedOption?: string;
    subjectId?: number;
    topicId?: number[];
    currentPage?: number;
    pageSize?: number;
    getAllCompFlaggedQues?: (query?: string, student?: number[], teacher?: number[], subject?: number[], markedBy?: string, status?: string, page?: number, size?: number) => void;
}

export const CreateAssignmentQuesListing = (props: QuestionListProps) => {
    const [expandedQuestionsData, setExpandedQuestionsData] = useState<{ [key: number]: Question[] }>({});
    const [expandedQuestionIds, setExpandedQuestionIds] = useState<number[]>([])
    const [editQuestionModal, setEditQuestionModal] = useState<boolean>(false);
    const [questionId, setQuestionId] = useState<number | undefined>(undefined);
    const [passageId, setPassageId] = useState<number | undefined>(undefined);
    const [passageText, setPassageText] = useState<string | undefined>('');
    const location = useLocation();
    const teacherApiClient = useTeacherApiClient();
    const [expandedPassageIds, setExpandedPassageIds] = useState<number[]>([]);

    useEffect(() => {
        if (expandedPassageIds && props.selectedOption) {
            expandAllComprehensions()
        }
    }, []);

     const expandAllComprehensions = () => {
         const passageIds = props.comprehensionQue?.map(passage => passage.id) || [];
         setExpandedPassageIds(passageIds);
         passageIds.forEach(passageId => {
                 getQuestionForComp(passageId, props.selectedOption ?? '');
             });
    };

    const togglePassageExpand = (passageId: number) => {
        setExpandedPassageIds(prevIds =>
            prevIds.includes(passageId)
                ? prevIds.filter(id => id !== passageId)
                : [...prevIds, passageId]
        );

        if (!expandedPassageIds.includes(passageId)) {
            if (props.selectedOption) {
                getQuestionForComp(passageId, props.selectedOption);
            }
        }
    };

    const toggleQuestionExpand = (questionId: number) => {
        setExpandedQuestionIds(prevIds => {
            if (prevIds.includes(questionId)) {
                return prevIds.filter(id => id !== questionId);
            } else {
                return [...prevIds, questionId];
            }
        });
    };
    const handleOnSuccess = () => {
        setPassageId(undefined);
        setEditQuestionModal(false);
        setQuestionId(undefined);
        if (props.getAllCompFlaggedQues) props.getAllCompFlaggedQues();
        if (props.getAllQuestionsForComprehensions) {
            props.getAllQuestionsForComprehensions(
                props.topicId,
                props.subjectId,
                props.selectedOption as VerificationData,
                '',
                props.currentPage,
                props.pageSize
            );
        }
        if (props.getAllComprehensions) props.getAllComprehensions();
        setEditQuestionModal(false);
    };

    const getQuestionForComp = (comprehensionId: number, verification: string) => {
        teacherApiClient
            .getComprehensionQuestions(comprehensionId, verification)
            .then(res => {
                // Append the fetched questions to the existing state
                const comprehensionQuestions: { [key: number]: Question[] } = {};
                comprehensionQuestions[comprehensionId] = res.data;
                setExpandedQuestionsData(prevData => ({
                    ...prevData,
                    ...comprehensionQuestions
                }));
                setExpandedQuestionIds(prevState => {
                    const newIdsSet = new Set([...prevState, ...res.data.map((res: any) => res.id)]);
                    return Array.from(newIdsSet);
                });
            })
            .catch(err => {
                console.error(err);
            });
    };

    return (
        <div className='mt-2'>
            {props?.comprehensionQue && props?.comprehensionQue?.length > 0 && props.comprehensionQue?.map((passage, passageIndex) => (
                <div key={passage?.id} onClick={() => {
                    setPassageId(passage.id);
                    setPassageText(passage?.text ?? '---');
                }}>
                    <div
                        style={{backgroundColor: "#F9F9F9", cursor: 'pointer'}}
                        className={'card mb-3'}
                    >
                        <div className="card-header">
                            <div className='d-flex'>
                                <div className='me-3'>
                                    {location.pathname === '/teacher/audit' || location.pathname === '/teacher/Verification' ? ('') :
                                        <input
                                            type="checkbox"
                                            name="questionsId"
                                            style={{backgroundColor: "#CDCDCD"}}
                                            checked={props.checkedSelect?.(passage.id) ?? false}
                                            onChange={() => {
                                                if (props.handleQuestionCheckboxChange) props.handleQuestionCheckboxChange(passage.id, [passage.text ?? '']);
                                                if (props.checkedSelect) props.checkedSelect(passage.id);
                                            }}
                                            className="form-check-inline"
                                        />}
                                </div>
                                <div className='d-flex align-items-start'
                                     onClick={() => togglePassageExpand(passage?.id)}>
                                    <div>{passageIndex + 1}.</div>
                                    <div className="mb-0">
                                        <ReadMore text={passage?.text} imageUrl={passage?.imageUrl} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card-body">
                                <CreateAssignmentExpandedQuestionListing
                                    key={passage?.questions?.map(res => res.id).toString()}
                                    question={passage}
                                    setQuestionId={setQuestionId}
                                    setEditQuestionModal={setEditQuestionModal}
                                    selectedOption={props.selectedOption ?? ''}
                                />

                            {expandedPassageIds.includes(passage.id) &&
                                <div>
                                    {expandedQuestionsData[passage.id] &&
                                        <CreateVerificationtExpandedQuestionListing
                                            question={passage}
                                            toggleQuestionExpand={toggleQuestionExpand}
                                            setQuestionId={setQuestionId}
                                            setEditQuestionModal={setEditQuestionModal}
                                            expandedQuestionId={expandedQuestionIds}
                                            selectedOption={props.selectedOption ?? ''}
                                            expandedQuestionData={expandedQuestionsData[passage.id]}
                                            expandedPassageId={passage.id}
                                            getQuestionForComp={getQuestionForComp}
                                            getAllQuestionsForComprehensions={props.getAllQuestionsForComprehensions}
                                        />
                                    }
                                </div>
                            }

                        </div>
                    </div>
                </div>
            ))}
            <CreateUpdateQuestions
                expandedPassageId={passageId ?? null}
                questionId={questionId}
                show={editQuestionModal}
                passageId={passageId}
                passgeText={passageText ?? ''}
                handleOnSuccess={handleOnSuccess}
                onHide={() => {
                    setPassageId(undefined)
                    setEditQuestionModal(false);
                    setQuestionId(undefined);
                }}
                onSuccess={handleOnSuccess}
            />
        </div>
    );
};
