import { useAdminApiClient } from "../../apiClients/AdminApiClient";
import { useEffect, useState } from "react";
import { User } from "../../../teacher/components/modals/CreateiUpdateQuestion";
import moment from "moment/moment";
import {CreateDataExecutiveModal} from "./CreateDataExecutiveModal";
import {DataExecutiveChangePasswordModal} from "./DataExecutiveChangePasswordModal";

interface CreateDataExecutiveData {
    id:number
    name: string;
    createdDate: string;
    user: User;
}

export const CreateDataExecutive = () => {
    const [data, setData] = useState<CreateDataExecutiveData[]>([]);
    const [showDataEntryExecutiveModal,setShowDataEntryExecutiveModal] = useState<boolean>(false);
    const [executiveId,setExecutiveId] = useState<number | undefined>(undefined)
    const [show,setShow] = useState<boolean>(false)
    const adminApiClient = useAdminApiClient();

    useEffect(() => {
        getDataEntryExecutives();
    }, []);

    const getDataEntryExecutives = () => {
        adminApiClient.getAllDataEntryExecutives().then((res) => {
            setData(res.data);
        }).catch((err) => {
            console.log(err);
        });
    };

    return (
        <div className='m-2'>
            <div className="shadow-sm bg-white mb-3 rounded-2 mt-4 p-3">
                <div className='d-flex align-items-center justify-content-between'>
                    <div>DataEntryExecutives</div>
                    <div>
                        <button className='btn btn-success' onClick={()=>setShowDataEntryExecutiveModal(true)}>Create</button>
                    </div>
                </div>
            </div>
            <div className="shadow-sm bg-white mb-3 rounded-2 mt-4 p-3">
                <table className="table">
                    <thead>
                    <tr>
                        <th>Sr No</th>
                        <th>UserName</th>
                        <th>Name</th>
                        <th>Phone</th>
                        <th>Email</th>
                        <th>Created Date</th>
                        <th>Change Password</th>
                    </tr>
                    </thead>
                    <tbody>
                    {data?.map((item, index) => (
                        <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item.user.username}</td>
                            <td>{item.name}</td>
                            <td>{item.user.mobile ?? '---'}</td>
                            <td>{item.user.email ?? '---'}</td>
                            <td>{moment(item.createdDate).format('DD-MM-YYYY')}</td>
                            <td><i onClick={()=>{
                                setExecutiveId(item.id)
                                setShow(true)}} className={'bi bi-pencil-square cursorPointer'}/></td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
            <CreateDataExecutiveModal show={showDataEntryExecutiveModal} onHide={()=>setShowDataEntryExecutiveModal(false)}
                                      onSuccess={()=>getDataEntryExecutives()}
            />
            <DataExecutiveChangePasswordModal show={show} onHide={()=>setShow(false)} executiveId={executiveId ?? 0}/>
        </div>
    );
};
