import {MenuItem} from "../../../../modals/MenuItem";
import {Route, Routes, useParams} from "react-router-dom";
import StudentQuiz from "../../components/StudentQuiz";
import {useEffect, useState} from "react";
import Sidebar from "../../../../components/common/sidebar/Sidebar";
import {Header} from "../../../../components/common/header/Header";
import {useStudentApiClient} from "../../apiClients/StudentApiClient";
import './StudentDashboard.scss';
import mathImg from "../../../../assets/images/maths (1) 2.png";
import dashImg from "../../../../assets/images/dashboard (1) 2.png";
import book from "../../../../assets/images/book.svg";
import liked from "../../../../assets/images/heart.svg";
import topics from "../../../../assets/images/journal-bookmark.svg";
import sciImg from "../../../../assets/images/atom (1) 2.png";
import engImg from "../../../../assets/images/book 3.png";
import gkImg from "../../../../assets/images/social-science (1) 1.png";
import StudentChapter from "../../components/chapter/StudentChapter";
import Instruction from "../../components/instruction/Instruction";
import Quiz from "../../components/quiz/quizQuestions/Quiz";
import {StudentAssignment} from "../../components/assignment/StudentAssignment";
import {StudentAllAssignments} from "../../components/assignment/studentAllAssignments/StudentAllAssignments";
import StudentTopics from "../../components/topic/StudentTopics";
import ResultScreen from "../../components/quiz/result/ResultScreen";
import AllTest from "../../components/test/alltests/AllTest";
import TestReview from "../review/TestReview";
import {LikedQuestion} from "../../components/likedQuestion/LikedQuestion";
import Profile from "../../../../components/common/Profile/Profile";
import CompInstruction from "../../components/instruction/CompInstruction";
import CompQuiz from "../../components/quiz/CompQuiz/CompQuiz";

export const StudentDashboard = () => {
    const studentApiClient = useStudentApiClient();
    const params = useParams();
    const [menuItems, setMenuItems] = useState<MenuItem[]>([]);
    const [sidebarCollapsed, setSidebarCollapsed] = useState<boolean>(true);
    const functionWithSwitch = (subject?: string) => {
        switch (subject) {
            case "maths":
                return mathImg
            case "science":
                return sciImg
            case "english":
                return engImg
            case "gk":
                return gkImg
            default:
                return mathImg;
        }
    }

    const getAllProgramsAccToBatch = () => {
        studentApiClient.getAllPrograms(params.courseBatchId ?? null as any).then(res => {
            const items: MenuItem[] = [];
            const item: MenuItem = {id: "dashboard",
                                    name: "Dashboard",
                                    icon: dashImg,
                                    path: `/student/course-batch/${params.courseBatchId}` };
            items.push(item);
            for (const course of res.data) {
                const item: MenuItem = {
                    id: course.id, name: course.name, icon: functionWithSwitch(course.icon), subMenuItems: [{
                        id: course.id + "_chapter",
                        name: "Chapters",
                        icon: book,
                        path: "program/" + course.id + "/chapter"
                    }, {
                        id: course.id + "_topics",
                        name: "Topics",
                        icon: topics,
                        path: "program/" + course.id + "/topics"
                    },
                        {
                        id: `${course.id} _liked_Question`,
                        name: "Liked Questions",
                        icon: liked,
                        path: "program/" + course.id + "/likedQuestions"
                    }
                    ]
                };
                items.push(item);
            }
            setMenuItems(items);
        }).catch((err) => {
            console.log(err)
        })
    }

    useEffect(() => {
        getAllProgramsAccToBatch();
    }, []);

    return <div className='wrapper'>
        <Sidebar menuItems={menuItems} moduleName={'Student'} collapsedSidebar={sidebarCollapsed}
                 onClose={(newState)=> setSidebarCollapsed(newState)}/>
        <Header collapsedSidebar={sidebarCollapsed} onClick={(newState) => setSidebarCollapsed(newState)}/>
            <div className={`${sidebarCollapsed? 'main_content_wrap': 'collapsed_main_wrap'}`} >
                <Routes>
                    {/*<Route path={"/profile"} element={<Profile/>}/>*/}
                    <Route path={"/quiz"} element={<StudentQuiz/>}/>
                    <Route path={"/program/:programId/chapter"} element={<StudentChapter/>}/>
                    <Route path={"/program/:programId/topics"} element={<StudentTopics/>}/>
                    <Route path="/:testType/:testId/instructions" element={<Instruction />} />
                    <Route path="/:testType/:testId/comp-instructions" element={<CompInstruction />} />
                    <Route path={"/:testType/:testId"} element={<Quiz/>}/>
                    <Route path={"/:testType/:testId/comp"} element={<CompQuiz/>}/>
                    <Route path={"/:testType/:testId/result/:resultId"} element={<ResultScreen/>}/>
                    <Route path={"/"} element={<StudentAssignment/>}/>
                    <Route path={"/assignments"} element={<StudentAllAssignments/>}/>
                    {/*<Route path={"/tests"} element={<AllTest/>}/>*/}
                    <Route path={"/:test"} element={<AllTest/>}/>
                    <Route path={"/:Test/review/:resultId"} element={<TestReview/>}/>
                    <Route path={`/program/:programId/likedQuestions`} element={<LikedQuestion/>}/>
                </Routes>
            </div>
    </div>
}
