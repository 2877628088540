import {Page} from "../utlis/Page";
import {TeacherCreateQuestions} from "../modal/CreateQuestions";
import {CreateTestData} from "../modal/CreateTestModal";
import {useAxios} from "../../../configs/AxiosClient";
import {FlagPayload} from "../../student/components/quiz/quizQuestions/Questions";
import {Questions} from "../components/modals/CreateiUpdateQuestion";
import {AuditMode} from "../components/audit/Audit";
import {MarkQuestionResolved} from "../components/createAssignment/createQuestion/resolveQuestion/ResolveQuestion";
import {ComprehensionPayload} from "../../../dto/ComprehensionQuestion";
import {VerificationData} from "../components/verification/Verification";

export const useTeacherApiClient = () => {
    const axiosClient = useAxios();

    const getTeacherProfile = () => {
        return axiosClient.get(`/teachers/me`);
    }

    const createQuestions = (payload: Questions, comprehensionId?: number) => {
        return axiosClient.post('/questions', payload, {
            params: {
                comprehensionId: comprehensionId
            }
        })
    }

    const getAllQuestions = (page?: number, size?: number, sort?: string[], query?: string, includeActive?: boolean,
                             testId?: number[], courseId?: number, flagged?: boolean, programId?: number
        , chapterId?: number[], topicId?: number[], subject?: number[], verified?: string, assignmentId?: number[]) => {
        const queryParams: any = {
            page: page,
            size: size,
            sort: sort,
            query: query,
            includeActive: includeActive,
            testId: testId && testId.join(','),
            courseId: courseId,
            flagged: flagged,
            programId: programId,
            chapterId: chapterId,
            topicId: topicId,
            subject: subject && subject.join(','),
            verified: verified,
            assignmentId: assignmentId && assignmentId.join(',')
        };

        if (chapterId && chapterId.length > 0) {
            if (chapterId.length === 1) {
                queryParams[`chapterId`] = chapterId[0];
            } else {
                queryParams[`chapterId`] = chapterId.join(',');
            }
        }

        if (topicId && topicId.length > 0) {
            if (topicId.length === 1) {
                queryParams[`topicId`] = topicId[0];
            } else {
                queryParams[`topicId`] = topicId.join(',');

            }
        }

        return axiosClient.get<Page<TeacherCreateQuestions>>('/questions', {params: queryParams});
    }

    const getVerifiedAndUnverifiedQuestions = (page?: number, size?: number, sort?: string[], query?: string, subjectId?: number, topicIds?: number[], verified?: string) => {
        const queryParams: any = {
            page: page,
            size: size,
            sort: sort,
            query: query,
            subjectId: subjectId,
            topicIds: topicIds && topicIds.join(','),
            verified: verified,
        };

        return axiosClient.get<Page<TeacherCreateQuestions>>('/questions/verification', {params: queryParams});
    }


    const updateQuestion = (id: number, payload: TeacherCreateQuestions) => {
        return axiosClient.put(`/questions/${id}`, payload);
    }

    const getQuestionById = (id: number) => {
        return axiosClient.get((`/questions/${id}`))
    }

    const createTest = (courseId: number, payload: CreateTestData) => {
        return axiosClient.post(`/courses/${courseId}/assignment`, payload)
    }

    const getAllTest = (courseId: string) => {
        return axiosClient.get(`/courses/${courseId}/tests`);
    }

    const getFlags = () => {
        return axiosClient.get(`/flag`)
    }

    const submitFlag = (questionId: string, payload: FlagPayload) => {
        return axiosClient.post(`/questions/${questionId}/flag`, payload)
    }

    const uploadImage = (file: File) => {
        const formData = new FormData();
        formData.append('file', file);
        return axiosClient.post(`/upload/media`, formData);
    };

    const getAllFlagQuestions = (query?: string, student?: number[], teacher?: number[], subject?: number[],
                                 markedBy?: AuditMode, status?: string, page?: number, size?: number,sortField?:string) => {
        return axiosClient.get<Page<TeacherCreateQuestions>>('/flag-questions', {
            params: {
                query: query,
                student: student,
                teacher: teacher,
                subject: subject && subject.join(','),
                markedBy: markedBy,
                status: status,
                page: page,
                size: size,
                sortField:sortField
            }
        });
    }

    const verifyQuestion = (id: number) => {
        return axiosClient.post(`/questions/${id}/verify`);
    }
    const unVerifyQuestion = (id: number) => {
        return axiosClient.post(`/questions/${id}/un-verify`);
    }
    const MarkQuestionResolved = (id: number, payload: MarkQuestionResolved) => {
        return axiosClient.post(`/questions/${id}/resolved`, payload)
    }
    const uploadProfile = (userId: number, file: File) => {
        const formData = new FormData();
        formData.append('file', file);
        return axiosClient.post(`/user/${userId}/upload`, formData)
    }
    const getAllQuestionDetails = () => {
        return axiosClient.post('/question-details')
    }

    const questionDelete = (questionId: number) => {
        return axiosClient.delete(`/questions/${questionId}/status`)
    }

    const undoQuestion = (questionId : number) => {
        return axiosClient.post(`/questions/${questionId}/status`)
    }

    const getAllBatchesForAssignment = (assignmentId: number) => {
        return axiosClient.get(`/assignments/${assignmentId}/batches`)
    }
    const getStudentsByAssignmentAndBatch = (assignmentId: number, batchId: number, name?: string, page?: number, size?: number) => {
        return axiosClient.get(`/assignments/${assignmentId}/batches/${batchId}/get-students`, {
            params: {
                name: name,
                page: page,
                size: size,
            }
        })
    }

    const createComprehension = (payload: ComprehensionPayload) => {
        return axiosClient.post(`/comprehension`, payload)
    }

    const updateComprehension = (comprehensionId: number, payload: ComprehensionPayload) => {
        return axiosClient.put(`/comprehension/${comprehensionId}`, payload)
    }

    const getSingleComprehension = (comprehensionId: number) => {
        return axiosClient.get(`/comprehension/${comprehensionId}`)
    }

    const getAllFlaggedCompQuestions = (page?: number, size?: number, subjects?: number[], sort?: string[], query?: string,sortField?:string,markedBy?:AuditMode) => {
        return axiosClient.get(`/flag-comprehensions`, {
            params: {
                page: page,
                size: size,
                subjects: subjects && subjects.join(','),
                sort: sort,
                query: query,
                sortField:sortField,
                markedBy:markedBy
            }
        })
    }

    const getAllComprehensions = (page?: number, size?: number, query?: string, courseId?: number, programId?: number[], chapterId?: number[], topicId?: number[], subject?: number[]) => {
        return axiosClient.get(`/comprehensions`, {
            params: {
                page: page,
                size: size,
                query: query,
                courseId: courseId,
                programId: programId && programId.join(','),
                chapterId: chapterId && chapterId.join(','),
                topicId: topicId && topicId.join(','),
                subject: subject && subject.join(',')
            }
        })
    }

    const getAllVerificationComprehensions = (topicIds?: number[], subjectId?: number, verification?: VerificationData, query?: string, page?: number, size?: number) => {
        return axiosClient.get(`/comprehension`, {
            params: {
                topicIds: topicIds && topicIds.join(','),
                subjectId: subjectId,
                verification: verification,
                query: query,
                page: page,
                size: size,
            }
        })
    }

    const getComprehensionQuestions = (comprehensionId: number, verification: string) => {
        return axiosClient.get(`/comprehensions/${comprehensionId}/verified-questions`, {params: {verification: verification}})
    }

    const getAllAuditComprehensionQuestions = (comprehensionId: number) => {
        return axiosClient.get(`/comprehensions/${comprehensionId}/questions`)
    }

    const getAuditComprehensionQuestionFlagId = (questionId: number,markedBy?:AuditMode) => {
        return axiosClient.get(`/questions/${questionId}/flagged-questions`,{params:{markedBy:markedBy}})
    }

    const createAssignment = (courseId: number, payload: CreateTestData) => {
        return axiosClient.post(`/courses/${courseId}/comp-assignment`, payload)
    }

    const getAllFilteredComprehensions = (page?: number, size?: number, sort?: [], assignmentId?: number[]) => {
        return axiosClient.get(`/comprehensions/filter`, {
            params: {
                page: page,
                size: size,
                sort: sort,
                assignmentId: assignmentId && assignmentId.join(',')
            }
        })
    }

    return {
        createQuestions,
        createTest,
        updateQuestion,
        getQuestionById,
        getAllQuestions,
        getTeacherProfile,
        getAllTest,
        submitFlag,
        getFlags,
        uploadImage,
        getAllFlagQuestions,
        verifyQuestion,
        MarkQuestionResolved,
        uploadProfile,
        questionDelete,
        getAllBatchesForAssignment,
        getStudentsByAssignmentAndBatch,
        unVerifyQuestion,
        getAllQuestionDetails,
        getVerifiedAndUnverifiedQuestions,
        createComprehension,
        getSingleComprehension,
        updateComprehension,
        getAllFlaggedAuditComprehensions: getAllFlaggedCompQuestions,
        getAllComprehensions,
        getAllVerificationComprehensions,
        createAssignment,
        getAllFilteredComprehensions,
        getComprehensionQuestions,
        getAllAuditComprehensionQuestions,
        getAuditComprehensionQuestionFlagId,
        undoQuestion
    }
}

