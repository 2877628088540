import {Form} from "react-bootstrap";
import React from "react";
import './CustomeSwitcher.scss'

export type CustomSwitcherData = {
    active: boolean,
    activeLabel?: string,
    inactiveLabel?: string,
    onChange: () => void
}

const CustomSwitcher2 = (props: CustomSwitcherData) => {

    return <Form>
        <Form.Check
            className={'d-flex align-items-center ps-0'}
            type="switch"
            label={props.active ? props?.activeLabel ?? 'Active' : props?.inactiveLabel ?? 'Deactive'}
            checked = {props.active}
            defaultChecked={props.active}
            id="custom-switch"
            onClick={() => {
                props.onChange();
            }}
        />

    </Form>
}

export default CustomSwitcher2;