import {Modal, ModalBody, ModalHeader} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {CourseRepo} from "../../../../../../repos/CourseRepo";
import {AllToasts} from "../../../../../toast/AllToasts";
import {TopicsData} from "../../../../../../modules/admin/components/subjects/subjectCard/subjectTopic/Topics";

interface EditTopicModalProps {
    show: boolean,
    onHide: () => void,
    topicId: number
}
interface TopicPayload {
    name:string
}
export const EditTopicModal = (props: EditTopicModalProps) => {
    const [data,setData] = useState<TopicPayload>({
        name:'',
    })

    useEffect(() => {
        console.log('data',data)
    }, [data]);

    useEffect(() => {
        if(props.topicId)
        getSingleTopicById(props.topicId)
    }, [props]);

    const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setData((prevPayload) => ({
            ...prevPayload,
            [name]: value,
        }));
    };

    const getSingleTopicById = (topicId: number) => {
        CourseRepo.getTopicById(topicId).then((res) => {
            setData(res.data)
        }).catch((err) => {
            console.log(err)
        })
    }

    const updateTopicName = (topicId: number, name: TopicPayload) => {
        CourseRepo.updateTopic(topicId, name).then((res) => {
            AllToasts.success('Topic Updated Successfully');
            props.onHide()
        }).catch((err) => {
            console.log(err)
            AllToasts.failure('Error updating')
        })
    }

    const handleSubmit =()=>{
        updateTopicName(props.topicId,data)
    }

    return <Modal show={props.show} onHide={props.onHide}>
        <ModalHeader closeButton>
            <h6>Update Topic</h6>
        </ModalHeader>
        <ModalBody>
            <div>
                <label className={'form-label'}>Topic Name</label>
                <input type={'text'} name={'name'} className={'form-control'} value={data?.name}
                       onChange={(e)=>handleInput(e)}/>
            </div>
            <div className='d-flex align-items-center justify-content-end mt-2'>
                <button className='btn btn-secondary me-2' onClick={()=>props.onHide()}>Close</button>
                <button className='btn btn-primary' onClick={handleSubmit}>Update</button>
            </div>
        </ModalBody>
    </Modal>
}