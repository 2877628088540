import {useEffect, useRef} from "react";
import {Chart, ChartConfiguration} from "chart.js";

interface PieChartProps {
    correctCount: number;
    wrongCount: number;
    skippedCount: number;
}

export const PieChart=(props:PieChartProps)=>{

    const chartRef=useRef<HTMLCanvasElement>(null);

    const chartInstanceRef = useRef<Chart | null>(null);
    useEffect(() => {
        if (chartRef.current) {
            const ctx = chartRef.current.getContext('2d');
            if (ctx) {
                if (chartInstanceRef.current) {
                    chartInstanceRef.current.destroy();
                }

                const chartConfig: ChartConfiguration = {
                    type: 'pie',
                    data: {
                        // labels: ['Correct', 'Wrong', 'Skipped'],
                        datasets: [
                            {
                                data: [props.correctCount, props.wrongCount, props.skippedCount],
                                backgroundColor: ['#4ECB71', '#FF9A62', '#85B6FF'],
                            },
                        ],
                    },
                };

                chartInstanceRef.current = new Chart(ctx, chartConfig);
            }
        }
    }, [props.correctCount, props.wrongCount, props.skippedCount]);


    return <canvas ref={chartRef} />;
}