import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useSubjectsApiClient} from "../../../../apiClients/SubjectsApiClient";
import CreateEditDeleteTopic from "./CreateEditDeleteTopicModal";
import {ConfirmationDialog} from "../../../../../../components/confirmationDialog/ConfirmationDialog";
import Pagination from "../../../../../../components/pagination/Pagination";


export interface TopicsData {
    id: string | number,
    name: string,
}

const Topics = () => {
    const [show, setShow] = useState<boolean>(false);
    const [topicPayload, setTopicPayload] = useState<TopicsData[]>([]);
    const [topicId, setTopicId] = useState<number | string | undefined>(undefined);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(10);
    const [totalElements, setTotalElements] = useState<number>(0);
    const [showDelete, setShowDelete] = useState<boolean>(false);
    const [query, setQuery] = useState<string>('')
    const topicsApiClient = useSubjectsApiClient();
    const params = useParams<any>();

    useEffect(() => {
        getTopicsById(params.id || '');
    }, [query]);


    const getTopicsById = (subjectId: string | number, size?: number, page?: number) => {
        topicsApiClient.getTopicsById(subjectId, size, page, query).then((res) => {
            setTopicPayload(res.data.content);
            setCurrentPage(res.data.number);
            setTotalPages(res.data.totalPages);
            setTotalElements(res.data.totalElements);
        }).catch((error) => {
            console.error("Error fetching topics data:", error);
        });
    }

    const deleteAccount = (topicId: any) => {
        topicsApiClient.deleteAccount(topicId ?? 0)
            .then((res) => {
                getTopicsById(params?.id || '');
                setShowDelete(false);
            })
            .catch((err) => {
                console.error(err);
                setShowDelete(false);
            });
    };

    const onAction = (isConfirm: boolean) => {
        if (!isConfirm) {
            setShowDelete(false)
        } else {
            deleteAccount(topicId);
        }
    }

    const [data, setData] = useState<any>('');


    useEffect(() => {
        getSubjectData(params?.id || '');
    }, []);

    const getSubjectData = (id: string) => {
        topicsApiClient.getSubjectById(id).then((res: any) => {
            setData(res.data);
        })
    }

    const navigate = useNavigate();

    return <div className='container-fluid page-content'>
        <div className="row page-titles mx-0 shadow-sm">
            <div className="col-sm-6 p-md-0">
                <div className="welcome-text">
                    <h4>All Topics</h4>
                </div>
            </div>
            <div className="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item d-flex text-primary" aria-current="page"
                        onClick={() => navigate(`/admin/subjects`)}
                        style={{cursor: 'pointer'}}>{data.subject?.name}</li>
                    <li className="breadcrumb-item">All Topics</li>
                </ol>
            </div>
        </div>
        <div className={'card border-0 shadow-sm'}>
            <div className={'card-body'}>
                <div className={'d-md-flex justify-content-between align-items-center'}>
                    <div className="col-md-6 px-0">
                        <div className="form-group searchBar position-relative">
                            <input
                                type={"search"}
                                className="form-control w-100 formControl"
                                placeholder={"Search here ..."}
                                onChange={(event) => setQuery(event.target.value)}
                            />
                            <button className={'bg-transparent transition p-0 border-0'}><i
                                className="bi bi-search"></i></button>
                        </div>
                    </div>

                    <div>
                        <button className={"btn btn-primary text-white rounded-1 btn-add"}
                                onClick={() => setShow(true)}><i className="bi bi-plus-lg"></i> Add topic
                        </button>
                    </div>
                </div>
                <div className={'table-responsive'}>
                    <table className="table mt-4">
                        <thead>
                        <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Actions</th>
                        </tr>
                        </thead>

                        <tbody>
                        {topicPayload?.map((res: TopicsData) => (
                            <tr key={res.id}>
                                <td>{res.name}</td>
                                <td>
                                    <button className="btn" onClick={() => {
                                        setTopicId(res.id);
                                        setShow(true)
                                    }}><i className="bi bi-pencil text-primary"></i></button>
                                    <button className="btn" onClick={() => {
                                        setTopicId(res.id);
                                        setShowDelete(true)
                                    }}><i className="bi bi-trash text-danger"></i></button>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>

                <Pagination currentPage={currentPage} totalPages={totalPages} totalElements={totalElements}
                            onPageChange={(page) => getTopicsById(params.id || '', pageSize, page)}/>
            </div>
        </div>


        <CreateEditDeleteTopic
            subjectId={params.id || ''}
            topicId={topicId}
            show={show}
            onHide={() => {
                setShow(false);
                setTopicId('');
            }}
            onCancel={() => {
                setShow(false)
                setTopicId(undefined);
            }}
            onSuccess={() => {
                setTopicId('');
                getTopicsById(params.id || ''); // Pass the correct subject ID
                setShow(false);
            }}
        />

        <ConfirmationDialog title={'Delete Topic'} description={"Are you sure you want to delete this topic?"}
                            show={showDelete} onAction={onAction}
                            onClose={() => setShowDelete(false)}/>


    </div>
}
export default Topics;