import {useAxios} from "../../../configs/AxiosClient";
import {CourseBatch, CourseProgram} from "../../../dto/CourseBatch";
import {answer} from "../../../modals/quizquestionData/Modal";
import {FlagPayload} from "../components/quiz/quizQuestions/Questions";
import {AdminResetStudentPassword} from "../../../dto/AdminResetStudentPassword";
import {BatchIdPayload} from "../../teacher/components/courseListing/course/assignment/BatchAssignment";
import {CouponRequest} from "../screen/home/courses/CourseDetails";

export const useStudentApiClient = () => {
    const axiosClient = useAxios();
    const getStudentProfile = () => {
        return axiosClient.get('/students/me');
    }
    const addStudent = (payload: any) => {
        return axiosClient.post('/students', payload);
    }
    const getStudent = (page?: number, size?: number, query?: string) => {
        return axiosClient.get('/students', {
            params: {
                page: page,
                size: size,
                query: query,
            }
        });
    }
    const softDeleteStudent = (studentId: number | undefined) => {
        return axiosClient.delete(`/students/${studentId}/active`);
    };
    const studentBatchDetail = (studentId: number | undefined) => {
        return axiosClient.get(`/students/${studentId}/batches`);
    };
    const restoreStudent = (studentId: number | undefined) => {
        return axiosClient.post(`/students/${studentId}/active`);
    };

    const updateStudent = (studentId: string, payload: any) => {
        return axiosClient.put(`/students/${studentId}`, payload)
    }

    const updateStudentMobile = (studentId:number,mobile:string) => {
        return axiosClient.put(`/students/${studentId}/update-mobile`,{mobile})
    }

    const getStudentById = (studentId: number) => {
        return axiosClient.get(`/students/${studentId}`)
    }
    const getAllProgramChapters = (programId: number) => {
        return axiosClient.get(`/programs/${programId}/chapters`)
    }
    const getAllProgramTopics = (programId: number) => {
        return axiosClient.get(`/programs/${programId}/topics`)
    }

    const getAllStudentCourseBatches = (type?:string) => {
        return axiosClient.get<CourseBatch[]>("/students/me/courses",{
            params:{
                type:type
            }
        });
    }

    const getStudentByCourseId = (courseId: number) => {
        return axiosClient.get(`/courses/${courseId}`)
    }

    const getAllPrograms = (courseId: number) => {
        return axiosClient.get<CourseProgram[]>(`/courses/${courseId}/programs`,);
    }

    const createTest = (courseId: number, topicIds: number[],programId:number, type?: string,percentage?:number,numberOfQuestions?:number) => {
        return axiosClient.post(`/courses/${courseId}/tests`, {topicId: topicIds,programId:programId, type: type, percentage:percentage,numberOfQuestions:numberOfQuestions})
    }

    const createCompTest = (courseId : number , topicIds : number[] , programId : number , type?: string , numberOfComprehension ?: number) => {
        const data = {
            topicIds : topicIds , programId : programId , type : type , numberOfComprehension : numberOfComprehension
        }
        return axiosClient.post(`/courses/${courseId}/comprehension-test` , data)
    }

    const getAllTestByCourseId = (courseId: number, query?: string, size?: number, page?: number) => {
        return axiosClient.get(`/tests`, {
            params: {
                size: size,
                page: page,
                query: query
            }
        })
    }

    const getLatestTestByCourseId = (course: number, status?: string, query?: string, size?: number, page?: number) => {
        return axiosClient.get(`/my-assignments`, {
            params: {
                course: course,
                status: status,
                size: size,
                page: page,
                query: query
            }
        })
    }

    const getStudentPreviousTest = (courseId:number,testType?:string,page?:number,size?:number,sort?:string[]) => {
        return axiosClient.get(`/courses/${courseId}/assignments-students`,{
            params: {
                testType:testType,
                page: page,
                size: size,
                sort:sort
            }
        })
    }

    const getAllTestQuestions = (testId: number) => {
        return axiosClient.get(`/test/${testId}`,)
    }

    const getAllTestComp = (comprehensionTestId : number) => {
        return axiosClient.get(`/comprehension-test/${comprehensionTestId}`)
    }

    const getAllAssignmentQuestions = (assignmentId: number) => {
        return axiosClient.get(`/assignments/${assignmentId}`,)
    }

    const getAllMockTestQuestions = (mockTestId: number) => {
        return axiosClient.get(`/test/${mockTestId}/mock-test`)
    }

    const getAllMockTestQuestionsComp = (comprehensionTestId: number) => {
        return axiosClient.get(`/comprehension-test/${comprehensionTestId}/mock-test`)
    }

    const getAllBatch = (courseId: number,page?:number,size?:number,query?:string) => {
        return axiosClient.get(`/courses/${courseId}/batches`, {
            params: {
                page:page,
                size:size,
                query:query
            }
        })
    }

    const assignTestToBatch = (assignmentId: number, payload: BatchIdPayload) => {
        return axiosClient.post(`/assignments/${assignmentId}/batches`, payload)
    }
    const testEvaluation = (testId: number, answers: answer[]) => {
        return axiosClient.post(`/test/${testId}/evaluate`, {answers: answers})
    }

    const assignmentEvaluation = (assignmentId:number,answers: answer[]) => {
        return axiosClient.post(`/assignments/${assignmentId}/evaluate`,{answers:answers})
    }

    const getTestResult = (testId: number) => {
        return axiosClient.get(`/tests/${testId}/evaluation`);
    }

    const getTestResults = (id: number) => {
        return axiosClient.get(`/tests/${id}/evaluate`)
    }
    const getStudentTestResult = (resultId: number) => {
        return axiosClient.get(`/tests/result/${resultId}/evaluate`)
    }
    const getAssignmentResult = (id: number) => {
        return axiosClient.get(`/assignments/${id}/evaluate`)
    }

    const getFlags = () => {
        return axiosClient.get(`/flag`)
    }

    const submitFlag = (questionId: string, payload: FlagPayload) => {
        return axiosClient.post(`/questions/${questionId}/flag`, payload)
    }

    const likeQuestion = (questionId: number) => {
        return axiosClient.post(`/questions/${questionId}/like`)
    }

    const unLikeQuestion = (questionId: number) => {
        return axiosClient.delete(`/questions/${questionId}/un-like`)
    }

    const changeFlag = (studentId: string) => {
        return axiosClient.post(`/student/${studentId}/flag`)
    }

    // const getPreviousTest = (courseID:number,type?:string,testType?:string,size?: number, page?: number) => {
    //     return axiosClient.get(`/courses/${courseID}/tests-students`, {
    //         params: {
    //             type:type,
    //             testType:testType,
    //             size: size,
    //             page: page,
    //         }
    //     })
    // }

    const getPreviousTest = (courseId: number, testType?: string, size?: number, page?: number) => {
        return axiosClient.get(`/student/courses/${courseId}/tests`, {
            params: {
                testType: testType,
                size: size,
                page: page
            }
        });
    };

    const resetPassword = (studentId: any, payload: AdminResetStudentPassword) => {
        return axiosClient.post(`/users/${studentId}`, payload)
    }

    const getAllCoursesForStudent = (page?: number, size?: number , query?: string) => {
        return axiosClient.get(`/students/courses`, {
            params: {
                page: page,
                size: size,
                query: query
            }
        })
    }

    const studentReport = (studentId: number,courseId:number, fromDate: any, toDate: any) => {
        return axiosClient.get(`/students/${studentId}/pdf`, {
            params: {courseid:courseId, fromDate: fromDate, toDate: toDate}, responseType: 'blob'
        });
    };

    const assignCourseToStudentByStudent = (courseId: number) => {
        return axiosClient.post(`/courses/${courseId}/student`)
    }

    const unAssignCourseByStudent = (courseId: number) => {
        return axiosClient.delete(`courses/${courseId}/students`)
    }

    const getTotalMarksForStudent = (courseId: number) => {
        return axiosClient.post(`/courses/${courseId}/program-coverage`)
    }

    const assignmentCount = (courseId: number) => {
        return axiosClient.get(`courses/${courseId}/count-of-assignment`)
    }

    const getStudentRank = (courseId: number) => {
        return axiosClient.get(`/students/courses/${courseId}/get-my-rank`)
    }

    const fetchStudentTestDetails = (courseId: number, programId: number) => {
        return axiosClient.post(`/courses/${courseId}/programs/${programId}/program-coverage`);
    }

    const fetchStudentEvaluationForTopic = (courseId:number,programId:number,page:number,pageSize:number)=>{
        return axiosClient.get(`/courses/${courseId}/programs/${programId}/topic-evaluation`,)
    }

    const getAssignmentDetailsForStudent = (courseId:number) => {
        return axiosClient.get(`/courses/${courseId}/completed-assignments`)
    }

    const getAllTimeSpendLearning = (courseId:number) => {
        return axiosClient.get(`/courses/${courseId}/total-time-spent`)
    }
    const getAllTopicLearned = (courseId:number) => {
        return axiosClient.get(`/courses/${courseId}/topic-count`)
    }

    const getAllQuestionCompletedByStudent = (courseId:number) => {
        return axiosClient.get(`/courses/${courseId}/question-performance`)
    }


    const getDetailsOfQuestions = (programId:number) => {
        return axiosClient.get(`/programs/${programId}/question-stats`)
    }

    const getTestHighestAndStudentPercentage = (courseId:number,programId:number) => {
        return axiosClient.get(`/students/courses/${courseId}/programs/${programId}/test-percentage`)
    }

    const getPracticeHighestAndStudentPercentage = (courseId:number,programId:number) => {
        return axiosClient.get(`/students/courses/${courseId}/programs/${programId}/practice-percentage`)
    }

    const getAssignmentHighestAndStudentPercentage = (courseId:number,programId:number) => {
        return axiosClient.get(`/students/courses/${courseId}/programs/${programId}/assignment-percentage`)
    }

    const getStudentComment =(courseId:number)=>{
        return axiosClient.get(`/students/comments`,{
            params:{
                courseId:courseId
            }
        })
    }

    const getAllQuestionsCompleted = (courseId:number)=>{
        return axiosClient.get(`/courses/${courseId}/questions-summary`)
    }
    const getAllNotice = ()=>{
        return axiosClient.get(`/notice`)
    }

    const getLeaderBoardData = (courseId:number,page?:number,size?:number)=> {
        return axiosClient.get(`/courses/${courseId}/leaderboard`,{
            params:{
                page:page,
                size:size
            }
        })
    }

    const getPaymentHistoryForStudent = () => {
        return axiosClient.get(`/students/payment-history`)
    }

    const updateEmail =(studentId:number,email:string)=> {
        return axiosClient.put(`/students/${studentId}/email`,{email})
    }

    const getCouponsByCourse = (courseId : number) => {
        return axiosClient.get(`/student/${courseId}/coupons`)
    }

    const applyCoupon = (courseId : number , payload : CouponRequest) => {
        return axiosClient.post(`/course/${courseId}/apply-coupon` , payload)
    }

    const validateCoupon = (courseId : number , code : string , amount : number) => {
        return axiosClient.get(`/coupon/${courseId}/validate` , {params : {code : code , amount : amount}})
    }

    return {
        addStudent, studentBatchDetail, getStudent, updateStudent, getAllProgramChapters, getAllStudentCourseBatches, getAllPrograms,
        getStudentProfile, createTest, getAllTestByCourseId, getAllTestQuestions, testEvaluation, getAllBatch,
        getTestResult, getPreviousTest, unLikeQuestion, likeQuestion, getAllProgramTopics, getFlags, submitFlag,
        changeFlag, getStudentById, getStudentByCourseId, getAllMockTestQuestions, softDeleteStudent, restoreStudent,
        getLatestTestByCourseId, resetPassword, assignTestToBatch, getAllCoursesForStudent, assignCourseToStudentByStudent,
        unAssignCourseByStudent, studentReport, getTotalMarksForStudent, assignmentCount, getStudentRank,fetchStudentTestDetails,
        fetchStudentEvaluationForTopic,getStudentPreviousTest,getAllAssignmentQuestions,assignmentEvaluation,getAssignmentResult,getTestResults,
        getStudentTestResult,getAssignmentDetailsForStudent,getAllQuestionCompletedByStudent,getDetailsOfQuestions,getTestHighestAndStudentPercentage,
        getPracticeHighestAndStudentPercentage,getAssignmentHighestAndStudentPercentage,getStudentComment,getAllQuestionsCompleted,
        updateStudentMobile,updateEmail,getLeaderBoardData,getPaymentHistoryForStudent , getCouponsByCourse , applyCoupon , validateCoupon
        , getAllTestComp,getAllMockTestQuestionsComp,createCompTest,getAllNotice,getAllTimeSpendLearning,getAllTopicLearned
    }
}
