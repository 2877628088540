import React from 'react';
import './Instruction.scss';
import {useNavigate, useParams} from 'react-router-dom';

const Instruction = () => {
    const params = useParams();
    const navigate = useNavigate()
    const questionData = [
        {topic: 'Sum of two numbers', questionNo: 1, maxMarks: 5},
        {topic: 'Algebra', questionNo: 2, maxMarks: 10},
        {topic: 'Chemistry', questionNo: 3, maxMarks: 8},
    ];

    // const totalDuration = '30 min';
    // console.log("#params",params);
    return (
        <div className="container py-4 instruction-wrap">
            <div className={'bg-white py-2 px-3 rounded instruction_title'}>
                <h5 className={'fw-bold'}>General Instructions</h5>
            </div>

            <div className={'mt-4 bg-white px-3 rounded py-3 instruction_body'}>
                <p className={'mb-2'}>
                    1. The test consists of 80 questions comprising the following sections
                </p>
                <div className={'margns'}>
                    <table className="table table-borderless question-table border">
                        <thead className={'tableHead'}>
                        <tr className={'table-secondary'}>
                            <th scope="col">Topic</th>
                            <th scope="col">No. of Questions</th>
                            <th scope="col">Max Marks</th>
                            <th scope="col">Total Questions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {questionData?.map((question) => (
                            <tr key={question.questionNo}>
                                <td>{question.topic}</td>
                                <td>{question.questionNo}</td>
                                <td>{question.maxMarks}</td>
                                <td className={'text-center'}>-</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    <div>
                        <p className={'mb-2'}>2. The clock has been set at the server and the countdown timer at the top
                            right corner of
                            your screen will display the time remaining for you to complete the exam. When the clock
                            runs out the exam ends by default - you are not required to end or submit your exam</p>
                        <p className={'mb-2'}>3. The question palette at the right of screen shows one of the following
                            statuses
                            of each of the questions numbered:</p>
                    </div>
                    <div className={'mb-2'}>
                        <div className={'p-1 d-flex align-items-center'}>
                            <p className={`status-icon unanswered pt-1 me-2 text-center`}>1</p>
                            <span className={'status-title'}>You have not visited the question yet.</span>
                        </div>
                        <div className={'p-1 d-flex align-items-center'}>
                            <p className={`status-icon pending pt-1 me-2 text-center text-white`}>2</p>
                            <span className={'status-title'}>You have not answered the question.</span>
                        </div>
                        <div className={'p-1 d-flex align-items-center'}>
                            <p className={`status-icon done pt-1 me-2 text-center text-white`}>3</p>
                            <span className={'status-title'}>You have answered the question</span>
                        </div>
                        <div className={'p-1 d-flex align-items-center'}>
                            <p className={`status-icon markForReview pt-1 me-2 text-center text-white`}>4</p>
                            <span className={'status-title'}>You have not answered question but marked review.</span>
                        </div>
                    </div>
                    <div>
                        <p>4. For multiple choice type question :</p>
                        <ul>
                            <li><p>To select your answer, click on one of the option buttons</p></li>
                            <li><p>To change your answer, click the another desired option button</p></li>
                            <li><p>To save your answer, you MUST click on<strong> Save & Next</strong></p></li>
                            <li><p>To deselect a chosen answer, click on the chosen option again or click on the Clear
                                Response button</p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="sticky-bottom-btns">
                    <button className={'btn btnGreen me-3 text-white'}
                            onClick={() => navigate(`../${params.testType}/${params.testId}`)}>Continue
                    </button>
                </div>
            </div>


        </div>
    );
};

export default Instruction;
