import {Modal, ModalBody, ModalHeader} from "react-bootstrap";
interface DeleteQuestionModalProps {
    show:boolean,
    onHide:()=> void
    onSuccess:()=> void
}
export const DeleteQuestionModal = (props:DeleteQuestionModalProps) => {
    return <Modal show={props.show} onHide={props.onHide}>
        <ModalHeader closeButton>
            <h6>Delete Question</h6>
        </ModalHeader>
        <ModalBody>
            <div>
                <h6>Are You sure you want to delete this question</h6>
            </div>
            <div className='d-flex align-items-center justify-content-end mt-3'>
                <button className='me-2 btn btn-secondary' onClick={props.onHide}>Close</button>
                <button className='btn btn-danger' onClick={()=>props.onSuccess()}>Delete</button>
            </div>
        </ModalBody>
    </Modal>
}