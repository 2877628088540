import React, {useEffect, useState} from "react";
import {CreateBatches} from "../../../components/modal/batches/CreateBatches";
import Pagination from "../../../components/pagination/Pagination";
import UpdateBatches from "../../../components/modal/batches/UpdateBatch";
import {UpdateBatchData} from "../../../modals/Model";
import {useNavigate, useParams} from "react-router-dom";
import {useAdminApiClient} from "../apiClients/AdminApiClient";
import CourseCardShimmer from "../../../components/shimmer/course/CourseCardShimmer";
import {AllToasts} from "../../../components/toast/AllToasts";
import '../admin.scss';
import CustomSwitcher from "../../../components/common/course/courseView/CustomSwitcher";



const AdminGroup = () => {
    const navigate = useNavigate();
    const batchesApiClient = useAdminApiClient();
    const [showSubject, setShowSubject] = useState<boolean>(false);
    const [query, setQuery] = useState<string>('')
    const [batch, setBatch] = useState<UpdateBatchData[]>([]);
    const [updateBatch, setUpdateBatch] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(10);
    const [totalElements, setTotalElements] = useState<number>(0);
    const [id, setId] = useState<number | undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(false);
    const [updateData, setUpdateData] = useState<UpdateBatchData>()

    useEffect(() => {
        getBatches();
    }, [query]);

    const getBatches = ( page?: number,size?: number) => {
        batchesApiClient.getBatches( page,size, query )
            .then((res) => {
                setBatch(res.data.content);
                setCurrentPage(res.data.number);
                setTotalPages(res.data.totalPages)
                setTotalElements(res.data.totalElements)
            })
            .catch((error) => {
                console.error('Error fetching batches:', error);
            });
    }

    const restoreBatch = (id: any) => {
        batchesApiClient.restoreBatch(id ?? 0)
            .then(() => {
                AllToasts.success('Batch Activated');
                getBatches();
            })
            .catch((error) => {
                console.error('Error activating batch:', error);
            })
    };

    const softdeleteBatch = (id: any) => {
        batchesApiClient.softdeleteBatch(id ?? 0)
            .then(() => {
                AllToasts.success('Batch Deactivated');
                getBatches();
            })
            .catch((error) => {
                console.error('Error deactivating  batch:', error);
            })
    };

    return (
        <div className={'container-fluid page-content'}>
            <div className="row page-titles">
                <div className="col-sm-6 p-md-0">
                    <div className="welcome-text">
                        <h4>All Batches</h4>
                    </div>
                </div>
            </div>
            <div className={'card'}>
                <div className={'card-body'}>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="col-md-6 px-0">
                                <div className="form-group searchBar position-relative">
                                    <input
                                        type={"search"}
                                        className="form-control w-100 formControl"
                                        placeholder={"Search here ..."}
                                        onChange={(event) => setQuery(event.target.value)}
                                    />
                                    <button className={'bg-transparent transition p-0 border-0'}><i
                                        className="bi bi-search"></i></button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 d-flex align-items-center justify-content-end">
                            <button className="btn btn-primary text-white rounded-1 btn-add"
                                    onClick={() => setShowSubject(true)}>
                                <i className="bi bi-plus"></i> Add new
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className='mt-3'>
                {loading ? <div className={'row row-cols-1 row-cols-md-3 m-4'}><CourseCardShimmer/></div> :
                    <div className={'container-fluid'}>
                        <div className={'row'}>
                            {batch.map((res: UpdateBatchData, index: number) => (
                                <div className="col-md-4 mb-4" key={res.id}>
                                    <div className="card border-0">
                                        <div className="card-box card-body shadow-sm border-1">
                                            <div
                                                className="card-title d-flex justify-content-between align-items-center">
                                                <div className="text-capitalize text-primary"
                                                     style={{cursor: "pointer"}} onClick={() => {
                                                    setId(res.id);
                                                    navigate(`${res.id}/${res.name}`)
                                                }}>
                                                    <h6 className="fw-bold text-capitalize"
                                                        style={{color: '#3498DB'}}>{res.name !== null ? res.name : 'No Name Available'}</h6>
                                                </div>
                                                <div className={'actions d-flex align-items-center'}>
                                                    <CustomSwitcher active={res.active} onChange={() => {
                                                        if (res.active) {
                                                            softdeleteBatch(res.id)
                                                        } else {
                                                            restoreBatch(res.id)
                                                        }
                                                    }}/>
                                                    <a className={'btn btn-sm bg-success-light rounded-circle'}
                                                       onClick={() => {
                                                           setId(res.id)
                                                           setUpdateData(res)
                                                           setUpdateBatch(true)
                                                       }}>
                                                        <i className="bi bi-pen"></i>
                                                    </a>
                                                </div>

                                            </div>
                                            <div className='mb-2'>
                                                <button className='btn-add rounded-2 text-white fs-6'>{res.course?.name}</button>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            <div className='float-end me-xxl-2 mt-4'>
                                <Pagination currentPage={currentPage}
                                            totalPages={totalPages}
                                            totalElements={totalElements}
                                            onPageChange={(page) => getBatches(page,pageSize)}/>

                            </div>
                        </div>
                    </div>
                }
            </div>

            <CreateBatches
                show={showSubject}
                onHide={() => setShowSubject(false)}
                onCancel={() => setShowSubject(false)}
                onSuccess={() => {
                    getBatches();
                    setShowSubject(false);
                }}
            />

            <UpdateBatches id={id ?? 0} show={updateBatch} onHide={() => {
                setUpdateBatch(false);
                setId(undefined)
            }} updateData={updateData}
                           onSuccess={() => getBatches()}/>
        </div>
    );
}

export default AdminGroup;





