import React, {useEffect, useState} from 'react';
import {Accordion, Card} from 'react-bootstrap';
import {useAdminApiClient} from "../apiClients/AdminApiClient";
import {AllToasts} from "../../../components/toast/AllToasts";
import Pagination from "../../../components/pagination/Pagination";
import './AdminDashboard.scss'
import {LeaderBoardAllStudentsModal} from "../../student/components/assignment/LeaderBoardAllStudentsModal";
import {VerificationData} from "../../teacher/components/verification/Verification";

interface Subject {
    id: number;
    subjectName: string;
    totalNumberOfTopics: number;
    totalQuestions: number;
    totalCorrectlyAttemptedQuestions: number;
    totalWronglyAttemptedQuestions: number;
    totalSkippedQuestions: number;
    createdDate: string;
    subjectGroup: string;
    totalAttempted: number;
}

interface RefactoredSubject {
    id: number;
    subjectName: string;
    totalNumberOfTopics: number;
    totalQuestions: number;
    totalCorrectlyAttemptedQuestions: number;
    totalWronglyAttemptedQuestions: number;
    totalSkippedQuestions: number;
    createdDate: string;
    totalAttempted: number
}

interface RefactoredClass {
    subjectGroup: string;
    subjects: RefactoredSubject[];
}

interface StatsData {
    totalQuestions: number;
    totalAttempted: number;
    totalCorrectlyAttempted: number;
    totalWronglyAttempted: number;
    totalQuestionsSkipped: number;
    totalLikedQuestions: number;
    uniqueStudentsCount: number
}

const MyAccordionView: React.FC = () => {
    const adminApiClient = useAdminApiClient();
    const [refactoredData, setRefactoredData] = useState<RefactoredClass[]>([]);
    const [statsData, setStatsData] = useState<StatsData>();
    const [showTable, setShowTable] = useState<boolean>(true);
    const [isToggleOpen, setIsToggleOpen] = useState(true);
    const [selectedTab, setSelectedTab] = useState<string>('student');
    const [courseData, setCourseData] = useState<any>();
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(10);
    const [totalElements, setTotalElements] = useState<number>(0);
    const [query, setQuery] = useState<string>('');
    const [courseId, setCourseId] = useState<number | undefined>(undefined);
    const [leaderBoardModal, setLeaderBoardModal] = useState<boolean>(false);
    const [courseName, setCourseName] = useState<string>('');
    const [selectedOption, setSelectedOption] = useState<String>('Subject');
    const [courseStatsData, setCourseStatsData] = useState<any[]>([]);
    const [currentPage1, setCurrentPage1] = useState<number>(0);
    const [totalPages1, setTotalPages1] = useState<number>(0);
    const [totalElements1, setTotalElements1] = useState<number>(0);
    const pageSize1 = 10;
    const [programStats, setProgramStats] = useState<any[]>([]);
    const [expandId , setExpandId] = useState<number | undefined>(undefined);

    useEffect(() => {
        getstats();
        getPaginatedCourseDetails();
    }, []);

    useEffect(() => {
        if (selectedTab === 'student') {
            getAllSubjects();
        } else if (selectedTab === 'course') {
            getAllCourseDetials(currentPage, pageSize)
        }
    }, [selectedTab, currentPage, pageSize]);

    useEffect(() => {
        const delayTimer = setTimeout(() => {
            getAllCourseDetials(0, pageSize, [], query)
        }, 300)
        return () => {
            clearTimeout(delayTimer);
        };
    }, [pageSize, query]);

    const getAllCourseDetials = (page?: number, size?: number, sort?: string[], query?: string) => {
        adminApiClient.getAdminCourseDetails(page, size, sort, query).then((res) => {
            setCourseData(res.data.content)
            setCurrentPage(res.data?.pageable?.pageNumber);
            setPageSize(res.data.pageable.pageSize);
            setTotalPages(res.data.totalPages);
            setTotalElements(res.data.totalElements)
        }).catch((err) => {
            console.log(err)
        })
    }

    const getPaginatedCourseDetails = (page ?: number , size ?: number) => {
        adminApiClient.getAdminCourseDetails(page , size).then(res => {
            setCourseStatsData(res.data.content);
            setCurrentPage1(res.data?.pageable?.pageNumber);
            setTotalPages1(res.data.totalPages);
            setTotalElements1(res.data.totalElements)
        }).catch(err => {
            console.log(err);
        })
    }

    const getProgramStatistics = (courseId: number) => {
        if (expandId === courseId) {
            setExpandId(undefined);
        }else {
            setExpandId(courseId);
            adminApiClient.programStatistics(courseId).then(res => {
                setProgramStats(res.data);
            })
        }
    }

    const getAllSubjects = async () => {
        try {
            const response = await adminApiClient.getAllSubjectDetails();
            const subjects: Subject[] = response.data;
            const refactoredData: RefactoredClass[] = subjects.reduce((accumulator: RefactoredClass[], currentSubject: Subject) => {
                    let refactoredClass = accumulator.find((item) => item.subjectGroup === currentSubject.subjectGroup);
                    if (!refactoredClass) {
                        refactoredClass = {subjectGroup: currentSubject.subjectGroup, subjects: [],};
                        accumulator.push(refactoredClass);
                    }
                    const refactoredSubject: RefactoredSubject = {
                        id: currentSubject.id,
                        subjectName: currentSubject.subjectName,
                        totalNumberOfTopics: currentSubject.totalNumberOfTopics,
                        totalQuestions: currentSubject.totalQuestions,
                        totalAttempted: currentSubject.totalAttempted,
                        totalCorrectlyAttemptedQuestions: currentSubject.totalCorrectlyAttemptedQuestions,
                        totalWronglyAttemptedQuestions: currentSubject.totalWronglyAttemptedQuestions,
                        totalSkippedQuestions: currentSubject.totalSkippedQuestions,
                        createdDate: currentSubject.createdDate,
                    };
                    refactoredClass.subjects.push(refactoredSubject);
                    return accumulator;
                },
                []
            );
            setRefactoredData(refactoredData);
        } catch (error) {
            console.error('Error fetching subject details:', error);
        }
    }
    const getstats = () => {
        adminApiClient.getAllStatsDetails().then((res) => {
            setStatsData(res.data);
        }).then(() => {

        })
    }
    const toggleTable = () => {
        setShowTable(!showTable);
        setIsToggleOpen(prevState => !prevState);
    };

    const refresh = () => {
        adminApiClient
            .refresh()
            .then((res: any) => {
                AllToasts.success('Rebuilding data. Reload for latest stats.');
            })
            .catch((err: any) => {
                console.log(err);
                AllToasts.failure(`Error ${err.message}`);
            });
    };


    return (
        <div className='container-fluid page-content admin_mainWrap'>
            <div className="row mb-3">
                <div className="col-md-6">
                    <div className="welcome-text">
                        <h4 style={{cursor: 'pointer'}}>Stats Dashboard</h4>
                    </div>
                </div>

                <div className="col-md-6 justify-content-md-end d-flex">
                    <button className="btn btn-primary text-white rounded-1 btn-add" onClick={refresh}>
                        Refresh
                    </button>
                </div>
            </div>
            <div className={'card mb-4 border-0 shadow-sm top_head_admin'}>
                <div className={'card-body'}>
                    <div className={'row align-items-center'}>
                        <div className={'col-xl-2 col-6 d-flex align-items-start justify-content-start'}>
                            <div className={'icon_wrap tq_color me-2'}><i className="bi bi-question-lg"></i></div>
                            <div className={''}>
                                <h6>Total Questions</h6>
                                <p> {statsData?.totalQuestions}</p>
                            </div>
                        </div>
                        <div className={'col-xl-2 col-6 d-flex align-items-start justify-content-start'}>
                            <div className={'icon_wrap ta_color me-2'}><i className="bi bi-award"></i></div>
                            <div className={''}>
                                <h6>Total Attempted</h6>
                                <p> {statsData?.totalAttempted}</p>
                            </div>
                        </div>
                        <div className={'col-xl-2 col-6 d-flex align-items-start justify-content-start'}>
                            <div className={'icon_wrap tc_color me-2'}><i className="bi bi-clipboard-check"></i>
                            </div>
                            <div className={''}>
                                <h6>Correct</h6>
                                <p> {statsData?.totalCorrectlyAttempted}</p>
                            </div>
                        </div>
                        <div className={'col-xl-2 col-6 d-flex align-items-start justify-content-start'}>
                            <div className={'icon_wrap tw_color me-2'}><i className="bi bi-x-circle"></i></div>
                            <div className={''}>
                                <h6>Wrong</h6>
                                <p> {statsData?.totalWronglyAttempted}</p>
                            </div>
                        </div>
                        <div className={'col-xl-2 col-6 d-flex align-items-start justify-content-start'}>
                            <div className={'icon_wrap tl_color me-2'}><i className="bi bi-heart"></i></div>
                            <div className={''}>
                                <h6>Liked Questions</h6>
                                <p> {statsData?.totalLikedQuestions}</p>
                            </div>
                        </div>
                        <div className={'col-xl-2 col-6 d-flex align-items-center justify-content-start'}>
                            <div className={'icon_wrap tas_color me-2'}><i className="bi bi-clock"></i></div>
                            <div className={''}>
                                <h6>Active student <br/> (Last 24 hrs)</h6>
                                <p> {statsData?.uniqueStudentsCount}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={'row'}>
                <div className={'col-xl-8 col-12 mb-3'}>
                    <div className={'card border-0 shadow-sm top_header'}>
                        <div className='d-flex flex-row align-items-center ms-4'>
                            <div className='d-flex mt-2 me-2 align-items-center'>
                                <button
                                    onClick={() => {
                                        if (selectedOption !== 'Subject') {
                                            setSelectedOption('Subject')
                                        }
                                    }}
                                    className={`p-2 text-center me-2 fs-6 ${selectedOption === 'Subject' ? 'btnNewAdd text-white' : 'btnRemove'}`}
                                    style={{cursor: 'pointer'}}>
                                    Subject Stats
                                </button>
                                <button
                                    onClick={() => {
                                        if (selectedOption !== 'Course') {
                                            setSelectedOption('Course')
                                        }
                                    }}
                                    className={`p-2 text-center me-2 fs-6 ${selectedOption === 'Course' ? 'btnNewAdd text-white' : 'btnRemove'}`}
                                    style={{cursor: 'pointer'}}>
                                    Course Stats
                                </button>
                            </div>
                        </div>
                        <div className={'card-body'}>
                            {
                                selectedOption === 'Subject' ?
                                    <Accordion>
                                        {refactoredData?.map((res, classIndex) => (
                                            <div className='mb-2' key={classIndex}>
                                                <Accordion.Item eventKey={classIndex.toString()}>
                                                    <Accordion.Header as={Card.Header}>
                                                        {res.subjectGroup ?? "---"}
                                                    </Accordion.Header>
                                                    <Accordion.Collapse eventKey={classIndex.toString()}>
                                                        <div>
                                                            <Card.Body>
                                                                <table className="table table-hover">
                                                                    <thead>
                                                                    <tr>
                                                                        <th>Subject</th>
                                                                        <th>Topics</th>
                                                                        <th>Questions Avl</th>
                                                                        <th>Attempted</th>
                                                                        <th>Correct</th>
                                                                        <th>Incorrect</th>
                                                                        <th>Skipped</th>
                                                                    </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                    {res.subjects
                                                                        .slice()
                                                                        .sort((a, b) => a.subjectName.localeCompare(b.subjectName))
                                                                        .map((subject: RefactoredSubject, subjectIndex: number) => (
                                                                            <tr key={subjectIndex}>
                                                                                <td>{subject.subjectName ?? "---"}</td>
                                                                                <td>{subject.totalNumberOfTopics ?? 0}</td>
                                                                                <td>{subject.totalQuestions ?? 0}</td>
                                                                                <td>{subject.totalAttempted ?? 0}</td>
                                                                                <td>{subject.totalCorrectlyAttemptedQuestions}</td>
                                                                                <td>{subject.totalWronglyAttemptedQuestions ?? 0}</td>
                                                                                <td>{subject.totalSkippedQuestions ?? 0}</td>
                                                                            </tr>
                                                                        ))
                                                                    }
                                                                    </tbody>
                                                                </table>
                                                            </Card.Body>
                                                        </div>
                                                    </Accordion.Collapse>
                                                </Accordion.Item>
                                            </div>
                                        ))}
                                    </Accordion> :
                                    <Accordion>
                                        {courseStatsData?.map((res, index) => (
                                            <div className='mb-2' key={index}>
                                                <Accordion.Item eventKey={index.toString()}
                                                                onClick = {() => {
                                                                    getProgramStatistics(res.id)
                                                                }}>
                                                    <Accordion.Header as={Card.Header}>
                                                        {res.name ?? "---"}
                                                    </Accordion.Header>
                                                    <Accordion.Collapse eventKey={index.toString()}>
                                                        <div>
                                                            <Card.Body>
                                                                <table className="table table-hover">
                                                                    <thead>
                                                                    <tr>
                                                                        <th>Programs</th>
                                                                        <th>Topics</th>
                                                                        <th>Questions Avl</th>
                                                                        <th>Attempted</th>
                                                                        <th>Correct</th>
                                                                        <th>Incorrect</th>
                                                                        <th>Skipped</th>
                                                                    </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                    {programStats.map((res2, index2) => (
                                                                        <tr key={index2}>
                                                                            <td>{res2.program.name ?? "---"}</td>
                                                                            <td>{res2.totalTopics ?? 0}</td>
                                                                            <td>{res2.availableQuestions ?? 0}</td>
                                                                            <td>{res2.totalAttempted ?? 0}</td>
                                                                            <td>{res2.correctlyAttempted}</td>
                                                                            <td>{res2.incorrectlyAttempted ?? 0}</td>
                                                                            <td>{res2.skipped ?? 0}</td>
                                                                        </tr>
                                                                    ))
                                                                    }
                                                                    </tbody>
                                                                </table>
                                                            </Card.Body>
                                                        </div>
                                                    </Accordion.Collapse>
                                                </Accordion.Item>
                                            </div>
                                        ))}
                                        <Pagination currentPage={currentPage1}
                                                    totalPages={totalPages1}
                                                    totalElements={totalElements1}
                                                    onPageChange={(page) => getPaginatedCourseDetails(page, pageSize)}/>
                                    </Accordion>
                            }
                        </div>
                    </div>
                </div>
                <div className={'col-xl-4 col-12'}>
                    <div className={'card border-0 shadow-sm top_header'}>
                        <div className={'card-header'}>
                            <h5>Course</h5>
                        </div>
                        <div className={'card-body'}>
                            <div className={"mb-3"}>
                                <div className="form-group searchBar position-relative w-100">
                                    <input
                                        type={"search"}
                                        className="form-control w-100 formControl"
                                        placeholder={"Search here ..."}
                                        onChange={(event) => setQuery(event.target.value)}
                                    />
                                    <button className={'bg-transparent transition p-0 border-0 '}><i
                                        className="bi bi-search"></i>
                                    </button>
                                </div>
                            </div>
                            <div className={'table-responsive'}>
                                <table className={`table studentTable`}>
                                    <thead className={'thead'} style={{color: 'black'}}>
                                    <tr>
                                        <th>Sr.No</th>
                                        <th>Name</th>
                                        <th>Students</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {courseData?.map((res: any, index: number) =>
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td onClick={() => {
                                                setCourseId(res.id)
                                                setCourseName(res?.name)
                                                setLeaderBoardModal(true)
                                            }}>{res?.name ? res?.name : '---'}</td>
                                            <td>{res?.studentsEnrolled}</td>
                                        </tr>
                                    )}
                                    </tbody>
                                </table>
                            </div>
                            {totalPages > 1 &&
                                <Pagination currentPage={currentPage}
                                            totalPages={totalPages}
                                            totalElements={totalElements}
                                            onPageChange={(page) => getAllCourseDetials(page, pageSize, [], query)}/>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <LeaderBoardAllStudentsModal show={leaderBoardModal} onHide={() => {
                setCourseId(undefined)
                setLeaderBoardModal(false)
            }} courseId={courseId} courseName={courseName}/>
        </div>
    );
}

export default MyAccordionView;
