import Form from "react-bootstrap/Form";
import React, {useState} from "react";
import './CustomeSwitcher.scss'

export type CustomSwitcherData = {
    active: boolean,
    activeLabel?: string,
    inactiveLabel?: string,
    onChange: () => void
}

const CustomSwitcher = (props: CustomSwitcherData) => {
    const [active, setActive] = useState(props.active);

    return <Form>
        <Form.Check
            className={'d-flex align-items-center ps-0'}
            type="switch"
            label={active ? props?.activeLabel ?? 'Active' : props?.inactiveLabel ?? 'Deactive'}
            defaultChecked={active}
            id="custom-switch"
            onClick={() => {
                setActive(!active);
                props.onChange();
            }}
        />

    </Form>
}

export default CustomSwitcher;