import React, {useEffect, useState} from "react";
import {MathJaxCheatSheet} from "../../../../teacher/components/createAssignment/createQuestion/MathJaxCheatSheet";
import {Option, Question} from "../../../../teacher/components/modals/CreateiUpdateQuestion";
import {MathX} from "../../../../../components/math/MathX";
import {QuizQuestion} from "../../../../student/components/quiz/quizQuestions/QuizQuestion";

export interface PreviewScreenProps {
    text: string
    selectedQuestionType: any
    payload?: any
}

export const PreviewScreen = (props: PreviewScreenProps) => {
    const [selectedMethod, setSelectedMethod] = useState<string>('question')
    const handleMethodChange = (method: 'question' | 'formula') => {
        setSelectedMethod(method);
    };

    return <div>
        <div className='nav-tabs pt-3 ms-4'>
            <div className='d-flex'>
                <div className={`questionButton me-2 p-3 ${
                    selectedMethod === 'question' ? 'methodButtons' : 'text-black'}`}
                     onClick={() => handleMethodChange('question')}>
                    <span className="button-label"><div>Update Question</div></span>
                </div>
                <div className={`formulaButton p-3 ${
                    selectedMethod === 'formula' ? 'methodButtons' : 'text-black'}`}
                     onClick={() => handleMethodChange('formula')}>
                    <span className="button-label">Formula Reference</span>
                </div>
            </div>
            <div>
                {selectedMethod === 'question' &&
                    <div>
                        <div className='m-2'>
                            {props.text.length > 0 && (
                                <div className={'d-flex'}>
                                    <h5 className='me-2'>Q.</h5>
                                    <h6 className='mt-1'><MathX>{props.text}</MathX></h6>
                                </div>
                            )}
                        </div>
                        <QuizQuestion question={props.payload} quizView={'teacher'}/>
                    </div>
                }
            </div>
            <div>
                {selectedMethod === 'formula' &&
                    <div className={'vh-100 overflow-y-scroll mt-3 bg-light'}>
                        <MathJaxCheatSheet/>
                    </div>
                }
            </div>
        </div>
    </div>
}