import {useAxios} from "../../../configs/AxiosClient";
import {BatchData} from "../components/AddStudentBatch";
import {CommentPayload} from "../components/studentEvauationScreen/StudentEvalutionCommentsModal";
import {User} from "../components/dataExecutive/CreateDataExecutiveModal";
import {CouponData} from "../components/coupon/createCoupon/CreateCouponModal";
import {ResetPasswordPayload} from "../components/dataExecutive/DataExecutiveChangePasswordModal";
import {TeachersPayload} from "../../../components/modal/teachers/AddEditTeacherModal";
import {NoticePayload} from "../../../components/modal/notice/AddEditNotice";

export const useAdminApiClient = () => {
    const axiosClient = useAxios();

    const createBatch = (courseId: number, payload: any) => {
        return axiosClient.post(`/courses/${courseId}/batches`, payload);
    }

    const updateBatch = (payload: any, id: number) => {
        return axiosClient.put(`/batches/${id}`, payload)
    }
    const restoreBatch = (id: string) => {
        return axiosClient.post(`/batches/${id}/active`)
    }
    const softdeleteBatch = (id: string) => {
        return axiosClient.delete(`/batches/${id}/active`)
    }
    const deleteStudent = (batchId: string, studentId: string) => {
        return axiosClient.delete(`/batches/${batchId}/students/${studentId}`)
    }

    const getBatches = (  page?: number,size?: number, query?: string) => {
        return axiosClient.get(`/courses/batches`, {
            params: {
                page: page,
                size: size,
                query: query,
            }
        });
    }

    const getAllCourses = (page?: number, size?: number, query?: string) => {
        return axiosClient.get('/courses', {
            params: {
                "page": page,
                "size": size,
                "query": query,
            }
        });
    }

    const addStudentToBatch = (batchId: string, payload: BatchData) => {
        return axiosClient.post(`/batches/${batchId}/students`, payload)}

    const getAllStudents = (notInBatch: string, page?: number, size?: number, query?: string) => {
        return axiosClient.get('/students', {
            params: {
                "page": page,
                "size": size,
                "query": query,
                // "batchId":batchId,
                "notInBatch": notInBatch
            }
        })
    }

    const getStudents = (batchId: string, page?: number, size?: number, query?: string) => {
        return axiosClient.get('/students', {
            params: {
                batchId: batchId,
                "page": page,
                "size": size,
                "query": query
            }
        })
    }

    const getAllSubjectDetails = () => {
        return axiosClient.get('/subject-details')
    }
    const getAllStatsDetails = () => {
        return axiosClient.get('/stats-dashboard')
    }
    const refresh = () => {
        return axiosClient.post('/subjects-details/update')
    }

    const getAdminCourseDetails = (page?:number,size?:number,sort?:string[],query?:string) => {
        return axiosClient.get(`/enrolled-students`,{
            params : {
                page:page,
                size:size,
                sort:sort,
                query: query
            }
        })
    }

    const getQuestionsAttemptedByStudent = (studentId:number,courseId:number)=> {
        return axiosClient.get(`/students/${studentId}/courses/${courseId}/question-performance`)
    }

    const getAssigmentAttemptedByStudent = (studentId:number,courseId:number)=> {
        return axiosClient.get(`/students/${studentId}/courses/${courseId}/completed-assignments`)
    }

    const getOverallStudentRank = (studentId:number,courseId:number)=> {
        return axiosClient.get(`admin/students/${studentId}/courses/${courseId}/rank`)
    }

    const getAllStudentCourses = (studentId:number) => {
        return axiosClient.get(`/students/${studentId}/courses`)
    }

    const getTopicEvaluationForStudent  = (studentId:number,courseId:number,programId:number)=>{
        return axiosClient.get(`/students/${studentId}/courses/${courseId}/programs/${programId}/topic-evaluation`)
    }

    const getStudentQuestionDetails  = (studentId:number,programId:number) => {
        return axiosClient.get(`/students/${studentId}/programs/${programId}/question-stats`)
    }

    const getStudentTestPercentage =(studentId:number,courseId:number,programId:number) => {
        return axiosClient.get(`/students/${studentId}/courses/${courseId}/programs/${programId}/test-percentage`)
    }

    const getStudentPracticePercentage = (studentId:number,courseId:number,programId:number) => {
        return axiosClient.get(`/students/${studentId}/courses/${courseId}/programs/${programId}/practice-percentage`)
    }

    const getStudentAssignmentPercentage = (studentId:number,courseId:number,programId:number) => {
        return axiosClient.get(`/students/${studentId}/courses/${courseId}/programs/${programId}/assignment-percentage`)
    }

    const getProgramForStudent = (studentId:number,courseId:number) => {
        return axiosClient.get(`/students/${studentId}/courses/${courseId}/program-coverage-percentage`)
    }

    const createCommentForStudent = (studentId:number,courseId:number,payload:CommentPayload)=>{
        return axiosClient.post(`/students/${studentId}/courses/${courseId}/comments`,payload)
    }

    const getAllComments = (studentId:number,courseId:number) => {
        return axiosClient.get(`/students/${studentId}/comments`,{
            params:{
                courseId:courseId
            }
        })
    }

    const getAllDataEntryExecutives =()=> {
        return axiosClient.get(`/data-entry-executive`)
    }

    const createDataEntryExecutive =(payload:User)=>{
        return axiosClient.post(`/data-entry-executive/create`,payload)
    }


    const studentPaymentHistoryForAdmin = (courses?:number[],fromDate?:string,toDate?:string,page?:number,size?:number,text?:string) => {
        return axiosClient.get(`/payments-history`,{
            params:{
                courses:courses,
                fromDate:fromDate,
                toDate:toDate,
                page:page,
                size:size,
                text:text
            }
        })
    }
    const getLeaderBoardDataByCourseId = (courseId:number,page?:number,size?:number) => {
        return axiosClient.get(`/courses/${courseId}/admin-leaderboard`,{
            params:{
                page:page,
                size:size
            }
        })
    }

    const getAllCoupon = (page?:number,size?:number) => {
        return axiosClient.get(`/admin/coupons`,{
            params:{
                page:page,
                size:size,
            }
        })
    }

    const createCouponByAdmin=(payload:CouponData)=>{
        console.log(payload)
        return axiosClient.post(`/admin/coupon`,payload)
    }

    const getSingleCoupon = (couponId:number)=> {
        return axiosClient.get(`/coupon/${couponId}`)
    }

    const updateCoupon = (couponId:number,payload:Partial<CouponData>)=> {
        return axiosClient.put(`/coupon/${couponId}`,payload)
    }

    const dataEntryExecutiveResetPassword = (executiveId:number,payload:ResetPasswordPayload) => {
        return axiosClient.put(`/admin/data-entry-executive/${executiveId}/reset-password`,payload)
    }

    const couponSoftDelete = (couponId:number)=>{
        return axiosClient.delete(`/coupon/${couponId}/active`)
    }

    const couponUndoSoftDelete = (couponId:number)=>{
        return axiosClient.put(`/coupon/${couponId}/active`)
    }

    const getCouponCount = (couponId ?: string) => {
        return axiosClient.get(`/coupon/${couponId}/count`)
    }

    const getCouponStats = (couponId : string , page ?: number , size ?: number) => {
        return axiosClient.get(`/coupon/${couponId}/stats` , {params : {page : page , size : size}})
    }

    const programStatistics = (courseId : number) => {
        return axiosClient.get(`/courses/${courseId}/statistics`)
    }

    const getCoursesList = () => {
        return axiosClient.get(`/courses`)
    }
    const deleteNotice = (noticeId:number) => {
        return axiosClient.delete(`/admin/notice/${noticeId}`)
    }
    const updateNotice = ( id:number ,payload: NoticePayload) => {
        return axiosClient.put(`/admin/notice/${id}`, payload);
    }
    const addNotice = ( payload: NoticePayload) => {
        return axiosClient.post(`/admin/notice`, payload);
    }
    const getAllNoticeForAdmin = (size?: number, page?: number, query?: string) => {
        return axiosClient.get('/admin/notice', {
            params: {
                size: size,
                page: page,
                query: query,
            }
        });
    };
    const getNoticeById = (id:number) => {
        return axiosClient.get(`/admin/notice/${id}`)
    }


    return {
        refresh, createBatch, updateBatch, softdeleteBatch, getBatches, getAllCourses, addStudentToBatch, getAllStudents,
        getStudents, deleteStudent, restoreBatch, getAllSubjectDetails, getAllStatsDetails, getAdminCourseDetails,
        getQuestionsAttemptedByStudent, getAssigmentAttemptedByStudent, getOverallStudentRank, getAllStudentCourses,
        getTopicEvaluationForStudent, getStudentQuestionDetails, getStudentTestPercentage, getStudentPracticePercentage,
        getStudentAssignmentPercentage,getProgramForStudent,createCommentForStudent,getAllComments,
        studentPaymentHistoryForAdmin,getAllDataEntryExecutives, createDataEntryExecutive,getLeaderBoardDataByCourseId,
        getAllCoupon,createCouponByAdmin,getSingleCoupon,updateCoupon,dataEntryExecutiveResetPassword,couponSoftDelete,
        couponUndoSoftDelete , getCouponCount , getCouponStats , programStatistics , getCoursesList,deleteNotice,addNotice,updateNotice,getAllNoticeForAdmin
        ,getNoticeById
    }
}