import React, {useEffect, useRef, useState} from 'react';
import { useAdminBulkUploadApiClient } from '../../apiClients/AdminBulkUploadApiClient';
import './BulkUpload.scss';
import {AllToasts} from "../../../../components/toast/AllToasts";
import moment from "moment/moment";

export interface TableDataItem {
    id:number;
    fileName: string;
    startDate: string;
    endDate: string;
    status: string;
    summary: string;
    totalRecordCount: number;
    totalRejectedCount: number;
}

export const BulkUpload: React.FC = () => {
    const [file, setFile] = useState<File | null>(null);
    const [dragEnter, setDragEnter] = useState<boolean>(false);
    const [viewFile, setViewFile] = useState<boolean>(false);
    const [fileName, setFileName] = useState<string | null>(null);
    const [uploadStatus, setUploadStatus] = useState<string | null>(null);
    const fileChooser = useRef<HTMLInputElement | null>(null);
    const [data,setData] = useState<TableDataItem[]>([]);
    useEffect(() => {
        getAllBulkFileUpload()
    }, []);
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            const selectedFile = event.target.files[0];
            setFile(selectedFile);
            setFileName(selectedFile.name);
            // console.log('file',selectedFile)
        }
    };

    const AdminBulkUploadApiClient = useAdminBulkUploadApiClient();

    const handleUpload = () => {
        if (!file) {
            return;
        }
        setUploadStatus('Uploading...');
        AdminBulkUploadApiClient.BulkUploadCsv(file)
            .then((res) => {
                setUploadStatus('Upload successful');
                AllToasts.success('Uploaded Successfully');
                getAllBulkFileUpload(); // Fetch data after successful upload
            })
            .catch((err) => {
                console.error('Error uploading file:', err);
                setUploadStatus('Error uploading file');
                AllToasts.failure(`Error Uploading File ${err.message}`);
            });
    };

    const getAllBulkFileUpload = ()=> {
        AdminBulkUploadApiClient.getAllImports().then((res)=>{
            setData(res.data)
        }).catch((err)=>{
            console.log(err)
        })
    }

    const handleRemoveFile = () => {
        setFile(null);
        setFileName(null);
        setUploadStatus(null);
    };

    const handleFormSubmit = (e: any) => {
        e.preventDefault();
        handleUpload();
    };

    const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            const droppedFile = e.dataTransfer.files[0];
            setFile(droppedFile);
            setFileName(droppedFile.name);
        }
        setDragEnter(false);
    };

    const handleDragAndLeaveEvent = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.type === 'dragover' || e.type === 'dragenter') {
            setDragEnter(true);
        } else if (e.type === 'dragleave') {
            setDragEnter(false);
        }
    };

    const handleCloseButton = ()=> {
        setFile(null)
        setFileName(null)
        setUploadStatus(null)
        setViewFile(false)
    }


    return (
        <div className="container bulk_upload page-content">
            <div className="row page-titles">
                <div className="col-sm-6 p-md-0">
                    <div className="welcome-text">
                        <h4>Upload Questions</h4>
                    </div>
                </div>
            </div>
            <div className={'card'}>
                <div className={'card-body'}>
                    <div className="d-flex">
                        <div className="col-md-6 px-0">
                            <div className="form-group searchBar position-relative">
                                <input
                                    type={"search"}
                                    className="form-control w-100 formControl"
                                    placeholder={"Search here ..."}
                                    // onChange={(event) => setQuery(event.target.value)}
                                />
                                <button className={'bg-transparent transition p-0 border-0'}><i
                                    className="bi bi-search"></i></button>
                            </div>
                        </div>
                        <div className="col-md-6 d-flex align-items-center justify-content-end">
                            {viewFile === false ? (
                                <button type="button" className={'btn btn-primary text-white bg-success border-3'}
                                        onClick={() => setViewFile(true)}>
                                    Upload File
                                </button>
                            ) : (
                                <button type="button" className={' btn btn-danger text-white bg-danger'}
                                        onClick={handleCloseButton}>
                                    Close
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <form className="container bg-white pt-3 pb-3 mt-3 ps-3 pe-3" onSubmit={handleFormSubmit}>
                <div>
                    {viewFile === true ? (
                        <div
                            className="card drop_box bg-light mt-2"
                            style={{width: 'auto%'}}
                            onClick={() => fileChooser.current?.click()}
                            onDragOver={handleDragAndLeaveEvent}
                            onDragLeave={handleDragAndLeaveEvent}
                            onDrop={handleDrop}>
                            {dragEnter || fileName ? (
                                <div className="d-flex flex-column">
                                    {fileName ? (
                                        <div className=''>
                                            <p className='pb-3'>File Name: {fileName}</p>
                                            {uploadStatus && <p>{uploadStatus}</p>}
                                            <button className="btn  text-white bg-primary me-2" type="button"
                                                    onClick={handleUpload}>
                                                Upload File
                                            </button>
                                            <button className=" btn btn-danger text-white bg-danger" type="button"
                                                    onClick={handleRemoveFile}>
                                                Remove File
                                            </button>
                                        </div>
                                    ) : (
                                        <div
                                            className="image_picker text-center flex-fill mt-4 p-5 position-relative w-100 h-100 ">
                                            <div
                                                className={`styledLabel ${dragEnter ? 'dark' : ''}`}
                                                onClick={() => fileChooser.current?.click()}>
                                                {dragEnter ? <p>Drop file here</p> : ''}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <div className="align-items-center">
                                    <p><b>Files Supported: .csv</b></p>
                                    <p><b>Or Drag and Drop a file</b></p>
                                    <input ref={fileChooser} multiple hidden onChange={handleFileChange} type="file"
                                           accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                           name="file_uploads" id="file_uploader" className="visually-hidden"
                                    />

                                    <br/>
                                    <div className="d-flex flex-column align-items-center justify-content-center">
                                        <button className=" bg-danger text-white mb-3">Choose File</button>
                                    </div>
                                </div>
                            )}
                        </div>
                    ) : (
                        ''
                    )}
                </div>
                <div className="table-responsive container bg-white pt-3 pb-3 mt-3 ps-3 pe-3">
                    <table className="table table-hover">
                        <thead>
                        <tr>
                            <th>File Name</th>
                            <th>Start Date</th>
                            <th>End Date</th>
                            <th>Status</th>
                            <th>Summary</th>
                            <th>Total Record Count</th>
                            <th>Rejected Count</th>
                        </tr>
                        </thead>
                        <tbody>
                        {data?.map((res) => (
                            <tr key={res.id}>
                                <td>{res?.fileName}</td>
                                <td>{moment(res?.startDate).format('DD/MM/YYYY HH:MM:SS')}</td>
                                <td>{moment(res?.endDate).format('DD/MM/YYYY HH:MM:SS')}</td>
                                <td>{res?.status}</td>
                                <td>{res?.summary}</td>
                                <td>{res?.totalRecordCount}</td>
                                <td>{res?.totalRejectedCount}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </form>
        </div>
    );
};
