import {useAxios} from "../configs/AxiosClient";
import {LoginPayload} from "../dto/Payload";
import {Token} from "../dto/Token";

export enum TeachersApi {
    SEND_OTP,
    LOGIN
}
const TeachersApisPaths = {
    [TeachersApi.SEND_OTP]: '/auth/send-otp',
    [TeachersApi.LOGIN]: '/auth/login'
}
export const useTeachersApi = () => {
    const axios = useAxios();

    const sendOtp = (mobile: string) => {
        return axios.post(TeachersApisPaths[TeachersApi.SEND_OTP], {username: mobile});
    }
    const login = (payload: LoginPayload) => {
        return axios.post<Token>(TeachersApisPaths[TeachersApi.LOGIN], payload);
    }
    return {sendOtp, login}
}