import React, {memo, useEffect, useState} from "react";
import Button from "../../../../../components/button/Button";
import {AllToasts} from "../../../../../components/toast/AllToasts";
import {useStudentApi} from "../../../../../api/StudentApiClent";
import {useNavigate} from "react-router-dom";
import {GoogleReCaptcha} from "react-google-recaptcha-v3";
import logo from "../../../../../assets/images/aiDronaLogo.png";
import StudentLoginImg from "../../../../../assets/images/studentLoginImg.png";
import '../LoginStudent.scss'
import {GOOGLE_CLIENT_ID} from "../../../../../common/AppConstants";
import {LoginScope} from "../../../../../dto/Payload";
import {SessionManager} from "../../../../../configs/SessionManager";


export interface selfLoginPayload {
    username: string;
    name: string;
    mobile?: string | null;
    email: string;
    password: string;
    confirmPassword: string;
    streetAddress: string;
    classSchedule: string;
    schoolName: string;
    createUser: boolean;
    recaptchaResponse?: string | null;
}

export interface ValidateName {
    username: string
}

const SelfLogin = () => {
    const [recaptchaToken, setRecaptchaToken] = useState<string>();
    const studentApi = useStudentApi();
    const navigate = useNavigate();
    const MAX_MOBILE_LENGTH = 10;
    const [refreshReCaptcha, setRefreshReCaptcha] = useState<boolean>(false);
    const [selfLoginPayload, setSelfLoginPayload] = useState<selfLoginPayload>({
        username: '',
        name: '',
        mobile: '' || null,
        email: '',
        password: '',
        confirmPassword: '',
        streetAddress: '',
        classSchedule: '',
        schoolName: '',
        createUser: true,
        recaptchaResponse: '' || null,
    });
    const [loading, setLoading] = useState<boolean>(false);
    const [mobileValid, setMobileValid] = useState<boolean>(true);
    const [mobileError, setMobileError] = useState<string | null>(null);
    const [userIdError, setUserIdError] = useState<string | null>(null);
    const [userIdValid, setUserIdValid] = useState<boolean>(true);
    const [passwordsMatch, setPasswordsMatch] = useState<boolean>(true);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [userName, setUserName] = useState<ValidateName>({
        username: ''
    });
    const [signupButtonDisabled, setSignupButtonDisabled] = useState<boolean>(false);

    useEffect(() => {
        google.accounts.id.initialize({
            client_id: GOOGLE_CLIENT_ID,
            callback: handleCallBackResponse
        })
        google.accounts.id.renderButton(
            document.getElementById("signInDiv") as HTMLElement,
            {theme: "filled_blue", size: "large", type: "standard",text:"continue_with" ,logo_alignment:"left",shape:"circle"}
        );

        google.accounts.id.prompt();
    }, []);

    const handleCallBackResponse = (res: any) => {
        console.log("#41", res.credential);
        studentApi.login({username: "$GOOGLE$", password: res.credential, scope: LoginScope.Student}).then(res => {
            SessionManager.createSession(res.data.access_token, '', res.data);
            navigate("/student")
        }).catch(err => {
            console.error(err);
        })

    }

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const createUser = (payload: selfLoginPayload) => {
        console.log('createUser');
        setLoading(true);
        setPasswordsMatch(true);
        setMobileError(null);
        setMobileValid(true);
        setUserIdError(null);
        setUserIdValid(true);
        setSignupButtonDisabled(true);
        payload.recaptchaResponse = recaptchaToken;
        studentApi.selfLogin(payload)
            .then((res) => {
                setLoading(false);
                setSelfLoginPayload({
                    username: "",
                    name: "",
                    mobile: "",
                    email: "",
                    password: "",
                    confirmPassword: "",
                    streetAddress: "",
                    classSchedule: "",
                    schoolName: "",
                    createUser: true,
                    recaptchaResponse: "",
                });
                navigate(`/student/login/success`);
            })
            .catch((error) => {
                AllToasts.failure(`${error?.response?.data?.reason}`);
                console.log(error.response.data.reason)
                setLoading(false);
                if (
                    error.response?.data?.reason === "The user name entered is not available"
                ) {
                    setUserIdError(`The user name entered is not available.`);
                }
                if (
                    error.response?.data?.reason ===
                    "userName: size must be between 3 and 2147483647"
                ) {
                    setUserIdError(`Size must be more than 3.`);
                }
                if (error.response?.data?.reason === "User already exists") {
                    setMobileError('Mobile number already exists');
                }
            }).finally(() => {
            setSignupButtonDisabled(false);
        });
    };

    const checkUsernameAvailability = (payload: ValidateName) => {
        if(selfLoginPayload.username !== '') {
            studentApi.validateUsername(payload).then((res) => {
                if (res.data.isValid === true) {
                    setUserIdValid(true);
                    setUserIdError('');
                } else {
                    setUserIdValid(false);
                    setUserIdError(res.data.message);
                }
            }).catch((error) => {
                console.error("Error checking username availability", error);
                if (error.response?.data?.reason === "The user name entered is not available") {
                    setUserIdError(`The user name entered is not available.`);
                }
            });
        }
    };

    const handleUsernameChange = (e: any) => {
        const newUsername = e.target.value;
        setUserName({username: newUsername});
        setSelfLoginPayload((prevState) => ({
            ...prevState,
            username: newUsername,
        }));
        setUserIdError('');
    };

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        if (selfLoginPayload.password !== selfLoginPayload.confirmPassword) {
            setPasswordsMatch(false);
            return;
        }
        if (selfLoginPayload.mobile && selfLoginPayload.mobile.length > MAX_MOBILE_LENGTH) {
            setMobileValid(false);
            return;
        }
        checkUsernameAvailability(userName);
        createUser(selfLoginPayload);
    }

    return  <div id={'main_wrapper'} className="loginScreen_wrapper register_screen">
        <div className={'container-fluid'}>
            <div className={'row min-vh-100'}>
                <div className={'col-md-6'}>
                    <div className={'h-100'}>
                        <div className={'login_info_wrap'}>
                            <img src={StudentLoginImg} width={'100%'} height={'100%'}/>
                        </div>
                    </div>
                </div>
                <div className={'col-md-6'}>
                    <div className={'mt-4 top-header'}>
                        <div className={'d-flex align-items-center'}>
                            <img onClick={() => navigate('/')}
                                 style={{width: '79px', height: '65px', cursor: 'pointer'}} src={logo}/>
                            <h3 className='fw-bold'>Register for Ai Drona</h3>
                        </div>
                        <div className={'mt-lg-2'}><a className='ms-2 text-black-50' style={{fontSize: '14px',cursor:'pointer'}}
                                onClick={() => navigate(`/student/login`)}><i className="bi bi-arrow-left"></i> Back
                            to home</a></div>
                    </div>
                    <form onSubmit={handleSubmit} className={'register_form'}>
                        <div className='ms-2 mt-3 '>
                            <h5>Login Credentials</h5>
                        </div>
                        <hr/>
                        <div className="form-group m-2">
                            <label className={'form-label'}>UserName <i className="bi bi-asterisk"
                                                                        style={{
                                                                            color: 'red',
                                                                            fontSize: '10px'
                                                                        }}></i></label>
                            <input
                                type="text"
                                className={`form-control ${
                                    (!userIdValid || userIdError) ? 'is-invalid' : ''
                                }`}
                                placeholder="Enter username"
                                onBlur={() => checkUsernameAvailability(userName)}
                                onChange={handleUsernameChange}
                                value={selfLoginPayload.username}
                                required
                            />
                            {userIdError && (
                                <div className="invalid-feedback">{userIdError}</div>
                            )}
                        </div>
                        <div className="form-group m-2 ">
                            <label>Password <i className="bi bi-asterisk"
                                               style={{color: 'red', fontSize: '10px'}}></i></label>
                            <div style={{position: 'relative'}}>
                                <input
                                    type={showPassword ? "text" : "password"}
                                    className="form-control"
                                    placeholder="Enter password"
                                    onChange={(e) => setSelfLoginPayload((prevState) => ({
                                        ...prevState,
                                        password: e.target.value
                                    }))}
                                    value={selfLoginPayload.password}
                                    required
                                />
                                <span
                                    className={`password-toggle-icon ${showPassword ? "visible" : ""}`}
                                    onClick={togglePasswordVisibility}
                                    style={{
                                        position: 'absolute',
                                        top: '50%',
                                        right: '10px',
                                        transform: 'translateY(-50%)'
                                    }}>
                            <i className="bi bi-eye"></i>
                        </span>
                            </div>
                        </div>
                        <div className="form-group m-2 ">
                            <label> Confirm Password <i className="bi bi-asterisk"
                                                        style={{color: 'red', fontSize: '10px'}}></i></label>
                            <div style={{position: 'relative'}}>
                                <input
                                    type={showConfirmPassword ? "text" : "password"}
                                    className="form-control"
                                    placeholder="Confirm password"
                                    onChange={(e) => setSelfLoginPayload((prevState) => ({
                                        ...prevState,
                                        confirmPassword: e.target.value
                                    }))}
                                    value={selfLoginPayload.confirmPassword}
                                    required
                                />
                                <span
                                    className={`password-toggle-icon ${showConfirmPassword ? "visible" : ""}`}
                                    onClick={toggleConfirmPasswordVisibility}
                                    style={{
                                        position: 'absolute',
                                        top: '50%',
                                        right: '10px',
                                        transform: 'translateY(-50%)'
                                    }}>
                                 <i className="bi bi-eye"></i>
                            </span>
                            </div>
                            {!passwordsMatch && (
                                <div className="alert alert-danger">
                                    Passwords do not match.
                                </div>
                            )}
                        </div>
                        <div className='ms-2 mt-3 '>
                            <h5>Personal details</h5>
                        </div>
                        <hr/>
                        <div className={'row row-cols-1 '}>
                            <div className={'col-md-6 register-type'}>
                                <div className="form-group m-2 ">
                                    <label>Name <i className="bi bi-asterisk"
                                                   style={{color: 'red', fontSize: '10px'}}></i></label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter name"
                                        onChange={(e) => setSelfLoginPayload((prevState) => ({
                                            ...prevState,
                                            name: e.target.value
                                        }))}
                                        value={selfLoginPayload.name}
                                        required
                                    />
                                </div>
                            </div>
                            <div className={'col-md-6 register-type'}>
                                <div className="form-group m-2 ">
                                    <label>Email</label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        placeholder="Enter email"
                                        onChange={(e) => setSelfLoginPayload((prevState) => ({
                                            ...prevState,
                                            email: e.target.value
                                        }))}
                                        value={selfLoginPayload.email}
                                    />
                                </div>
                            </div>
                            <div className={'col-md-6 register-type'}>
                                <div className="form-group m-2 ">
                                    <label>Mobile</label>
                                    <input
                                        type="text"
                                        className={`form-control ${
                                            (!mobileValid || mobileError) ? 'is-invalid' : ''
                                        }`}
                                        placeholder="Enter mobile"
                                        onChange={(e) =>
                                            setSelfLoginPayload((prevState) => ({
                                                ...prevState,
                                                mobile: e.target.value !== '' ? e.target.value : null,
                                            }))
                                        }
                                        value={selfLoginPayload.mobile ?? ''}
                                    />
                                    {!mobileValid && (
                                        <div className="invalid-feedback">
                                            Mobile number must be between {MAX_MOBILE_LENGTH} characters.
                                        </div>
                                    )}
                                    {mobileError && (
                                        <div className="invalid-feedback">{mobileError}</div>
                                    )}
                                </div>
                            </div>
                            <div className={'col-md-6 register-type'}>
                                <div className="form-group m-2">
                                    <label>Address </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter location"
                                        onChange={(e) => setSelfLoginPayload((prevState) => ({
                                            ...prevState,
                                            streetAddress: e.target.value
                                        }))}
                                        value={selfLoginPayload.streetAddress}
                                    />
                                </div>
                            </div>
                            <div className={'col-md-6 register-type'}>
                                <div className="form-group m-2  ">
                                    <label>School name </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter School name"
                                        onChange={(e) => setSelfLoginPayload((prevState) => ({
                                            ...prevState,
                                            schoolName: e.target.value
                                        }))}
                                        value={selfLoginPayload.schoolName}
                                    />
                                </div>
                            </div>
                            <div className={'col-md-6 register-type'}>
                                <div className="form-group m-2">
                                    <label>Class</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter class"
                                        onChange={(e) => setSelfLoginPayload((prevState) => ({
                                            ...prevState,
                                            classSchedule: e.target.value
                                        }))}
                                        value={selfLoginPayload.classSchedule}
                                    />
                                </div>
                            </div>

                        </div>
                        <GoogleReCaptcha
                            onVerify={setRecaptchaToken}
                            refreshReCaptcha={refreshReCaptcha}
                        />
                        <div className='mt-4 mb-4'>
                            <Button label={'Register'} loading={loading} disabled={signupButtonDisabled}
                                    cssName={'btn btn-register w-100 text-white'}/>
                        </div>
                        <div id="signInDiv" className="mb-3 d-flex justify-content-center"
                             style={{marginTop: "50px"}}>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
}
export default SelfLogin;