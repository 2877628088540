import React, {useEffect, useState} from "react";
import {useLocation, useParams} from "react-router-dom";
import {AddStudentBatch} from "./AddStudentBatch";
import {useAdminApiClient} from "../apiClients/AdminApiClient";
import Pagination from "../../../components/pagination/Pagination";
import {TableShimmer} from "../../../components/shimmer/Tableshimmer/TableShimmer";
import {AllToasts} from "../../../components/toast/AllToasts";
import {ConfirmationDialog} from "../../../components/confirmationDialog/ConfirmationDialog";
import {EmptyState} from "../../../components/emptyState/EmptyState";

export interface StudentData {
    id: number;
    name: string;
    gender: string;
    email: string;
    parentContact: number;
    active: boolean;
}

const BatchDetails = () => {
    const params = useParams();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const [searchQuery, setSearchQuery] = useState("");
    const [confirmation,setConfirmation]=useState<boolean>(false);
    const [targetId, setTargetId] = useState<string>('');
    const [pageSize, setPageSize] = useState<number>(10);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [showSubject, setShowSubject] = useState(false);
    const [totalElements, setTotalElements] = useState<number>(0);
    const [selectedStudentId, setSelectedStudentId] = useState(null);
    const [students, setStudents] = useState<StudentData[]>([]);
    const[loading,setLoading]=useState<boolean>(false);

    const batchesApiClient = useAdminApiClient();

    const sortStudentsById = (students: StudentData[]) => {
        // console.log('dataChange',students.slice().sort((a: StudentData, b: StudentData) => a.id - b.id))
        return students.slice().sort((a: StudentData, b: StudentData) => a.id - b.id);
    };

    const handleSuccess = () => {
        setShowSubject(false);
        setSelectedStudentId(null);
    };
    const getStudents = (batchId:string, page?:number, size?:number, query?:string) => {
        batchesApiClient.getStudents(batchId, page, size,query)
            .then(res=>{
                setStudents(res.data.content);
                setCurrentPage(res.data.number);
                setTotalPages(res.data.totalPages)
                setTotalElements(res.data.totalElements)
            })
            .catch((error)=>{
                console.error('Error fetching students:', error);
            });
    }
    useEffect(() => {
        if (params.id) {
            getStudents(params.id, currentPage, pageSize, searchQuery);
        }
    }, [params.id, currentPage, searchQuery]);
    const onAction = (isConfirm: boolean) => {
        if (!isConfirm) {
            setConfirmation(false);
        } else {
            deleteStudent(params?.id ?? '', targetId);
        }
    }



    const deleteStudent = (batchId: string, studentId: string) => {
        batchesApiClient.deleteStudent(batchId, studentId).then(() => {
            AllToasts.success('Student  removed successfully.');
            getStudents(params?.id || '');
        }).catch(() => {
            AllToasts.failure('Fail to delete.');
        }).finally(() => {
            setConfirmation(false);
        });
    }
    const renderColumns = () => {
        const columns = [];
        for (let i = 0; i < 8; i++) {
            columns.push(
                <tr>
                    <td><TableShimmer/></td>
                    <td><TableShimmer/></td>
                    <td><TableShimmer/></td>
                    <td><TableShimmer/></td>
                </tr>
            );
        }
        return columns;
    };


    return ( <div className={'container-fluid page-content'}>
            <div className="row page-titles mx-0 shadow-sm">
                <div className="col-sm-6 p-md-0">
                    <div className="welcome-text">
                        <h4>Batch details for {params.name}</h4>
                    </div>
                </div>
                <div className="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
                    <button className="btn btn-primary text-white rounded-1 btn-add" onClick={()=> setShowSubject(true)}>
                        <i className="bi bi-plus"></i> Add student
                    </button>
                </div>
            </div>
        <div className={'card border-0 shadow-sm'}>
            <div className={'card-body'}>
                <div className={'d-md-flex justify-content-between align-items-center'}>
                    <div className="form-group searchBar border rounded-2 bg-transparent">
                        <i className="bi bi-search formFeedback bg-transparent text-primary fw-bold"></i>
                        <input type="search" className="form-control formControl bg-light w-auto bg-white"
                               placeholder="Search here..." onChange={(e) => setSearchQuery(e.target.value)}/>
                    </div>
                    <div>

                    </div>
                </div>
                    {students && students.length > 0 ? (
                        <div className={'table-responsive'}>
                            <table className="table mt-3">
                                <thead>
                                <tr>
                                    <th scope="col">Student Id.</th>
                                    <th scope="col">Name</th>
                                    <th scope="col">Email</th>
                                    <th scope="col">Mobile No.</th>
                                    <th scope="col">Actions</th>
                                </tr>
                                </thead>
                                <tbody>
                                {loading ? renderColumns() : sortStudentsById(students)?.map((res: StudentData, index: number) => (
                                    <tr key={res.id}>
                                        <td>{res.id}</td>
                                        <td>{res.name}</td>
                                        <td>{res.email}</td>
                                        <td>{res.parentContact}</td>
                                        <td>
                                            <i
                                                className="bi bi-trash text-danger ms-2"
                                                onClick={() => {
                                                    if (res.id) {
                                                        setTargetId(res.id.toString());
                                                        setConfirmation(true);
                                                    }
                                                }}
                                            ></i>

                                        </td>

                                    </tr>
                                ))}
                                </tbody>

                            </table>
                        </div>
                    ) : (
                        <EmptyState/>
                    )}
                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    totalElements={totalElements}
                    onPageChange={(page) => getStudents(params.id ?? '', page, pageSize, searchQuery)}
                />
            </div>
        </div>

            <AddStudentBatch
                show={showSubject}
                onHide={() => setShowSubject(false)}
                batchId={params.id ?? ""}
                notInBatch={params.id ?? ''}
                onCancle={() => setShowSubject(false)}
                onSuccess={() => {
                    setShowSubject(true);
                    setSelectedStudentId(null);
                    getStudents(params.id ?? '', currentPage, pageSize, searchQuery);
                }}
                selectedStudentId={selectedStudentId}
            />
            <ConfirmationDialog title={'Remove Student'}
                                description={"Are you sure you want to remove Student from Batch?"} show={confirmation}
                                onAction={onAction}
                                onClose={() => setConfirmation(false)}/>

        </div>
    );
};

export default BatchDetails;
