import React, {ChangeEvent, useEffect, useState} from "react";
import {Option, Questions} from "../../../../teacher/components/modals/CreateiUpdateQuestion";
import {AddQuestionModal} from "../modal/addQuestionModal/AddQuestionModal";
import {PreviewScreen} from "../previewScreen/PreviewScreen";
import {MathX} from "../../../../../components/math/MathX";
import {useDataEntryQuestionsApiClient} from "../../../apiClients/UseDataEntryQuestionsApiClient";
import {AllToasts} from "../../../../../components/toast/AllToasts";
import {useNavigate, useParams} from "react-router-dom";
import {QuestionType} from "../../../../../modals/quizquestionData/Modal";
import {SubjectsData} from "../../../../admin/components/AdminSubjects";
import {useSubjectsApiClient} from "../../../../admin/apiClients/SubjectsApiClient";
import {SelectDropDown} from "./SelectDropDown";

export const CreateQuestion = () => {
    const subjectsApiClient = useSubjectsApiClient()
    const navigate = useNavigate();
    const params = useParams();
    const dataEntryQuestionApiClient = useDataEntryQuestionsApiClient();
    const [addQuestionModal, setAddQuestionModal] = useState<boolean>(false);
    const [options, setOptions] = useState<Option[]>([{id: -1, text: '', correct: false}]);
    const [selectedSubject, setSelectedSubject] = useState<{ value: string, label: string }[]>([]);
    const [selectedOptionTopic, setSelectedTopic] = useState<{ value: string, label: string }[]>([])
    const [selectedQuestionType, setSelectedQuestionType] = useState<QuestionType | undefined>(undefined);
    const [subjects, setSubjects] = useState<SubjectsData[]>([]);
    const [topics, setTopics] = useState<any[]>([]);
    const [show, setShow] = useState<boolean>(false)
    const [payload, setPayload] = useState<Questions>({
        id: -1, text: '', imageCid: '', options: [{id: -1, text: '', correct: false}], topicIds: [0], explanation: '',
        difficultyLevel: '', oneWordAnswer: '', knowledge_memory: '', student_preparation: '', analytical_thinking: '',
        concept_application: '', concept_clarity: '', problem_solving_skill: '', numerical_accuracy: '', logic: '',
        time_management: '', totalScore: '', category: '',
    });

    useEffect(() => {
        if (params && params.questionId) {
            getSingleQuestionById(Number(params.questionId));
        }
    }, [params]);

    useEffect(() => {
        getAllSubjects();
    }, [])

    useEffect(() => {
        if (params && !params.questionId) {
            const subject = localStorage.getItem('Subject');
            const Topic = localStorage.getItem('Topic');
            const Question = localStorage.getItem('Question Type');
            if (subject && Topic) {
                setSelectedSubject(JSON.parse(subject));
                setSelectedTopic(JSON.parse(Topic));
                setSelectedQuestionType(Question as QuestionType);
            } else {
                setSelectedSubject([]);
                setSelectedTopic([]);
                setSelectedQuestionType(undefined);
            }
        }
    }, [localStorage, params]);

    useEffect(() => {
        if (params && params.questionId) {
            const selectedSubjects = subjects?.filter(subject => {
                return payload.topics?.some((questionTopic: any) => {
                    return subject.topics.some((subjectTopic: any) => subjectTopic.id === questionTopic.id)
                        ;});
            });
            setSelectedSubject(selectedSubjects?.map((subject: any) => ({value: subject.id, label: subject.name})));
            setSelectedTopic(payload.topics?.map((res: any) => ({value: res.id, label: res.name})) ?? []);
            setSelectedQuestionType(payload.type);
        }
    }, [params.questionId, subjects]);


    useEffect(() => {
        if (params && params['*'] === 'createQuestion') {
            if (selectedQuestionType === 'TrueFalse') {
                setOptions([{id: -1, text: 'True', correct: false}, {id: -1, text: 'False', correct: false}]);
            } else if (selectedQuestionType === 'OneAnswer') {
                setOptions([{id: -1, text: '', correct: false}, {id: -1, text: '', correct: false},
                    {id: -1, text: '', correct: false}, {id: -1, text: '', correct: false}]);
            } else if (selectedQuestionType === 'MultiSelect') {
                setOptions([{id: -1, text: '', correct: false}, {id: -1, text: '', correct: false},
                    {id: -1, text: '', correct: false}, {id: -1, text: '', correct: false}]);
            } else if (selectedQuestionType === 'OneWord') {
                setOptions([{id: -1, text: '', correct: false}]);
            } else {
                setOptions([{id: -1, text: '', correct: false}]);
            }
        }
    }, [selectedQuestionType]);

    const getAllSubjects = () => {
        subjectsApiClient.getSubjects().then((res) => {
            setSubjects(res.data);
        });
    };

    const clearOptions = () => {
        let updatedOptions = [];
        if (selectedQuestionType === 'TrueFalse') {
            updatedOptions = [{id: -1, text: 'True', correct: false}, {id: -1, text: 'False', correct: false}];
        } else if (selectedQuestionType === 'OneAnswer') {
            updatedOptions = [{id: -1, text: '', correct: false}, {id: -1, text: '', correct: false},
                {id: -1, text: '', correct: false}, {id: -1, text: '', correct: false}];
        } else if (selectedQuestionType === 'MultiSelect') {
            updatedOptions = [{id: -1, text: '', correct: false}, {id: -1, text: '', correct: false},
                {id: -1, text: '', correct: false}, {id: -1, text: '', correct: false}];
        } else if (selectedQuestionType === 'OneWord') {
            updatedOptions = [{id: -1, text: '', correct: false}];
        } else {
            updatedOptions = [{id: -1, text: '', correct: false}];
        }
        setOptions(updatedOptions);
        setPayload({
            id: -1, text: '', imageCid: '', options: [], topicIds: [0], explanation: '', difficultyLevel: '',
            oneWordAnswer: '', knowledge_memory: '', student_preparation: '', analytical_thinking: '',
            concept_application: '', concept_clarity: '', problem_solving_skill: '', numerical_accuracy: '',
            logic: '', time_management: '', totalScore: '', category: '',
        });
    }

    const getSingleQuestionById = (questionStorageId: number) => {
        dataEntryQuestionApiClient.getQuestionById(questionStorageId).then((res) => {
            setPayload(res.data);
            setOptions(res.data.options);
        }).catch((err) => {
            console.log(err);
        });
    };

    const createQuestions = (payload: Questions) => {
        dataEntryQuestionApiClient.createQuestions(payload).then((res) => {
            AllToasts.success('Question Created Successfully')
            clearOptions();
        }).catch((err) => {
            console.log(err)
            AllToasts.failure(`Error ${err.response.data.detail}`)
        })
    }

    const updateQuestion = (questionStorageId: number, payload: Questions) => {
        dataEntryQuestionApiClient.updateQuestion(questionStorageId, payload).then((res) => {
            AllToasts.success('Question Updated Successfully');
            clearOptions();
            navigate('/dataEntry')
        }).catch((err) => {
            console.log(err);
            AllToasts.failure(`Error ${err.response.data.detail}`)
        })
    }

    const handleChange = (event: any) => {
        const {name, value} = event.currentTarget;
        setPayload((prevState) => ({...prevState, [name]: value}));
    };

    const handleOptionCorrectChanges = (index: number, correct: boolean) => {
        const newOptions = options.map((option, i) => {
            if (i === index) {
                option.correct = correct;
            } else {
                option.correct = false;
            }
            return option;
        });
        setOptions(newOptions);
        setPayload((prevPayload) => ({
            ...prevPayload,
            options: newOptions
        }));
    };

    const handleOptionCorrectChange = (index: number, correct: boolean) => {
        setOptions((prevOptions) => {
            const newOptions = [...prevOptions];
            newOptions[index].correct = correct;
            setPayload((prevPayload) => ({
                ...prevPayload,
                options: newOptions
            }));
            return newOptions;
        });
    };

    const handleOptionTextChange = (index: number, text: string,) => {
        const newOptions = [...options];
        newOptions[index].text = text;
        setOptions(newOptions);
        setPayload((prevPayload) => ({
            ...prevPayload,
            options: newOptions
        }));
    };

    const handlePayloadChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        setPayload(prevPayload => ({
            ...prevPayload,
            [event.target.name]: event.target.value
        }));
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
        const updatedPayload = {
            ...payload,
            topicIds: selectedOptionTopic?.map((res) => parseInt(res.value)),
            type: selectedQuestionType as QuestionType,
            options: selectedQuestionType === 'OneWord' ? [{id: -1, text: '', correct: false}] : payload.options,
        };
        createQuestions(updatedPayload);
    }

    const handleSave = () => {
        const updatedPayload = {
            ...payload,
            topicIds: selectedOptionTopic.map((res) => parseInt(res.value)),
            type: selectedQuestionType as QuestionType,
            options: selectedQuestionType === 'OneWord' ? [{id: -1, text: '', correct: false}] : payload.options,
        };
        if (params && params.questionId) {
            updateQuestion(Number(params.questionId), updatedPayload)
        } else {
            createQuestions(updatedPayload);
        }
    }

    const isAnyFieldEmpty = () => {
        return (
            payload.text === '' ||
            (selectedQuestionType !== 'OneWord' && payload.options.some(option => option.text === ''))
        );
    };

    const handleSubjectChange = (selectedSubject: { label: string, value: string }[]) => {
        if (selectedSubject && selectedSubject.length > 0) {
            const selectedSubjectId = selectedSubject[0].value;
            const subject = subjects.find((subject) => subject.id.toString() === selectedSubjectId);
            if (subject) {
                const topicsOfSelectedSubject = subject.topics || [];
                setTopics(topicsOfSelectedSubject);
                const updatedSelectedTopics = selectedOptionTopic.filter((selectedTopic) =>
                    topicsOfSelectedSubject.some((topic) => topic.id.toString() === selectedTopic.value)
                );
                setSelectedTopic(updatedSelectedTopics);
                setPayload((prevPayload) => ({
                    ...prevPayload,
                    topicId: updatedSelectedTopics?.map((topic) => parseInt(topic.value)),
                }));
            }
        }
    };

    const handleTopicsChange = (selectedTopics: { label: string, value: number }[]) => {
        setSelectedTopic(selectedTopics.map(topic => ({label: topic.label, value: topic.value.toString()})));
        const associatedSubject = subjects.find(subject =>
            selectedTopics.every(selectedTopic =>
                subject.topics.some(topic => topic.id === selectedTopic.value)
            )
        );
        if (associatedSubject) {
            const {id, name} = associatedSubject;
            const modifiedSubject = {value: id.toString(), label: name};
            setSelectedSubject([modifiedSubject]);
            setPayload(prevPayload => ({
                ...prevPayload,
                topicId: selectedTopics.map(topic => topic.value),
            }));
        } else {
            setPayload(prevPayload => ({
                ...prevPayload,
                topicId: [],
            }));
        }
    };

    const handleDropDownSelect = (event: ChangeEvent<HTMLSelectElement>) => {
        const {name, value} = event.currentTarget;
        localStorage.setItem('Question Type', value)
        setSelectedQuestionType(value as QuestionType);
        if (event.target.value === 'TrueFalse') {
            setOptions(prevOptions => [
                {id: -1, text: 'True', correct: false}, {id: -1, text: 'False', correct: false}
            ]);
            setPayload(prevPayload => ({
                ...prevPayload,
                options: [{id: -1, text: 'True', correct: false}, {id: -1, text: 'False', correct: false}]
            }));
        } else if (event.target.value === 'OneAnswer') {
            setOptions(prevOptions =>
                [{id: -1, text: '', correct: false}, {id: -1, text: '', correct: false},
                    {id: -1, text: '', correct: false}, {id: -1, text: '', correct: false}]);
            setPayload(prevPayload => ({
                ...prevPayload,
                options: [
                    {id: -1, text: '', correct: false}, {id: -1, text: '', correct: false},
                    {id: -1, text: '', correct: false}, {id: -1, text: '', correct: false}
                ]
            }));
        } else if (event.target.value === '') {
            setOptions(prevOptions => [
                {id: -1, text: '', correct: false}
            ]);
            setPayload(prevPayload => ({
                ...prevPayload,
                options: [
                    {id: -1, text: '', correct: false}
                ]
            }))
        } else if (event.target.value === 'MultiSelect') {
            setOptions(prevOptions => [
                {id: -1, text: '', correct: false}, {id: -1, text: '', correct: false},
                {id: -1, text: '', correct: false}, {id: -1, text: '', correct: false}
            ])
            setPayload(prevPayload => ({
                ...prevPayload,
                options: [
                    {id: -1, text: '', correct: false}, {id: -1, text: '', correct: false},
                    {id: -1, text: '', correct: false}, {id: -1, text: '', correct: false}
                ]
            }));
        } else if (value === 'OneWord') {
            setOptions([{id: -1, text: '', correct: false}]);
            setPayload(prevPayload => ({
                ...prevPayload,
                options: [{id: -1, text: '', correct: false}]
            }));
        }
        setPayload((prevState) => ({...prevState, [name]: value}));
    };

    return (
        <div style={{width: '100%'}} className={'bg-light d-flex'}>
            <div style={{width: '40%'}}>
                <PreviewScreen selectedQuestionType={selectedQuestionType} text={payload.text} payload={payload}/>
            </div>
            <div style={{width: '60%'}} className='container'>
                <div
                    className="d-flex flex-row align-items-center justify-content-between card border-0 shadow-sm p-3 mt-3">
                    <h6>Question {'>'} Create Draft</h6>
                    <div className='d-flex align-items-center justify-content-center cursorPointer'
                         onClick={() => navigate(`/dataEntry`)}>
                        <i className={'bi bi-arrow-bar-left me-2 fs-5'}></i>
                        <div>Back</div>
                    </div>
                </div>
                <form onSubmit={(e) => handleSubmit(e)}>
                    <div className="card border-0 shadow-sm p-3 mt-3">
                        {params.questionId ?
                            <div>
                                <SelectDropDown subjects={subjects} handleSubjectChange={handleSubjectChange}
                                                selectedSubject={selectedSubject} topics={topics} show={show}
                                                handleTopicsChange={handleTopicsChange}
                                                selectedOptionTopic={selectedOptionTopic}
                                                handleDropDownSelect={handleDropDownSelect}
                                                handleChange={handleChange} payload={payload}
                                                selectedQuestionType={selectedQuestionType}
                                                setShow={() => setShow(!show)}
                                />
                            </div>
                            :
                            <div className='d-flex align-items-center '>
                                <div className='w-25 me-5'>
                                    <label className='form-label'>Subject</label>
                                    <select className='form-select bg-light' disabled={true}>
                                        <option>{selectedSubject.map((res) => res.label)[0]}</option>
                                    </select>
                                </div>
                                <div className='w-25 me-5 '>
                                    <div className={'input-group '}>
                                        <div className='flex-column'>
                                            <label className='form-label'>Topic</label>
                                            <select className='form-select bg-light' disabled={true}>
                                                {selectedOptionTopic.map((res) => (
                                                    <option key={res.value} value={res.value}>
                                                        {res.label}
                                                    </option>
                                                ))}
                                            </select>
                                            {/*<span className='mt-4 ms-1'>{selectedOptionTopic.length}</span>*/}
                                        </div>
                                    </div>
                                </div>

                                <div className='w-25 me-5'>
                                    <label className='form-label'>Question Type</label>
                                    <select className='form-select bg-light' disabled={true}>
                                        <option>{selectedQuestionType}</option>
                                    </select>
                                </div>
                                <div className='mt-4' onClick={() => setAddQuestionModal(true)}>
                                    <i className='bi bi-pencil-square fs-5 cursorPointer'/>
                                </div>
                            </div>
                        }
                        <div className={'d-flex flex-column mt-4 mb-1'}>
                            <label className='form-label'>Questions</label>
                            <textarea className='form-control' name="text" value={payload.text}
                                      onChange={handlePayloadChange}/>
                        </div>
                        <div className='d-flex w-100 align-items-center justify-content-between flex-wrap'>
                            {selectedQuestionType === "OneWord" ? (
                                    <div className='w-75'>
                                        <label htmlFor={'oneWordAnswer'}>Options<span
                                            className='ms-1 text-danger mb-2'>*</span></label>
                                        <input id={'oneWordAnswer'} className="form-control mt-2 mb-3" type="text"
                                               name={'oneWordAnswer'} placeholder={`Option`}
                                               value={payload.oneWordAnswer}
                                               onChange={(e) => handleChange(e)} required/>
                                    </div>
                                ) :
                                options?.map((option, index) => (
                                    <div key={`${index}-${option.correct}`} className=' '>
                                        <div className='d-flex flex-row align-items-center'>
                                            <div className='d-flex flex-row align-items-center mt-2'>
                                                {(selectedQuestionType === 'TrueFalse' || selectedQuestionType === 'OneAnswer') ? (
                                                    <input id={`correctOption${index}`} type='radio'
                                                           className='checkbox-style'
                                                           checked={option.correct} name={`radioOption`} onChange={() =>
                                                        handleOptionCorrectChanges(index, !option.correct)}
                                                    />
                                                ) : (
                                                    <input
                                                        id={`correctOption${index}`} type='checkbox'
                                                        className='checkbox-style'
                                                        checked={option.correct} name={`checkboxOption`}
                                                        onChange={() => handleOptionCorrectChange(index, !option.correct)}/>
                                                )}
                                            </div>
                                            <div className='w-75'>
                                                <label htmlFor={`options${index}`}>
                                                    Options {index + 1}
                                                    <span className='ms-1 text-danger mb-2'>*</span>
                                                </label>
                                                <MathX> <input id={`options${index}`} className="form-control mt-2 mb-3"
                                                               type="text" name={'options'}
                                                               placeholder={`Option ${index + 1}`}
                                                               value={option.text}
                                                               onChange={(e) => handleOptionTextChange(index, e.target.value)}
                                                               required/></MathX>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        </div>
                        <div className='d-flex flex-column mb-2'>
                            <label className='form-label'>Explanation</label>
                            <textarea className={'form-control'} name="explanation" value={payload.explanation}
                                      onChange={handlePayloadChange}/>
                        </div>
                        <div className='d-flex align-items-center justify-content-end'>
                            {params && params.questionId ?
                                <div>
                                    <button
                                        type={'button'}
                                        className={`${isAnyFieldEmpty() ? 'btnDisabled' : 'btn btn-outline-success me-3'}`}
                                        disabled={isAnyFieldEmpty()}
                                        onClick={() => handleSave()}
                                    >
                                        Update
                                    </button>
                                </div> :
                                <div>
                                    <button type={'submit'} className='btn btn-success'
                                            disabled={isAnyFieldEmpty()}>Save & Add
                                    </button>
                                </div>
                            }
                        </div>
                    </div>
                </form>
            </div>
            <AddQuestionModal show={addQuestionModal} onHide={() => setAddQuestionModal(false)}
                              payload={payload}
            />
        </div>
    );
};

