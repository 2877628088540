import React, { useState } from "react";

const ReadMore = ({ text, imageUrl }: any) => {
    const [isReadMore, setIsReadMore] = useState(false);

    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };

    return (
        <div className="text">
            {isReadMore ? (
                <div className={'d-flex flex-column'}>
                    {text}
                    {imageUrl && <img src={imageUrl} alt="Read more" style={{ maxWidth: "700px", maxHeight: "700px" }} />}
                    {text && text.length > 110 && <span style={{ color: "rgb(52, 152, 219)", cursor: "pointer" }} onClick={toggleReadMore}> show less</span>}
                </div>
            ) : (
                <>
                    {text && text.length > 110 ? text.slice(0, 110) + "..." : text}
                    {text && text.length > 110 && <span style={{ color: "rgb(52, 152, 219)", cursor: "pointer" }} onClick={toggleReadMore}>read more</span>}
                </>
            )}
        </div>
    );
};

export default ReadMore;
