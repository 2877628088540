import {Option, PostData, Question, QuestionType, ViewType} from "../../../../../modals/quizquestionData/Modal";
import React, {useEffect, useState} from "react";
import {MathX} from "../../../../../components/math/MathX";
import {useParams} from "react-router-dom";


export const QuizQuestion = (props: {
    question: Question,
    selectedOptionId?: number[] | undefined,
    attemptedOption?: number,
    onOptionSelect?: (option: Option[]) => void,
    highlightedQuestionId?: number,
    quizView: string,
    onPostDataChange?: (postData: PostData) => void,
    answer?: string
    status?: string
    correctOption?: string
    attemptedOptions?: number[]
}) => {

    const [selectedOptions, setSelectedOptions] = useState<Map<number, Option>>(new Map());
    const params = useParams();

    useEffect(() => {
        if (selectedOptions.size === 0) {
            const initialOptions = new Map<number, Option>();
            if (props.selectedOptionId) {
                props.selectedOptionId.forEach((id) => {
                    const option = props.question.options && props.question.options.find((o) => o.id === id);
                    if (option) {
                        initialOptions.set(option.id, option);
                    }
                });
            }
            setSelectedOptions(initialOptions);
        } else {
            const updatedOptions = new Map<number, Option>(selectedOptions);
            props.selectedOptionId?.forEach((id) => {
                const option = props.question.options && props.question.options.find((o) => o.id === id);
                if (option) {
                    updatedOptions.set(option.id, option);
                }
            });
            setSelectedOptions(updatedOptions);
        }
    }, [props.question, props.selectedOptionId]);

    const handleSelection = (selectedOption: Option, reset: boolean) => {
        setSelectedOptions((prevState) => {
            const options = new Map<number, Option>(reset ? null : prevState);
            options.set(selectedOption.id, selectedOption);
            const postData: PostData = {
                type: props.question?.type ?? "UnknownType",
                question: props.question,
                selectedOptions: (Array.from(options.values()))
            };
            if (props.onPostDataChange)
                props.onPostDataChange(postData);

            if (props.onOptionSelect)
                props.onOptionSelect(Array.from(options.values()));
            return options;
        });
    }

    const handleDeSelection = (selectedOption: Option) => {
        setSelectedOptions((prevState) => {
            const options = new Map(prevState);
            console.log(options)
            options.delete(selectedOption.id);

            if (props.onPostDataChange) {
                props.onPostDataChange({
                    question: props.question,
                    type: QuestionType.MultiSelect,
                    selectedOptions: (Array.from(options.values()))
                });
            }
            return options;
        });
    }

    const getBorderStyle = (option: Option) => {
        if (props.quizView === ViewType.REVIEW) {
            if (option.correct) {
                return 'green';
            } else if (props?.selectedOptionId?.indexOf(option.id) !== -1 || props.attemptedOption && props.attemptedOption === option.id) {
                return 'red';
            }
        } else if ((props.highlightedQuestionId || -1) > -1) {
            if (option.correct) {
                return 'green';
            } else if (props.selectedOptionId && props.selectedOptionId.includes(option.id)) {
                return 'red';
            }
        } else {
            return '';
        }
    };

    const getBorderColor = () => {
        if ((props.highlightedQuestionId || -1) > 1 || props.quizView === ViewType.REVIEW) {
            if (props?.answer?.trim().toLowerCase() === props.question.oneWordAnswer?.trim().toLowerCase()) {
                // console.log("true")
                return 'green'
            } else
                return 'red'
        }
    }

    const handleResetButton = () => {
        if (props.question.type === QuestionType.OneWord) {
            if (props.onPostDataChange) {
                props.onPostDataChange({
                    answer: undefined,
                    question: props.question,
                    type: QuestionType.OneWord
                });
            }
            setSelectedOptions(new Map())
        } else if (props.question.type === QuestionType.MultiSelect) {
            if (props.onPostDataChange) {
                props.onPostDataChange({
                    answer: '',
                    question: props.question,
                    type: QuestionType.MultiSelect
                });
            }
            setSelectedOptions(new Map())

        } else if (props.question.type === QuestionType.OneAnswer) {
            if (props.onPostDataChange) {
                props.onPostDataChange({
                    answer: undefined,
                    question: props.question,
                    type: QuestionType.OneAnswer
                });
            }
            setSelectedOptions(new Map())

        } else if (props.question.type === QuestionType.TrueFalse) {
            if (props.onPostDataChange) {
                props.onPostDataChange({
                    answer: undefined,
                    question: props.question,
                    type: QuestionType.TrueFalse
                });
            }
            setSelectedOptions(new Map())
        }
    }
    return (
        <div>
            {props.question.options &&
                props.question.options
                    .filter((choice) => choice.text !== '' || choice.url).map((choice: Option, index) => {
                    return (
                        <label
                            key={choice.id}
                            className='d-flex align-items-center py-2 my-2 px-3 questionLabel'
                            style={{
                                borderColor: getBorderStyle(choice)
                            }}>
                            {params['*'] === 'createTest' || params['*'] === 'Verification' || params['*'] === 'audit' ?
                            <div>
                                {
                                    props.question.type !== QuestionType.MultiSelect ?
                                        (
                                            <div>
                                                <input
                                                    type='radio'
                                                    name='choice'
                                                    checked={(choice.correct ?? false)}
                                                    className='choice-box index'
                                                    onChange={() => {
                                                        handleSelection(choice, true);
                                                    }}
                                                />
                                            </div>
                                        ) :
                                        (
                                            <input
                                                type='checkbox'
                                                name={`choice ${index}`}
                                                checked={(choice.correct ?? false)}
                                                className='choice-box index'
                                                onChange={(event) => {
                                                    if (!event.target.checked) {
                                                        handleDeSelection(choice)
                                                    } else {
                                                        handleSelection(choice, false)
                                                    }
                                                }}
                                            />
                                        )
                                }
                            </div> :

                                <div>
                                    {
                                        props.question.type !== QuestionType.MultiSelect ?
                                            (
                                                <div>
                                                    <input
                                                        type='radio'
                                                        name='choice'
                                                        checked={(props.quizView === "REVIEW" ? props.attemptedOption === choice.id : props.selectedOptionId && props.selectedOptionId.includes(choice.id))}
                                                        className='choice-box index'
                                                        onChange={() => {
                                                            handleSelection(choice, true);
                                                        }}
                                                    />
                                                </div>
                                            ) :
                                            (
                                                <input
                                                    type='checkbox'
                                                    name={`choice ${index}`}
                                                    checked={(props.selectedOptionId && props.selectedOptionId.some((selectedId) =>
                                                        selectedId === choice.id))}
                                                    className='choice-box index'
                                                    onChange={(event) => {
                                                        if (!event.target.checked) {
                                                            handleDeSelection(choice)
                                                        } else {
                                                            handleSelection(choice, false)
                                                        }
                                                    }}
                                                />
                                            )
                                    }
                                </div>
                            }
                            <span className='ms-3 d-flex'>
                              {choice.url ? (
                                  <img
                                      src={choice.url}
                                      alt={`Option ${index + 1}`}
                                      className='option-Image img-fluid'
                                      style={{maxWidth: '100%', maxHeight: 'auto', width: '300px', height: '220px'}}
                                  />

                              ) : (
                                  <MathX>{choice.text}</MathX>
                              )}

                                {
                                    props.quizView === "REVIEW" &&
                                    <div className={'d-flex align-items-center justify-content-center'}>
                                        {choice.correct && <i className={'bi bi-check-lg text-success ms-3 fs-6'}/>}
                                        {!choice.correct && (props.attemptedOption === choice.id || props.selectedOptionId && props.selectedOptionId.some((selectedId) =>
                                                selectedId === choice.id)) &&
                                            <i className={'bi bi-x-lg ms-3 fs-6 text-danger'}/>}
                                    </div>
                                }
                            </span>
                        </label>
                    );
                })}
            {
                props.question.type === QuestionType.OneWord &&
                <div>
                    <span className='ms-1 col-2'>Your Answer :</span>
                    <input type={'text'} style={{borderColor: getBorderColor()}}
                           className={'one-word  py-2 px-2 border-1 questionLabel'} onChange={(e) => {
                        if (props.onPostDataChange)
                            props.onPostDataChange({
                                answer: e.target.value,
                                question: props.question,
                                type: QuestionType.OneWord
                            });
                    }} value={props.answer?.length ? props.answer : props.question.oneWordAnswer}
                           placeholder={'Enter your Answer here ...'}/>
                    {getBorderColor() === "red" &&
                        <div className={'mt-3'}>
                            <label className='d-flex align-items-center p-0  questionLabel'>
                                <span className='ms-1 col-2'>Correct Answer :</span>
                                <input value={props.question.oneWordAnswer}
                                       className={'one-word border-0 py-2  ps-2 flex-fill'}/>
                            </label>
                        </div>
                    }
                </div>
            }
            {/*{params.testType === "TEST" || params.testType === "ASSIGNMENT" ?*/}
            {/*    <div className='d-flex align-items-center justify-content-end'>*/}
            {/*        <button type={"button"} className={'btn  btn-outline-success mt-2'} onClick={handleResetButton}>*/}
            {/*            Reset Answer*/}
            {/*        </button>*/}
            {/*    </div> : ""}*/}
        </div>
    );
};
