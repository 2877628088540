export class Helper {
    static removeStringSpaces(inputString: string): string {
        return inputString.replace(/\s+/g, '');
    }

    static targetLocation(location: any): Promise<string> {
        return new Promise<string>((resolve) => {
            const currentPath = location.pathname+location.hash,
            locations = Helper.slpitDataType(currentPath, '/'),
            targetLocation = locations[locations.length - 1];
            console.log(locations);
            resolve(targetLocation);
        });
    }

    static slpitDataType(str: string, separator: any) {
        return str.split(separator);
    }

    static getAbsolutePathname = (pathname: string): string=> {
        let constructedPathname = pathname;
        if (pathname.endsWith("/")) {
            constructedPathname = pathname.substring(0, pathname.length-1);
        }
        return constructedPathname;
    }
}