import React, {useEffect, useState} from 'react';
import './CreateTestAssignment.css';
import {TeacherCreateQuestions} from '../../../../modal/CreateQuestions';
import CreateUpdateQuestions from '../../createQuestion/CreateUpdateQuestions';
import CreateTestModal from '../../../../modal/CreateTestModal';
import {useTeacherApiClient} from '../../../../apiClients/TeacherApiClient';
import Pagination from '../../../../../../components/pagination/Pagination';
import TeacherSelectedQuestionList from '../../../Sidebar/TeacherSelectedQuestionList';
import {useDispatch} from 'react-redux';
import {
    addSelectedQuestion,
    removeAllQuestions,
    removeSelectedQuestion
} from '../../../../../../redux/reducers/QuestionListSlice';
import {EmptyState} from '../../../../../../components/emptyState/EmptyState';
import {InitialDataState, NetworkState, PromiseData} from '../../../../../../modals/PromiseData';
import Skeleton from 'react-loading-skeleton';
import QuestionList from '../questionList/QuestionList';
import FilterDropdown from "../filterDropdown/FilterDropdown";
import {SelectedOptionProgram, SelectedOptionsCourse,} from "../../createQuestion/SelectedOptions";
import {CreateAssignmentQuesListing} from "../createAssignmentComprehensionList/CreateAssignmentQuesListing";
import {Passage} from "../../../../../../dto/CreateAssignmentQuestionListing";
import {Button, Modal} from "react-bootstrap";

export enum SelectedQuestionType {
    InitialState = '',
    General = 'General',
    Comprehension = 'Comprehension'
}

const CreateAssignment = () => {
    const [filter, setFilter] = useState<boolean>(true);
    const [modal, showModal] = useState<boolean>(false)
    const [editQuestionModal, setEditQuestionModal] = useState<boolean>(false);
    const [createTestModal, setCreateTestModal] = useState<boolean>(false);
    const [selectedQuestionModal, setSelectedQuestionModal] = useState<boolean>(false);
    const [question, setQuestion] = useState<PromiseData<TeacherCreateQuestions[]>>(InitialDataState);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(10);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [totalElements, setTotalElements] = useState<number>(0);
    const [query, setQuery] = useState<string>('');
    const [questionId, setQuestionId] = useState<number | undefined>(0);
    const [selectedQuestions, setSelectedQuestions] = useState<any[]>([]);
    const [selectedOptionCourse, setSelectedOptionCourse] = useState<SelectedOptionsCourse[] | null>(null);
    const [courseId, setCourseId] = useState<number[]>([]);
    const [selectedOptionProgram, setSelectedOptionProgram] = useState<SelectedOptionProgram[] | null>(null)
    const [programId, setProgramId] = useState<number[]>([])
    const [selectedOptionChapters, setSelectedOptionChapters] = useState<number[] | null>(null)
    const [selectedOptionTopic, setSelectedOptionTopic] = useState<number[] | null>(null)
    const [isFiltersDisabled, setIsFiltersDisabled] = useState<boolean>(true);
    const [selectedQuestionType, setSelectedQuestionType] = useState<SelectedQuestionType>(SelectedQuestionType.General);
    const [questionType, setQuestionType] = useState<string>(SelectedQuestionType.InitialState);
    const [comprehensionQue, setComprehensionQue] = useState<PromiseData<Passage[]>>(InitialDataState)
    const [expandedQuestion, setExpandedQuestion] = useState<{ id: number | null; expanded: boolean }>({
        id: null, expanded: false,
    });
    const [isComprehension, setIsComprehension] = useState<boolean>(false)
    const teacherApiClient = useTeacherApiClient();
    const dispatch = useDispatch();

    useEffect(() => {
        if (selectedOptionCourse && selectedOptionProgram) {
            const delayTimer = setTimeout(() => {
                if (selectedQuestionType === SelectedQuestionType.Comprehension) {
                    getAllComprehensions(0, pageSize, query, courseId[0], programId, selectedOptionChapters || [], selectedOptionTopic || [], [])
                } else if (selectedQuestionType === SelectedQuestionType.General) {
                    getAllQuestionsCreateAssignment(0, pageSize, [], query);
                }
            }, 500);
            return () => {
                clearTimeout(delayTimer);
            };
        }
    }, [query, selectedQuestionType]);

    useEffect(() => {
        if (currentPage !== 0 && selectedOptionCourse && selectedOptionProgram) {
            if (selectedQuestionType === SelectedQuestionType.Comprehension) {
                getAllComprehensions(0, pageSize, query, courseId[0], programId, selectedOptionChapters || [], selectedOptionTopic || [], [])
            } else if (selectedQuestionType === SelectedQuestionType.General) {
                getAllQuestionsCreateAssignment(0, pageSize, [], query);
            }
        }
    }, [query]);

    const getAllQuestionsCreateAssignment = (page?: number, size?: number, sort?: string[], query?: string, includeActive?: boolean,
                                             testId?: number[], courseId?: number, flagged?: boolean, programId?: number
        , chapterId?: number[], topicId?: number[], subject?: number[], verified?: string) => {
        setQuestion({state: NetworkState.Loading});
        teacherApiClient
            .getAllQuestions(page, size, [], query, includeActive, testId,
                courseId || (selectedOptionCourse ? selectedOptionCourse?.map((courseId) => courseId.value)[0] : undefined), undefined,
                programId || (selectedOptionProgram ? selectedOptionProgram?.map((programId) => programId.value)[0] : undefined),
                chapterId || (selectedOptionChapters ?? undefined),
                topicId || (selectedOptionTopic ?? undefined), subject, verified
            )
            .then((res) => {
                setQuestion({state: NetworkState.Success, data: res.data.content});
                setCurrentPage(res.data.number);
                setTotalPages(res.data.totalPages);
                setTotalElements(res.data.totalElements);
                setPageSize(pageSize);
            })
            .catch((err) => {
                setQuestion({state: NetworkState.Failed, message: err.message});
                console.error(err);
            });
    };

    const handleApplyFilters = () => {
        if (courseId && programId) {
            setIsFiltersDisabled(false);
            if (selectedQuestionType === SelectedQuestionType.Comprehension) {
                getAllComprehensions(currentPage, pageSize, query, courseId[0], programId, selectedOptionChapters || [], selectedOptionTopic || [], [])
            } else if (selectedQuestionType === SelectedQuestionType.General) {
                getAllQuestionsCreateAssignment(currentPage, pageSize, [], query, false, [] ?? undefined,
                    courseId[0], undefined, programId[0], selectedOptionChapters || [], selectedOptionTopic || [], [], '');
            }
        } else {
            setIsFiltersDisabled(true);
        }
    };

    const handleQuestionCheckboxChange = (selectedId: number, questionText: string[]) => {
        if (selectedId) {
            const existingQuestion = selectedQuestions.find((q) => q.id === selectedId);
            if (existingQuestion) {
                handleRemoveQuestion(selectedId);
                // @ts-ignore
                dispatch(removeSelectedQuestion(selectedId));
            } else {
                handleAddQuestion(selectedId, questionText);
                // @ts-ignore
                dispatch(addSelectedQuestion({id: selectedId, text: questionText}));
            }
        } else {
            handleRemoveAllQuestion();
        }
    };

    const handleRemoveAllQuestion = () => {
        setSelectedQuestions([]);
        dispatch(removeAllQuestions());
    };

    const handleAddQuestion = (id: number, text: string[]) => {
        const updatedSelections = [...selectedQuestions, {id, text}];
        setSelectedQuestions(updatedSelections);
    };

    const handleRemoveQuestion = (id: string | number) => {
        const updatedSelections = selectedQuestions.filter((q) => q.id !== id);
        setSelectedQuestions(updatedSelections);
    };

    const handleQuestionClick = (questionId: number) => {
        if (expandedQuestion.id === questionId) {
            setExpandedQuestion({id: null, expanded: false});
        } else {
            setExpandedQuestion({id: questionId, expanded: true});
        }
    };

    const checkedSelect = (selectQuestionId: number) => {
        return selectedQuestions.some((question) => question.id === selectQuestionId);
    };

    const renderColumns = () => {
        const columns = [];
        for (let i = 0; i < 10; i++) {
            columns.push(
                <table key={i}>
                    <tbody>
                    <tr>
                        <Skeleton height={60} width={1200}/>
                    </tr>
                    </tbody>
                </table>
            );
        }
        return columns;
    };

    const handleCourseChange = (selectedOptionCourse: SelectedOptionsCourse[]) => {
        setSelectedOptionCourse(selectedOptionCourse);
        setCourseId(selectedOptionCourse.map((res) => res.value))
    };
    const handleProgramChange = (selectedOptionProgram: SelectedOptionProgram[]) => {
        setSelectedOptionProgram(selectedOptionProgram);
        setProgramId(selectedOptionProgram.map((res) => res.value))
    };
    const handleChaptersChange = (selectedOptionChapters: (number | string)[]) => {
        if (selectedOptionChapters) {
            const numericChapters = selectedOptionChapters.map(chapter => {
                if (typeof chapter === 'string' && chapter.startsWith('chapter_')) {
                    return parseInt(chapter.replace('chapter_', ''), 10);
                } else if (typeof chapter === 'number') {
                    return chapter;
                }
                return 0;
            });
            setSelectedOptionChapters(numericChapters);
        }
    };

    const getAllComprehensions = (page?: number, size?: number, query?: string, courseId?: number, programId?: number[], chapterId?: number[], topicId?: number[], subject?: number[]) => {
        setComprehensionQue({state: NetworkState.Loading, data: []});
        teacherApiClient.getAllComprehensions(page, size, query, courseId, programId, chapterId, topicId, subject).then((res) => {
            setComprehensionQue({
                state: NetworkState.Success,
                data: res.data.content,
            });

            setCurrentPage(res.data.pageable.pageNumber);
            setTotalPages(res.data.totalPages);
            setTotalElements(res.data.totalElements);
        }).catch((err) => {
            console.error(err);
            setComprehensionQue({state: NetworkState.Failed, message: err.message});
        })
    }

    const handleTopicsChange = (selectedOptionTopic: (number | string)[]) => {
        if (selectedOptionTopic) {
            const numericTopics = selectedOptionTopic.map(topic => {
                if (typeof topic === 'string' && topic.startsWith('topic_')) {
                    return parseInt(topic.replace('topic_', ''), 10);
                } else if (typeof topic === 'number') {
                    return topic;
                }
                return 0;
            });
            setSelectedOptionTopic(numericTopics);
        }
    };

    const handleToggle = () => {
        setFilter(!filter);
    };


    const handleQuestionSelectClick = (options: SelectedQuestionType) => {
        showModal(true);
        setExpandedQuestion({id: null, expanded: false});
        setSelectedQuestionType(options);
        handleRemoveAllQuestion();
        setCurrentPage(0);
        if (options === SelectedQuestionType.Comprehension) {
            setQuestion({state: NetworkState.Idle, data: []});
            getAllComprehensions(0, pageSize, query);
        } else if (options === SelectedQuestionType.General) {
            setComprehensionQue({state: NetworkState.Idle, data: []});
            getAllQuestionsCreateAssignment(0, pageSize, [], query);
        }
    };

    const handleIsComprehension = (isComp: boolean) => {
        setIsComprehension(isComp);
    }


    return (
        <div className="pt-4 m-auto container">
            <div className="row page-titles mb-3 ">
                <div className="col-sm-6 p-md-0">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb mb-0 list-unstyled ps-0">
                            <li className="breadcrumb-item">
                                <a href="/public" className="text-decoration-none text-secondary"> Dashboard </a>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">
                                <a href={`/teacher/assignments`}
                                   className={'text-decoration-none text-black'}>Create New Assignment</a></li>
                        </ol>
                    </nav>
                </div>
                <div className="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
                    <button className={'btn bg-white shadow-sm'}>
                        <a href={`/teacher/assignments`}
                           className={'text-decoration-none'} style={{color: '#3498DB'}}>
                            <i className="bi bi-arrow-left"></i> Back to listing</a></button>
                </div>
            </div>
            <div className={'card border-0 shadow-sm'}>
                <div className={'card-body'}>
                    <div className={'d-md-flex justify-content-between align-items-center'}>
                        <div className="form-group searchBar position-relative">
                            <input
                                type={'search'}
                                className="form-control w-100 formControl"
                                placeholder={'Search here ...'}
                                onChange={(e) => setQuery(e.target.value)}
                            />
                            <button className={'bg-transparent transition p-0 border-0'}><i
                                className="bi bi-search"></i></button>
                        </div>
                        <div className={'d-flex align-items-center mb-2'}>
                            <div>
                                <a className={'patch_question position-relative text-decoration-none border p-2 rounded-3 text-black'}
                                   onClick={() => setSelectedQuestionModal(true)}>
                                    <div className='number'>{selectedQuestions.length}</div>
                                    {/*<i className="bi bi-question-square patch_icn"></i>*/}
                                    <i className="bi bi-question-lg me-2 fs-5"></i>
                                    Selected Questions
                                </a>
                            </div>
                            <div>
                                <div className={"me-3"} onClick={handleToggle}>
                                    <button>  <span
                                        className='d-flex align-items-center justify-content-center border p-2 rounded-3'>
                                        <i className="bi bi-funnel me-2"></i> Filters
                                        </span></button>
                                </div>
                            </div>
                            <div className={"d-flex align-items-center"}>
                                <button
                                    className={`float-end btnNewAdd ${isFiltersDisabled ? 'disabled' : ''}`}
                                    onClick={() => setEditQuestionModal(true)}>
                                    Insert Questions <i className="bi bi-plus-lg"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div>
                        {filter && (
                            <FilterDropdown handleApplyFilters={handleApplyFilters}
                                            handleCourseChange={handleCourseChange}
                                            handleProgramChange={handleProgramChange}
                                            handleChaptersChange={handleChaptersChange}
                                            handleTopicsChange={handleTopicsChange}
                                            canHaveComprehensions={handleIsComprehension}
                                // selectedQuestionType={selectedQuestionType}
                            />
                        )}
                    </div>
                    {(isFiltersDisabled) ? (
                        <div>
                            <div className="alert alert-warning">
                                Please select both course and program from the filters to view questions.
                            </div>
                            <EmptyState/>
                        </div>
                    ) : (
                        <div>
                            {question.state === NetworkState.Loading ? (
                                renderColumns()
                            ) : question.state === NetworkState.Success ? (
                                <div>
                                    <div>
                                        <button
                                            onClick={() => {
                                                if (selectedQuestions.length > 0) {
                                                    setCurrentPage(0)
                                                    showModal(true);
                                                    setQuestionType(SelectedQuestionType.General);
                                                } else {
                                                    setCurrentPage(0)
                                                    setSelectedQuestionType(SelectedQuestionType.General)
                                                }
                                            }}
                                            className={` p-2 text-center me-2 ${selectedQuestionType === SelectedQuestionType.General ? 'btnNewAdd text-white' : 'btnRemove'}`}
                                            style={{cursor: 'pointer'}}>
                                            General
                                        </button>
                                        <button
                                            onClick={() => {
                                                if (selectedQuestions.length > 0) {
                                                    showModal(true);
                                                    setCurrentPage(0);
                                                    setQuestionType(SelectedQuestionType.Comprehension);
                                                } else {
                                                    setCurrentPage(0);
                                                    setSelectedQuestionType(SelectedQuestionType.Comprehension);
                                                }
                                            }}
                                            disabled={!isComprehension}
                                            className={`p-2 text-center ${
                                                selectedQuestionType === SelectedQuestionType.Comprehension
                                                    ? 'btnNewAdd'
                                                    : isComprehension
                                                        ? 'btnRemove'
                                                        : 'btnDisable'
                                            }`}
                                        >
                                            Comprehension
                                        </button>

                                    </div>
                                    {selectedQuestionType === SelectedQuestionType.General &&
                                        <div className='mt-2'>
                                            <QuestionList
                                                questions={question.data as TeacherCreateQuestions[]}
                                                selectedQuestions={selectedQuestions}
                                                handleQuestionCheckboxChange={handleQuestionCheckboxChange}
                                                handleQuestionClick={handleQuestionClick}
                                                checkedSelect={checkedSelect}
                                                getAllQuestionsCreateAssignment={getAllQuestionsCreateAssignment}
                                                expandedQuestion={expandedQuestion}
                                                currentPage={currentPage}
                                                pageSize={pageSize}
                                            />
                                        </div>
                                    }
                                </div>
                            ) : (
                                <EmptyState/>
                            )}
                        </div>
                    )}

                    {selectedQuestionType === SelectedQuestionType.Comprehension && (
                        <div>
                            {comprehensionQue.state === NetworkState.Loading ? (
                                renderColumns()
                            ) : comprehensionQue.state === NetworkState.Success ? (
                                <div>
                                    {comprehensionQue?.data && comprehensionQue?.data?.length > 0 ? (
                                        <div>
                                            <div className='mb-2'>
                                                {!isComprehension ?
                                                    <div>
                                                        <div className={'mt-2 mb-2 fw-bold'}>ComprehensionQue is not
                                                            enabled for this course
                                                        </div>
                                                        <EmptyState/>
                                                    </div>
                                                    :
                                                    <CreateAssignmentQuesListing
                                                        key={comprehensionQue?.data?.map((res) => res.id).toString()}
                                                        comprehensionQue={comprehensionQue.data}
                                                        checkedSelect={checkedSelect}
                                                        handleQuestionCheckboxChange={handleQuestionCheckboxChange}
                                                        getAllComprehensions={() => getAllComprehensions(currentPage, pageSize, query)}
                                                    />
                                                }
                                            </div>
                                        </div>
                                    ) : (
                                        <EmptyState/>
                                    )}
                                </div>
                            ) : (
                                <EmptyState/>
                            )}
                        </div>
                    )}
                    {selectedQuestionType === SelectedQuestionType.General && question.data && question.data.length > 0 &&
                        <div className='d-flex align-items-center justify-content-center'>
                            <button
                                type="submit"
                                className="btn btn-primary btn-add mb-3"
                                onClick={() => setCreateTestModal(true)}
                                disabled={selectedQuestions.length === 0}
                            >
                                Create Assignment
                            </button>
                        </div>
                    }
                    {selectedQuestionType === SelectedQuestionType.Comprehension && isComprehension && comprehensionQue.data && comprehensionQue.data?.length > 0 &&
                        <div className='d-flex align-items-center justify-content-center'>
                            <button
                                type="submit"
                                className="btn btn-primary btn-add mb-3"
                                onClick={() => setCreateTestModal(true)}
                                disabled={selectedQuestions.length === 0}
                            >
                                Create Assignment
                            </button>
                        </div>
                    }
                    <div>
                        {selectedQuestionType === SelectedQuestionType.General &&
                            <div>
                                {totalPages > 1 && !isFiltersDisabled && (
                                    <div className='me-sm-2'>
                                        <Pagination
                                            currentPage={currentPage}
                                            totalPages={totalPages}
                                            onPageChange={(page) => getAllQuestionsCreateAssignment(page, pageSize, [], query)}
                                            totalElements={totalElements}
                                        />
                                    </div>
                                )}
                            </div>
                        }
                        {selectedQuestionType === SelectedQuestionType.Comprehension && isComprehension && comprehensionQue.data && comprehensionQue.data?.length > 0 &&
                            <div className='me-sm-2'>
                                {totalPages > 1 && comprehensionQue?.data?.length ? (
                                    <Pagination
                                        currentPage={currentPage}
                                        totalPages={totalPages}
                                        onPageChange={(page) => getAllComprehensions(page, pageSize, query)}
                                        totalElements={totalElements}
                                    />) : ''
                                }
                            </div>
                        }
                    </div>
                </div>
                <div>
                </div>
            </div>

            <CreateUpdateQuestions questionId={questionId} show={editQuestionModal} currentPage={currentPage}
                                   pageSize={pageSize}
                                   onHide={() => {
                                       setEditQuestionModal(false);
                                       setQuestionId(undefined);
                                   }}
                                   onSuccess={() => {
                                       setQuestionId(undefined);
                                       setEditQuestionModal(false);
                                       if (selectedQuestionType === SelectedQuestionType.General) {
                                           getAllQuestionsCreateAssignment(currentPage, pageSize, [], query);
                                       } else if (selectedQuestionType === SelectedQuestionType.Comprehension) {
                                           getAllComprehensions(currentPage, pageSize, query)
                                       }
                                   }}
            />
            <CreateTestModal show={createTestModal} onHide={() => setCreateTestModal(false)}
                             onSuccess={() => setCreateTestModal(false)} removeAllQuestions={handleRemoveAllQuestion}
                             questionId={selectedQuestions}
                             courseId={selectedOptionCourse ? Number(selectedOptionCourse.map((courseId) => courseId.value)[0]) : 0}
                             programId={selectedOptionProgram ? Number(selectedOptionProgram?.map((programId) => programId.value)[0]) : 0}
                             selectedQuestionType={selectedQuestionType}
            />
            <TeacherSelectedQuestionList show={selectedQuestionModal}
                                         onDelete={(questionId: string) => handleRemoveQuestion(questionId)}
                                         onHide={() => setSelectedQuestionModal(false)}/>
            <Modal show={modal} onHide={() => showModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Removal of Selected Questions</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        Confirming this action will remove the selected questions associated with
                        the {''}{selectedQuestionType} question.
                        Are you sure you want to proceed?
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className='d-flex justify-content-end'>
                        <Button variant="secondary" className={'me-2'} onClick={() => showModal(false)}>
                            Cancel
                        </Button>
                        <Button variant="primary" onClick={() => {
                            if (selectedQuestions.length > 0) {
                                handleQuestionSelectClick(questionType as SelectedQuestionType)
                            }
                            showModal(false)
                        }}>
                            Confirm
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default CreateAssignment;
