import React from "react";

export interface CardProps {
    data: any;
    selected: boolean;
    toggleSelection: () => void;
    disabled?:boolean;
    openLockModal?: () => void;
}

export const StudentTopicCard = (props: CardProps) => {
    return (
        <div onClick={() => {
            props.disabled ? props.openLockModal && props.openLockModal() : props.toggleSelection();
        }}>
            <div className="row d-flex newRow align-items-center justify-content-center g-0">
                <div
                    className="card border-0 shadow-sm"
                    style={{
                        backgroundColor:props.selected ? "#3498db45" : "#ffffff",
                        color:props.selected ? "#3498DB" : "#000000",
                        cursor:"pointer"
                    }}
                >
                    <div className="card-body d-flex justify-content-between align-items-center">
                        <div className={'d-flex'}>
                            <input
                                type="checkbox"
                                className="form-check-input me-2"
                                checked={props.disabled ? false : props.selected}
                                onChange={() => {
                                }}
                            />
                            <h6 className="card-title ms-2">{props.data?.name}</h6>
                        </div>
                        <div>
                            {props.disabled && <i className="bi bi-lock-fill"></i>}
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};
