import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import CreateProgram from "../program/createProgram/CreateProgram";
import {ProgramRepo} from "../../../../repos/ProgramRepo";
import {CourseRepo} from "../../../../repos/CourseRepo";
import {Program} from "../../../../dto/Courses";
import {InitialDataState, NetworkState, PromiseData} from "../../../../modals/PromiseData";
import ProgramCard from "../program/programCard/ProgramCard";
import CourseViewShimmer from "../../../shimmer/CourseView/CourseViewShimmer";
import {SessionManager} from "../../../../configs/SessionManager";


export const CourseView = () => {

    const [programs, setPrograms] = useState<PromiseData<Program[]>>(InitialDataState);
    const [showEditor, setShowEditor] = useState<boolean>(false);
    const [data, setData] = useState<any>('');
    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        getCourseData(params?.courseId || '');
    }, [params?.courseId]);

    useEffect(() => {
        getPrograms();
    }, []);

    const getCourseData = (courseId: string) => {
        CourseRepo.getSingleCourse(courseId).then((res: any) => {
            setData(res.data);
        }).catch((err) => {
            console.log(err)
        })
    }

    const getPrograms = () => {
        setPrograms({state: NetworkState.Loading})
        ProgramRepo.getAllPrograms(params?.courseId || '').then((res) => {
            setPrograms({state: NetworkState.Success, data: res.data});
        }).catch((err) => {
            console.log('Err', err);
            setPrograms({state: NetworkState.Failed, data: err.message});
        })
    }

    const renderColumns = () => {
        const columns = [];
        for (let i = 0; i < 8; i++) {
            columns.push(
                <tr>
                    <td><CourseViewShimmer/></td>
                </tr>
            );
        }
        return columns;
    };
    const [role,setRole] = useState<string>('')
    useEffect(() => {
        let role = SessionManager.getSessionUser()?.role ?? ''
        setRole(role);
    }, [SessionManager])



    return <div className={'container-fluid page-content'}>
        <div className="row page-titles mx-0 shadow-sm">
            <div className="col-sm-6 p-md-0">
                <h4>All Program / Subject</h4>
            </div>
            {role === 'Admin' ?
                <div className="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item  text-primary" onClick={() => navigate(`/admin/courses`)}
                                style={{cursor: 'pointer'}}>All Courses
                            </li>
                            <li className="breadcrumb-item active d-flex " aria-current="page">{data?.name}</li>
                        </ol>
                    </nav>
                </div> :
                <div className="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item  text-primary" onClick={() => navigate(`/teacher/courses`)}
                                style={{cursor: 'pointer'}}>All Courses
                            </li>
                            <li className="breadcrumb-item active d-flex " aria-current="page">{data?.name}</li>
                        </ol>
                    </nav>
                </div>
            }
        </div>

        <div className={'card border-0 shadow-sm'}>
            <div className={'card-body'}>
                <div className={'d-md-flex justify-content-between align-items-center'}>
                    <div>
                        <h5>{data?.name}</h5>
                    </div>
                    <div>
                        <button className="btn btn-primary text-white rounded-1 btn-add"
                                onClick={() =>
                                    setShowEditor(true)
                                }><i className="bi bi-plus"></i> Add Program / Subject
                        </button>
                    </div>
                </div>
                <div className={'card bg-light border-none rounded mt-4 border-0 h-100 text-center'}>
                    {programs?.data?.length ? (
                        <div className={'row row-cols-1 row-cols-md-5 m-4'}>
                            {programs.state === NetworkState.Loading ? (
                                renderColumns()
                                // <div>Loading...</div>
                            ) : (
                                programs.state === NetworkState.Success ?
                                    programs?.data?.map((program) => (
                                        <ProgramCard program={program} onEdit={() => getPrograms()}/>
                                    )) : ""
                            )}
                        </div>
                    ) : null
                    }
                </div>
            </div>
            <CreateProgram id={params?.courseId || ''} show={showEditor} onSuccess={() => {
                setShowEditor(false);
                getPrograms();
            }} onCancel={() => {
                setShowEditor(false);
            }}/>

        </div>
    </div>
}