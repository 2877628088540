import Select from "react-dropdown-select";
import React, {useEffect, useState} from "react";
import {Chapter, Courses, Program} from "../../../../../../dto/Courses";
import {useStudentApiClient} from "../../../../../student/apiClients/StudentApiClient";
import {useAdminApiClient} from "../../../../../admin/apiClients/AdminApiClient";
import {ProgramRepo} from "../../../../../../repos/ProgramRepo";
import {SelectedOptionProgram, SelectedOptionsCourse} from "../../createQuestion/SelectedOptions";
import './FilterDropdown.scss'
import TreeMultiSelectDropDown from "../treeMultiSelect/TreeMultiSelectDropdown";
import {TreeNode} from "../treeMultiSelect/TreeMultiSelect";

export interface FilterDropDownProps {
    handleApplyFilters: () => void;
    handleCourseChange: (selectedOptionCourse: SelectedOptionsCourse[]) => void;
    handleProgramChange: (selectedOptionProgram: SelectedOptionProgram[]) => void;
    handleChaptersChange: (selectedOptionChapter: number[]) => void;
    handleTopicsChange: (selectedOptionTopic: number[]) => void;
    canHaveComprehensions: (isComp: boolean) => void
}

const FilterDropdown = (props: FilterDropDownProps) => {
    const adminApiClient = useAdminApiClient();
    const studentApiClient = useStudentApiClient();
    const [courses, setCourses] = useState<Courses[]>([]);
    const [chapters, setChapters] = useState<Chapter[]>([]);
    const [programs, setPrograms] = useState<Program[]>([]);
    const [selectedOptionCourse, setSelectedOptionCourse] = useState<SelectedOptionsCourse[]>([]);
    const [selectedOptionProgram, setSelectedOptionProgram] = useState<SelectedOptionProgram[]>([]);
    const [selectedNodes, setSelectedNodes] = useState<number[]>([]);
    const [selectedCourseId, setSelectedCourseId] = useState<number | null>(null);
    const [treeData, setTreeData] = useState<TreeNode[]>([]);
    const [isFiltersSelected, setIsFiltersSelected] = useState<boolean>(false);
    const [isLoading,setIsLoading] = useState<boolean>(false)
    useEffect(() => {
        if (selectedOptionCourse.length > 0 && selectedOptionProgram.length > 0) {
            setIsFiltersSelected(true);
        } else {
            setIsFiltersSelected(false);
        }
    }, [selectedOptionCourse, selectedOptionProgram]);

    useEffect(() => {
        // Check if the selected course has changed
        if (selectedOptionCourse && selectedOptionCourse.length > 0) {
            const newCourseId = selectedOptionCourse[0].value;
            if (newCourseId !== selectedCourseId) {
                setSelectedNodes([]);
                setSelectedOptionProgram([]);
                setSelectedCourseId(newCourseId);
                handleTreeSelectChange([]);
                setTreeData([]);
            }
        }
    }, [selectedOptionCourse, selectedCourseId, selectedNodes]);

    useEffect(() => {
        const updatedTreeData: TreeNode[] = chapters?.map((chapter) => {
            const chapterId = Number(chapter.id);
            const truncatedChapterName = chapter.name.length > 30 ? chapter.name.substring(0, 35) + '...' : chapter.name;
            const uniqueTopics = chapter?.topics?.filter((topic, index, self) =>
                index === self.findIndex((t) => t.id === topic.id)
            );
            const chapterNode: TreeNode = {
                label: truncatedChapterName,
                value: `chapter_${chapterId}`,
                children: uniqueTopics?.map((topic, index) => {
                    const topicId = Number(topic.id);
                    const truncatedTopicName = topic.name.length > 30 ? topic.name.substring(0, 30) + '...' : topic.name;
                    return {
                        label: truncatedTopicName,
                        value: `topic_${topicId}_${index}`,
                        parent_id: chapterId,
                    };
                }) || []
            };
            return chapterNode;
        });
        setTreeData(updatedTreeData);
    }, [chapters]);

    useEffect(() => {
        getAllCourses();
    }, []);

    useEffect(() => {
        if (selectedOptionCourse && selectedOptionCourse.length > 0) {
            setSelectedOptionProgram([]);
            getPrograms();
        }
    }, [selectedOptionCourse]);

    useEffect(() => {
        if (selectedOptionProgram && selectedOptionProgram.length > 0) {
            getAllChapters();
        }
    }, [selectedOptionProgram]);

    const getAllCourses = () => {
        setIsLoading(true)
        adminApiClient
            .getAllCourses()
            .then((res) => {
                setSelectedNodes([])
                setCourses(res.data);
                setIsLoading(false)
            })
            .catch((err) => {
                console.log(err);
                setIsLoading(false)
            });
    };

    const getPrograms = () => {
        setIsLoading(true)
        const programId = selectedOptionCourse?.map((res) => res.value)[0];
        ProgramRepo.getAllPrograms(programId ?? 0)
            .then((res) => {
                setPrograms(res.data);
                setIsLoading(false)
            })
            .catch((err) => {
                console.log('Err', err);
                setIsLoading(false)
            });
    };

    const getAllChapters = () => {
        if (selectedOptionProgram && selectedOptionProgram.length > 0) {
            const programId = selectedOptionProgram[0].value;
            studentApiClient
                .getAllProgramChapters(programId)
                .then((res) => {
                    setChapters(res.data);
                })
                .catch(() => {
                    console.log('#30');
                });
        }
    };

    const handleCourseChange = (option: SelectedOptionsCourse[]) => {
        setSelectedOptionCourse(option);
        setSelectedOptionProgram([]);
        setSelectedNodes(selectedNodes ?? []);
        handleTreeSelectChange([]);
        setTreeData([]);
        props.handleCourseChange(option);
    };

    const handleProgramChange = (program: SelectedOptionProgram[]) => {
        setSelectedOptionProgram(program);
        setSelectedNodes([]);
        setTreeData([]);
        props.handleProgramChange(program);
    };

    const isChapter = (node: TreeNode) => !!node.children;
    const isTopic = (node: TreeNode) => !node.children;

    treeData.forEach((node) => {
        if (isChapter(node)) {
            // console.log(`Chapter: ${node.value}`);
        } else if (isTopic(node)) {
            // console.log(`Topic: ${node.value}`);
        }
    });

    const handleTreeSelectChange = (selectedNodes: number[]) => {
        // Create arrays to store selected chapter and topic IDs
        const selectedChapterIds: number[] = [];
        const selectedTopicIds: number[] = [];

        // Traverse the selected nodes
        selectedNodes.forEach((nodeId) => {
            // Find the node in the tree data
            const selectedNode = findNodeById(treeData, nodeId);
            // Check if it's a chapter or a topic and add its ID to the corresponding array
            if (selectedNode) {
                if (isChapter(selectedNode)) {
                    selectedChapterIds.push(selectedNode.value as any);
                } else if (isTopic(selectedNode)) {
                    // Check if the parent node is a chapter
                    const parentChapter = findParentChapter(treeData, selectedNode);
                    if (parentChapter) {
                        selectedChapterIds.push(parentChapter.value as any);
                    }
                    selectedTopicIds.push(selectedNode.value as any);
                }
            }
        });

        // Remove duplicate chapter IDs
        const uniqueSelectedChapterIds = [...new Set(selectedChapterIds) as any];
        setSelectedNodes(selectedNodes ?? [])
        // Update the state with the selected chapter and topic IDs
        props.handleChaptersChange(uniqueSelectedChapterIds);
        props.handleTopicsChange(selectedTopicIds);
    };

    // Helper function to find a node by its ID in the tree data
    function findNodeById(treeData: TreeNode[], id: number): TreeNode | undefined {
        for (const node of treeData) {
            if (node.value as any === id) {
                return node;
            } else if (node.children) {
                const foundNode = findNodeById(node.children, id);
                if (foundNode) {
                    return foundNode;
                }
            }
        }
        return undefined;
    }

    // Helper function to find the parent chapter node of a topic node
    function findParentChapter(treeData: TreeNode[], topicNode: TreeNode): TreeNode | undefined {
        for (const node of treeData) {
            if (node.children) {
                const foundTopicNode = node.children.find((childNode) => childNode.value === topicNode.value);
                if (foundTopicNode) {
                    return node;
                }
                const foundParentNode = findParentChapter(node.children, topicNode);
                if (foundParentNode) {
                    return foundParentNode;
                }
            }
        }
        return undefined;
    }

    return (
        <div>
            <div className="d-flex align-items-center justify-content-start mt-0">
                <div className='me-2 col-3'>
                    <Select
                        className={'rounded-2 p-2'}
                        options={courses?.map((course) => ({value: course.id, label: course.name.length > 26 ? course.name.substring(0, 26) + '...' : course.name}))}
                        onChange={(option) => handleCourseChange(option)}
                        values={selectedOptionCourse || []}
                        placeholder="Select Course"
                    />
                </div>
                <div className='me-2 col-2'>
                    <Select
                        className={'me-3 rounded-2 p-2'}
                        options={programs?.map((program) => ({value: Number(program.id), label: program.name.length > 15 ? program.name.substring(0, 15) + '...' : program.name}))}
                        onChange={(program) => handleProgramChange(program)}
                        values={selectedOptionProgram || []}
                        placeholder="Select Programs"
                    />
                </div>

                <div className='mt-2'>
                    {treeData.length > 0 && (
                        <TreeMultiSelectDropDown
                            placeholder="Select Chapters and Topics"
                            customClass="multiSelectDropdown "
                            handleChange={handleTreeSelectChange}
                            data={treeData || []}
                            selectedNodes={selectedNodes}
                        />
                    )}
                    {treeData.length == 0 && (
                        <TreeMultiSelectDropDown
                            placeholder="Select Chapters and Topics"
                            customClass="multiSelectDropdown"
                            handleChange={handleTreeSelectChange}
                            data={[]}
                            selectedNodes={selectedNodes}
                        />
                    )}
                </div>

                <div className='ms-4'>
                    <button
                        className={isFiltersSelected ? `btnRemove` : "btnDisable"}
                        onClick={() => {
                            const selectedProgramId = selectedOptionProgram[0].value;
                            const selectedProgram = programs.find(program => Number(program.id) === selectedProgramId);
                            const canHaveComprehensions = selectedProgram ? selectedProgram.canHaveComprehensions : false;
                            props.canHaveComprehensions && props.canHaveComprehensions(canHaveComprehensions ?? false);
                            props.handleApplyFilters()
                        }}
                        disabled={!isFiltersSelected}>
                        Apply Filter
                    </button>

                </div>
            </div>

        </div>
    );
};

export default FilterDropdown;
