import {useEffect, useState} from "react";
import {InitialDataState, NetworkState, PromiseData} from "../../../../modals/PromiseData";
import {TeacherCreateQuestions} from "../../../teacher/modal/CreateQuestions";
import {useSubjectsApiClient} from "../../../admin/apiClients/SubjectsApiClient";
import {useParams} from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import QuestionList from "../../../teacher/components/createAssignment/createTest/questionList/QuestionList";
import {EmptyState} from "../../../../components/emptyState/EmptyState";
import Pagination from "../../../../components/pagination/Pagination";

export const LikedQuestion = () => {
    const [questions, setQuestions] = useState<PromiseData<TeacherCreateQuestions[]>>(InitialDataState);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(10);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [totalElements, setTotalElements] = useState<number>(0);
    const [query, setQuery] = useState<string>('')
    const [expandedQuestion, setExpandedQuestion] = useState<{ id: number | null; expanded: boolean }>({
        id: null,
        expanded: true,
    });

    const subjectsApiClient = useSubjectsApiClient();
    const params = useParams();

    useEffect(() => {
        getAllLikedQuestionsByStudent(Number(params.programId), currentPage, pageSize, query);
    }, [currentPage, pageSize]);


    useEffect(() => {
        if (query !== '') {
            const delayTimer = setTimeout(() => {
                getAllLikedQuestionsByStudent(Number(params.programId), 0, pageSize, query);
            }, 300);
            return () => {
                clearTimeout(delayTimer);
            };
        } else {
            getAllLikedQuestionsByStudent(Number(params.programId), currentPage, pageSize, query);
        }
    }, [query, pageSize, currentPage]);

    const getAllLikedQuestionsByStudent = (programId: number, page?: number, size?: number, query?: string) => {
        setQuestions({state: NetworkState.Loading});
        subjectsApiClient.getAllLikedQuestions(programId, page, size, query).then((res) => {
            setQuestions({state: NetworkState.Success, data: res.data.content});
            setCurrentPage(res.data.number);
            setTotalPages(res.data.totalPages);
            setTotalElements(res.data.totalElements);
            setPageSize(pageSize);
        }).catch((err) => {
            console.log(err)
            setQuestions({state: NetworkState.Failed, message: err.message});

        })
    }

    const renderColumns = () => {
        const columns = [];
        for (let i = 0; i < 10; i++) {
            columns.push(
                <table key={i}>
                    <tbody>
                    <tr>
                        <Skeleton height={60} width={1200}/>
                    </tr>
                    </tbody>
                </table>
            );
        }
        return columns;
    };

    const handleQuestionClick = (questionId: number) => {
        if (expandedQuestion.id === questionId) {
            setExpandedQuestion({id: null, expanded: false});
        } else {
            setExpandedQuestion({id: questionId, expanded: true});
        }
    };

    return (
        <div className={"pt-4 m-auto container liked_que"}>
            <div className="row mx-0 py-3 px-2 shadow-sm bg-white shadow-sm mb-3 rounded-2">
                <div className=" d-flex align-items-center justify-content-between">
                    <div>
                        <h5 className={'mb-0'}>Liked Questions</h5>
                    </div>
                </div>
            </div>
            <div className={"card border-0 shadow-sm"}>
                <div className={"card-body"}>
                    <div className={"d-md-flex justify-content-between align-items-center"}>
                        <div className="form-group searchBar position-relative">
                            <input
                                type={"search"}
                                className="form-control w-100 formControl"
                                placeholder={"Search here ..."}
                                onChange={(event) => setQuery(event.target.value)}/>
                            <button className={'bg-transparent transition p-0 border-0'}><i
                                className="bi bi-search"></i>
                            </button>
                        </div>
                    </div>
                    <div>
                        <hr/>
                    </div>
                </div>
                <div>
                    <div className='container'>
                        {questions.state === NetworkState.Loading ? (
                            renderColumns()
                        ) : questions.state === NetworkState.Success ? (
                                <QuestionList key={questions?.data?.map(res => res.id).toString()}
                                              questions={questions?.data as TeacherCreateQuestions[]}
                                              expandedQuestion={expandedQuestion}
                                              currentPage={currentPage}
                                              pageSize={pageSize}
                                              handleQuestionClick={handleQuestionClick}
                                />)
                            : (<EmptyState/>)}
                    </div>
                </div>
                <Pagination currentPage={currentPage} totalPages={totalPages} totalElements={totalElements}
                            onPageChange={(page) => getAllLikedQuestionsByStudent(Number(params.programId), page, pageSize, query)}
                />
            </div>
        </div>
    );
};
