import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import React from "react";
import './TableShimmer.scss'

export const TableShimmer = () => {
    return <div>
        <div className="row d-flex">
            <Skeleton className="title"/>
        </div>
    </div>
}
