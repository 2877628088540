import {Modal, ModalBody, ModalHeader} from "react-bootstrap";
import Select from "react-dropdown-select";
import {SubjectsData} from "../../../../../admin/components/AdminSubjects";
import React, {ChangeEvent, FormEvent, useEffect, useState} from "react";
import {useSubjectsApiClient} from "../../../../../admin/apiClients/SubjectsApiClient";
import {Option, Questions} from "../../../../../teacher/components/modals/CreateiUpdateQuestion";
import {useNavigate, useParams} from "react-router-dom";

export interface AddQuestionModalProps {
    show: boolean,
    onHide: () => void,
    payload?: Questions
}

export const AddQuestionModal = (props: AddQuestionModalProps) => {
    const [subjects, setSubjects] = useState<SubjectsData[]>([])
    const [topics, setTopics] = useState<any[]>([]);
    const [selectedSubject, setSelectedSubject] = useState<any[]>([]);
    const [selectedOptionTopic, setSelectedTopic] = useState<any[]>([]);
    const [options, setOptions] = useState<Option[]>([{id: -1, text: '', correct: false}]);
    const subjectsApiClient = useSubjectsApiClient();
    const [payload, setPayload] = useState<Questions>({
        id: -1,
        text: '',
        imageCid: '',
        options: [{id: -1, text: '', correct: false}],
        topicId: [0],
        explanation: '',
        difficultyLevel: '',
        oneWordAnswer: '',
        knowledge_memory: '',
        student_preparation: '',
        analytical_thinking: '',
        concept_application: '',
        concept_clarity: '',
        problem_solving_skill: '',
        numerical_accuracy: '',
        logic: '',
        time_management: '',
        totalScore: '',
        category: '',
    });
    const [selectedQuestionType, setSelectedQuestionType] = useState<any>('');
    const navigate = useNavigate();
    const params = useParams();

    const handleChange = (event: any) => {
        const {name, value} = event.currentTarget;
        setPayload((prevState) => ({...prevState, [name]: value}));
    };

    useEffect(() => {
        if (selectedSubject.length > 0) {
            const selectedSubjectId = selectedSubject[0].value;
            const subject = subjects.find(subject => subject.id === selectedSubjectId);
            setTopics(subject?.topics ?? []);
        }
    }, [selectedSubject, selectedOptionTopic, subjects]);

    useEffect(() => {
        if (params['*'] === 'createQuestion') {
            const subject = localStorage.getItem('Subject');
            const Topic = localStorage.getItem('Topic');
            const Question = localStorage.getItem('Question Type');
            if (subject && Topic && Question) {
                setSelectedSubject(JSON.parse(subject));
                setSelectedTopic(JSON.parse(Topic));
                setSelectedQuestionType(Question);
                setSelectedQuestionType(Question);
                setPayload((prevState: any) => ({...prevState, type: Question}));
            }
        }
    }, [localStorage, props, params]);

    useEffect(() => {
        if (params && params['*'] === `editQuestion/${params.questionId}`) {
            if (params.questionId && props.payload) {
                subjectsApiClient.getSubjects().then(() => {
                    const selectedSubjects = subjects?.filter(subject => {
                        return props.payload && props.payload.topics?.some((questionTopic: any) => {
                            return subject.topics.some(subjectTopic => subjectTopic.id === questionTopic.id);
                        });
                    });
                    setSelectedSubject(selectedSubjects?.map((subject: any) => ({
                        value: subject.id,
                        label: subject.name
                    })));
                    if(props.payload)
                    setSelectedTopic( props.payload.topics?.map((res: any) => ({
                        value: res.id,
                        label: res.name
                    })) ?? []);
                    if(props.payload)
                    setSelectedQuestionType(props.payload.type?.toString());
                });
            } else {
                setSelectedSubject([]);
                setSelectedTopic([]);
                setSelectedQuestionType('');
            }
        }
    }, [props.payload, params.questionId, subjects]);


    useEffect(() => {
        if (props.show)
            getAllSubjects();
    }, [props]);

    const getAllSubjects = () => {
        subjectsApiClient.getSubjects().then((res) => {
            setSubjects(res.data);
        });
    };

    const handleSubjectChange = (selectedSubject: { label: string, value: number }[]) => {
        localStorage.setItem('Subject', JSON.stringify(selectedSubject.map(res => ({
            value: res.value,
            label: res.label
        }))));
        if (selectedSubject && selectedSubject.length > 0) {
            const selectedSubjectId = selectedSubject[0].value;
            const subject = subjects.find((subject) => subject.id === selectedSubjectId);
            if (subject) {
                const topicsOfSelectedSubject = subject.topics || [];
                setTopics(topicsOfSelectedSubject);
                const updatedSelectedTopics = selectedOptionTopic.filter((selectedTopic) =>
                    topicsOfSelectedSubject.some((topic) => topic.id === selectedTopic.value)
                );
                setSelectedTopic(updatedSelectedTopics);
                setPayload((prevPayload) => ({
                    ...prevPayload,
                    topicId: updatedSelectedTopics.map((topic) => topic.value),
                }));
            }
        } else {
            setTopics([]);
            setSelectedTopic([]);
            setPayload((prevPayload) => ({
                ...prevPayload,
                topicId: [],
            }));
        }
    };

    const handleTopicsChange = (selectedTopics: { label: string, value: number }[]) => {
        localStorage.setItem('Topic', JSON.stringify(selectedTopics.map(res => ({
            value: res.value,
            label: res.label
        }))));
        setSelectedTopic(selectedTopics);
        const associatedSubject = subjects.find(subject =>
            selectedTopics.every(selectedTopic =>
                subject.topics.some(topic => topic.id === selectedTopic.value)
            )
        );
        if (associatedSubject) {
            const {id, name} = associatedSubject;
            const modifiedSubject = {value: id, label: name};
            setSelectedSubject([modifiedSubject]);
            setPayload(prevPayload => ({
                ...prevPayload,
                topicId: selectedTopics.map(topic => topic.value),
            }));
        } else {
            setPayload(prevPayload => ({
                ...prevPayload,
                topicId: [],
            }));
        }
    };

    const handleDropDownSelect = (event: ChangeEvent<HTMLSelectElement>) => {
        const {name, value} = event.currentTarget;
        localStorage.setItem('Question Type', value)
        console.log('value',event.target.value)
        setSelectedQuestionType(value);
        if (event.target.value === 'TrueFalse') {
            setOptions(prevOptions => [
                {id: -1, text: 'True', correct: false}, {id: -1, text: 'False', correct: false}
            ]);
            setPayload(prevPayload => ({
                ...prevPayload,
                options: [
                    {id: -1, text: 'True', correct: false},
                    {id: -1, text: 'False', correct: false}
                ]
            }));
        } else if (event.target.value === 'OneAnswer') {
            setOptions(prevOptions => [
                {id: -1, text: '', correct: false}, {id: -1, text: '', correct: false},
                {id: -1, text: '', correct: false}, {id: -1, text: '', correct: false}
            ]);
            setPayload(prevPayload => ({
                ...prevPayload,
                options: [
                    {id: -1, text: '', correct: false}, {id: -1, text: '', correct: false},
                    {id: -1, text: '', correct: false}, {id: -1, text: '', correct: false}
                ]
            }));
        } else if (event.target.value === '') {
            setOptions(prevOptions => [
                {id: -1, text: '', correct: false}
            ]);
            setPayload(prevPayload => ({
                ...prevPayload, // Copy the existing payload
                options: [
                    {id: -1, text: '', correct: false}
                ]
            }))
        } else if (event.target.value === 'MultiSelect') {
            setOptions(prevOptions => [
                {id: -1, text: '', correct: false}, {id: -1, text: '', correct: false},
                {id: -1, text: '', correct: false}, {id: -1, text: '', correct: false}
            ])
            setPayload(prevPayload => ({
                ...prevPayload,
                options: [
                    {id: -1, text: '', correct: false},
                    {id: -1, text: '', correct: false},
                    {id: -1, text: '', correct: false},
                    {id: -1, text: '', correct: false}
                ]
            }));
        } else if (value === 'OneWord') {
            setOptions([{id: -1, text: '', correct: false}]);
            setPayload(prevPayload => ({
                ...prevPayload,
                options: [{id: -1, text: '', correct: false}]
            }));
        }
        setPayload((prevState) => ({...prevState, [name]: value}));
    };

    return <Modal show={props.show} onHide={props.onHide} className='modal-lg'>
        <ModalHeader closeButton>
            <div>
                <h6>Question Type</h6>
            </div>
        </ModalHeader>
        <ModalBody>
            <div className='d-flex align-items-center'>
                <div className="mb-2 w-50 me-4">
                    <label className="form-label" htmlFor={'subjects'}>Subject<span
                        className='ms-1 text-danger'>*</span></label>
                    <Select
                        options={subjects?.map((subject: SubjectsData) => ({
                            value: subject.id,
                            label: subject.name
                        }))}
                        onChange={(selectedSubject) => handleSubjectChange(selectedSubject)}
                        values={selectedSubject || []} // Set the selected subject state here
                        placeholder="Select Subjects"
                        required
                    />
                </div>

                <div className="mb-2 w-50">
                    <label className="form-label" htmlFor={'topics'}>Topics<span
                        className='ms-1 text-danger'>*</span></label>
                    <Select
                        options={topics?.map((topic: any) => ({
                            value: topic.id,
                            label: topic.name
                        }))}
                        onChange={(selectedTopics) => handleTopicsChange(selectedTopics)}
                        values={selectedOptionTopic || []}
                        placeholder="Select Topics"
                        multi
                        required
                    />
                </div>
            </div>
            <div className="mb-2 d-flex align-items-center">
                <div className='d-flex flex-row align-items-center w-100'>
                    <div className='w-100'>
                        <label className="form-label" htmlFor={'type'}>Question Type<span
                            className='ms-1 text-danger'>*</span></label>
                        <select required={true} id="type" className="form-select w-100"
                                onChange={(event) => {
                                    handleDropDownSelect(event);
                                    handleChange(event);
                                }}
                                value={payload.type ?? selectedQuestionType} name="type">
                            <option value="">Select</option>
                            <option value={'MultiSelect'}>MultiSelect</option>
                            <option value={'OneAnswer'}>One Answer</option>
                            <option value={'TrueFalse'}>True/False</option>
                            <option value={'OneWord'}>One Word</option>
                        </select>
                    </div>
                </div>
            </div>
            <div className='d-flex align-items-center justify-content-end m-3s'>
                {params && params["*"] === "createQuestion" || params["*"] === "" ? (
                    <button
                        className="btn btn-success"
                        disabled={
                            selectedSubject.length === 0 ||
                            selectedOptionTopic.length === 0 ||
                            selectedQuestionType === ""
                        }
                        onClick={() => {
                            navigate("/dataEntry/createQuestion");
                            if (params && params["*"] === "createQuestion") {
                                window.location.reload();
                            }
                            props.onHide();
                        }}
                    >
                        Save & Next
                    </button>
                ) : params["*"] === `editQuestion/${params.questionId}` ? (
                    <button
                        className="btn btn-success"
                        disabled={
                            selectedSubject.length === 0 ||
                            selectedOptionTopic.length === 0 ||
                            selectedQuestionType === ""
                        }
                        onClick={() => {
                            if (params["*"] === `editQuestion/${params.questionId}`) {
                                // window.location.reload();
                            }
                            props.onHide();
                        }}
                    >
                        Save & Next
                    </button>
                ) : (
                    ""
                )}
            </div>
        </ModalBody>
    </Modal>
}
