import React, {useEffect, useState} from "react";
import {useTeacherApiClient} from "../../../apiClients/TeacherApiClient";
import Pagination from "../../../../../components/pagination/Pagination";
import {EmptyState} from "../../../../../components/emptyState/EmptyState";
import {InitialDataState, NetworkState, PromiseData} from "../../../../../modals/PromiseData";
import AssignmentShimmer from "../../../../../components/shimmer/assignment/AssignmentShimmer";

interface StudentDetails {
    name: string;
    hasAttempted: boolean;
    marks: number;
}

interface StudentBatchListingProps {
    currentTabId: number;
    assignmentId: number;
}

export const StudentBatchListing: React.FC<StudentBatchListingProps> = (props) => {
    const teacherApiClient = useTeacherApiClient();
    const [data, setData] = useState<PromiseData<StudentDetails[]>>(InitialDataState);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [totalElements, setTotalElements] = useState(0);
    const [pageSize, setPageSize] = useState<number>(10);
    const [query, setQuery] = useState<string>('');

    useEffect(() => {
        if (props.assignmentId !== undefined && props.currentTabId !== undefined) {
            getAllBatchesStudentDetails(props.assignmentId, props.currentTabId,query,currentPage,pageSize);
        }
    }, [props.assignmentId, props.currentTabId]);

    useEffect(() => {
            const delayTimer = setTimeout(() => {
                getAllBatchesStudentDetails(props.assignmentId, props.currentTabId,query,0,pageSize);
            }, 300);
            return () => {
                clearTimeout(delayTimer);
            };

    }, [query]);

    const getAllBatchesStudentDetails = (
        assignmentId: number,
        batchId: number,
        name?: string,
        page?: number,
        size?: number
    ) => {
        setData({state: NetworkState.Loading});
        teacherApiClient
            .getStudentsByAssignmentAndBatch(assignmentId, batchId, name, page, size)
            .then((res) => {
                setData({state: NetworkState.Success, data: res.data.content});
                setCurrentPage(res.data.pageable.pageNumber);
                setTotalPages(res.data.totalPages);
                setTotalElements(res.data.totalElements);
                setPageSize(res.data.pageable.pageSize);
            })
            .catch((err) => {
                console.log(err);
                setData({state: NetworkState.Failed, data: err.message});
            });
    };

    const renderColumns = () => {
        const columns = [];
        for (let i = 0; i < 10; i++) {
            columns.push(
                <tr key={i}>
                    <td><AssignmentShimmer/></td>
                </tr>
            );
        }
        return columns;
    };

    return (
        <div>
            <div className="container">
                <div className={'card-head shadow-sm p-3 p-sm-20 p-md-25'}>
                    <div className={"d-md-flex justify-content-between align-items-center"}>
                        <div className="form-group searchBar position-relative">
                            <input
                                type={"search"}
                                className="form-control w-100 formControl"
                                placeholder={"Search here ..."}
                                onChange={(event) => setQuery(event.target.value)}
                            />
                            <button className={'bg-transparent transition p-0 border-0'}><i
                                className="bi bi-search"></i></button>
                        </div>
                    </div>
                </div>
                {data.state === NetworkState.Loading ? (
                    renderColumns()
                ) : data.state === NetworkState.Success && data.data && data.data.length > 0 ? (
                    <table className="table table-striped">
                        <thead>
                        <tr>
                            <th>Name</th>
                            <th>Has Attempted</th>
                            <th>Marks</th>
                        </tr>
                        </thead>
                        <tbody>
                        {data.data.map((student) => (
                            <tr key={student.name}>
                                <td>{student.name}</td>
                                <td>{student.hasAttempted ? "Yes" : "No"}</td>
                                <td>{student.marks}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                ) : (
                    <EmptyState/>
                )}
            </div>

            {totalPages > 1 && (
                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={(page) =>
                        getAllBatchesStudentDetails(props.assignmentId, props.currentTabId, query, page, pageSize)
                    }
                    totalElements={totalElements}
                />
            )}
        </div>
    );
};
