import { Modal, ModalBody} from "react-bootstrap";
import React, {FormEvent, useState} from "react";
import {ProgramRepo} from "../../../../../repos/ProgramRepo";
import Button from "../../../../button/Button";



export type ProgramPayload = {
    name?: string,
    canHaveComprehensions ?: boolean

}
const CreateProgram=(props:{show:boolean,onCancel:()=>void,id:string,onSuccess:()=>void})=>{
    const[payload,setPayload]=useState<ProgramPayload>({
        name: "",
        canHaveComprehensions : false
    });
    const [loading,setLoading] =  useState<boolean>(false);

    const handleInput=(e:any)=> {
        let name=e.target.name;
        let value=e.target.value;
        setPayload({...payload, [name]:value});
    }
    const handleForm=(e:FormEvent,courseId:string,payload:any)=>{
        e.preventDefault();
        ProgramRepo.createProgram(courseId,payload).then(()=>{
            props.onSuccess();
            setPayload({});
        }).catch((e)=>{
            console.log("error",e)
        })
    }

    const onClose = () => {
        props.onCancel();
        setPayload({});
    }
    return<div>
        <Modal show={props.show} onHide={onClose}>
            <Modal.Header closeButton className="border-bottom">
                <h5>Add New Program</h5>
            </Modal.Header>
            <ModalBody className="modal_body mx-2">
                <form onSubmit={(e) => handleForm(e, props.id, payload)} id={`create-program-form`}>
                    <div className="form-group">
                        <label htmlFor="exampleInputName">Program Name <span className='text-danger'>*</span></label>
                        <input type="text" className="form-control" id="exampleInputName"
                               aria-describedby="nameHelp" onChange={handleInput} placeholder="Enter name" name="name"
                               required/>
                    </div>
                    <div className="form-group">
                        <label>
                            <div className={'d-flex'}>
                                <input
                                    className={'mt-4 ms-1'}
                                    type="checkbox"
                                    checked={payload.canHaveComprehensions}
                                    onChange={e => setPayload({...payload, [e.target.name]: e.target.checked})}
                                    name="canHaveComprehensions"
                                />
                                <div className={'ms-2 mt-4'}>
                                    Can have comprehensions
                                </div>
                            </div>
                        </label>
                    </div>
                    <div className={'d-flex mt-3'}>
                        <div className={'flex-fill'}></div>
                        <div>
                            <button className="btn btn-cancel me-2" type={'button'} onClick={onClose}>Cancel</button>
                            <Button label={'Add Program'} loading={loading} cssName={'btn btn-add text-white'}/>

                        </div>
                    </div>
                </form>
            </ModalBody>
        </Modal>
    </div>
}
export default CreateProgram;