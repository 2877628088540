import React, {useEffect, useState} from "react";
import {CreateCouponModal} from "./createCoupon/CreateCouponModal";
import {useAdminApiClient} from "../../apiClients/AdminApiClient";
import moment from "moment-timezone";
import Pagination from "../../../../components/pagination/Pagination";
import CustomSwitcher from "../../../../components/common/course/courseView/CustomSwitcher";
import {AllToasts} from "../../../../components/toast/AllToasts";
import StatsModal from "./stats/StatsModal";

interface Coupon {
    id: number;
    code: string;
    courseName: string
    validFrom: string;
    validTill: string;
    discount: number;
    active?: boolean
    status: boolean;
}

export const CouponScreen = () => {
    const adminApiClient = useAdminApiClient();
    const [show, setShow] = useState<boolean>(false);
    const [coupons, setCoupons] = useState<Coupon[]>([]);
    const [couponId, setCouponId] = useState<number | undefined>(undefined);
    const [pageSize, setPageSize] = useState<number>(10);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [totalElements, setTotalElements] = useState<number>(0);
    const [showStats, setShowStats] = useState<boolean>(false);
    const [statsCouponId, setStatsCouponId] = useState<string | undefined>(undefined);

    useEffect(() => {
        getAllCouponsForAdmin();
    }, []);

    const getAllCouponsForAdmin = (page?: number, size?: number) => {
        adminApiClient.getAllCoupon(page, size).then((res) => {
            setCoupons(res.data.content);
            setPageSize(res.data.pageable.pageSize);
            setTotalPages(res.data.totalPages);
            setCurrentPage(res.data.pageable.pageNumber);
            setTotalElements(res.data.totalElements);
        }).catch((err) => {
            console.log(err)
        })
    }

    const couponSoftDelete = (couponId: number) => {
        adminApiClient.couponSoftDelete(couponId).then((res) => {
            AllToasts.success('success');
            getAllCouponsForAdmin();
        }).catch((err) => {
            console.log(err)
            AllToasts.success('faliure')
        })
    }

    const couponUndoSoftDelete = (couponId: number) => {
        adminApiClient.couponUndoSoftDelete(couponId).then((res) => {
            AllToasts.success('success')
            getAllCouponsForAdmin();
        }).catch((err) => {
            console.log(err)
            AllToasts.success('faliure')
        })
    }

    return (
        <div className="container mt-2">
            <div className="row pt-2 pb-2 mx-0 shadow-sm bg-white shadow-sm mb-3 rounded-2">
                <div className="d-flex align-items-center justify-content-between">
                    <div>
                        <h4>Coupon Mgmt</h4>
                    </div>
                    <div className="d-flex flex-row align-items-center">
                        <div className="d-flex me-2">
                            <button onClick={() => setShow(true)} className="btn btn-primary btn-outline-light"
                                    style={{background: '#3498DB'}}>
                                <i className="bi bi-plus-lg"></i> Create
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="shadow-sm bg-white shadow-sm mb-3 rounded-2">
                <div>
                    <table className="table table-striped">
                        <thead>
                        <tr>
                            <th>Sr No</th>
                            <th>Coupon</th>
                            <th>Course</th>
                            <th>Valid From</th>
                            <th>Valid Till</th>
                            <th>Discount(%)</th>
                            <th>Status</th>
                            <th>Active</th>
                            <th className={'text-center'}>Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {coupons?.map((coupon, index) => (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{coupon.code}</td>
                                <td>{coupon.courseName}</td>
                                <td>{coupon.validFrom ? moment(coupon.validFrom).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('DD-MM-YYYY') : '---'}</td>
                                <td>{coupon.validTill ? moment(coupon.validTill).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('DD-MM-YYYY') : '---'}</td>
                                <td>{coupon.discount}%</td>
                                <td style={{
                                    color: `${coupon.status ? "green" : "red"}`,
                                    fontWeight: "600"
                                }}>{coupon.status ? 'Available' : 'Expired'}</td>
                                <td>
                                    <div className={'ms-4 d-flex align-items-center justify-content-center'}>
                                        <CustomSwitcher active={coupon.active ?? false}
                                                        onChange={() => coupon.active ? couponSoftDelete(coupon.id) : couponUndoSoftDelete(coupon.id)}
                                        />
                                    </div>
                                </td>
                                <td className={'d-flex justify-content-evenly'}>
                                    <i onClick={() => {
                                        setCouponId(coupon.id)
                                        setShow(true)
                                    }} className="bi bi-pencil-square"></i>
                                    <i className={'bi bi-eye'}
                                       onClick={() => {
                                           setStatsCouponId(coupon.id.toString())
                                           setShowStats(true);
                                       }}>
                                    </i>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <CreateCouponModal show={show} onHide={() => {
                setCouponId(undefined);
                setShow(false);
            }} couponId={couponId ?? 0} onSuccess={() => getAllCouponsForAdmin()}/>
            {
                statsCouponId && <StatsModal show={showStats}
                                             onHide={() => {
                                                 setStatsCouponId(undefined);
                                                 setShowStats(false)
                                             }}
                                             couponId={statsCouponId}/>
            }
            {totalPages > 1 &&
                <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={(page) =>
                    getAllCouponsForAdmin(page, pageSize)} totalElements={totalElements}/>}
        </div>
    );
};


