import React, {useEffect} from "react";
import {MathX} from "../../../../../../components/math/MathX";
import {Question} from "../../../modals/CreateiUpdateQuestion";

interface ExpandedQuestionsProps {
    toggleQuestionExpand: (questionId: number) => void;
    setQuestionId: React.Dispatch<React.SetStateAction<number | undefined>>;
    setEditQuestionModal: React.Dispatch<React.SetStateAction<boolean>>;
    setFlaggedQuestionId: React.Dispatch<React.SetStateAction<number | undefined>>;
    comprehensionIds: number[];
    expandedQuestionIds:number[]
    expandedQuestionData: Question[];
    comprehensionId:number
    isLoading: boolean
    auditResponseData: { [key: number]: any }
    setFlaggedQuestionIds:(id:number[]) => void
}

export const ExpandedQuestionsAudit = (props: ExpandedQuestionsProps) => {
    const handleEditClick = (questionId: number) => {
        const flagIds: number[] = [];
        props.auditResponseData[questionId]?.flagData.map((flag: any) => {
            flagIds.push(flag.id);
        });
        props.setFlaggedQuestionIds(flagIds)
        props.setQuestionId(questionId);
        props.setEditQuestionModal(true);
    };

    return (
        <div>
            {props.isLoading ?
                <div className={' d-flex align-items-center justify-content-center'}>
                    <div className="spinner-border text-primary" role="status">
                    </div>
                </div>
                :
                props.expandedQuestionData?.map((questionWrapper, questionIndex) =>
                    <div
                        key={questionWrapper.id}
                        className={`card accordion mb-2`}
                    >
                        <div>
                            <div className="card-header">
                                <div className="mb-0 d-flex align-items-center justify-content-between">
                                    <div className={ questionWrapper.flagged ? " text-danger" : "text-black"} onClick={() =>
                                        props.toggleQuestionExpand(questionWrapper.id)}
                                    >
                                        {questionIndex + 1}. {questionWrapper?.text}{" "}
                                    </div>
                                    <div>
                                        <i
                                            onClick={() => handleEditClick(questionWrapper.id)}
                                            className={"bi bi-pencil-square fs-5"}
                                        />
                                    </div>
                                </div>
                            </div>
                            {props.expandedQuestionIds.includes(questionWrapper.id ) && (
                                <div className="card-body">
                                    {props.auditResponseData[questionWrapper.id] ? (
                                        <div className="mb-2 d-flex align-items-center">
                                            <span className="text-danger me-3">Issues: </span>{" "}
                                            <div className="w-100">
                                                {props.auditResponseData[questionWrapper.id].flagData?.map((flag: any, index: number) => {
                                                    return (
                                                        <React.Fragment key={flag.id}>
                                                            {index !== 0 && " , "}
                                                            <span>{flag.flagReason} - {flag.flagCount}</span>
                                                        </React.Fragment>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="mb-2">
                                            <span className="text-white"></span>
                                        </div>
                                    )}

                                    <div className={"row"}>
                                        {questionWrapper?.options?.map(
                                            (option: any, optionIndex: number) => (
                                                <div className={`col-lg-6`} key={option.id}>
                                                    <div className="d-flex align-items-center w-100">
                                                        <div
                                                            key={option.id}
                                                            className="d-flex align-items-center"
                                                        >
                                                            <div className={"d-flex"}>
                                                                    <span className="me-2">
                                                                        {" "}
                                                                        Option {optionIndex + 1}:
                                                                    </span>
                                                                <span className="me-2">
                                                                        <MathX>{option.text}</MathX>
                                                                    </span>
                                                            </div>
                                                            <div className={""}>
                                                                {option.correct ? (
                                                                    <div className={"correct_icon"}>
                                                                        <i className="bi bi-check2"/>
                                                                    </div>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        )}
                                    </div>
                                    {questionWrapper?.oneWordAnswer?.length > 0 && (
                                        <div>
                                            Option: {questionWrapper?.oneWordAnswer}
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                )}
        </div>
    );
};
