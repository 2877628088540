import {Modal, ModalBody, ModalHeader} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {CourseRepo} from "../../../../../repos/CourseRepo";
import {Courses} from "../../../../../dto/Courses";
import {useAdminApiClient} from "../../../apiClients/AdminApiClient";
import {AllToasts} from "../../../../../components/toast/AllToasts";
import {useAdminProfileApiClient} from "../../../apiClients/AdminProfileApiClient";
import moment from "moment-timezone";

interface CreateCouponModalProps {
    show: boolean;
    onHide: () => void;
    couponId?: number;
    onSuccess: () => void
}

export interface CouponData {
    code?: string,
    courseId?: string | undefined,
    courseName?: string
    validFrom?: string,
    validTill?: string,
    discount?: number | undefined
}

export const CreateCouponModal = (props: CreateCouponModalProps) => {
    const adminApiClient = useAdminApiClient();
    const [courses, setCourses] = useState<Courses[]>([])
    const [selectedCourse, setSelectedCourse] = useState<string | undefined>(undefined)
    const [formData, setFormData] = useState<CouponData>({
        code: '',
        courseId: '',
        courseName: '',
        validFrom: '',
        validTill: '',
        discount: undefined
    });
    const[disabled , setDisabled] = useState<boolean>(true);

    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    useEffect(() => {
        if (props.show && !props.couponId) {
            handleResetForm();
        }
    }, [props.show, props.couponId]);

    useEffect(() => {
        getAllCourses();
    }, []);

    useEffect(() => {
        if (props.couponId) {
            getSingleCouponById(props.couponId)
        }
    }, [props.couponId]);


    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const {name, value} = event.target;
        setFormData({...formData, [name]: value.toUpperCase()});
        console.log(formData.validFrom);
    };

    const localToUtc = (localDate ?: string): string => {
        return moment.tz(localDate, userTimeZone).utc(true).format();
    }

    const handleSubmit = (e: any) => {
        e.preventDefault();
        if (props.couponId) {
            const {code, validFrom, validTill, discount} = formData;
            const updatedPayload = {code, validFrom: localToUtc(validFrom), validTill: localToUtc(validTill), discount};
            updateSingleCoupon(props.couponId, updatedPayload);
            props.onHide();
        } else {
            const {code, courseId, validFrom, validTill, discount} = formData;
            const finalCourseId = courseId === "-1" ? "" : courseId;
            const updatedPayload = {
                code,
                courseId: finalCourseId,
                validFrom: localToUtc(validFrom),
                validTill: localToUtc(validTill),
                discount
            };
            console.log(updatedPayload);
            createCoupon(updatedPayload);
            props.onHide();
        }
    };


    // const handleSubmit = (e: any) => {
    //     e.preventDefault();
    //     const currentDate = new Date();
    //     const utcDateTime = currentDate.toISOString(); // Get current UTC time in ISO format
    //
    //     const formDataWithCurrentTime = {
    //         ...formData,
    //         validFrom: formData.validFrom ? `${formData.validFrom}T${utcDateTime.split('T')[1]}` : '', // Keep date from form, use UTC time
    //         validTill: formData.validTill ? `${formData.validTill}T${utcDateTime.split('T')[1]}` : '', // Keep date from form, use UTC time
    //     };
    //
    //     if (props.couponId) {
    //         const { code, validFrom, validTill, discount } = formDataWithCurrentTime;
    //         const updatedPayload = { code, validFrom, validTill, discount };
    //         updateSingleCoupon(props.couponId, updatedPayload);
    //         props.onHide();
    //     } else {
    //         createCoupon(formDataWithCurrentTime);
    //         props.onHide();
    //     }
    // };


    const handleResetForm = () => {
        setFormData({
                code: '',
                courseId: '',
                courseName: '',
                validFrom: '',
                validTill: '',
                discount: undefined
            }
        );
    }

    const getAllCourses = () => {
        CourseRepo.getAllCourses().then((res) => {
            setCourses(res.data);
        }).catch((err) => {
            console.log(err)
        })
    }

    const createCoupon = (payload: CouponData) => {
        adminApiClient.createCouponByAdmin(payload).then((res) => {
            AllToasts.success('Coupon Created Successfully');
            handleResetForm();
            props.onSuccess();
        }).catch((err) => {
            console.log(err)
            AllToasts.failure(err.response.data.reason)
        })
    }

    const getSingleCouponById = (couponId: number) => {
        adminApiClient.getSingleCoupon(couponId).then((res) => {
            const couponData = res.data;
            const formattedCouponData = {
                code: couponData.code || '',
                courseName: couponData.courseName === 'All' ? 'All' : couponData.courseName,
                validFrom: couponData.validFrom ? moment(couponData.validFrom).tz(userTimeZone).format('YYYY-MM-DD') : '',
                validTill: couponData.validTill ? moment(couponData.validTill).tz(userTimeZone).format('YYYY-MM-DD') : '',
                discount: couponData.discount !== null ? couponData.discount.toString() : ''
            };
            setFormData(formattedCouponData);
        }).catch((err) => {
            console.log(err);
        });
    };

    const updateSingleCoupon = (couponId: number, payload: CouponData) => {
        adminApiClient.updateCoupon(couponId, payload).then((res) => {
            AllToasts.success('Updated Successfully');
            handleResetForm();
            props.onSuccess();
        }).catch((err) => {
            console.log(err)
            AllToasts.failure(`Err ${err.response.data.message}`)
        });
    };


    const handleSelectedCourse = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const courseId = e.target.value
        if (courseId !== "-2"){
            setDisabled(false);
        } else {
            setDisabled(true);
        }
        console.log("#1785", courseId)
        setSelectedCourse(courseId);
        setFormData((prevState) => ({
            ...prevState,
            courseId: courseId
        }));

    };

    return (
        <Modal show={props.show} onHide={props.onHide}>
            <ModalHeader closeButton>
                <h6 className='fs-5'>{props.couponId ? 'Update Coupon' : 'Create Coupon'}</h6>
            </ModalHeader>
            <ModalBody>
                <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <label className="form-label">Coupon Name <span className='text-danger'>*</span></label>
                        <input
                            type="text"
                            className="form-control"
                            name="code"
                            value={formData.code?.toUpperCase()}
                            onChange={handleChange}
                            required
                            disabled={!!props.couponId}
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Select Courses <span className='text-danger'>*</span></label>
                        {props.couponId ?
                            <select
                                className="form-select bg-light"
                                name="courseId"
                                value={formData.courseName}
                                disabled
                            >
                                <option>{formData.courseName}</option>
                            </select> :
                            <select
                                className="form-select"
                                name="courseId"
                                value={formData.courseId}
                                onChange={handleSelectedCourse}
                            >
                                <option value="-2">Select a course</option>
                                <option value="-1">All Courses</option>
                                {courses?.map((res) => (
                                    <option key={res.id} value={res.id}>{res.name}</option>
                                ))}
                            </select>
                        }
                    </div>
                    <div className='d-flex align-items-center justify-content-between w-100'>
                        <div className="mb-3 w-100">
                            <label className="form-label">Valid From <span className='text-danger'>*</span></label>
                            <input
                                type="date"
                                className="form-control"
                                name="validFrom"
                                value={formData.validFrom}
                                onChange={handleChange}
                                disabled={!!props.couponId}
                                min={new Date().toISOString().split('T')[0]}
                            />
                        </div>
                        <div className="mb-3 w-100">
                            <label className="form-label">Valid Till <span className='text-danger'>*</span></label>
                            <input
                                type="date"
                                className="form-control"
                                name="validTill"
                                value={formData.validTill}
                                onChange={handleChange}
                                min={formData.validFrom}
                            />
                        </div>
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Discount (%)<span className='text-danger'>*</span></label>
                        <input
                            type="number"
                            className="form-control"
                            name="discount"
                            value={formData.discount}
                            onChange={handleChange}
                            disabled={!!props.couponId}
                        />
                    </div>
                    <div className='d-flex align-items-center justify-content-end'>
                        <button type={'button'} className={'btn btn-secondary me-2'} onClick={props.onHide}>Close
                        </button>
                        <button className={'btn btn-primary'}
                                onClick={handleSubmit} disabled={(!props.couponId && disabled)}>{props.couponId ? 'Update' : 'Submit'}
                        </button>
                    </div>
                </form>
            </ModalBody>
        </Modal>
    );
};

