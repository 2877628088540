import {useAxios} from "../../../configs/AxiosClient";
import {Questions} from "../../teacher/components/modals/CreateiUpdateQuestion";
import {Page} from "../../teacher/utlis/Page";

export enum Status {
    Draft , Submitted
}

// export interface StatsData{
//     date : string,
//     numberOfQuestions : number
// }
export const useDataEntryQuestionsApiClient = () => {
    const axiosClient = useAxios();



    const createQuestions = (payload: Questions) => {
        return axiosClient.post('/questions-storage/create', payload)
    }

    const getAllDraftQuestions = () => {
        return axiosClient.get('/questions-storage/draft')
    }

    const deleteQuestions = (questionStorageId: number) => {
        return axiosClient.delete(`/questions-storage/${questionStorageId}/storage`)
    }

    const changeQuestionStatus = (questionId: number[], status: string) => {
        return axiosClient.put(`/questions-storage/change-status`,{}, {
            params: {
                questionId: questionId && questionId.join(','),
                status:status
            }
        })
    }
    const getQuestionById = (questionStorageId:number)=>{
        return axiosClient.get(`/questions-storage/${questionStorageId}`)
    }

    const updateQuestion = (questionStorageId:number,payload:Questions) =>{
        return axiosClient.put(`/questions-storage/${questionStorageId}/update`,payload)
    }

    const getDataEntryExecutiveProfile = ()=> {
        return axiosClient.get(`/data-entry-executive/me`)
    }

    const getFilteredQuestions = (page ?: number , size ?: number , status ?: string , fromDate ?: string , toDate ?: string ,
                                  subjectId ?: number , topicIds ?: number[]) => {
        const params = {
            page : page , size : size , status : status , fromDate : fromDate ,
            toDate : toDate , subjectId : subjectId , topicIds : topicIds ? topicIds.join(',') : undefined
        }
        return axiosClient.get(`/question-storage/filtered` , {params : params})
    }

    const getStatsDashboard = (page ?: number , size ?: number) => {
        const params = {page : page , size : size}
        return axiosClient.get(`/executive/stats` , {params:params})
    }

    return {
        getAllDraftQuestions, createQuestions, deleteQuestions,changeQuestionStatus,getQuestionById,updateQuestion,
        getDataEntryExecutiveProfile , getFilteredQuestions , getStatsDashboard
    }
}