import {User, UserUtils} from "../dto/User";
import {Constants} from "./Constants";
export class SessionManager {
    private static KEY_SESSION_USER = 'SESSION_USER';
    private static KEY_ACCESS_TOKEN = 'ACCESS_TOKEN';
    private static KEY_REFRESH_TOKEN = 'REFRESH_TOKEN';
    private static KEY_IS_LOGGED_IN = 'IS_LOGGED_IN';
    static createSession(accessToken: string, refreshToken?: string,data?: any) {
        localStorage.setItem(this.KEY_ACCESS_TOKEN, accessToken);
        localStorage.setItem(this.KEY_REFRESH_TOKEN, refreshToken ||'');
        localStorage.setItem(this.KEY_IS_LOGGED_IN, 'true');
        localStorage.setItem("email",data.email ?? '');
        localStorage.setItem("role",data.role ?? '');
        localStorage.setItem("role",data.imageUrl ?? '');
        this.setSessionUser({name: data.name,email: data.email,role: data.role, id: this.getRoleId(data.role,data), mobile: data.mobile,imageUrl:data.imageUrl,imageCid:data.imageCid});
    }

    private static getRoleId = (role: string,data: any) => {
        console.log('data',data.role)
        if(role == 'Teacher')
            return data.teacher_id;
        else if (role == 'Student')
            return data.student_id;
        else if(role == 'Admin')
            return data.user_id;
    }
    static saveUserId = (userId: string) => {
        localStorage.setItem(Constants.USER_ID, userId ?? '');
    }
    static getToken(): string | null{
        return  localStorage.getItem(this.KEY_ACCESS_TOKEN);

    }
    static getRefreshToken = () => {
        return localStorage.getItem(this.KEY_REFRESH_TOKEN);
    }
    static setLoggedIn = (isLoggedIn: boolean) =>  {
        localStorage.setItem(this.KEY_IS_LOGGED_IN, isLoggedIn?"true":"false");
    }


    static isLoggedIn(): boolean {
        const isLoggedIn = localStorage.getItem(this.KEY_IS_LOGGED_IN);
        return (isLoggedIn !== null && isLoggedIn === 'true');
    }
    static setSessionUser(user: User) {
        localStorage.setItem(this.KEY_SESSION_USER, JSON.stringify(user))
    }
    static updateSession = (token: string, refreshToken?: string) => {
        if (refreshToken) {
            localStorage.setItem(this.KEY_REFRESH_TOKEN, refreshToken);
        }
        this.setLoggedIn(true);
        localStorage.setItem(this.KEY_ACCESS_TOKEN, token);
    }
    static getSessionUser(): User|null {
        const userInfo = localStorage.getItem(this.KEY_SESSION_USER)
        if (userInfo!=null) {
            const userInfoMap = JSON.parse(userInfo);
            return {id: userInfoMap['id'],
                email: userInfoMap['email'],
                mobile: userInfoMap['mobile'],
                name: userInfoMap['name'],
                imageUrl: userInfoMap['imageUrl'],
                imageCid: userInfoMap['imageCid'],
                role: UserUtils.parseRole(userInfoMap['role']
                )}
        }
        return null;
    }

    static clearSession() {
        localStorage.removeItem(this.KEY_SESSION_USER);
        localStorage.removeItem(this.KEY_ACCESS_TOKEN);
        localStorage.removeItem(this.KEY_REFRESH_TOKEN);
        localStorage.removeItem(this.KEY_IS_LOGGED_IN);
    }
}