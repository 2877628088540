import React from "react";
import {useNavigate} from "react-router-dom";
import successRegister from '../../../../../assets/images/registerSuccess.svg'
import "../LoginStudent.scss"
import logo from "../../../../../assets/images/aiDronaLogo.png";
const LoginSuccess=()=>{
    const navigate = useNavigate();

    return<div className={'bg-light'}>
        <div className='container'>
            <div className="row">
                <div className={'d-flex align-items-center justify-content-center mt-4 mb-4'}>
                    <img onClick={() => navigate('/')}
                         style={{width: '79px', height: '65px', cursor: 'pointer'}} src={logo}/>
                    <h3 className='fw-bold'>Welcome to Ai Drona</h3>
                </div>
                <div className="rounded wrapper d-flex align-items-center justify-content-center">
                    <div className='text-center p-4 shadow'>
                        <img src={successRegister} height={400} className={'mb-5 img-fluid'}/>
                        <h5 className={'fw-bold mb-2'}>Congratulations! </h5>
                        <h5>Your account has been successfully created !</h5>
                        <div className='d-flex justify-content-center mt-4'>
                            <a className='ms-2 btn-register w-50 text-decoration-none' onClick={() => navigate(`/student/login`)}> Login / SignIn </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}
export default LoginSuccess;