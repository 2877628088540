import {useStudentApiClient} from "../../apiClients/StudentApiClient";
import {CourseBatch} from "../../../../dto/CourseBatch";
import React, {useEffect, useRef, useState} from "react";
import './StudentHome.scss'
import {AllStudentCourses} from "./courses/AllStudentCourses";
import {StudentEnrolledCourses} from "../../components/courses/StudentEnrolledCourses";
import CourseDashboard from "../../../../assets/images/CourseDashboardImage.png";

export enum Tab {
    Enrolled = "Enrolled",
    Explore = "Explore"
}

export const StudentHome = () => {
    const [courseBatches, setCourseBatches] = useState<CourseBatch[]>([]);
    const [selectedTab, setSelectedTab] = useState<Tab>(Tab.Enrolled);
    const [selectedCourseType, setSelectedCourseType] = useState<string>('PAID')
    const studentApiClient = useStudentApiClient();
    const [query , setQuery] = useState<string | undefined>(undefined);

    const allStudentCoursesRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        // setCourseBatches([])
        if (selectedTab === Tab.Explore) {
            setSelectedCourseType("PAID")
        } else if (selectedTab === Tab.Enrolled) {
            getStudentCourseBatches(selectedCourseType);
        }
    }, [selectedCourseType, selectedTab]);

    const getStudentCourseBatches = (type?: string) => {
        studentApiClient.getAllStudentCourseBatches(type).then(res => {
            setCourseBatches(res.data)
            console.log('courseBatch',courseBatches)
        }).catch((err) => {
            console.log(err)
        })
    }

    const handleSelectedMethod = (method: Tab) => {
        setSelectedTab(method);
    };

    const handleSelectedCourse = (courseType: string) => {
        setSelectedCourseType(courseType);
    }

    const scrollToCourses = () => {
        if (allStudentCoursesRef.current) {
            allStudentCoursesRef.current.scrollIntoView({ behavior: "smooth" });
        }
    };
    return <>
        <div className={'page-wrapper'}>
            <div
                style={{
                    position: 'relative',
                    display: 'inline-block',
                    width: '100%',
                    backgroundImage: `url(${CourseDashboard})`,
                    backgroundSize: 'cover',
                    backgroundPosition: '30% 33%',
                    backgroundRepeat: 'no-repeat',
                    height: '45vh',
                }}
            >
                <div className='dashboard-image-overlay'/>
                <div className='dashboard-search-button'>
                    <div className="form-group searchBar position-relative w-100" style={{ marginBottom: '1rem' }}>
                        <input type="search" className="form-control w-100 formControl" placeholder="Search Topics / Courses"
                               onChange={(event) => setQuery(event.target.value)} style={{ paddingRight: '2.5rem' }}/>
                        <button className="bg-transparent transition p-0 border-0 position-absolute"
                                style={{right: '0.5rem', top: '50%', transform: 'translateY(-50%)', zIndex: 3,
                                }}>
                            <i className="bi bi-search" style={{ fontSize: '1.2rem' }}></i>
                        </button>
                    </div>
                 <button className="btn btn-primary " style={{background:'#3498DB', borderColor:'#3498DB', cursor:'pointer'}}  onClick={scrollToCourses}>Explore Courses</button>
                </div>

            </div>
            <div className={`p-2 pt-2 container pb-5`}>
                <div>
                    <StudentEnrolledCourses onSuccess={getStudentCourseBatches} courseBatches={courseBatches}
                                            selectedTab={selectedTab} selectedCourse={(courseType) =>
                        handleSelectedCourse(courseType)}/>
                </div>

                  <div ref={allStudentCoursesRef}  className='allStudentCourse'>
                      <AllStudentCourses/>
                  </div>

            </div>
            {/*<div className={`p-2 pt-2 container pb-5`}>*/}
            {/*    <div className=' w-100 nav-tabs ps-3 pt-3 d-flex justify-content-between align-items-center'>*/}
            {/*        <div className='d-flex'>*/}
            {/*            <div className={`studentTab me-2 p-3 ${*/}
            {/*                selectedTab === Tab.Enrolled ? 'methodButtons' : 'tabTextColor '}`}*/}
            {/*                 onClick={() => handleSelectedMethod(Tab.Enrolled)}>*/}
            {/*                <div>Enrolled</div>*/}
            {/*            </div>*/}
            {/*            <div className={`studentTab p-3 ${*/}
            {/*                selectedTab === Tab.Explore ? 'methodButtons' : 'tabTextColor'}`}*/}
            {/*                 onClick={() => handleSelectedMethod(Tab.Explore)}>*/}
            {/*                <span className="button-label">Explore Courses</span>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*    {selectedTab === Tab.Enrolled &&*/}
            {/*        <StudentEnrolledCourses onSuccess={getStudentCourseBatches} courseBatches={courseBatches}*/}
            {/*                                selectedTab={selectedTab} selectedCourse={(courseType) =>*/}
            {/*            handleSelectedCourse(courseType)}/>*/}
            {/*    }*/}
            {/*    {selectedTab === Tab.Explore &&*/}
            {/*        <AllStudentCourses/>*/}
            {/*    }*/}
            {/*</div>*/}

        </div>
    </>
}