import React, {useEffect, useState} from "react";
import {PieTooltipProps, ResponsivePie} from "@nivo/pie";
import {useStudentApiClient} from "../../../modules/student/apiClients/StudentApiClient";
import StudentTestStatisticsChart from "../../chart/StudentTestStatisticsChart";
import './StudentAssignmentDropDown.scss';
import {TopicsAnalysisModal} from "./TopicsAnalysisModal";
import {LeaderBoardData} from "../../../dto/StudentDashBoard";

export interface StudentAssignmentModalProps {
    show: boolean;
    onHide: () => void;
    selectedSubject?: string;
    modalData?: any
    courseId: number;
    programId: number;
    leaderBoard: LeaderBoardData[]
}

export interface StudentQuestionDetails {
    totalQuestions: number,
    attemptedQuestions: number,
    skippedQuestions: number,
    correctlyAttempted?: number
    wronglyAttempted?: number
}

export interface StudentPercentage {
    highestPercentage: number;
    myPercentage: number
}

export interface TopicAnalysisData {
    id: number;
    name: string;
    active: boolean;
    totalQuestions: number;
    correctlyAnsweredQuestions: number;
    percentage: number;
    topicEvaluation: string;
}

export interface ChartData {
    id: string;
    label: string;
    topicEvaluation: string;
    value: number;
    percentage?: number;
}

export interface TopicData {
    id: number;
    name: string;
    active: boolean;
    totalQuestions: number;
    correctlyAnsweredQuestions: number;
    percentage: number;
    topicEvaluation: string;
}


export const StudentAssignmentDropDown = (props: StudentAssignmentModalProps) => {
    const studentApiClient = useStudentApiClient();
    const [data, setData] = useState<TopicAnalysisData[]>([]);
    const [chartData, setChartData] = useState<ChartData[]>([])
    const [weakTopic, setWeakTopic] = useState<TopicData[]>([])
    const [strongTopic, setStrongTopic] = useState<TopicData[]>([])
    const [loading, setLoading] = useState(true);
    const [testData, setTestData] = useState<StudentPercentage>({myPercentage: 0, highestPercentage: 0})
    const [practiceData, setPracticeData] = useState<StudentPercentage>({myPercentage: 0, highestPercentage: 0})
    const [assignmentData, setAssignmentData] = useState<StudentPercentage>({myPercentage: 0, highestPercentage: 0})
    const [questionDetail, setQuestionDetail] = useState<StudentQuestionDetails>({
        attemptedQuestions: 0,
        skippedQuestions: 0,
        totalQuestions: 0
    })
    const [topicsEvaluationModal, setTopicsEvaluationModal] = useState<boolean>(false)
    const [pageSize, setPageSize] = useState<number>(10);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [totalElements, setTotalElements] = useState<number>(0);

    const truncateName = (name: string) => {
        return name?.length > 14 ? name.substring(0, 14) + '...' : name;
    };

    useEffect(() => {
        if (data) {
            const maxTopicsToShow = 4;
            const strongTopics = data.filter((topic: any) => topic.topicEvaluation === 'Strong_Topic').slice(0, maxTopicsToShow);
            const weakTopics = data.filter((topic: any) => topic.topicEvaluation === 'Weak_Topic').slice(0, maxTopicsToShow);
            const constantValue = 1;
            const formattedData: ChartData[] = [
                ...strongTopics?.map((topic: any) => ({
                    id: truncateName(topic?.name),
                    label: truncateName(topic?.name),
                    value: constantValue,
                    percentage: topic?.percentage,
                    topicEvaluation: 'Strong_Topic',
                })),
                ...weakTopics?.map((topic: any) => ({
                    id: truncateName(topic?.name),
                    label: truncateName(topic?.name),
                    value: constantValue,
                    percentage: topic?.percentage,
                    topicEvaluation: 'Weak_Topic',
                })),
            ];
            setChartData(formattedData);
            console.log('data', chartData)
        }
    }, [data]);

    useEffect(() => {
        if (props.courseId && props.programId) {
            fetchStudentEvaluationForTopics(props.courseId, props.programId, currentPage, pageSize)
        }
    }, [props.courseId, props.programId, currentPage, pageSize]);

    useEffect(() => {
        const strongTopics = data?.filter((topic: any) => topic.topicEvaluation === "Strong_Topic");
        setStrongTopic(strongTopics)
        const weakTopics = data?.filter((topic: any) => topic.topicEvaluation === "Weak_Topic");
        setWeakTopic(weakTopics)
    }, [data]);

    useEffect(() => {
        if (props.courseId && props.programId) {
            getDetailOfStudentQuestions(props.programId)
        }
    }, [props.courseId, props.programId]);

    useEffect(() => {
        if (props.courseId && props.programId && props.programId !== 0 || undefined) {
            getStudentTestHighestPercentage(props.courseId, props.programId);
            getStudentPracticeHighestPercentage(props.courseId, props.programId);
            getStudentAssignmentHighestPercentage(props.courseId, props.programId);
        }
    }, [props.courseId, props.programId]);

    const fetchStudentEvaluationForTopics = (courseId: number, programId: number, page: number, pageSize: number) => {
        setLoading(true)
        studentApiClient.fetchStudentEvaluationForTopic(courseId, programId, page, pageSize).then((res) => {
            setData(res.data);
            console.log('#123', res.data)
            setLoading(false)
            // setCurrentPage(res.data)
            // setTotalPages(res.data)
            // setTotalElements(res.data)
        }).catch((error) => {
            console.log(error)
        })
    }

    const getDetailOfStudentQuestions = (programId: number) => {
        studentApiClient.getDetailsOfQuestions(programId).then((res) => {
            setQuestionDetail(res.data)
        }).catch((err) => {
            console.log(err)
        })
    }

    const getStudentTestHighestPercentage = (courseId: number, programId: number) => {
        studentApiClient.getTestHighestAndStudentPercentage(courseId, programId).then((res) => {
            setTestData(res.data)
        }).catch((err) => {
            console.log(err)
        })
    }

    const getStudentPracticeHighestPercentage = (courseId: number, programId: number) => {
        studentApiClient.getPracticeHighestAndStudentPercentage(courseId, programId).then((res) => {
            setPracticeData(res.data)
        }).catch((err) => {
            console.log(err)
        })
    }

    const getStudentAssignmentHighestPercentage = (courseId: number, programId: number) => {
        studentApiClient.getAssignmentHighestAndStudentPercentage(courseId, programId).then((res) => {
            setAssignmentData(res.data)
        }).catch((err) => {
            console.log(err)
        })
    }
    const strongColor = '#93DB8C';
    const weakColor = '#F47C7C';
    const getArcLabel = (percentage: number) => {
        return `${percentage}%`;
    };

    return <div className=''>
        <div className={"card"}>
            <div className={'row'}>
                <div className={'col-8'}>
                    <div className={'py-3 px-3'}>
                        <h5 className={'fw-medium text-capitalize mb-3'}>{props.selectedSubject}</h5>
                        <div className='card-header bg-transparent p-0 d-flex justify-content-between'>
                            <div className={'d-flex align-items-center'}>
                                <h6 className={''} style={{
                                    color: '#898989'
                                }}> Topic Analysis
                                </h6>
                            </div>
                            <div>
                                <a onClick={() => setTopicsEvaluationModal(true)}
                                   className={"text-decoration-underline"}
                                   style={{
                                       cursor: 'pointer',
                                       color: '#3498DB',
                                       fontSize: '14px',
                                       fontWeight: '500'
                                   }}>
                                    View All
                                </a>
                            </div>
                        </div>
                        <div className=''>
                            {chartData && chartData.length > 0 ? (
                                <StudentTestStatisticsChart
                                    testData={testData}
                                    assignmentData={assignmentData}
                                    practiceData={practiceData}
                                    chartData={chartData.map(data => ({
                                        ...data,
                                        percentage: data.percentage ?? 0
                                    }))}
                                    width={150}
                                    height={200}
                                />
                            ) : (
                                <div className="no-data-message text-center fw-bold" style={{marginTop:'8rem'}}>
                                    No data available
                                </div>
                            )}
                        </div>

                    </div>
                </div>
                {/*<div className='d-flex align-items-center justify-content-center'*/}
                {/*     style={{width: 'auto', height: '300px'}}>*/}
                {/*    {loading ? ("") : chartData.length > 0 ?*/}
                {/*        <ResponsivePie*/}
                {/*            data={chartData}*/}
                {/*            tooltip={({datum}: PieTooltipProps<any>) => (*/}
                {/*                <div className='bg-light p-1'>*/}
                {/*                    <strong>*/}
                {/*                        <div*/}
                {/*                            style={{*/}
                {/*                                backgroundColor: datum.data.topicEvaluation === 'Weak_Topic' ? weakColor : strongColor,*/}
                {/*                                width: '10px',*/}
                {/*                                height: '10px',*/}
                {/*                                display: 'inline-block',*/}
                {/*                                marginRight: '5px',*/}
                {/*                            }}*/}
                {/*                        ></div>*/}
                {/*                        {datum.id}*/}
                {/*                    </strong>: {datum.data.percentage !== undefined ? `${datum.data.percentage}%` : 'N/A'}*/}
                {/*                </div>*/}
                {/*            )}*/}
                {/*            margin={{top: 50, right: 80, bottom: 80, left: 80}}*/}
                {/*            innerRadius={0.5}*/}
                {/*            padAngle={1}*/}
                {/*            cornerRadius={5}*/}
                {/*            activeOuterRadiusOffset={8}*/}
                {/*            borderWidth={1}*/}
                {/*            borderColor={{from: "color", modifiers: [["darker", 0.2]]}}*/}
                {/*            arcLinkLabelsSkipAngle={7}*/}
                {/*            arcLinkLabelsTextColor="#333333"*/}
                {/*            arcLinkLabelsThickness={2}*/}
                {/*            arcLinkLabelsColor={{from: "color"}}*/}
                {/*            arcLabelsSkipAngle={10}*/}
                {/*            arcLabelsTextColor={{from: "color", modifiers: [["darker", 2]]}}*/}
                {/*            colors={(d) => {*/}
                {/*                const topicEvaluation = d.data?.topicEvaluation;*/}
                {/*                return topicEvaluation === 'Weak_Topic' ? weakColor : strongColor;*/}
                {/*            }}*/}
                {/*            enableArcLabels={true}*/}
                {/*            arcLabel={(arc) => getArcLabel(arc.data?.percentage ?? 0)}*/}
                {/*            animate={false}*/}
                {/*            isInteractive={false}*/}
                {/*        />*/}
                {/*        :*/}
                {/*        <img*/}
                {/*            style={{width: "300px"}}*/}
                {/*            src={"https://cdn.dribbble.com/users/837373/screenshots/19400228/media/714312f1b47a877348dc2976eb541173.png?resize=400x300&vertical=center"}*/}
                {/*        />}*/}
                {/*</div>*/}
                {/*{chartData.length > 0 &&*/}
                {/*    <div className='row d-flex mx-1 mb-3'>*/}
                {/*        <div className='col-6'>*/}
                {/*            <div className='card h-100' style={{borderColor: '#e2e2e2'}}>*/}
                {/*                <div className={'card-body'}>*/}
                {/*                    <div className='mb-2'><b>Strong Topics:</b></div>*/}
                {/*                    {strongTopic?.slice(0, 4).map((topic: any, index: number) => (*/}
                {/*                        <div key={index} className={'topics_listing gr_list'}>*/}
                {/*                            <ul className='p-0'>*/}
                {/*                                <li>{topic?.name}</li>*/}
                {/*                            </ul>*/}
                {/*                        </div>*/}
                {/*                    ))}*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*        <div className='col-6'>*/}
                {/*            <div className='card h-100' style={{borderColor: '#e2e2e2'}}>*/}
                {/*                <div className={'card-body'}>*/}
                {/*                    <div className='mb-2'><b>Weak Topics:</b></div>*/}
                {/*                    {weakTopic?.slice(0, 4).map((topic: any, index: number) => (*/}
                {/*                        <div key={index} className={'topics_listing re_list'}>*/}
                {/*                            <ul className='p-0'>*/}
                {/*                                <li>{topic?.name}</li>*/}
                {/*                            </ul>*/}
                {/*                        </div>*/}
                {/*                    ))}*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*}*/}
                {/*</div>*/}
                <div className={'col-3 ms-5 mt-5'}>
                    <div className='card mb-3 border-2' style={{borderColor: '#3498DBCC'}}>
                        <div className='card-header' style={{background: '#3498DB'}}>
                            <div className={'d-flex align-items-center justify-content-center'}>
                                {/*<h6 className={'fw-bold me-2 text-capitalize'}>{props.selectedSubject}</h6>*/}
                                <h6 className={'text-white mb-0 fw-light'}>Score Card</h6>
                            </div>
                        </div>
                        <div className='analysis-card-body'>
                            <div className="border-bottom m-3">
                                <div className="row">
                                    <h5 style={{color: '#01375B'}}
                                        className='text-center'>{questionDetail.attemptedQuestions}/{questionDetail.totalQuestions}</h5>
                                    <h6 style={{fontSize: '13px'}} className='text-center'>Attempted / Pending</h6>
                                </div>
                            </div>
                            <div className="border-bottom m-3">
                                <div className="row">
                            <h5 className="text-center" style={{color:'#01375B'}}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className={'me-2'}
                                     fill="none">
                                    <path
                                        d="M8.81806 19.779L2.4541 13.415L5.2841 10.585L8.81806 14.129L18.716 4.22107L21.546 7.05107L8.81806 19.779Z"
                                        fill="#01375B"/>
                                </svg>
                                {questionDetail.correctlyAttempted}
                            </h5>
                                    <h6 style={{fontSize: '13px'}} className="text-center">Correct Answers</h6>
                                </div>
                            </div>
                            <div className="border-bottom m-3">
                                <div className="row">
                            <h5 className="text-center" style={{color:'#01375B'}}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className={'me-2'}
                                     fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                          d="M1.40332 11.9998C1.40332 6.14749 6.14755 1.40326 11.9999 1.40326C17.8522 1.40326 22.5963 6.14749 22.5963 11.9998C22.5963 17.852 17.8522 22.5963 11.9999 22.5963C6.14755 22.5963 1.40332 17.852 1.40332 11.9998ZM11.9999 2.92325C6.98702 2.92325 2.92333 6.98696 2.92333 11.9998C2.92333 17.0126 6.98702 21.0763 11.9999 21.0763C17.0126 21.0763 21.0763 17.0126 21.0763 11.9998C21.0763 6.98696 17.0126 2.92325 11.9999 2.92325ZM15.7657 8.23429C16.0781 8.54671 16.0781 9.05325 15.7657 9.36567L13.1314 12L15.7657 14.6343C16.0781 14.9467 16.0781 15.4533 15.7657 15.7657C15.4533 16.0781 14.9468 16.0781 14.6344 15.7657L12 13.1313L9.36573 15.7657C9.05331 16.0781 8.54677 16.0781 8.23435 15.7657C7.92194 15.4533 7.92194 14.9467 8.23435 14.6343L10.8687 12L8.23435 9.36567C7.92194 9.05325 7.92194 8.54671 8.23435 8.23429C8.54677 7.92188 9.05331 7.92188 9.36573 8.23429L12 10.8686L14.6344 8.23429C14.9468 7.92188 15.4533 7.92188 15.7657 8.23429Z"
                                          fill="#01375B"/>
                                </svg>
                                {questionDetail.wronglyAttempted}</h5>
                                    <h6 style={{fontSize: '13px'}} className="text-center">Wrong Answers</h6>
                                </div>
                            </div>
                            <div className=" m-3">
                                <div className="row">
                            <h5 className="text-center" style={{color:'#01375B'}}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className={'me-2'}
                                     fill="none">
                                    <path
                                        d="M4 19.999C3.904 19.999 3.809 19.984 3.714 19.957C3.29 19.831 3 19.441 3 18.999V17.999C3 13.199 6.381 9.13505 11 8.20305V6.49905C11 5.96505 11.208 5.46305 11.585 5.08505C12.341 4.32805 13.66 4.32905 14.414 5.08405L20.702 11.287C20.893 11.475 21 11.73 21 11.999C21 12.268 20.893 12.523 20.702 12.711L14.409 18.918C13.663 19.664 12.342 19.669 11.586 18.913C11.208 18.535 11 18.033 11 17.499V16.062C8.505 16.263 6.477 17.047 4.836 19.546C4.646 19.834 4.331 19.999 4 19.999ZM12 14.01L13 14V17.499L18.576 11.999L13 6.50305V10C13 10 12.616 9.99605 12.109 10.052C8.693 10.43 5.984 12.916 5.217 16.132C7.338 14.404 9.768 14.066 12 14.01Z"
                                        fill="#01375B"/>
                                </svg>
                                {questionDetail.skippedQuestions}</h5>
                                    <h6 className="text-center" style={{fontSize: '13px'}}>Skipped</h6>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className={''}>

            <div className={"ms-3 mt-3 px-4"}>
                <TopicsAnalysisModal show={topicsEvaluationModal}
                                     onHide={() => setTopicsEvaluationModal(false)}
                                     data={data} courseId={props.courseId} programId={props.programId}
                                     currentPage={currentPage}
                                     pageSize={pageSize} totalPages={totalPages}
                                     totalElements={totalElements}
                                     fetchStudentEvaluationForTopics={() => fetchStudentEvaluationForTopics}
                />
            </div>
        </div>
    </div>
}


