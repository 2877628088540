import { useAxios } from "../../../configs/AxiosClient";
import {OrderDetails, RazorPayPaymentValidationDTO} from "../../../modals/RazorPayModals";
import {SubscriptionForCourse} from "../screen/home/courses/CourseDetails";
import {config} from "dotenv";

export const usePaymentApiClient = () => {
    const axiosClient = useAxios();

    const createOrderForCourser = (courseId: number,subscriptionType:SubscriptionForCourse , newCoursePrice ?: number) => {
        return axiosClient.post<OrderDetails>(`/courses/${courseId}/orders`,{subscriptionType} , {params : {newCoursePrice : newCoursePrice}});
    }

    const verifyPayment = (payload: RazorPayPaymentValidationDTO) => {
        return axiosClient.post(`/orders/payments/razorpay`, payload);
    }

    return {createOrderForCourser, verifyPayment};
}