import React, {ChangeEvent, FormEvent, useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {SessionManager} from "../../../../configs/SessionManager";
import {InputUtils} from "../../../../utils/InputUtils";
import {useStudentApi} from "../../../../api/StudentApiClent";
import {LoginScope} from "../../../../dto/Payload";
import logo from "../../../../assets/images/aiDronaLogo.png";
import StudentLoginImg from "../../../../assets/images/studentLoginImg.png";
import LoginStudentUserId, {StudentLoginUsingId} from "../../../student/screen/login/LoginStudentUserId";
import {GOOGLE_CLIENT_ID} from "../../../../common/AppConstants";

interface StudentLoginForInput {
    username: string,
    password: string,
    scope: LoginScope
}

const DataEntryExecutiveLogin = () => {
    const navigate = useNavigate();
    const [payload, setPayload] = useState<StudentLoginUsingId>({
        password: '',
        username: '',
        scope: LoginScope.DataEntryExecutive
    });
    const studentApi = useStudentApi();
    const [error, setError] = useState<string>();
    const [loginButtonDisabled , setLoginButtonDisabled] = useState<boolean>(false) ;

    useEffect(() => {
        google.accounts.id.initialize({
            client_id: GOOGLE_CLIENT_ID,
            callback: handleCallBackResponse
        })
        google.accounts.id.renderButton(
            document.getElementById("signInDiv") as HTMLElement,
            {theme: "filled_blue", size: "large", type: "standard",text:"continue_with" ,logo_alignment:"left",shape:"circle"}
        );

        google.accounts.id.prompt();
    }, []);

    const handleCallBackResponse = (res: any) => {
        console.log("#41", res.credential);
        passwordLogin({username: "$GOOGLE$", password: res.credential, scope: LoginScope.DataEntryExecutive});
    }

    const passwordLogin = (payload:StudentLoginUsingId) => {
        setError(undefined);
        setLoginButtonDisabled(true) ;
        studentApi.userIdPasswordLogin(payload).then(res => {
            SessionManager.createSession(res.data.access_token, '', res.data);
            navigate("/dataEntry")
        }).catch(err => {
            console.error(err)
            setError(`Login Error: ${err.message}`)
        }).finally(() => {
            setLoginButtonDisabled(false) ;
        })
    }

    const handleChange = (event: ChangeEvent<HTMLDivElement>) => {
        const inp = InputUtils.parseInput(event);
        setPayload((prevState) => ({...prevState, [inp.name]: inp.value}));
    }
    const submitForm = (evt: FormEvent) => {
        evt.preventDefault();
        passwordLogin(payload) ;
    }

    return (
        <div id={'main_wrapper'} className="loginScreen_wrapper">
            <div className={'container-fluid'}>
                <div className={'row min-vh-100 m-4'}>
                    <div className={'col-3'}></div>
                    <div className={'col-md-6'}>
                        <div className={'login-wrap h-100'}>
                            <img  style={{width:'79px',height:'65px'}} src={logo}/>
                            <div className={'login_info_wrap'}>
                                <h3 className='mb-4 fw-bold'>Welcome to Ai Drona</h3>
                                <div className={'d-flex align-items-center justify-content-between mb-4'}>
                                    <div className={'col-6 me-auto'}>
                                        <h6 className='text-black-50'>Login to your account</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="app-routes w-100">
                                <form className="mt-3 w-100" onSubmit={submitForm}>
                                    {error && (
                                        <div className="alert alert-danger" role="alert">
                                            {error}
                                        </div>
                                    )}
                                    <div className="mb-3">
                                        <label className="form-label d-flex" htmlFor="studentId">
                                            User Id
                                        </label>
                                        <input id="studentId"
                                               placeholder="User Id" className="form-control m-auto " type="text"
                                               name="username"
                                               onChange={handleChange}
                                        />
                                    </div>
                                    <div className="mb-2">
                                        <label className="form-label" htmlFor="password">
                                            Password
                                        </label>
                                        <input id="password" placeholder="Enter Password"
                                               className="form-control m-auto"
                                               type="password" name="password" onChange={handleChange}
                                        />
                                    </div>
                                    <div className={'loginBtn mt-5'}>
                                        <button
                                            className='btn btn-otp w-100 text-white'
                                            disabled={loginButtonDisabled}>Login
                                        </button>
                                    </div>
                                    <div id="signInDiv" className="mb-3 d-flex justify-content-center"
                                         style={{marginTop: "100px"}}>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    {/*<div className={'col-md-6 right-side'}>*/}
                    {/*    <img src={StudentLoginImg} height={780}/>*/}
                    {/*</div>*/}
                </div>
            </div>
        </div>
    );
};

export default DataEntryExecutiveLogin;
