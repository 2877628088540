import React, {useState} from "react";
import "./TabSelector.scss";
import {QuizMode} from "../../../../modals/quizquestionData/Modal";

const TabSelector = (props: { option: string, handleOptionClick: (option: string) => void }) => {
    const [selectedTest, setSelectedTest] = useState<string>(props.option.toUpperCase());

    const testSwitcher = (label: string) => {
        return <label className="checkbox">
            <input type="checkbox" className="checkbox__input" checked={selectedTest === label.toUpperCase()}
                   onClick={() => {
                       setSelectedTest(label.toUpperCase());
                       props.handleOptionClick(label == 'Test' ? QuizMode.TEST : QuizMode.PRACTISE);
                   }}/>
            <span className="checkbox__inner"></span>
            <div className="checkbox__body ms-2">{label}</div>
        </label>
    }


    return <div className={'chips d-flex '}>
        <div className={'mt-2 d-flex'}>
            {testSwitcher('Test')}
            {testSwitcher('Practice')}
        </div>
    </div>

}
export default TabSelector;