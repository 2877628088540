import {TeachersPayload} from "../../../components/modal/teachers/AddEditTeacherModal";
import {useAxios} from "../../../configs/AxiosClient";
import {access} from "fs";

export const useTeacherApiClient = () => {
    const axiosClient = useAxios();
    const getTeachers = (size?: number, page?: number, query?: string) => {
        return axiosClient.get('/teachers', {
            params: {
                size: size,
                page: page,
                query: query,
            }
        });
    };

    const createTeacher = (payload: TeachersPayload) => {
        return axiosClient.post('/teachers', payload);
    }

    const updateTeacher = ( id:number ,payload: TeachersPayload) => {
        return axiosClient.put(`/teachers/${id}`, payload);
    }

    const getTeacherById = (id:number) => {
        return axiosClient.get(`/teachers/${id}`)
    }

    const softDeleteTeacher = (teacherId: number) => {
        return axiosClient.delete(`/teachers/${teacherId}/active`);
    };

    const restoreTeacher = (teacherId: number) => {
        return axiosClient.post(`/teachers/${teacherId}/active`);
    };

    return {getTeachers, createTeacher, updateTeacher, getTeacherById, softDeleteTeacher,restoreTeacher}
}