import React, {useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import {ModalBody, Dropdown} from 'react-bootstrap';
import './NoOfQuestionsModal.scss'

interface StudentNoOfQuestionsModalProps {
    show: boolean;
    onHide: () => void;
    createTest: () => void;
    numberOfQuestions: (number: string) => void;
    ofComp?: boolean;
    numberOfComp?: (number: number) => void;
}

export const StudentNoOfQuestionsModal = (props: StudentNoOfQuestionsModalProps) => {
    const [numberOfQuestions, setNumberOfQuestions] = useState('');
    const [error, setError] = useState('');
    const [startTestDisabled, setStartTestDisabled] = useState(false);
    const [numberOfComp, setNumberOfComp] = useState<number | undefined>(undefined);

    const handleInputChange = (e: any) => {
        const inputNumber = e.target.value;

        if (inputNumber === '' || (parseInt(inputNumber) <= 100 && parseInt(inputNumber) > 0)) {
            setError('');
            setNumberOfQuestions(inputNumber);
            props.numberOfQuestions(inputNumber);
        } else {
            setError('Please enter a valid number (1 to 100).');
            setStartTestDisabled(true);
        }
    };

    const handleSubmit = () => {
        setNumberOfQuestions('');
        setError('');
        props.createTest()
        setStartTestDisabled(true);
    };

    const cancelModal = () => {
        props.onHide();
        setNumberOfComp(undefined);
        setNumberOfQuestions('');
    }

    const options: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

    return (
        <Modal show={props.show} onHide={cancelModal} centered={true}
        >
            <Modal.Header closeButton>
                <Modal.Title>{props.ofComp ? 'Number of Comprehensions' : 'Set Number of Questions'}</Modal.Title>
            </Modal.Header>
            <ModalBody>
                {
                    props.ofComp ?
                        <form>
                            <label className='form-label'>
                                Please select the number of comprehensions you want to attempt:
                            </label>
                            <div className='me-2'>
                                <Dropdown>
                                    <Dropdown.Toggle
                                        style={{
                                            backgroundColor: "white",
                                            color: "black",
                                            width: "60%",
                                            boxShadow: "none",
                                        }}>
                                        {numberOfComp ?? 'Select Comprehensions'}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu style={{maxHeight: '200px', overflowY: 'auto', width: "225px"}}>
                                        {options?.map((option) => (
                                            <Dropdown.Item
                                                key={option}
                                                onClick={() => {
                                                    setNumberOfComp(option);
                                                    props.numberOfComp && props.numberOfComp(option);
                                                }}
                                            >
                                                {option}
                                            </Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </form> :
                        <form>
                            <label className='form-label'>
                                Please enter the number of questions you want to attempt:
                            </label>
                            <input
                                className='form-control'
                                type="text"
                                placeholder="Enter number of questions"
                                value={numberOfQuestions}
                                onChange={handleInputChange}
                            />
                            {error && <div className="text-danger">{error}</div>}
                        </form>
                }

            </ModalBody>
            <Modal.Footer>
                <button type="button" className='btn btnCancel' onClick={cancelModal}>
                    Cancel
                </button>
                <button
                    className="btn btnGreen"
                    type='button'
                    onClick={handleSubmit}>
                    Start Test
                </button>
            </Modal.Footer>
        </Modal>
    );
};