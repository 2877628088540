import React, {useEffect, useState} from "react";
import {MathX} from "../../../../../../components/math/MathX";
import {Passage} from "../../../../../../dto/CreateAssignmentQuestionListing";
import QuestionError from "../../questionError/QuestionErrorModal";
import CustomSwitcher from "../../../../../../components/common/course/courseView/CustomSwitcher";
import {useLocation} from "react-router-dom";
import {useTeacherApiClient} from "../../../../apiClients/TeacherApiClient";
import {VerificationData} from "../../../verification/Verification";
import {Question} from "../../../modals/CreateiUpdateQuestion";

interface ExpandedQuestionsProps {
    // toggleQuestionExpand: (questionId: number) => void
    question: Partial<Passage>
    setQuestionId: React.Dispatch<React.SetStateAction<number | undefined>>
    setEditQuestionModal: React.Dispatch<React.SetStateAction<boolean>>
    // expandedQuestionId: number[]
    getAllQuestionsForComprehensions?:(topicIds?: number[], subjectId?: number, verified?: VerificationData, query?: string, page?: number, size?: number)=>void
    getAllComprehensions?:(page?: number, size?: number, query?: string)=>void
    selectedOption:string
    // expandedQuestionData:Question[]
}

export const CreateAssignmentExpandedQuestionListing = (props: ExpandedQuestionsProps) => {
    const [questionErrorModal, setQuestionErrorModal] = useState(false);
    const [questionId, setQuestionId] = useState<number | undefined>(0);
    const [expandedQuestionIds, setExpandedQuestionIds] = useState<number[]>([])
    const location = useLocation();
    const teacherApiClient = useTeacherApiClient();

    useEffect(() => {
        const questionId =props.question.questions && props.question.questions?.map((res)=> res.id) || []
        setExpandedQuestionIds(questionId)
    }, [props.question]);

    const toggleQuestionExpand = (questionId: number) => {
        setExpandedQuestionIds(prevIds => {
            if (prevIds.includes(questionId)) {
                return prevIds.filter(id => id !== questionId);
            } else {
                return [...prevIds, questionId];
            }
        });
    };
    const markQuestionResolved = (id: number) => {
        teacherApiClient.verifyQuestion(id).then(() => {
        }).catch((err) => {
            console.log(err)
        })
    }
    const markQuestionUnResolved = (id: number) => {
        teacherApiClient.unVerifyQuestion(id).then(() => {
        }).catch((err) => {
            console.log(err)
        })
    }

    const deleteQuestion = (questionId: number) => {
        teacherApiClient.questionDelete(questionId).then((res) => {
        }).catch((err) => {
            console.log(err)
        })
    }

    return <div>
        {props.question.questions?.map((question, questionIndex) => (
            <div key={question.id} className="accordion mb-2">
                <div className="card">
                    <div className="card-header">
                        <div className="mb-0 w-100">
                            <div
                                className="d-flex align-items-center justify-content-between w-100">
                                <div className='me-2' onClick={(e) => {
                                    toggleQuestionExpand(question?.id);
                                }}>{questionIndex + 1}. {question?.text}</div>
                                <div className='d-flex align-items-center justify-content-center'>
                                    <div className='d-flex align-items-center justify-content-end'>
                                        {location.pathname === '/teacher/Verification' ? (
                                            <div>
                                                <div className='ms-2 me-4'>
                                                    {props.selectedOption === 'UnVerified' ?
                                                        <CustomSwitcher
                                                            inactiveLabel={'Verify'}
                                                            active={false}
                                                            activeLabel={'Verified'}
                                                            onChange={() => {
                                                                markQuestionResolved(question.id);
                                                            }}
                                                        /> :
                                                        (props.selectedOption === 'Verified' &&
                                                            <CustomSwitcher
                                                                inactiveLabel={'UnVerified'}
                                                                active={true}
                                                                activeLabel={'Verified'}
                                                                onChange={() => {
                                                                    markQuestionUnResolved(question.id)
                                                                }}
                                                            />)}
                                                </div>
                                                {props.selectedOption === 'UnVerified' &&
                                                    <div>
                                                        <div>
                                                            <div className='ms-2 me-2'>
                                                                <div className='mt-2'>
                                                                    <CustomSwitcher
                                                                        inactiveLabel={'Delete'}
                                                                        active={false}
                                                                        activeLabel={'Deleted'}
                                                                        onChange={() => {
                                                                            deleteQuestion(question?.id ?? 0)
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        ) : ''}
                                    </div>
                                    {location.pathname === '/teacher/audit' || location.pathname === '/teacher/Verification' ? ('') :
                                        <div className='me-2'>
                                            <i
                                                className="bi bi-exclamation-triangle fs-4 me-2"
                                                onClick={() => {
                                                    setQuestionErrorModal(true);
                                                    setQuestionId(question?.id);
                                                }}
                                            ></i>
                                        </div>
                                    }
                                    <i onClick={() => {
                                        props?.setQuestionId(question?.id)
                                        props?.setEditQuestionModal(true)
                                    }} className={'bi bi-pencil-square fs-5'}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    {expandedQuestionIds.includes(question?.id) && (
                        <div className="card-body">
                            <div className={'row'}>
                                {(question?.options)?.map((option: any, optionIndex) => (
                                    <div className="col-lg-6" key={option?.id}>
                                        <div
                                            className="d-flex align-items-center w-100">
                                            <div key={option?.id}
                                                 className="d-flex align-items-center">
                                                <div className={'d-flex'}>
                                                    <span
                                                        className="me-2"> Option {optionIndex + 1}:</span>
                                                    <span
                                                        className="me-2"><MathX>{option?.text}</MathX></span>
                                                </div>
                                                <div className={''}>
                                                    {option?.correct ? (
                                                        <div className={'correct_icon'}>
                                                            <i className="bi bi-check2"></i>
                                                        </div>
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            {(question && question?.oneWordAnswer?.length) > 0 ?
                                <div>Option: {question?.oneWordAnswer}</div> : ""}
                        </div>
                    )}
                </div>
            </div>
        ))}
        <QuestionError
            questionId={questionId}
            show={questionErrorModal}
            onHide={() => {
                setQuestionErrorModal(false);
                setQuestionId(undefined);
            }}
            onCancel={() => {
                setQuestionErrorModal(false);
            }}
            onSuccess={() =>props.getAllComprehensions &&  props.getAllComprehensions()}
        />
    </div>
}