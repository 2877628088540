import React, {useState} from "react";
import './subjectsCard.scss' ;
import {useNavigate} from "react-router-dom";
import AddEditSubject from "../modal/AddEditSubject";
interface CardProps {
    id?:string,
    data?:any,
    reload?:()=>void,
}

const Card= (props:CardProps) => {
    const [subjectId, setSubjectId] = useState<number | undefined>(0)
    const [show, setShow] = useState<boolean>(false);

    const navigate =useNavigate();
    return(
    <div className={'subject-card mb-4'}  style={{cursor:"pointer"}}>
        <div className="card shadow-sm border-0">
            <div className="d-flex justify-content-between align-items-center card-body p-3">
                <h6 className="text-primary" onClick={()=>navigate(`${props.data.id}/topics`)} >{props.data?.name}</h6>
                <div>
                    <a className={'btn btn-sm me-2 rounded-circle'}
                       onClick={() => {setShow(true) ; setSubjectId(props.data?.id)}}>
                        <i className="bi bi-pen"></i>
                    </a>
                </div>
            </div>

        </div>

        <AddEditSubject
            subjectId={subjectId}
            onCancel={() => setShow(false)}
            onHide={() => setShow(false)}
            show={show}
            onSuccess={() => {
                setShow(false);
                if (props.reload && typeof props.reload === 'function') {
                    props.reload();
                }
            }}
        />


    </div>
    );
}

export default Card;