import {Modal} from "react-bootstrap";
import './LockModal.css';
import {useNavigate} from "react-router-dom";

const LockModal = (props: { show: boolean, onHide: () => void, courseId: string }) => {

    const navigate = useNavigate();

    const handleBuyNowClick = () => {
        navigate(`/student/course-details/${props.courseId}`);
    }

    return (
        <div className={'parent'}>
            <div>
                <Modal show={props.show} onHide={props.onHide} centered>
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body className={'d-flex flex-column align-items-center justify-content-center'}>
                        <div className={'lock-icon'}>
                            <i className={'bi bi-lock-fill icon'}></i>
                        </div>
                        <div className={'icon-text'}>
                            Locked Content
                        </div>
                        <div className={'description'}>
                            <p>All the content marked with a <i className={'bi bi-lock-fill'}></i> icon is locked. To
                            </p>
                            <p>access this content, please purchase the course by</p>
                            <p>clicking the button below.</p>
                        </div>
                        <button
                            className={'buy-button'}
                            type={'button'}
                            onClick={handleBuyNowClick}>
                            Buy Now
                        </button>
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    )
}

export default LockModal;