import React, {useEffect, useMemo, useState} from "react";
import Select from "react-dropdown-select";

interface SelectFilterProps {
    label?: string;
    options: any[];
    selectedValues?: number[];
    onSelectionChange?: (selectedValues: string[]) => void;
    onApplyFilter: () => void;
    onSortChange?: (selectedValues: string) => void;
}

export const VerificationFilter = (props: SelectFilterProps) => {
    const [selectedSubjects, setSelectedSubjects] = useState<{ value: number, label: string }[]>([]);
    const [sort, setSort] = useState<string>('');

    useEffect(() => {
    }, [selectedSubjects,sort]);

    const handleSubjectChange = (options: any[]) => {
        setSelectedSubjects(options);

    };

    const handleSortChange = (options: string) => {
        setSort(options)

    }
    const handleApplyFilter = () => {
        props.onApplyFilter()
    };

    return (
        <div>
            <div className='d-flex mt-2'>
                <div className='me-4' style={{ width: "200px" }}>
                    <Select
                        options={props.options?.map((subject) => ({ value: subject.id, label: subject.name })) ?? []}
                        onChange={(option) => handleSubjectChange(option)}
                        values={selectedSubjects || []}
                        placeholder="Select Subjects"
                    />
                </div>
                <div className='d-flex'>
                    <select className='form-select me-4' style={{ width: "200px" }}
                            onChange={(event) => handleSortChange(event.target.value)}
                    >
                        <option>Sort</option>
                        <option value={'NUMBER'}>By Number</option>
                        <option value={'LATEST_FLAGGED'}>Latest flagged</option>
                    </select>
                    <div>
                        <button className='btnAdd' onClick={()=>{
                            props.onSelectionChange && props.onSelectionChange(selectedSubjects?.map((res) => res.value.toString()) ?? []);
                            props.onSortChange && props.onSortChange(sort);
                            handleApplyFilter();
                        }}>
                            Apply
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

