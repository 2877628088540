import React from "react";

export interface QuestionListTableProps {
    handleChange: any;
    payload: any;
}

interface InputField {
    label: string;
    id: string;
    name: string;
    valueKey: string;
}

export const inputFields: InputField[] = [
    { label: "Difficulty Level", id: "diffsicultyLevel", name: "difficultyLevel", valueKey: "difficultyLevel" },
    {label: "Knowledge Memory", id: "knowledge_memory", name: "knowledge_memory", valueKey: "knowledge_memory"},
    {label: "Student Preparation", id: "student_preparation", name: "student_preparation", valueKey: "student_preparation"},
    {label: "Analytical Thinking", id: "analytical_thinking", name: "analytical_thinking", valueKey: "analytical_thinking"},
    {label: "Concept Application", id: "concept_application", name: "concept_application", valueKey: "concept_application"},
    {label: "Concept Clarity", id: "concept_clarity", name: "concept_clarity", valueKey: "concept_clarity"},
    {label: "Problem Solving Skill", id: "problem_solving_skill", name: "problem_solving_skill", valueKey: "problem_solving_skill"},
    {label: "Numerical Accuracy", id: "numerical_accuracy", name: "numerical_accuracy", valueKey: "numerical_accuracy"},
    {label: "Logic", id: "logic", name: "logic", valueKey: "logic"},
    {label: "Time Management", id: "time_management", name: "time_management", valueKey: "time_management"},
    {label: "Total Score", id: "totalScore", name: "totalScore", valueKey: "totalScore"},
];

export const QuestionListTable = (props: QuestionListTableProps) => {
    return (
        <div>
            <div className="d-flex align-items-center">
                <div className="mb-2 w-100">
                    <div className="row">
                        {inputFields.map((field, index) => (
                            <div key={field.id} className={'col-6'}>
                                <label className="form-label" htmlFor={field.id}>
                                    {field.label}
                                </label>
                                <input
                                    type="text"
                                    id={field.id}
                                    name={field.name}
                                    className={`form-control d-flex${field.valueKey === 'totalScore' ? ' bg-body-secondary' : ''}`}
                                    onChange={props.handleChange}
                                    value={props.payload[field.valueKey]}
                                    pattern="[0-9]+"
                                    disabled={field.valueKey === 'totalScore'}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};
