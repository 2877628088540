import {useAdminApiClient} from "../../apiClients/AdminApiClient";
import React, {useEffect, useState} from "react";
import Pagination from "../../../../components/pagination/Pagination";
import moment from "moment/moment";

interface User {
    id: number;
    name: string;
    dateOfBirth: string;
    gender: string;
    email: string;
    mobile: string;
    streetAddress: string;
    city: string;
    postalCode: string;
    country: string;
    parent: string;
    parentContact: string;
    schoolName: string;
    classSchedule: string;
    user: User;
    active: boolean;
    flag: string;
    state: string;
}

interface User {
    id: number;
    name: string;
    username: string;
    imageCid: string;
    imageUrl: string;
    email: string;
    mobile: string;
    createdDate: string;
    role: string;
    lastLogin: string;
}

interface Order {
    student: User;
    orderId: number;
    courseName: string;
    amount: number;
    purchasedDate: string;
    orderStatus: string;
    orderType: string;
    transactionIds: string[]
}

export const PaymentScreen = () => {
    const adminApiClient = useAdminApiClient();
    const [data, setData] = useState<Order[]>([]);
    const [formDate, setFormDate] = useState<string | undefined>(undefined);
    const [toDate, toFormDate] = useState<string | undefined>(undefined);
    const [courses, setCourses] = useState<number[] | undefined>(undefined);
    const [courseId, setCourseId] = useState<number | undefined>(undefined);
    const [pageSize, setPageSize] = useState<number>(10);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [totalElements, setTotalElements] = useState<number>(0);
    const [query, setQuery] = useState<string | undefined>(undefined);

    // useEffect(() => {
    //     if (courseId && formDate && toDate)
    //         getAllPayments(courseId, formDate, toDate);
    // }, [courseId, formDate, toDate]);
    //
    // useEffect(() => {
    //     getAllCourses()
    // }, []);
    useEffect(() => {
        getAllPayments(courses, formDate, toDate, 0, pageSize, query);
    }, [courses, formDate, toDate, query]);

    // useEffect(() => {
    //     getCoursesList();
    // }, []);


    // useEffect(() => {
    //     if (courseId && formDate && toDate) {
    //         const delayTimer = setTimeout(() => {
    //             getAllPayments(courseId, formDate, toDate, 0, pageSize, query);
    //         }, 300);
    //         return () => {
    //             clearTimeout(delayTimer);
    //         }
    //     }
    // }, [query, pageSize, courseId, formDate, toDate]);

    const getAllPayments = (courses?: number[], fromDate?: string, toDate?: string, page?: number, size?: number, text?: string) => {
        adminApiClient.studentPaymentHistoryForAdmin(courses, fromDate, toDate, page, size, text).then((res) => {
            setData(res.data.content);
            setPageSize(res.data.pageable.pageSize);
            setTotalPages(res.data.totalPages);
            setCurrentPage(res.data.pageable.pageNumber);
            setTotalElements(res.data.totalElements)
        }).catch((err) => {
            console.log(err);
        });
    };

    // const getCoursesList = () => {
    //     adminApiClient.getCoursesList().then((res) => {
    //         setCourses(res.data.map((course : any) => course.id))
    //     }).catch((err) => {
    //         console.log(err)
    //     })
    // }

    const handleFormDate = (e: any) => {
        setFormDate(e.target.value || undefined)
        setData([]);
    }

    const handleToDate = (e: any) => {
        toFormDate(e.target.value || undefined)
        setData([])
    }

    const handleSelectedId = (e: any) => {
        if (e.target.value === '' || undefined) {
            setCourseId(undefined)
            setData([])
        } else {
            setCourseId(e.target.value)
        }
    }


    const resetFilters = () => {
        setCourseId(undefined);
        setFormDate(undefined)
        toFormDate(undefined)
        setQuery(undefined);
        setData([]);
    };

    return (
        <div className='container-fluid page-content'>
            <div className={"card-body"}>
                <div className={'d-flex flex-column'}>
                    <div className="row page-titles mx-0 shadow-sm bg-white rounded-2 mb-3">
                        <div className="d-flex align-items-center justify-content-between m-2">
                            <div>
                                <h4>Payments</h4>
                            </div>
                            <div></div>
                        </div>
                    </div>
                    <div className='card border-0 shadow-sm'>
                        <div>
                            <div className={'d-flex flex-row mt-3 ms-2 justify-content-evenly'}>
                                <div className="col-md-5 px-0">
                                    <div className="form-group searchBar position-relative">
                                        <input
                                            type={"search"}
                                            className="form-control w-100 formControl"
                                            placeholder={"Search here ..."}
                                            onChange={(event) => setQuery(event.target.value)}
                                        />
                                        <button className={'bg-transparent transition p-0 border-0'}><i
                                            className="bi bi-search"></i></button>
                                    </div>
                                </div>
                                {/*<div className='w-25 me-3 d-flex align-items-center justify-content-end'>*/}
                                {/*    <select className='form-select w-100' value={courseId || 'Select'}*/}
                                {/*            onChange={(e) => handleSelectedId(e)}>*/}
                                {/*        <option value={'Select'}>Select</option>*/}
                                {/*        {courses?.map((course) => (*/}
                                {/*            <option key={course} value={course}>{course.name}</option>*/}
                                {/*        ))}*/}
                                {/*    </select>*/}
                                {/*</div>*/}
                                <div>
                                    <div className='d-flex flex-row align-items-center justify-content-end mt-2'>
                                        <label htmlFor="fromDate" className='form-label me-2'>From</label>
                                        <input
                                            type="date"
                                            id="fromDate"
                                            className="form-control me-2"
                                            name={'formDate'}
                                            value={formDate || ''}
                                            onChange={(e) => {
                                                handleFormDate(e)
                                            }}
                                        />
                                        <label htmlFor="toDate" className='form-label me-2'>To</label>
                                        <input
                                            type="date"
                                            id="toDate"
                                            name={'toDate'}
                                            value={toDate || ''}
                                            className="form-control me-2"
                                            onChange={(e) => {
                                                handleToDate(e)
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className='d-flex align-items-center justify-content-center me-2'>
                                    <button className="btn btn-success" onClick={resetFilters}>Reset</button>
                                </div>
                            </div>
                        </div>
                        <div className={''}>
                            <div className={'m-4'}>
                                <table className={`table table-hover studentTable `}>
                                    <thead className={'thead'} style={{color: 'black'}}>
                                    <tr>
                                        <th scope="col">Order Id</th>
                                        <th scope="col">Username</th>
                                        <th scope="col">Mobile</th>
                                        <th scope="col">Email</th>
                                        <th scope="col">Course Name</th>
                                        <th scope="col">Order Type</th>
                                        <th scope="col">Amount</th>
                                        <th scope="col">Transaction Id</th>
                                        <th scope="col">Payment date</th>
                                        <th scope="col">OrderStatus</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {data?.map((payment, index) => (
                                        <tr key={index}>
                                            <td>{payment?.orderId}</td>
                                            <td>{payment?.student?.user?.username}</td>
                                            <td>{payment?.student?.mobile}</td>
                                            <td>{payment?.student?.email}</td>
                                            <td>{payment?.courseName}</td>
                                            <td>{payment?.orderType}</td>
                                            <td>{(payment?.amount) / 100}</td>
                                            <td className={`${!payment?.transactionIds[0] ? 'd-flex justify-content-center align-items-center' : ''}`}>{payment?.transactionIds[0] ?? "--"}</td>
                                            <td>{moment(payment?.purchasedDate).format('DD/MM/YYYY')}</td>
                                            <td>{payment?.orderStatus}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {totalPages > 0 &&
                <Pagination currentPage={currentPage} totalPages={totalPages}
                            onPageChange={(page) => getAllPayments(courses, formDate, toDate, page, pageSize)}
                            totalElements={totalElements}/>}
        </div>
    )
}