import {useAxios} from "../configs/AxiosClient";


export class ChapterRepo {
    static axiosClient = useAxios();

    static addChapter(programId: string, payload: any) {
        return ChapterRepo.axiosClient.post(`/courses/programs/${programId}/chapters`, payload);
    }

    static getSingleChapter(chapterId: string) {
        return ChapterRepo.axiosClient.get(`/chapters/${chapterId}`)
    }

    static getAllChapter(query?: string) {
        return ChapterRepo.axiosClient.get(`/chapters`, {
            params: {
                query: query
            }
        });
    }

    static getAllChapters(programId: string) {
        return ChapterRepo.axiosClient.get(`/programs/${programId}/chapters`);
    }

    static deleteChapter(chapterId: string) {
        return ChapterRepo.axiosClient.delete(`/chapters/${chapterId}`)
    }

    static updateChapter(programId: string, chapterId: string, payload: any) {
        return ChapterRepo.axiosClient.put(`/courses/programs/${programId}/chapters/${chapterId}`, payload)
    }

    static getAllTopics(page?: number, size?: number, query?: string) {
        return ChapterRepo.axiosClient.get(`/topics`, {
            params: {
                page: page,
                size: size,
                query: query
            }
        });
    }

    static getAllChapterTopics(chapterId: string | number) {
        return ChapterRepo.axiosClient.get(`/chapters/${chapterId}/topics`);
    }

    static getAllSubjectTopics(subjectId: string) {
        return ChapterRepo.axiosClient.get(`/subjects/${subjectId}/topics`);
    }

    static addTopic(chapterId: string, payload: any) {
        return ChapterRepo.axiosClient.post(`/chapters/${chapterId}/topics`, payload);
    }

    static getTopicById(topicId: number) {
        return ChapterRepo.axiosClient.get(`/topics/${topicId}`)
    }

    static updateTopic(topicId: number) {
        return ChapterRepo.axiosClient.put(`/topics/${topicId}`)
    }

    static getAllSubjects() {
        return ChapterRepo.axiosClient.get(`/subjects`);
    }

    static deleteChapterTopic(chapterId: string, topicId: string) {
        return ChapterRepo.axiosClient.delete(`/chapters/${chapterId}/topics/${topicId}`)
    }

    static updateSubscriptionType(chapterId: number, subscriptionType: string) {
        return ChapterRepo.axiosClient.put(`/chapters/${chapterId}/subscription-type`, null, {
            params: {
                subscriptionType: subscriptionType
            }
        });
    }


}




