import React, {useEffect, useState} from "react";
import {ComprehensionData} from "../../../../../../dto/comprehensionFlaggedQuestions";
import CreateUpdateQuestions from "../../createQuestion/CreateUpdateQuestions";
import {useTeacherApiClient} from "../../../../apiClients/TeacherApiClient";
import {Question} from "../../../modals/CreateiUpdateQuestion";
import {ExpandedQuestionsAudit} from "./ExpandedQuestionsAudit";
import {AuditMode} from "../../../audit/Audit";
import ReadMore from "../../../../../admin/components/studentEvauationScreen/ReadMore";

interface QuestionListProps {
    flaggedQuestion?: ComprehensionData[];
    getAllComprehensions?: (page?: number, size?: number, query?: string) => void;
    getAllCompFlaggedQues?: (query?: string, student?: number[], teacher?: number[], subject?: number[], markedBy?: string, status?: string, page?: number, size?: number) => void;
    unResolvedQuestionIds?: number[]
    selectedOption?:AuditMode
}

export const QuestionListComprehension = (props: QuestionListProps) => {
    const [expandedQuestionData, setExpandedQuestionData] = useState<{ [key: number]: Question[] }>({});
    const [expandedQuestionIds, setExpandedQuestionIds] = useState<number[]>([])
    const [comprehensionIds, setComprehensionIds] = useState<number[]>([]);
    const [editQuestionModal, setEditQuestionModal] = useState<boolean>(false);
    const [questionId, setQuestionId] = useState<number | undefined>(undefined);
    const [flaggedQuestionId, setFlaggedQuestionId] = useState<number | undefined>(undefined);
    const [flaggedIds, setFlaggedIds] = useState<number[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [auditResponseData, setAuditResponseData] = useState<{ [key: number]: any }>({});
    const [unresolvedQuestionId, setUnresolvedQuestionId] = useState<number[]>([]);
    const [passageId, setPassageId] = useState<number | undefined>(undefined);
    const [allFlaggedQuestionIds, setAllFlaggedQuestionIds] = useState<number[]>([])
    const teacherApiClient = useTeacherApiClient();

    useEffect(() => {
        const comprehensionIds = Object.keys(expandedQuestionData).map(key => parseInt(key));
        const flaggedQuestionIds: number[] = [];
        comprehensionIds.forEach(comprehensionId => {
            const comprehensionQuestions = expandedQuestionData[comprehensionId];
            if (comprehensionQuestions) {
                comprehensionQuestions.forEach(question => {
                    if (question.flagged) {
                        flaggedQuestionIds.push(question.id);
                    }
                });
            }
        });
        setAllFlaggedQuestionIds(flaggedQuestionIds)
    }, [expandedQuestionData]);

    useEffect(() => {
        const selectedQuestionKey = Object.keys(expandedQuestionData).find((key: string) =>
            expandedQuestionData[key as any]?.some((question: Question) => question.id === questionId)
        ) as keyof typeof expandedQuestionData | undefined;
        setPassageId(selectedQuestionKey)
    }, [expandedQuestionData, questionId]);

    useEffect(() => {
        if (unresolvedQuestionId.length > 0) {
            unresolvedQuestionId.forEach(id => {
                getAllAuditComprehensionQuestionFlagId(id,props.selectedOption);
            });
        }
    }, [unresolvedQuestionId,props.selectedOption]);

    useEffect(() => {
        if (props.flaggedQuestion) {
            expandAllComprehensions();
        }
    }, []);

    const expandAllComprehensions = () => {
        const questionIds = props.flaggedQuestion?.map(comp => comp.id) || [];
        setComprehensionIds(questionIds);
        questionIds.forEach(questionId => {
            getAllAuditComprehensionQuestions(questionId);
        });
    };

    const toggleComprehensionExpand = (questionId: number) => {
        setComprehensionIds(prevIds => {
            if (prevIds.includes(questionId)) {
                return prevIds.filter(id => id !== questionId);
            } else {
                return [...prevIds, questionId];
            }
        });
    };

    const toggleQuestionExpand = (questionId: number) => {
        setExpandedQuestionIds(prevIds => {
            if (prevIds.includes(questionId)) {
                return prevIds.filter(id => id !== questionId);
            } else {
                return [...prevIds, questionId];
            }
        });
    };

    const handleOnSuccess = () => {
        setEditQuestionModal(false);
        setQuestionId(undefined);
        setFlaggedQuestionId(undefined);
        if (props.getAllCompFlaggedQues) {
            props.getAllCompFlaggedQues();
            setEditQuestionModal(false);
        }
    };
    const getAllAuditComprehensionQuestions = (questionId: number) => {
        setIsLoading(true);
        teacherApiClient.getAllAuditComprehensionQuestions(questionId)
            .then((res) => {
                const flaggedQuestionIds = res.data?.filter((res: any) => res.flagged).map((res: any) => res.id) || [];
                const uniqueIdsSet = new Set([...unresolvedQuestionId, ...flaggedQuestionIds]);
                const uniqueIdsArray = Array.from(uniqueIdsSet);
                setUnresolvedQuestionId(uniqueIdsArray);
                const comprehensionQuestions: { [key: number]: Question[] } = {};
                comprehensionQuestions[questionId] = res.data;
                setExpandedQuestionData(prevData => ({
                    ...prevData,
                    ...comprehensionQuestions
                }));

                setExpandedQuestionIds(prevState => {
                    const newIdsSet = new Set([...prevState, ...flaggedQuestionIds]);
                    return Array.from(newIdsSet);
                });
                setIsLoading(false);
            })
            .catch((err) => {
                console.log('err', err);
                setIsLoading(false);
            });
    };

    const getAllAuditComprehensionQuestionFlagId = (questionId: number,markedBy?:AuditMode) => {
        teacherApiClient.getAuditComprehensionQuestionFlagId(questionId,markedBy)
            .then((res) => {
                setAuditResponseData(prevData => ({
                    ...prevData,
                    [questionId]: {
                        ...prevData[questionId],
                        flagData: res.data
                    }
                }));
            })
            .catch((err) => {
                console.log(err)
            })
    };

    const handleFlagQuestionIds = (id: number[]) => {
        setFlaggedIds(id)
    }

    return (
        <div>
            <div className="mt-2">
                {props.flaggedQuestion && props.flaggedQuestion.length > 0 &&
                    props.flaggedQuestion?.map((comprehensions, questionIndex) => (
                        <div key={comprehensions.id}>
                            <div style={{backgroundColor: "#F9F9F9", cursor: "pointer"}} className={"card mb-3"}>
                                <div className="card-header">
                                    <div className="d-flex align-items-start"
                                         onClick={() => toggleComprehensionExpand(comprehensions.id)}>
                                        <div>{questionIndex + 1}.</div>
                                        <div className="mb-0">
                                            <ReadMore text={comprehensions?.text} imageUrl={comprehensions?.imageUrl} />
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    {comprehensionIds.includes(comprehensions.id) &&
                                        <div>
                                            {
                                                expandedQuestionData[comprehensions.id] &&
                                                <ExpandedQuestionsAudit toggleQuestionExpand={toggleQuestionExpand}
                                                                        setQuestionId={setQuestionId}
                                                                        setFlaggedQuestionId={setFlaggedQuestionId}
                                                                        setEditQuestionModal={setEditQuestionModal}
                                                                        comprehensionIds={comprehensionIds}
                                                                        expandedQuestionIds={expandedQuestionIds}
                                                                        expandedQuestionData={expandedQuestionData[comprehensions.id]}
                                                                        comprehensionId={comprehensions.id}
                                                                        isLoading={isLoading}
                                                                        auditResponseData={auditResponseData}
                                                                        setFlaggedQuestionIds={handleFlagQuestionIds}
                                                />
                                            }
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    ))}
            </div>
            <CreateUpdateQuestions questionId={questionId} show={editQuestionModal} passageId={passageId}
                                   flaggedQuestionId={flaggedQuestionId ?? undefined}
                                   hasIssue={comprehensionIds.includes(questionId ?? 0)}
                                   setFlaggedQuestionId={setFlaggedQuestionId}
                                   flaggedIds={flaggedIds}
                                   allFlaggedQuestionIds={allFlaggedQuestionIds}
                                   onHide={() => {
                                       setEditQuestionModal(false);
                                       setQuestionId(undefined);
                                       setFlaggedQuestionId(undefined);
                                   }}
                                   onSuccess={handleOnSuccess}
            />
        </div>
    );
};
