import React, {useEffect, useState} from "react";
import {Nav, Tab} from "react-bootstrap";
import {StudentBatchListing} from "./StudentBatchListing";
import {useNavigate, useParams} from "react-router-dom";
import {useTeacherApiClient} from "../../../apiClients/TeacherApiClient";
import {EmptyState} from "../../../../../components/emptyState/EmptyState";
import AssignmentShimmer from "../../../../../components/shimmer/assignment/AssignmentShimmer";
import {InitialDataState, NetworkState, PromiseData} from "../../../../../modals/PromiseData";

interface Batches {
    id: number;
    name: string;
    active: boolean;
}

export const AssignmentBatchListing = () => {
    const params = useParams();
    const teacherApiClient = useTeacherApiClient();
    const [batches, setBatches] = useState<PromiseData<any[]>>(InitialDataState)
    const [activeTab, setActiveTab] = useState<number | null>(null);
    const navigate = useNavigate();

    useEffect(() => {
        getAllBatches(Number(params.assignmentId) ?? 0);
    }, []);

    const getAllBatches = (assignmentId: number) => {
        setBatches({state: NetworkState.Loading})
        teacherApiClient.getAllBatchesForAssignment(assignmentId)
            .then((res) => {
                setBatches({state: NetworkState.Success, data: res.data});
                setActiveTab(res.data.length > 0 ? res.data[0].id : null);
            })
            .catch((err) => {
                console.log(err);
                setBatches({state: NetworkState.Failed, data: err.message})
            });
    };

    const handleTabClick = (tabId: number) => {
        setActiveTab(tabId);
    };

    const renderColumns = () => {
        const columns = [];
        for (let i = 0; i < 10; i++) {
            columns.push(
                <tr key={i}>
                    <td><AssignmentShimmer/></td>
                </tr>
            );
        }
        return columns;
    };

    return (
        <div>
            <div className="pt-4 m-auto container">
                <div className="row page-titles mb-3">
                    <div className="col-sm-6 p-md-0">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 list-unstyled ps-0">
                                <li className="breadcrumb-item">
                                    <a href="/teacher" className="text-decoration-none text-secondary">
                                        {" "} Dashboard{" "}
                                    </a>
                                </li>
                                <li className="breadcrumb-item text-black" aria-current="page"
                                    onClick={() => navigate('/teacher/assignments')}>
                                    Assignment List
                                </li>
                                <li className="breadcrumb-item text-black" aria-current="page">
                                    Batch
                                </li>
                            </ol>
                        </nav>
                    </div>
                    <div className="col-sm-6 d-flex justify-content-end">
                        <h6 onClick={() => navigate('/teacher/assignments')} style={{cursor: 'pointer'}}>
                            <i className="bi bi-arrow-bar-left me-2"></i>Back
                        </h6>
                    </div>
                </div>
            </div>
            <div>
                <div className={"card border-0 shadow-sm"}>
                    <div className={"card-head shadow-sm p-3 p-sm-20 p-md-25"}>
                        {batches.state === NetworkState.Loading ? (
                            renderColumns()
                        ) : batches.state === NetworkState.Success && batches.data && batches.data.length > 0 ? (
                            <>
                                <Nav variant="tabs">
                                    {batches.data.map((res) => (
                                        <Nav.Item key={res.id}>
                                            <Nav.Link
                                                eventKey={res.id.toString()}
                                                onClick={() => handleTabClick(res.id)}
                                                active={activeTab === res.id}
                                            >
                                                {res.name}
                                            </Nav.Link>
                                        </Nav.Item>
                                    ))}
                                </Nav>
                                <Tab.Content>
                                    {batches.data.map((res) => (
                                        <Tab.Pane
                                            key={res.id}
                                            eventKey={res.id.toString()}
                                            className={`fade ${activeTab === res.id ? "show active" : ""}`}
                                        >
                                            <StudentBatchListing
                                                currentTabId={activeTab ?? 0}
                                                assignmentId={Number(params.assignmentId)}
                                            />
                                        </Tab.Pane>
                                    ))}
                                </Tab.Content>
                            </>
                        ) : (
                            <EmptyState/>
                        )}

                    </div>
                </div>
            </div>
        </div>
    );
};
