import React, {useEffect, useState} from "react";
import {Modal, ModalBody, ModalHeader} from "react-bootstrap";
import {AllToasts} from "../../toast/AllToasts";
import Button from "../../button/Button";
import {useAdminApiClient} from "../../../modules/admin/apiClients/AdminApiClient";

export type EditNoticeProps = {
    show: boolean;
    onSuccess: () => void;
    onHide: () => void;
    onCancel: () => void;
    noticeId: number | undefined;
};

export type NoticePayload = {
    content?: string;
    publishedDate?: string;
};


const AddEditNotice = (props: EditNoticeProps) => {
    const adminApiClient = useAdminApiClient();
    const [loading, setLoading] = useState<boolean>(false);
    const [payload, setPayload] = useState<NoticePayload>({
        content: "",
        publishedDate: "",
    });

    useEffect(() => {
        setPayload({
            content: "",
            publishedDate: "",
        });
        if (props.noticeId) {
            getNoticeById(props.noticeId);
        }
    }, [props.noticeId]);



    const getNoticeById = (id: number) => {
        adminApiClient.getNoticeById(id).then((res) => {
            setPayload(res.data);
        });
    };

    const createNotice = (payload: any) => {
        setLoading(true);
        adminApiClient.addNotice(payload)
            .then((res) => {
                setLoading(false);
                props.onSuccess();
                props.onHide();
                AllToasts.success("Notice added successfully");
                setPayload({publishedDate: "", content: ""});
            })
            .catch((error) => {
                console.error("Error creating Notice:", error);
                setLoading(false);
                if (error.response?.data?.code === 4000) {
                }
            });
    };


    const updateNotice = (id: number, payload: any) => {
        setLoading(true);
        adminApiClient
            .updateNotice(id, payload)
            .then((res) => {
                setLoading(false);
                props.onSuccess();
                props.onHide();
                AllToasts.success("Notice details updated successfully");
                setPayload({publishedDate: "", content: ""});
            })
            .catch((err) => {
                console.log("Update error:", err);
                setLoading(false);
                if (err.response?.data?.code === 4000) {
                }
            });
    };

    const handleCancel = () => {
        props.onCancel();
    };

    const handleSubmit = () => {
        if (props.noticeId) {
            updateNotice(props.noticeId, payload);
        } else {
            createNotice(payload);
        }
    };


    return (
        <div>
            <Modal show={props.show} onHide={props.onCancel}>
                <ModalHeader closeButton className="border-bottom">
                    <div className={"flex-fill"}>
                        <h5>{props.noticeId ? "Update notice" : "Add notice"}</h5>
                    </div>
                </ModalHeader>
                <ModalBody>
                    <form >
                        <div className="m-2">
                            <label htmlFor="exampleInputContent">Content <span className='text-danger'>*</span></label>
                            <textarea
                                className="form-control"
                                id="exampleInputContent"
                                aria-describedby="content"
                                onChange={(e) =>
                                    setPayload((prevState) => ({
                                        ...prevState,
                                        content: e.target.value,
                                    }))
                                }
                                value={payload?.content}
                                placeholder="Enter content"
                                required
                            />
                        </div>
                        <div className="m-2">
                            <label htmlFor="exampleInputContent">Due date <span className='text-danger'>*</span></label>
                            <input
                                type="date"
                                className="form-control"
                                value={payload?.publishedDate}
                                onChange={(e) =>
                                    setPayload((prevState) => ({
                                        ...prevState,
                                        publishedDate: e.target.value,
                                    }))
                                }
                                required
                            />
                        </div>


                        <div className={"d-flex mt-5"}>
                            <div className={"flex-fill"}></div>
                            <div>
                                <div className="btn btn-cancel me-2" onClick={handleCancel}>
                                    Cancel
                                </div>
                                <div className={"btn btn-add text-white"} onClick={() => handleSubmit()}>{props.noticeId ? "Update" : "Add"}</div>
                            </div>
                        </div>
                    </form>
                </ModalBody>
            </Modal>
        </div>
    );
};

export default AddEditNotice;
