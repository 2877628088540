import React, { useEffect, useState } from 'react';
import "./StudentChapter.scss";
import { useParams } from "react-router-dom";
import { StudentChapterPaid } from "./StudentChapterPaid";
import StudentChapterFree from "./StudentChapterFree";
import { useStudentApiClient } from "../../apiClients/StudentApiClient";

function StudentChapter() {
    const params = useParams();
    const [course, setCourse] = useState<any[]>([]);
    const studentApiClient = useStudentApiClient();
    const [subscriptionType, setSubscriptionType] = useState<string>('');
    const [canHaveComp , setCanHaveComp] = useState<boolean>(false);


    useEffect(() => {
        const SelectedCourse = course.filter((res) => res.id.toString() === params.courseBatchId?.toString());
        if (SelectedCourse.length > 0) {
            setSubscriptionType(SelectedCourse?.map((res)=> res.typeOfCourse)[0]);
        }
    }, [course, params.courseBatchId]);

    useEffect(() => {
        getStudentCourses();
    }, []);

    const getStudentCourses = () => {
        studentApiClient.getAllStudentCourseBatches().then((res) => {
            setCourse(res.data);
            // setCanHaveComp(res.data.)
        }).catch((err) => {
            console.log(err);
        });
    }

    return (
        <div className={'chapters_wrap'}>
            <div>
                {subscriptionType === 'PAID' &&
                    <StudentChapterPaid />}
            </div>
            <div>
                {subscriptionType === 'FREE_TRIAL' &&
                    <StudentChapterFree />}
            </div>
        </div>
    );
}

export default StudentChapter;
