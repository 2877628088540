import React, {useEffect, useState} from "react";
import {useTeacherApiClient} from "../../../../apiClients/TeacherApiClient";
import {AllToasts} from "../../../../../../components/toast/AllToasts";
import {AuditMode} from "../../../audit/Audit";

export interface MarkQuestionResolved {
    reasonForResolution: string;
}

interface ResolveQuestionProps {
    flaggedQuestionId: number | null;
    flaggedQuestionIds?:number[]
    onHide?:()=>void
    handleOnSuccess?: (query?: string | undefined, student?: number[] | undefined, teacher?: number[] | undefined, subject?: number[] | undefined, markedBy?: AuditMode, status?: string | undefined, page?: number | undefined, size?: number | undefined) => void
    selectedOption?:string
    currentPage?:number
    pageSize?:number
    filteredSubjectIds?:number[]
}

export const ResolveQuestion = (props: ResolveQuestionProps) => {
    const [resolveQuestion, setResolveQuestion] = useState<MarkQuestionResolved>({
        reasonForResolution: ""
    });
    const [resolved, setResolved] = useState(false); // Track resolution status
    const [validationError, setValidationError] = useState<string | null>(null);
    const teacherApiClient = useTeacherApiClient();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.currentTarget;
        setResolveQuestion((prevState) => ({...prevState, [name]: value}));
        if (value.trim() === "") {
            setValidationError("Resolution is required");
        } else {
            setValidationError(null);
        }
    };

    const markQuestionResolved = (id:any , reason:any) => {
        if (resolveQuestion.reasonForResolution.trim() === "") {
            setValidationError("Resolution is required");
            return;
        }

        teacherApiClient
            .MarkQuestionResolved(id, reason)
            .then((res) => {
                setResolved(true);
                setResolveQuestion({reasonForResolution: ""});
                setValidationError(null);
                if(props.onHide)
                props.onHide();
                props.handleOnSuccess &&
                props.handleOnSuccess('', [], [], props.filteredSubjectIds, props.selectedOption as any,
                    'UnResolved', props.currentPage, props.pageSize)
                if(props.flaggedQuestionIds && props.flaggedQuestionIds?.length > 0){
                    return
                }else {
                    AllToasts.success("Issue Resolved");
                }
            })
            .catch((err) => {
                console.log(err);
                AllToasts.failure("Error");
            });
    };

    const handleQuestionResolve = () => {
        if(props.flaggedQuestionId !== undefined && props.flaggedQuestionId !==null && props.flaggedQuestionId !== 0) {
            markQuestionResolved(props.flaggedQuestionId ,resolveQuestion)
        } else if(props.flaggedQuestionIds && props.flaggedQuestionIds?.length > 0) {
            props.flaggedQuestionIds?.forEach(id => {
                markQuestionResolved(id,resolveQuestion)
                console.log('id',id)
            })
        }
    }

    return (
        <>
            <div>
                <div className="mb-2 me-4  ">
                    <label className="form-label" htmlFor="difficultyLevel">
                        Resolution<span className='ms-1 text-danger'>*</span>
                    </label>
                    <div className='d-flex'>
                        <div className='d-flex flex-column'>
                            <input
                                type='text'
                                className={`form-control me-2 ${validationError ? 'is-invalid' : ''}`}
                                value={resolveQuestion.reasonForResolution}
                                onChange={handleChange}
                                name='reasonForResolution'
                            />
                            {validationError && (
                                <div className="invalid-feedback">{validationError}</div>
                            )}

                        </div>
                        <div className='ms-3'>
                            {resolved ? (
                                <button type='button' className='btnAdd' disabled>
                                    Unflagged
                                </button>
                            ) : (
                                <button type='button' className='btnAdd' onClick={handleQuestionResolve}>
                                    Unflag
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
