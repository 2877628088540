import {Accordion, Card} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {useTeacherApiClient} from "../../apiClients/TeacherApiClient";

interface QuestionDetails {
    totalFlaggedQuestions: number;
    totalVerifiedQuestions: number;
    totalCreatedQuestions: number;
}

export const TeacherDashboard = () => {
    const teacherApiClient = useTeacherApiClient();
    const [questionDetails, setQuestionDetails] = useState<QuestionDetails>();

    const getQuestionDetails = () => {
        teacherApiClient.getAllQuestionDetails().then((res) => {
            setQuestionDetails(res.data);
        }).then(() => {
        })
    }

    useEffect(() => {
        getQuestionDetails();
    }, []);

    return<div className='container page-content'>
        <div className="row page-titles">
                <div className={'col-md-6'}>
                    <h4>Teacher Dashboard</h4>
                </div>
           <div className={'card mt-4'}>
               <div className={'card-body'}>
                   <table className={`table studentTable`}>
                       <thead className={'thead'} style={{color: 'black'}}>
                       <tr>
                           <th>Total Created Questions</th>
                           <th>Total Flagged Questions</th>
                           <th>Total Verified Questions</th>
                       </tr>
                       </thead>
                       <tbody>
                       <tr>
                           <td>{questionDetails?.totalCreatedQuestions}</td>
                           <td>{questionDetails?.totalFlaggedQuestions}</td>
                           <td>{questionDetails?.totalVerifiedQuestions}</td>
                       </tr>
                       </tbody>
                   </table>
               </div>
           </div>
        </div>
    </div>;
}