import React, {ChangeEvent, useEffect, useState} from "react";
import {FormControl, Modal, ModalBody} from "react-bootstrap";
import {useAdminApiClient} from "../../../modules/admin/apiClients/AdminApiClient";
import Select from "react-dropdown-select";
import LoaderButton from "../../button/Button";
import {AllToasts} from "../../toast/AllToasts";
import '../../../App.scss'


type BatchProps= {
    show : boolean
    onSuccess: () => void
    onHide: () => void;
    onCancel: () => void;

}

export interface BatchCourse {
    id: number;
    name: string;
    active?: boolean
}

interface optionsCourse {
    value:number,
    label:string
}

export const CreateBatches = (props: BatchProps) => {
    const [currentPage, setCurrentPage] = useState<any>()
    const [loading, setLoading] = useState<boolean>(false);
    const [coursePayload, setCoursePayload] = useState<BatchCourse[]>([])
    const [selectedCourses, setSelectedCourses] = useState<optionsCourse[]>([]);
    const [payload, setPayload] = useState<BatchCourse>({
      id : 0,
        name: "",
        active: true,
    });
    const batchesApiClient = useAdminApiClient();
    useEffect(() => {
        getCourses(currentPage);
    }, []);

    const createBatch = (courseId: number, payload: BatchCourse) => {
        batchesApiClient.createBatch(courseId, payload)
            .then((res) => {
                console.log('Success #29')
                console.log("data", res.data);
                props.onSuccess();
                props.onHide();
                AllToasts.success('Batch Created Successfully.');
            })
            .catch((error) => {
                AllToasts.failure('Fail to Add.');
            });
    }

    const getCourses = (page: number, size?: number, query?: string) => {
        batchesApiClient.getAllCourses(page, size, query)
            .then((res) => {
                setCoursePayload(res.data);
            })
            .catch(() => {
                console.log("error");
            });
    };
    const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
        setPayload((prevState: any) => ({...prevState, active: event.target.checked}));
    }
    const handleCourseChange = (option: optionsCourse[]) => {
        setSelectedCourses(option);
        console.log(option);
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();
        if (selectedCourses.length > 0) {
            const selectedCourseValue = selectedCourses[0].value;
            createBatch(selectedCourseValue, payload);
            setPayload({
                id: 0,
                name: "",
                active: true,
            });
            setSelectedCourses([]);
        } else {
        }
    };

    return (
        <div>
            <Modal show={props.show} onHide={() => props.onHide()}>
                <Modal.Header closeButton className="border-bottom">
                    <h5>Create Batch</h5>
                </Modal.Header>
                <ModalBody className="modal_body mx-2">
                    <form onSubmit={(e) => handleSubmit(e)}>
                        <FormControl
                            type="text"
                            className="my-2 form-control rounded px-3"
                            placeholder="Enter Batch Name" name={'name'}
                            onChange={(e) => setPayload((prevState) => ({...prevState, name: e.target.value}))}
                            value={payload.name}
                            required
                        />

                        <Select
                            options={coursePayload.map((course) => ({ value: course.id, label: course.name }))}
                            name="course"
                            multi={false}
                            className={'form-control my-2 rounded px-3'}
                            placeholder="Select Courses"
                            required
                            values={selectedCourses}
                            onChange={(option)=>handleCourseChange(option)}
                        />
                        <div className={'d-flex align-items-center'}>

                            <input
                                className="form-check-input me-2"
                                type="checkbox"
                                checked={payload.active}
                                name={`active`}
                                id="flexCheckDefault"
                                onChange={handleCheckboxChange}
                            />
                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                Active
                            </label>

                        </div>
                        <div className="d-flex justify-content-end mt-3">
                            <button type={`button`} className="btn btn-cancel me-2" onClick={props.onCancel}>Cancel
                            </button>
                            <LoaderButton
                                label={'Add'}
                                loading={loading}
                                cssName={'btn btn-add'}/>
                        </div>
                    </form>

                </ModalBody>
            </Modal>
        </div>
    )
}

export default CreateBatches;
