import React, {memo, useEffect, useState} from "react";
import QuestionError from "../../questionError/QuestionErrorModal";
import CreateUpdateQuestions from "../../createQuestion/CreateUpdateQuestions";
import {TeacherCreateQuestions} from "../../../../modal/CreateQuestions";
import {useLocation, useParams} from "react-router-dom";
import {EmptyState} from "../../../../../../components/emptyState/EmptyState";
import CustomSwitcher from "../../../../../../components/common/course/courseView/CustomSwitcher";
import {useTeacherApiClient} from "../../../../apiClients/TeacherApiClient";
import {MathX} from "../../../../../../components/math/MathX";
import {useStudentApiClient} from "../../../../../student/apiClients/StudentApiClient";
import {VerificationData} from "../../../verification/Verification";
import {AuditMode} from "../../../audit/Audit";
import './QuestionList.scss';
import ReadMore from "../../../../../admin/components/studentEvauationScreen/ReadMore";

interface QuestionListProps {
    questions: TeacherCreateQuestions[];
    selectedQuestions?: any[];
    handleQuestionCheckboxChange?: (selectedId: number, questionText: string[]) => void;
    handleQuestionClick: (questionId: number) => void;
    checkedSelect?: (selectQuestionId: number) => boolean;
    getAllQuestions?: (query?: string | undefined, student?: number[] | undefined, teacher?: number[] | undefined, subject?: number[] | undefined, markedBy?: AuditMode, status?: string | undefined, page?: number | undefined, size?: number | undefined) => void
    getAllQuestionsCreateAssignment?: (page?: number, size?: number, sort?: string[], query?: string, includeActive?: boolean, testId?: number[], courseId?: number, flagged?: true, programId?: number, chapterId?: number[], topicId?: number[], subject?: number[], verified?: string, assignmentId?: number[]) => void,
    expandedQuestion: { id: number | null; expanded: boolean };
    getVerifiedAndUnverifiedQuestions?: (page?: number, size?: number, sort?: string[], query?: string, subjectId?: number, topicIds?: number[], verified?: VerificationData) => void
    currentPage: number;
    pageSize: number;
    selectedOption?: string;
    subjectId?: number;
    topicId?: number[];
    filteredSubjectIds?: number[];
    onVerificationStatusChange?: (status: any) => void
}

const QuestionList = memo((props: QuestionListProps) => {
    const [questionErrorModal, setQuestionErrorModal] = useState(false);
    const [editQuestionModal, setEditQuestionModal] = useState(false);
    const [questionId, setQuestionId] = useState<number | undefined>(0);
    const [flaggedQuestionId, setflaggedQuestionId] = useState<number | undefined>(0);
    const [likedQuestions, setLikedQuestions] = useState<number[]>([]);
    const allQuestionIds = props.questions?.map((res) => res.id);
    const [expandedQuestions, setExpandedQuestions] = useState<number[]>(allQuestionIds ?? []);
    const [deletedQuestionIds, setDeletedQuestionIds] = useState<number[]>([]);
    const teacherApiClient = useTeacherApiClient();
    const studentApiClient = useStudentApiClient();
    const params = useParams()
    const location = useLocation();

    useEffect(() => {
        const initialLikedQuestions = props.questions.map((res) => res.id);
        setLikedQuestions(initialLikedQuestions);
    }, [props.questions]);

    // Update the expandedQuestions when the questions prop changes
    useEffect(() => {
        const allQuestionIds = props.questions?.map((res) => res.id);
        setExpandedQuestions(allQuestionIds ?? []);
    }, [props.questions]);

    // Toggle question expansion
    const toggleQuestionExpand = (questionId: number) => {
        if (expandedQuestions.includes(questionId)) {
            setExpandedQuestions(expandedQuestions.filter((id) => id !== questionId));
        } else {
            setExpandedQuestions([...expandedQuestions, questionId]);
        }
    };

    // Mark a question as resolved
    const markQuestionResolved = (id: number) => {
        teacherApiClient.verifyQuestion(id).then(() => {
            //for reload the page when the question is verified
            // props.getVerifiedAndUnverifiedQuestions && props.getVerifiedAndUnverifiedQuestions(props.currentPage, props.pageSize, [], '', props.subjectId, props.topicId, props.selectedOption as any)
        }).catch((err) => {
            console.log(err)
        })
    }

    const markQuestionUnResolved = (id: number) => {
        teacherApiClient.unVerifyQuestion(id).then(() => {
        }).catch((err) => {
            console.log(err)
        })
    }

    const deleteQuestion = (questionId: number) => {
        if (deletedQuestionIds.includes(questionId)) {
            teacherApiClient.undoQuestion(questionId)
                .then(() => {
                    setDeletedQuestionIds(deletedQuestionIds.filter(id => id !== questionId));
                })
                .catch(err => {
                    console.log("Error undeleting question:", err);
                });
        } else {
            teacherApiClient.questionDelete(questionId)
                .then(() => {
                    setDeletedQuestionIds([...deletedQuestionIds, questionId]);
                    //for reload the page when the question is verified
                    // props.getVerifiedAndUnverifiedQuestions && props.getVerifiedAndUnverifiedQuestions(props.currentPage, props.pageSize, [], '', props.subjectId, props.topicId, props.selectedOption as any)

                })
                .catch(err => {
                    console.log("Error deleting question:", err);
                });
        }
    };


    const handleLikeToggle = (questionId: any) => {
        if (likedQuestions.includes(questionId)) {
            // Question is liked, so unlike it
            unlikeQuestion(questionId.questionId);
            setLikedQuestions((prevLiked) => prevLiked.filter((id) => id !== questionId));
        } else {
            // Question is not liked, so like it
            likeQuestion(questionId.questionId);
            setLikedQuestions((prevLiked) => [...prevLiked, questionId]);
        }
    };

    const likeQuestion = (questionId: number) => {
        studentApiClient.likeQuestion(questionId).then((res) => {
        }).catch((err) => console.log(err));
    };

    const unlikeQuestion = (questionId: number) => {
        studentApiClient.unLikeQuestion(questionId).then((res) => {
        }).catch((err) => console.log(err));
    };
    const handleOnSuccess = () => {
        setQuestionId(undefined);
        setEditQuestionModal(false);
        if (props.getAllQuestions) {
            props?.getAllQuestions('', [], [], [], props.selectedOption as any, 'UnResolved', props.currentPage, props.pageSize)
        } else if (props.getAllQuestionsCreateAssignment) {
            props?.getAllQuestionsCreateAssignment(props.currentPage, props.pageSize)
        } else if (props.getVerifiedAndUnverifiedQuestions) {
            props.getVerifiedAndUnverifiedQuestions(props.currentPage, props.pageSize, [], '', props.subjectId, props.topicId, props.selectedOption as any)
        }
    }
    const formatText = (text:string) => {
        if (!text) return "No question available";
        return text.split('\n').map((item, index) => (
            <span key={index}>
            {item}
                <br />
        </span>
        ));
    };
    return (
        <div>
            {props.questions && props.questions.length > 0 ? (
                props.questions?.map((res, index) => (
                    <div key={res.id} style={{backgroundColor: "#F9F9F9", cursor: 'pointer'}} className={'card mb-3'}>
                        <div className={`card-header d-flex  ${
                            props.expandedQuestion.id === res.id && "expanded-question"}`}>
                            {location.pathname === '/teacher/audit' || location.pathname === '/teacher/Verification' ||
                            (params && params['*']) === (`program/${params.programId}/likedQuestions`)
                                ? ("") :
                                <div className="me-4">
                                    <input
                                        type="checkbox"
                                        name="questionsId"
                                        style={{backgroundColor: "#CDCDCD"}}
                                        checked={props.checkedSelect && props.checkedSelect(res.id)}
                                        onChange={() => {
                                            if (props.handleQuestionCheckboxChange) props.handleQuestionCheckboxChange(res.id, [res.text]);
                                            if (props.checkedSelect) props.checkedSelect(res.id);
                                        }}
                                        className="form-check-inline"
                                    />
                                </div>}
                            <div className={"me-2"}>{props.currentPage * 10 + index + 1}.</div>
                            {location.pathname === '/teacher/audit' || location.pathname === '/teacher/Verification' ? (
                                <div
                                    className="flex-grow-1"
                                    onClick={() => {
                                        props.handleQuestionClick(res?.question?.id || res.id);
                                        toggleQuestionExpand(res.id);
                                    }}
                                >
                                    {location.pathname === '/teacher/audit' ? res.question?.text : ""}
                                    {<MathX><ReadMore text={res?.text} imageUrl={res?.imageUrl} />
                                    </MathX> || "No question available"}
                                </div>
                            ) : (
                                <div className="flex-grow-1" onClick={() => props.handleQuestionClick(res.id)}>
                                    {<MathX>{formatText(res.text)}</MathX> || "No question available"}
                                </div>
                            )}

                            {params['*'] === `program/${params.programId}/likedQuestions` ?
                                <div>
                                    <div className={'fav_icon'}>
                                        <i
                                            className={`bi ${likedQuestions.includes(res.id) ? "bi-heart-fill text-danger likeIcon" : "bi-heart"} fs-4 ms-2`}
                                            onClick={() => handleLikeToggle(res.id)}
                                        ></i>

                                    </div>
                                </div>
                                : ""}

                            {location.pathname === '/teacher/Verification' ? (
                                <div>
                                    <div className='ms-2 me-2'>
                                        {props.selectedOption === 'UnVerified' ?
                                            <CustomSwitcher
                                                inactiveLabel={'Verify'}
                                                active={false}
                                                activeLabel={'Verified'}
                                                onChange={() => {
                                                    markQuestionResolved(res.id);
                                                }}
                                            /> :
                                            (props.selectedOption === 'Verified' &&
                                                <CustomSwitcher
                                                    inactiveLabel={'UnVerified'}
                                                    active={true}
                                                    activeLabel={'Verified'}
                                                    onChange={() => {
                                                        markQuestionUnResolved(res.id)
                                                    }}
                                                />)}
                                    </div>
                                    {props.selectedOption === 'UnVerified' &&
                                        <div>
                                            <div>
                                                <div className='ms-2 me-2'>
                                                    <div className='mt-2'>
                                                        <CustomSwitcher
                                                            inactiveLabel={'Delete'}
                                                            active={false}
                                                            activeLabel={'Deleted'}
                                                            onChange={() => {
                                                                setDeletedQuestionIds(prevState => [...prevState, res.id]);
                                                                deleteQuestion(res.id);
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            ) : ''}

                            <div className="d-flex">
                                <div>
                                    {location.pathname === '/teacher/audit' || location.pathname === '/teacher/Verification' || params['*'] === `program/${params.programId}/likedQuestions` ? "" : (
                                        <i
                                            className="bi bi-exclamation-triangle fs-4 me-2"
                                            onClick={() => {
                                                setQuestionErrorModal(true);
                                                setQuestionId(res?.id);
                                            }}
                                        ></i>
                                    )}
                                </div>
                                {params['*'] === `program/${params.programId}/likedQuestions` ? "" :
                                    <span className="text-primary"
                                          onClick={() => {
                                              setQuestionId(location.pathname === '/teacher/audit' ? res.question?.id : res.id);
                                              setflaggedQuestionId(res.id)
                                              setEditQuestionModal(true);
                                          }}>
                                        <i className="bi bi-pencil-square fs-5" style={{color: "#C4C4C4"}}></i>
                                    </span>
                                }
                            </div>
                        </div>

                        <div>
                            <div key={res.id}>
                                {expandedQuestions.includes(res.id) && (res.question?.options) ? (
                                    <div>
                                        <span className='text-danger ms-3'>Issue:</span>
                                        <span
                                            className='ms-2'>{props.selectedOption === AuditMode.Student ? res.flagReasonDto?.name : res.issue}</span>
                                    </div>
                                ) : ""}
                            </div>

                            {location.pathname === '/teacher/audit' || location.pathname === '/teacher/Verification' ? (
                                expandedQuestions.includes(res.id || res.question?.id) && (res.question?.options || res.options) ? (
                                    <div className="card-body">
                                        <div className={'row'}>
                                            {(res.question?.options || res.options)?.map((option: any, index: number) => (
                                                <div className="col-lg-6" key={option.id}>
                                                    <div className="d-flex align-items-center w-100">
                                                        <div key={option.id} className="d-flex align-items-center">
                                                            <div className={'d-flex'}>
                                                                <span className="me-2"> Option {index + 1}:</span>
                                                                <span
                                                                    className="me-2"><MathX>{option.text}</MathX></span>
                                                            </div>
                                                            <div className={''}>
                                                                {option.correct ? (
                                                                    <div className={'correct_icon'}>
                                                                        <i className="bi bi-check2"></i>
                                                                    </div>
                                                                ) : (
                                                                    ""
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        {(res.question && res.question?.oneWordAnswer?.length) || (res.oneWordAnswer && res.oneWordAnswer.length > 0) ?
                                            <div>Option: {res.question?.oneWordAnswer ?? res.oneWordAnswer}</div> : ""}
                                    </div>
                                ) : ""
                            ) : props.expandedQuestion.id === res.id ? (
                                <div className="card-body">
                                    <div className={'row'}>
                                        {res.options?.map((option: any, index: number) => (
                                            <div className="col-lg-6" key={option.id}>
                                                <div className="d-flex align-items-center w-100">
                                                    <div key={option.id} className="d-flex align-items-center">
                                                        <div className={'d-flex'}>
                                                            <span className="me-2"> Option {index + 1}:</span>
                                                            <span
                                                                className="me-2"><MathX>{option.text}</MathX></span>
                                                        </div>
                                                        <div className={''}>
                                                            {option.correct ? (
                                                                <div className={'correct_icon'}>
                                                                    <i className="bi bi-check2"></i>
                                                                </div>
                                                            ) : (
                                                                ""
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    {res.oneWordAnswer?.length > 0 ?
                                        <div>Option: {res.oneWordAnswer ?? ''}</div> : ""}
                                </div>
                            ) : ""}

                        </div>
                    </div>
                ))
            ) : (
                <EmptyState/>
            )}
            <QuestionError
                questionId={questionId}
                show={questionErrorModal}
                onHide={() => {
                    setQuestionErrorModal(false);
                    setQuestionId(undefined);
                }}
                onCancel={() => {
                    setQuestionErrorModal(false);
                }}
                onSuccess={() => props.getAllQuestionsCreateAssignment && props?.getAllQuestionsCreateAssignment(props.currentPage, props.pageSize)}
            />

            <CreateUpdateQuestions
                questionId={questionId}
                show={editQuestionModal}
                currentPage={props.currentPage}
                pageSize={props.pageSize}
                selectedOption={props.selectedOption}
                filteredSubjectIds={props.filteredSubjectIds}
                handleOnSuccess={() =>{
                    setQuestionId(undefined)
                    props.getAllQuestions && props?.getAllQuestions('', [], [], props.filteredSubjectIds, props.selectedOption as any, 'UnResolved', props.currentPage, props.pageSize)}
                }
                flaggedQuestionId={flaggedQuestionId ?? undefined}
                onHide={() => {
                    setEditQuestionModal(false);
                    setQuestionId(undefined);
                }}
                onSuccess={handleOnSuccess}
            />

        </div>
    );
});

export default QuestionList;
