import {Modal, ModalBody} from "react-bootstrap";
import React, {FormEvent, useEffect, useState} from "react";
import {ChapterRepo} from "../../../../../repos/ChapterRepo";
import {AllToasts} from "../../../../toast/AllToasts";
import SelectDropdown , {Option} from "../../../../selectDropdown/SelectDropdown";
import Button from "../../../../button/Button";



export type ChapterPayload = {
    name?: string,
    topicId?: number[]

}
const AddChapter=(props:{show:boolean,onCancel:(shouldRefresh?:boolean)=>void,id:string,defaultValue?:any,title?:string,onSucces:()=>void})=>{
    const[payload,setPayload]=useState<ChapterPayload>({
        name: "",
        topicId: []
    })
    const[topics,setTopics]=useState<any[]>([]);
    const[selectedTopic, setSelectedTopic] = useState<number[]>([])
    const[loading,setLoading]=useState<boolean>(false);
    //
    // useEffect(()=>{
    //     getAllTopics();
    // },[])

    useEffect(()=> {
        console.log("#21",payload);
        if(props.defaultValue) {
            getSingleChapter(props.defaultValue.id);
        }
        else
            setPayload({ name: "",
                topicId: []});
    },[props.defaultValue])
    const handleInput=(e:any)=> {
        let name=e.target.name;
        let value=e.target.value;
        setPayload({...payload, [name]:value});
    }
    const handleForm=(e:FormEvent,programId:string,payload:any)=>{
        e.preventDefault();
        if(props.defaultValue){
            updateChapter();
            setPayload({});
        }
        else{
            setLoading(true);
            setPayload({});
            ChapterRepo.addChapter(programId,payload).then(()=>{
                setLoading(false);
                AllToasts.success('Chapter Added Successfully')
                setPayload({});
                setTopics([]);
                props.onSucces();
            }).catch((e)=>{
                AllToasts.failure('Fail to add');
                console.log("error",e)
            })
        }

    }

    const updateChapter =()=>{
        setLoading(true);
        ChapterRepo.updateChapter(props.id,props.defaultValue.id,payload).then((res)=>{
            setLoading(false);
            setPayload({});
            props.onSucces();
            AllToasts.success('Chapter Updated Successfully')
        }).catch(()=> {
            setLoading(false);
            AllToasts.failure('Unable to update');
        })
    }
    // const getAllTopics=()=>{
    //     ChapterRepo.getAllTopics().then((res)=>{
    //         setTopics(res.data);
    //     })
    //
    // }

    const getSingleChapter = (id:string)=> {
        ChapterRepo.getSingleChapter(id).then((res)=> {
            setPayload({...res.data, topicId: res.data.topics.map((topic:any)=> topic.id)});
            if (res.data.topics)
                setSelectedTopic(res.data.topics.map((topic: any)=>({value: topic.id, label: topic.name})));
        })
    }

    // const onTopicChange=(options: Option[])=>{
    //     setPayload((prevState: any)=> ({...prevState, topicId: options.map(option=> option.value)}))
    //     if(options){
    //         setSelectedTopic(options.map(option=> option.value))
    //     }else setSelectedTopic([])
    // }

    const onClose = () => {
        props.onCancel();
        setPayload({  name: "", topicId: []});
    }



    return<div>
        <Modal show={props.show} onHide={onClose}>
            <Modal.Header closeButton className="border-0 pb-0">
                <h5>{props.title ?? ''}</h5>
            </Modal.Header>
            <ModalBody className="modal_body mx-2">
                <form onSubmit={(e)=>handleForm(e,props.id,payload)} id={`create-program-form`}>
                    <div className="form-group">
                        <label htmlFor="exampleInputName">Chapter Name  <span className='text-danger'>*</span></label>
                        <input type="text" className="form-control" id="exampleInputName" value={payload.name}
                               aria-describedby="nameHelp"  onChange={handleInput} placeholder="Enter name" name="name" required/>
                    </div>
                    {/*{props.defaultValue ? ' ' :*/}

                    {/*<div className="form-group mt-2">*/}
                    {/*    <label htmlFor="exampleInputSubjects">Select Topics</label>*/}
                    {/*    <SelectDropdown values={selectedTopic}  options={topics?.map((topic:any)=> ({label: topic?.name, value: topic?.id}))}*/}
                    {/*                    onSelect={onTopicChange} label={'select topic'} placeholders={'select'}/>*/}
                    {/*    /!*<SelectDropdown values={selectedTopic ? {label: selectedTopic.label ?? '', value: selectedTopic.value} : null}  options={topics.map((topic:any)=> ({label: topic.name, value: topic.id}))} onSelect={()=>onTopicChange} label={'select topic'} placeholders={'select'}/>*!/*/}
                    {/*</div>*/}
                    {/*}*/}

                    <div className={'d-flex mt-3'}>
                        <div className={'flex-fill'}></div>
                        <div>
                            <button type={`button`}  className="btn btn-secondary me-2" onClick={onClose}>Cancel</button>
                            <Button label={props.defaultValue ? 'Update' : 'Add Chapter'} loading={loading} cssName={'btn btn-sm btn-create'}/>
                        </div>
                    </div>
                </form>
            </ModalBody>
        </Modal>
    </div>
}
export default AddChapter;