import React, {useEffect, useState} from "react";
import {useStudentApiClient} from "../../apiClients/StudentApiClient";
import {useNavigate, useParams} from "react-router-dom";
import './StudentAssignment.scss';
import {TestPayload} from "../test/PreviousTest";
import AssignmentCard from "./AssignmentCard";
import {StudentAssignmentDropDown} from "../../../../components/modal/chart/StudentAssignmentDropDown";
import {ExamDetails} from "../../../../modals/StudentAssignment";
import PerformanceChart from "../../../../components/chart/PerformanceChart";
import {LeaderBoard} from "./LeaderBoard";
import {CourseStatistics} from "./CourseStatistics";
import {
    assignmentStatistic,
    LeaderBoardData, SingleCourseBatch,
    StudentAttemptedQuestion,
    StudentQuestions
} from "../../../../dto/StudentDashBoard";
import moment from "moment/moment";

export const StudentAssignment = () => {
    const studentApiClient = useStudentApiClient();
    const params = useParams();
    const navigate = useNavigate();
    const [assignmentPayload, setAssignmentPayload] = useState<any[]>([]);
    const [test, setTestPayload] = useState<TestPayload[]>([]);
    const [practice, setPracticePayload] = useState<TestPayload[]>([]);
    const [show, setShow] = useState<boolean>(false)
    const [selectedSubject, setSelectedSubject] = useState<string>('');
    const [timeSpendLearning, setTimeSpendLearning] = useState('');
    const [topicLearned, setTopicLearned] = useState(0);
    const [totalQuestions, setTotalQuestions] = useState<StudentAttemptedQuestion>({
        totalQuestionAttempted: 0,
        totalCorrectlyAttemptedQuestions: 0
    })
    const [studentQuestions, setStudentQuestions] = useState<StudentQuestions>({
        totalQuestions: 0,
        totalQuestionAttempted: 0
    })
    const [studentRank, setStudentRank] = useState<{ rank: string }>({rank: "0/0"});
    const [performanceChartData, setPerformanceChartData] = useState<any[]>([])
    const [programId, setProgramId] = useState<number[]>([]);
    const [loading, setLoading] = useState(true);
    const [selectedProgramId, setSelectedProgramId] = useState<number>(0)
    const [studentTestDetails, setStudentTestDetails] = useState<ExamDetails[]>();
    const [assignmentStatisticsData, setAssignmentStatisticsData] = useState<assignmentStatistic>({
        totalAssignments: 0,
        totalAssignmentsCompleted: 0
    })
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(12);
    const [comments, setComment] = useState<string>('');
    const [notice, setNotice] = useState<any[]>([]);
    const [leaderBoardData, setLeaderBoardData] = useState<LeaderBoardData[]>([])
    const [courseBatches, setCourseBatches] = useState<SingleCourseBatch>({id: 0, name: '', course: {name: ''}});
    const [currentIndex, setCurrentIndex] = useState(0);
    const chartsPerPage = 4;
    const totalPages = Math.ceil(performanceChartData.length / chartsPerPage);

    useEffect(() => {
        getAllAssignmentByCourseId(Number(params.courseBatchId), "Pending");
        getAllStudentQuestion(Number(params.courseBatchId));
        getTimeSpendLearning(Number(params.courseBatchId));
        getTopicLearned(Number(params.courseBatchId));
        getTotalAssignmentsCompletedByStudent(Number(params.courseBatchId));
        getCommentForStudent(Number(params.courseBatchId));
        getAllQuestions(Number(params.courseBatchId));
        getLeaderBoardDataForStudent(Number(params.courseBatchId));
        getStudentCourseBatches(Number(params.courseBatchId));
        getAllProgramsAccToBatch(Number(params.courseBatchId));
        getStudentRankForAllCourses(Number(params.courseBatchId));
        getAllNoticeForStudent();
    }, [params.courseBatchId]);

    useEffect(() => {
        setSelectedProgramId(programId[0]);
        setSelectedSubject(performanceChartData?.map((res: any) => res.name)[0]);
    }, [programId, performanceChartData]);

    useEffect(() => {
        if (programId.length > 0) {
            const fetchTestDetailsForPrograms = async () => {
                const allTestDetails = [];
                const maxPrograms = programId.length;
                for (let i = 0; i < programId.length && i < maxPrograms; i++) {
                    try {
                        const testDetails = await fetchStudentTestDetails(Number(params.courseBatchId), programId[i]);
                        allTestDetails.push(testDetails);
                    } catch (error) {
                        console.log('Error fetching test details for programId:', programId[i], error);
                    }
                }
                setStudentTestDetails(allTestDetails);
            };
            fetchTestDetailsForPrograms();
        }
    }, [programId]);

    useEffect(() => {
        if (params && params.courseBatchId)
            getPreviousTests(Number(params.courseBatchId), 'TEST', pageSize, currentPage);
    }, [currentPage, pageSize]);

    useEffect(() => {
        if (params && params.courseBatchId)
            getPreviousPracticeTests(Number(params.courseBatchId), 'PRACTICE', pageSize, currentPage);
    }, [currentPage, pageSize]);

    useEffect(() => {
        if (studentTestDetails) {
            const data = studentTestDetails?.map((testDetail, index: number) => {
                const percentage = ((testDetail.obtainedMarks / testDetail.totalMarks) * 100).toFixed(2);
                return {
                    name: testDetail.program && testDetail?.program?.name,
                    percentage: parseFloat(percentage),
                    score: testDetail.obtainedMarks,
                    total: testDetail.percentage,
                };
            });
            setPerformanceChartData(data);
        }
    }, [studentTestDetails]);


    const getAllStudentQuestion = (courseId: number) => {
        studentApiClient.getAllQuestionCompletedByStudent(courseId).then((res) => {
            setTotalQuestions(res.data)
        }).catch((err) => {
            console.log(err)
        })
    }
    const getTimeSpendLearning = (courseId: number) => {
        studentApiClient.getAllTimeSpendLearning(courseId).then((res) => {
            setTimeSpendLearning(res.data)
        }).catch((err) => {
            console.log(err)
        })
    }
    const getTopicLearned = (courseId: number) => {
        studentApiClient.getAllTopicLearned(courseId).then((res) => {
            setTopicLearned(res.data);
        }).catch((err) => {
            console.log(err);
        });
    };


    const getStudentCourseBatches = (courseId: number) => {
        studentApiClient.getStudentByCourseId(courseId).then(res => {
            setCourseBatches(res.data)
        }).catch((err) => {
            console.log(err)
        })
    }

    const getPreviousTests = (courseId: number, testType?: string, size?: number, page?: number) => {
        studentApiClient.getPreviousTest(courseId, testType, size, page).then(res => {
            setLoading(true)
            setTestPayload(res.data.content);
            setCurrentPage(res.data.pageable.currentPage)
            setPageSize(res.data.pageable.pageSize);
            setLoading(false)
        }).catch((err) => {
            console.log(err);
        });
    };

    const getPreviousPracticeTests = (courseId: number, testType?: string, size?: number, page?: number) => {
        studentApiClient.getPreviousTest(courseId, testType, size, page).then(res => {
            setLoading(true)
            setPracticePayload(res.data.content);
            setCurrentPage(res.data.pageable.currentPage)
            setPageSize(res.data.pageable.pageSize);
            setLoading(false)
        }).catch((err) => {
            console.log(err);
        });
    };


    const getAllProgramsAccToBatch = (courseId: number) => {
        studentApiClient.getAllPrograms(courseId).then(res => {
            setProgramId(res.data.map(res => res.id) as any)
        }).catch((err) => {
            console.log(err);
        });
    };

    const getAllAssignmentByCourseId = (courseId: number, status: string) => {
        studentApiClient.getLatestTestByCourseId(courseId, status).then((res) => {
            setLoading(true)
            setAssignmentPayload(res.data.content);
            setLoading(false)
        }).catch((err) => {
            console.log(err);
        });
    };

    const getTotalAssignmentsCompletedByStudent = (courseId: number) => {
        studentApiClient.getAssignmentDetailsForStudent(courseId).then((res) => {
            setAssignmentStatisticsData(res.data)
        })
    }

    const getStudentRankForAllCourses = (courseId: number) => {
        studentApiClient.getStudentRank(courseId).then((res) => {
            setStudentRank(res.data)
        }).then((error) => {
            console.log(error)
        })
    }

    const fetchStudentTestDetails = (courseId: number, programId: number) => {
        return studentApiClient.fetchStudentTestDetails(courseId, programId)
            .then((res) => {
                return res.data;
            })
            .catch((error) => {
                throw error;
            });
    };

    const getCommentForStudent = (courseId: number) => {
        studentApiClient.getStudentComment(courseId).then((res) => {
            setComment(res.data.map((res: any) => res.comment)[0])
        }).catch((err) => {
            console.log(err)
        })
    }
    const getAllNoticeForStudent = () => {
        studentApiClient.getAllNotice().then((res) => {
            setNotice(res.data)
        }).catch((err) => {
            console.log(err)
        })
    }

    const getAllQuestions = (courseId: number) => {
        studentApiClient.getAllQuestionsCompleted(courseId).then((res) => {
            setStudentQuestions(res.data)
        }).catch((err) => {
            console.log(err)
        })
    }

    const getLeaderBoardDataForStudent = (courseId: number) => {
        studentApiClient.getLeaderBoardData(courseId).then((res) => {
            setLeaderBoardData(res.data.content)
        }).catch((err) => {
            console.log(err)
        })
    }

    const handleSubjectClick = (dataIndex: number, selectedSubject: string) => {
        const clickedProgramId = programId[dataIndex];
        setSelectedSubject(selectedSubject);
        setShow(true);
        setSelectedProgramId(clickedProgramId)
    };

    const handleSelectCurrentChart = (selectedProgramId: number, subject: string) => {
        setSelectedProgramId(selectedProgramId);
        setSelectedSubject(subject)
    };

    const handleNextPage = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % performanceChartData.length);
    };

    const handlePrevPage = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + totalPages) % totalPages);
    };

    const formatTime = (time: any) => {
        const [hours, minutes] = time.split(':');
        return `${hours}hr ${minutes}min`;
    };
    return (
        <div className={'container-fluid mb-xxl-5'}>
            <div
                className={'bg-white rounded-2 p-3 chapterHeader mt-4 d-flex align-items-center justify-content-between top_header'}>
                <div className={'mb-1 me-2'}>
                    <h6>Course Name - {courseBatches?.name}</h6>
                </div>
                <button onClick={() => navigate(`/student`)} className="bg-success btn-primary"
                        style={{width: "auto", height: "auto", fontSize: "13px"}}>
                    <span className='me-2'>Switch</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 49 49" fill="none">
                        <g clip-path="url(#clip0_129_2)">
                            <path d="M48.5981 0.946655H0.598145V48.9467H48.5981V0.946655Z" fill="white"
                                  fill-opacity="0.01"/>
                            <path d="M42.5982 19.9467H6.59814" stroke="white" stroke-width="4" stroke-linecap="round"
                                  stroke-linejoin="round"/>
                            <path d="M30.5981 7.94666L42.5981 19.9467" stroke="white" stroke-width="4"
                                  stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M7.39722 29.9467H43.3972" stroke="white" stroke-width="4" stroke-linecap="round"
                                  stroke-linejoin="round"/>
                            <path d="M7.39697 29.9467L19.397 41.9467" stroke="white" stroke-width="4"
                                  stroke-linecap="round" stroke-linejoin="round"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_129_2">
                                <rect width="48" height="48" fill="white" transform="translate(0.598145 0.946655)"/>
                            </clipPath>
                        </defs>
                    </svg>
                </button>
            </div>
            <div>


                <div className={'row mt-3'}>
                    <div className='col-8'>
                        <div className='row'>
                            <div className='d-grid grid_4'>
                                <div className='question-answered top_head_card m-2'>
                                    <div className='row'>
                                        <span className='' style={{fontSize: '14px'}}> Questions Answered </span>
                                        <span
                                            style={{fontSize: '20px'}}>{totalQuestions?.totalQuestionAttempted ? totalQuestions?.totalQuestionAttempted : '--'}</span>
                                    </div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="90" height="90" viewBox="0 0 100 100"
                                         fill="none" style={{position: 'absolute', bottom: '0', right: '0'}}>
                                        <path
                                            d="M67.4259 17.9289C71.3019 14.0237 77.587 14.0237 81.4631 17.9289C85.3397 21.8341 85.3397 28.1658 81.4631 32.071L77.528 36.0355L63.4907 21.8934L67.4259 17.9289Z"
                                            fill="#156515" fill-opacity="0.6"/>
                                        <path
                                            d="M56.4718 28.9645L14.8887 70.858V85H28.926L70.5091 43.1066L56.4718 28.9645Z"
                                            fill="#156515" fill-opacity="0.6"/>
                                    </svg>
                                </div>
                                <div className='time-spend m-2 top_head_card'>
                                    <div className='row'>
                                        <span style={{fontSize: '14px'}}> Time Spend Learning </span>
                                        <span
                                            style={{fontSize: '20px'}}> {timeSpendLearning ? formatTime(timeSpendLearning) : '--'}</span>
                                    </div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="90" height="90" viewBox="0 0 101 100"
                                         fill="none" style={{position: 'absolute', bottom: '0', right: '0'}}>
                                        <path
                                            d="M79.9585 91.6667H84.1252V83.3334H79.9585V79.1667C79.9506 74.105 78.6281 69.1321 76.1206 64.7351C73.6132 60.338 70.0067 56.6676 65.6543 54.0834C64.1752 53.2084 63.2918 51.8042 63.2918 50.3292V49.6709C63.2918 48.1959 64.1752 46.7917 65.6502 45.9209C70.0033 43.3361 73.6106 39.6652 76.1188 35.2675C78.6269 30.8698 79.95 25.8961 79.9585 20.8334V16.6667H84.1252V8.33337H17.4585V16.6667H21.6252V20.8334C21.6336 25.8961 22.9567 30.8698 25.4649 35.2675C27.9731 39.6652 31.5803 43.3361 35.9335 45.9209C37.4085 46.7917 38.2918 48.1917 38.2918 49.6709V50.3292C38.2918 51.8042 37.4085 53.2084 35.9335 54.0792C31.5803 56.6639 27.9731 60.3349 25.4649 64.7326C22.9567 69.1303 21.6336 74.104 21.6252 79.1667V83.3334H17.4585V91.6667H79.9585ZM71.6252 16.6667V20.8334C71.6189 25.3427 70.1511 29.7286 67.4418 33.3334H34.1418C31.4326 29.7286 29.9648 25.3427 29.9585 20.8334V16.6667H71.6252ZM40.1668 61.2584C44.2127 58.8667 46.6252 54.7834 46.6252 50.3292V50H54.9585V50.3292C54.9585 54.7792 57.371 58.8667 61.421 61.2584C65.1649 63.4864 68.1125 66.8364 69.846 70.8334H31.7377C33.4715 66.8353 36.4208 63.4851 40.1668 61.2584Z"
                                            fill="#9A3C17" fill-opacity="0.6"/>
                                    </svg>
                                </div>
                                <div className='topic-learned m-2 top_head_card'>
                                    <div className='row'>
                                        <span style={{fontSize: '14px'}}>  Topic Learned </span>
                                        <span style={{fontSize: '20px'}}>{topicLearned ? topicLearned : '--'}</span>
                                    </div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100"
                                         viewBox="0 0 100 100" fill="none"
                                         style={{position: 'absolute', bottom: '0', right: '0'}}>
                                        <path
                                            d="M30 15H70C72.7615 15 75 17.2386 75 20V45.1121C76.717 45.2668 78.3885 45.5794 80 46.0352V20C80 14.4771 75.523 10 70 10H30C24.4771 10 20 14.4771 20 20V80C20 85.523 24.4771 90 30 90H51.286C50.0175 88.4645 48.9131 86.789 47.9986 85H30C27.2386 85 25 82.7615 25 80H46.0352C45.5794 78.3885 45.2668 76.717 45.1121 75H25V20C25 17.2386 27.2386 15 30 15Z"
                                            fill="#0E504C" fill-opacity="0.6"/>
                                        <path
                                            d="M35 20C32.2386 20 30 22.2386 30 25V30C30 32.7614 32.2386 35 35 35H65C67.7615 35 70 32.7614 70 30V25C70 22.2386 67.7615 20 65 20H35ZM65 25V30H35V25H65Z"
                                            fill="#0E504C" fill-opacity="0.6"/>
                                        <path
                                            d="M95 72.5C95 84.9265 84.9265 95 72.5 95C60.0735 95 50 84.9265 50 72.5C50 60.0735 60.0735 50 72.5 50C84.9265 50 95 60.0735 95 72.5ZM70.1015 62.67C69.7115 62.4055 69.2795 62.273 68.8045 62.273C68.474 62.273 68.1605 62.3435 67.864 62.4845C67.567 62.617 67.3085 62.798 67.0885 63.027C66.868 63.2565 66.69 63.5255 66.554 63.834C66.427 64.143 66.3635 64.469 66.3635 64.813V80.187C66.3635 80.5225 66.427 80.8445 66.554 81.153C66.69 81.462 66.868 81.735 67.0885 81.9735C67.317 82.2025 67.58 82.388 67.8765 82.529C68.173 82.6615 68.4825 82.7275 68.8045 82.7275C69.0505 82.7275 69.2795 82.692 69.4915 82.6215C69.7115 82.551 69.928 82.445 70.14 82.304L81.621 74.4715C81.9685 74.2425 82.24 73.9335 82.4345 73.5455C82.6295 73.1575 82.727 72.7515 82.727 72.328C82.727 71.887 82.6255 71.4815 82.422 71.111C82.2185 70.7315 81.939 70.4275 81.583 70.198L70.1015 62.67Z"
                                            fill="#0E504C" fill-opacity="0.6"/>
                                    </svg>
                                </div>
                                <div className='reward-earned m-2 top_head_card'>
                                    <div className='row'>
                                        <span style={{fontSize: '14px'}}>  Badges Earned </span>
                                        <span style={{fontSize: '20px'}}>0</span>
                                    </div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="95" height="95" viewBox="0 0 95 95"
                                         fill="none" style={{position: 'absolute', bottom: '0', right: '0'}}>
                                        <path
                                            d="M59.1667 72.2C57.4167 72.2 56.25 71.6 54.5 71L47.5 68L40.5 71C39.3333 71.6 37.5833 72.2 35.8333 72.2C34.6667 72.2 34.0833 72.2 32.9167 71.6L30 95L47.5 83L65 95L61.5 71.6C60.9167 72.2 59.75 72.2 59.1667 72.2Z"
                                            fill="#34186F" fill-opacity="0.6"/>
                                        <path
                                            d="M76.9359 34.7679C76.3353 33.5893 76.3353 31.8214 76.9359 30.6429L80.5397 23.5714C81.7409 21.2143 80.5397 18.2679 77.5366 17.0893L69.7285 14.1429C68.5272 13.5536 67.326 12.375 66.7254 11.1964L63.7223 3.53571C63.1216 1.17857 61.3198 0 59.5179 0C58.9173 0 57.716 0 57.1154 0.589286L49.3073 4.125C48.7067 4.125 48.1061 4.125 47.5055 4.125C46.9048 4.125 46.3042 4.125 45.7036 3.53571L37.8955 0.589286C37.2949 0 36.0936 0 35.493 0C33.6912 0 31.8893 1.17857 30.688 2.94643L27.6849 11.1964C27.6849 12.375 26.4837 13.5536 25.2825 14.1429L16.8737 17.0893C14.4713 17.6786 13.27 20.625 14.4713 23.5714L18.075 31.2321C18.6756 32.4107 18.6756 34.1786 18.075 35.3571L14.4713 42.4286C13.27 44.7857 14.4713 47.7321 17.4744 48.9107L25.2825 51.8571C26.4837 52.4464 27.6849 53.625 28.2856 54.8036L31.2887 62.4643C31.8893 64.8214 33.6912 66 35.493 66C36.0936 66 36.6943 66 37.2949 65.4107L45.103 61.875C45.7036 61.875 46.3042 61.2857 46.9048 61.2857C47.5055 61.2857 48.1061 61.2857 48.7067 61.875L56.5148 65.4107C57.1154 66 57.716 66 58.3167 66C60.1185 66 61.9204 64.8214 63.1216 63.0536L66.1247 55.3929C66.7254 54.2143 67.9266 53.0357 69.1279 52.4464L76.9359 49.5C79.3384 48.3214 81.1403 45.375 79.939 43.0179L76.9359 34.7679ZM47.5055 56.5714C34.2918 56.5714 23.4806 45.9643 23.4806 33C23.4806 20.0357 34.2918 9.42857 47.5055 9.42857C60.7192 9.42857 71.5303 20.0357 71.5303 33C71.5303 45.9643 60.7192 56.5714 47.5055 56.5714Z"
                                            fill="#34186F" fill-opacity="0.6"/>
                                        <path
                                            d="M65 33C65 42.942 57.1658 51 47.5 51C37.8342 51 30 42.942 30 33C30 23.058 37.8342 15 47.5 15C57.1658 15 65 23.058 65 33Z"
                                            fill="#34186F" fill-opacity="0.6"/>
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className={"card-body m-0 p-0"}
                                 style={{overflowX: "hidden"}}>
                                <div
                                    className="d-flex align-items-center justify-content-between mb-3"
                                    style={{width: "auto"}}>
                                    <button className={'bg-white me-3 text-black'}
                                            onClick={handlePrevPage}
                                            disabled={totalPages <= 1 || currentIndex === 0}>
                                        <i className="bi bi-chevron-left fs-3"></i>
                                    </button>
                                    <div
                                        className="d-flex align-items-center justify-content-around"
                                        style={{flex: "1", overflowX: "auto"}}>
                                        {performanceChartData.length > 0 && (
                                            Array.from({length: Math.min(chartsPerPage, performanceChartData.length)}).map((_, index) => {
                                                const chartIndex = (currentIndex + index) % performanceChartData.length;
                                                const testDetail = performanceChartData[chartIndex];
                                                return (
                                                    <div
                                                        key={index}
                                                        className={`my-2 mx-3 rounded-3 px-3 py-3 ${selectedProgramId === programId[chartIndex] ? 'bgsuccess' : 'bglight'}`}
                                                        style={{
                                                            border: '1px solid #D3D3D3',
                                                            width: '150px',
                                                            maxWidth: '100%'
                                                        }}
                                                        onClick={() => handleSelectCurrentChart(programId[chartIndex], testDetail.name)}
                                                    >
                                                        <PerformanceChart
                                                            width={30}
                                                            height={5}
                                                            percentage={testDetail?.percentage ? testDetail?.percentage : 0}
                                                            subjectName={testDetail?.name}
                                                            onClick={() => handleSubjectClick(chartIndex, testDetail.name)}
                                                            programIds={programId}
                                                            backgroundColor={selectedProgramId === programId[chartIndex] ? '#4CAF50' : '#4CAF50'}
                                                            percentageColor={selectedProgramId === programId[chartIndex] ? '#faf5f5' : '#0a0a0a'}
                                                            subjectColor={selectedProgramId === programId[chartIndex] ? '#faf5f5' : '#0a0a0a'}
                                                        />
                                                    </div>
                                                );
                                            })
                                        )}
                                    </div>
                                    <button className={'bg-white me-3 text-black'}
                                            onClick={handleNextPage}
                                            disabled={totalPages <= 1 || currentIndex === totalPages - 1}>
                                        <i className="bi bi-chevron-right fs-3"></i>
                                    </button>
                                </div>
                            </div>
                            <div className={'row'}>
                                <div className={''}>
                                    <StudentAssignmentDropDown show={show} onHide={() => setShow(false)}
                                                               selectedSubject={selectedSubject}
                                                               courseId={Number(params.courseBatchId)}
                                                               programId={selectedProgramId}
                                                               leaderBoard={leaderBoardData}
                                    />
                                    {comments && (
                                        <div className='card shadow-sm px-2 pt-2 mt-2' style={{borderColor: '#EFF0F6'}}>
                                            <div className='m-2'>
                                                <h6>Comments</h6>
                                            </div>
                                            <ul>
                                                <li>{comments}</li>
                                            </ul>
                                        </div>
                                    )}

                                </div>

                            </div>
                        </div>
                    </div>
                    <div className='col-4'>
                        <div>
                            <div className={'row'}>
                                <div className={'mb-3 mt-4'}>
                                    <LeaderBoard leaderBoardData={leaderBoardData}/>
                                </div>
                                <div>
                                    <div className='card shadow-sm mb-3 h-auto'
                                         style={{backgroundColor: 'rgb(129,85,220)'}}>
                                        <div
                                            className='d-flex align-items-center px-2 py-1 justify-content-around'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="61" height="61"
                                                 viewBox="0 0 61 61" fill="none">
                                                <g clip-path="url(#clip0_35_200)">
                                                    <path d="M10.1665 12.7083H15.2498" stroke="white" stroke-width="3"
                                                          stroke-linecap="round" stroke-linejoin="round"/>
                                                    <path d="M12.7085 10.1667V15.25" stroke="white" stroke-width="3"
                                                          stroke-linecap="round" stroke-linejoin="round"/>
                                                    <path d="M29.2293 10.1667L27.9585 15.25" stroke="white"
                                                          stroke-width="3" stroke-linecap="round"
                                                          stroke-linejoin="round"/>
                                                    <path d="M45.75 12.7083H50.8333" stroke="white" stroke-width="3"
                                                          stroke-linecap="round" stroke-linejoin="round"/>
                                                    <path d="M48.2915 10.1667V15.25" stroke="white" stroke-width="3"
                                                          stroke-linecap="round" stroke-linejoin="round"/>
                                                    <path d="M38.1252 22.875L35.5835 25.4167" stroke="white"
                                                          stroke-width="3" stroke-linecap="round"
                                                          stroke-linejoin="round"/>
                                                    <path d="M45.75 33.0416L50.8333 31.7708" stroke="white"
                                                          stroke-width="3" stroke-linecap="round"
                                                          stroke-linejoin="round"/>
                                                    <path d="M45.75 48.2917H50.8333" stroke="white" stroke-width="3"
                                                          stroke-linecap="round" stroke-linejoin="round"/>
                                                    <path d="M48.2915 45.75V50.8333" stroke="white" stroke-width="3"
                                                          stroke-linecap="round" stroke-linejoin="round"/>
                                                    <path
                                                        d="M35.5832 41.9833L19.0167 25.4167L7.85875 49.7659C7.64288 50.2386 7.57665 50.766 7.66894 51.2775C7.76122 51.7889 8.00761 52.2599 8.37509 52.6274C8.74257 52.9949 9.21358 53.2413 9.72502 53.3335C10.2365 53.4258 10.7639 53.3596 11.2366 53.1437L35.5832 41.9833Z"
                                                        fill="white" stroke="white" stroke-width="4"
                                                        stroke-linecap="round" stroke-linejoin="round"/>
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_35_200">
                                                        <rect width="61" height="61" fill="white"/>
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                            <h6 style={{color: 'white', fontSize: '18px'}} className={'ms-2 mb-0'}> New
                                                Badge Unlocked</h6>
                                            <div className='ms-4'>
                                                <button className="ViewNowBtn btn-primary rounded-1 fw-medium"
                                                        style={{fontSize: "12px", backgroundColor: "#FFFFFF33"}}>
                                                    View now
                                                </button>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                                <div className='mb-3'>
                                    <div className='card border pt-2 h-auto' style={{borderColor: '#EFF0F6'}}>
                                        <div className='card-header bg-transparent mx-3 px-0 d-flex'>
                                            <p className='notice me-1 mb-0'></p>
                                            <h6 className={'fw-bold mb-0'}> Notice Board</h6>
                                        </div>
                                        <div className='m-2'>
                                            {notice.map((item, index) => (
                                                <div>
                                                    <div className='card m-2 p-2 border-0'
                                                         style={{backgroundColor: '#6F6F6F0F'}} key={index}>
                                                        <p className={'mb-0 text-capitalize fw-medium'}
                                                           style={{fontSize: '14px'}}>{item.content}</p>
                                                        <span className='text-end'
                                                              style={{fontSize: '12px'}}>{moment(item.createdAt).format('d MMMM, YYYY')} </span>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>

                                    </div>
                                </div>
                                {/*<AssignmentCard assignmentData={test}*/}
                                {/*                assignmentName={'Tests'}*/}
                                {/*                buttonName={'Review'} loading={loading}/>*/}
                                <AssignmentCard assignmentData={practice}
                                                assignmentName={'Practice Test'}
                                                buttonName={'Review'} loading={loading}/>
                                {/*<AssignmentCard assignmentData={assignmentPayload}*/}
                                {/*                assignmentName={'New Assignment'}*/}
                                {/*                buttonName={'Attempt'} loading={loading}*/}
                                {/*/>*/}
                            </div>
                        </div>
                    </div>

                    {/*<div className={'col-xl-8 mb-3 set_order_0'}>*/}
                    {/*    <div className={`card shadow-sm h-100`}*/}
                    {/*         style={{borderColor: '#EFF0F6'}}>*/}
                    {/*        <div className="mx-3 my-3 px-0">*/}
                    {/*            <div className="top_head">*/}
                    {/*                <h6 className={'fw-bold'}>Performance Evaluation </h6> <p>*Click on any Program card*/}
                    {/*                for further details*</p>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        <div className={"card-body m-0 p-0 d-flex align-items-center"}*/}
                    {/*             style={{overflowX: "hidden"}}>*/}
                    {/*            <div*/}
                    {/*                className="d-flex align-items-center justify-content-between"*/}
                    {/*                style={{width: "100%"}}>*/}
                    {/*                <button*/}
                    {/*                    onClick={handlePrevPage}*/}
                    {/*                    disabled={totalPages <= 1 || currentIndex === 0}>*/}
                    {/*                    <i className="bi bi-chevron-compact-left fs-3"></i>*/}
                    {/*                </button>*/}
                    {/*                <div*/}
                    {/*                    className="d-flex align-items-center justify-content-center"*/}
                    {/*                    style={{flex: "1", overflowX: "auto"}}>*/}
                    {/*                    {performanceChartData.length > 0 && (*/}
                    {/*                        Array.from({length: Math.min(chartsPerPage, performanceChartData.length)}).map((_, index) => {*/}
                    {/*                            const chartIndex = (currentIndex + index) % performanceChartData.length;*/}
                    {/*                            const testDetail = performanceChartData[chartIndex];*/}
                    {/*                            return (*/}
                    {/*                                <div*/}
                    {/*                                    key={index}*/}
                    {/*                                    className={`my-2 mx-3 rounded-3 px-3 py-3 ${selectedProgramId === programId[chartIndex] ? 'bgsuccess' : 'bglight'}`}*/}
                    {/*                                    onClick={() => handleSelectCurrentChart(programId[chartIndex], testDetail.name)}*/}
                    {/*                                >*/}
                    {/*                                    <PerformanceChart*/}
                    {/*                                        width={100}*/}
                    {/*                                        height={100}*/}
                    {/*                                        percentage={testDetail?.percentage ? testDetail?.percentage : 0}*/}
                    {/*                                        subjectName={testDetail?.name}*/}
                    {/*                                        onClick={() => handleSubjectClick(chartIndex, testDetail.name)}*/}
                    {/*                                        programIds={programId}*/}
                    {/*                                        backgroundColor={selectedProgramId === programId[chartIndex] ? '#4CAF50' : '#4CAF50'}*/}
                    {/*                                        percentageColor={selectedProgramId === programId[chartIndex] ? '#faf5f5' : '#0a0a0a'}*/}
                    {/*                                        subjectColor={selectedProgramId === programId[chartIndex] ? '#faf5f5' : '#0a0a0a'}*/}
                    {/*                                    />*/}
                    {/*                                </div>*/}
                    {/*                            );*/}
                    {/*                        })*/}
                    {/*                    )}*/}
                    {/*                </div>*/}
                    {/*                <button className={'bglight me-3'}*/}
                    {/*                        onClick={handleNextPage}*/}
                    {/*                        disabled={totalPages <= 1 || currentIndex === totalPages - 1}>*/}
                    {/*                    <i className="bi bi-chevron-compact-right fs-3"></i>*/}
                    {/*                </button>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    {/*<div className={'col-xl-4 mb-3 set_order_1'}>*/}
                    {/*    <CourseStatistics studentRank={studentRank}*/}
                    {/*                      totalQuestions={totalQuestions}*/}
                    {/*                      studentQuestions={studentQuestions}*/}
                    {/*                      assignmentStatisticsData={assignmentStatisticsData}*/}
                    {/*    />*/}
                    {/*</div>*/}
                </div>
                {/*<div className={'row'}>*/}
                {/*    <div className={'col-xl-8'}>*/}
                {/*        <StudentAssignmentDropDown show={show} onHide={() => setShow(false)}*/}
                {/*                                   selectedSubject={selectedSubject}*/}
                {/*                                   courseId={Number(params.courseBatchId)}*/}
                {/*                                   programId={selectedProgramId} leaderBoard={leaderBoardData}*/}
                {/*        />*/}
                {/*        {comments && (*/}
                {/*            <div className='card shadow-sm px-2 pt-2 mt-2' style={{borderColor: '#EFF0F6'}}>*/}
                {/*                <div className='m-2'>*/}
                {/*                    <h6>Comments</h6>*/}
                {/*                </div>*/}
                {/*                <ul>*/}
                {/*                    <li>{comments}</li>*/}
                {/*                </ul>*/}
                {/*            </div>*/}
                {/*        )}*/}

                {/*    </div>*/}

                {/*</div>*/}
            </div>
        </div>
    );
};



