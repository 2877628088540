import {Offcanvas} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {removeSelectedQuestion} from "../../../../redux/reducers/QuestionListSlice";
import "./TeacherSelectedQuestionList.scss";
import {EmptyState} from "../../../../components/emptyState/EmptyState";
import ReadMore from "../../../admin/components/studentEvauationScreen/ReadMore";

interface TeacherSelectedQuestionListProps {
    show: boolean;
    onDelete: (questionId: string) => void;
    onHide: () => void;
}

const TeacherSelectedQuestionList = (props: TeacherSelectedQuestionListProps) => {
    const dispatch = useDispatch();
    const selectedQuestions = useSelector((state: any) => state.question.selectedQuestions);
    const flattenedText = selectedQuestions.map((question: any) => question.text.join(" "));
    return (
        <Offcanvas show={props.show} onHide={props.onHide} placement="end" style={{width: "50%"}} className="container">
            <Offcanvas.Header className="m-0 p-0">
                <div className="w-100 nav-tabs ps-3 pt-3 d-flex justify-content-between align-items-center">
                    <div className="mb-3">
                        <h4>Selected Questions</h4>
                    </div>
                    <div onClick={props.onHide} className="me-3 mb-3">
                        <i className="bi bi-x-lg"></i>
                    </div>
                </div>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <div className="row">
                    {selectedQuestions.length !== 0 ? (
                        selectedQuestions.map((question: any, index: number) => (
                            <div key={question.id} className="teacherSideBar mb-3 me-3">
                                <div className="d-flex justify-content-between align-items-center p-2">
                                    <div className={`d-flex`}>
                                        <span className={`me-1`}>{question.id}.</span>
                                        <p><ReadMore>{flattenedText[index]}</ReadMore></p>
                                    </div>
                                    <div className="ms-2">
                                        <i
                                            className="bi bi-trash3-fill text-danger"
                                            onClick={() => {
                                                props.onDelete(question.id);
                                                dispatch(removeSelectedQuestion(question.id));
                                            }}
                                        ></i>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <EmptyState/>
                    )}
                </div>
            </Offcanvas.Body>
        </Offcanvas>
    );
};

export default TeacherSelectedQuestionList;
