import '../Sidebar.scss'
import {Link, NavLink, useLocation, useNavigate} from "react-router-dom";
import {MenuItem} from "../../../../modals/MenuItem";
import dashImg from "../../../../assets/images/dashboard (1) 2.png";
import {useEffect, useState} from "react";
import {Helper} from "../../../../utils/Helper";

export const SidebarMenuItem = (prop: {
    menuItem: MenuItem,
    itemIndex?: number,
    moduleName?: string,
    hasParent?: boolean,
    onActive: (menuItem: MenuItem) => void,
    collapsedSidebar?: boolean,
    onClose?: () => void
}) => {
    const [isActive, setActive] = useState<boolean>(false);
    const routerLocation = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (routerLocation) {
            const currentPathname = Helper.getAbsolutePathname(routerLocation.pathname);
            if (prop.menuItem.path && currentPathname.endsWith(prop.menuItem.path)) {
                onActive(prop.menuItem);
            }
        }
    }, [routerLocation.pathname]);

    const onActive = (menuItem: MenuItem) => {
        setActive(true);
        prop.onActive(menuItem);
    }

    const getInitials = (data: any) => {
        if (data && typeof data === 'string') {
            let initials: any = data.split('');
            if (initials.length >= 2)
                return initials[0].substring(0, 1).toUpperCase() + initials[1].substring(0, 1).toUpperCase();
        }
        return '';
    }

    const onSubMenuItemClick = () => {
        navigate(`${prop.menuItem.path ?? ''}`);
        handleClose();
    };
    const handleClose = () => {
        if (window.innerWidth <= 576) {
            if (prop.onClose)
                prop.onClose();
        }
    }


    return <li className={`menuItem list-unstyled ${prop.hasParent ? 'ms-2' : ''}`}>
        {(prop.menuItem.subMenuItems) ?
            <button aria-expanded="false" data-bs-target={`#submenu_${prop.menuItem.id}`}
                    aria-controls={`#submenu_${prop.menuItem.id}`} data-bs-toggle="collapse"
                    className={`nav-link w-100 ${(prop.menuItem.subMenuItems) ? 'arrow_icon' : ''} ${isActive ? 'active' : ''}`}
                    onClick={(event) => {
                        event.stopPropagation();
                    }}>
                {/*<img src={prop.menuItem?.icon ?? dashImg} className={'me-3'} alt={prop.menuItem?.name}/>*/}
                <div className={'initials me-2'}>{getInitials(prop.menuItem?.name)}</div>
                <span> {prop.menuItem?.name}</span>
            </button> :
            <button className={`nav-link w-100 ${isActive ? 'active' : ''}`}
                    onClick={onSubMenuItemClick}>
                <img src={prop.menuItem?.icon ?? dashImg} className={'me-3'}
                     alt={prop.menuItem?.name}/><span> {prop.menuItem?.name}</span>
            </button>
        }
        {
            (prop.menuItem.subMenuItems) &&
            <div className={`collapse ${isActive ? 'show' : ''} submenu`} id={`submenu_${prop.menuItem.id}`}
                 data-bs-parent="#sidebarMenu">
                <ul className="nav nav-sm flex-column">
                    {prop.menuItem.subMenuItems?.map((menuItem, index) => (
                        <SidebarMenuItem key={index} menuItem={menuItem} collapsedSidebar={prop.collapsedSidebar}
                                         onClose={handleClose}
                                         onActive={(activeMenu) => onActive(activeMenu)}/>
                    ))}
                </ul>
            </div>
        }
    </li>;
}
export default SidebarMenuItem;
