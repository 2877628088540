import React, { ReactNode } from 'react';
import "./treeMultiSelect.scss";
import TreeSelect from 'rc-tree-select';
import { TreeNode } from "./TreeMultiSelect";

export interface TreeMultiSelectProps {
    placeholder: string;
    customClass: string;
    handleChange: (selectedNodes: number[], selectedLabels: ReactNode, extra: any) => void;
    data: TreeNode[];
    selectedNodes:number[]
}

const TreeMultiSelectDropDown: React.FC<TreeMultiSelectProps> = (props: TreeMultiSelectProps) => {

    const filterTreeNode = (inputValue: string, treeNode: any): boolean => {
        if (inputValue === null || treeNode === null) return false;
        const nodeLabel = treeNode.label;
        return nodeLabel.toLowerCase().includes(inputValue.toLowerCase());
    }

    return <div className='form-group'>
        <TreeSelect
            treeLine={true}
            multiple={true}
            treeData={props.data}
            showSearch={true}
            allowClear={true}
            treeCheckable={true}
            animation={'slide-up'}
            placeholder={props.placeholder}
            onChange={props.handleChange}
            filterTreeNode={filterTreeNode}
            dropdownClassName={`shadow  border-color-0 rounded ${props.customClass}`}
        />
    </div>;
}

export default TreeMultiSelectDropDown;
