import React, {useEffect, useState} from "react";
import {StudentTopicCard} from "./card/StudentTopicCard";
import {useStudentApiClient} from "../../apiClients/StudentApiClient";
import {useNavigate, useParams} from "react-router-dom";
import StudentChapterShimmer from "../chapter/StudentChapterShimmer";
import {EmptyState} from "../../../../components/emptyState/EmptyState";
import TabSelector from "../tabSelector/TabSelector";
import "./StudentTopics.scss"
import {QuizMode} from "../../../../modals/quizquestionData/Modal";
import {Chapter} from "../../../../dto/Courses";
import {StudentNoOfQuestionsModal} from "./StudentNoOfQuestionsModal";
import {ProgramRepo} from "../../../../repos/ProgramRepo";
import LockModal from "../lockModal/LockModal";
export interface Topics {
    id: number;
    name: string;
    active: boolean,
    totalQuestions: number
}

const StudentTopicFreeTrail = () => {
    const studentApiClient = useStudentApiClient();
    const params = useParams();
    const [selectAll, setSelectAll] = useState(false);
    const [topics, setTopics] = useState<Topics[]>([]);
    const [selectedCardIds, setSelectedCardIds] = useState<number[]>([]);
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(true);
    const [selectedOption, setSelectedOption] = useState(QuizMode.TEST);
    const [show, setShow] = useState<boolean>(false)
    const [numberOfQuestions, setNumberOfQuestions] = useState('');
    const [chapters, setChapters] = useState<Chapter[]>([]);
    const [course, setCourse] = useState<any[]>([]);
    const [studentRank, setStudentRank] = useState<any>();
    const [subscriptionType, setSubscriptionType] = useState<string>('');
    const [canHaveComprehensions , setCanHaveComprehensions] = useState<boolean>(false);
    const [ofComp , setOfComp] = useState<boolean>(false);
    const [numberOfComp , setNumberOfComp] = useState<number | undefined>(undefined);
    const [showLockModal, setShowLockModal] = useState<boolean>(false);

    useEffect(() => {
        getAllChapters();
        getProgramId(params.programId || '0');
    }, [params.programId]);

    useEffect(() => {
        getStudentRankForAllCourses()
        getStudentAllCourses();
    }, []);

    useEffect(() => {
        const SelectedCourse = course.filter((res) => res.id.toString() === params.courseBatchId?.toString());
        if (SelectedCourse.length > 0) {
            setSubscriptionType(SelectedCourse.map((res)=> res.typeOfCourse)[0]);
        }
    }, [course, params.courseBatchId]);

    const getProgramId = (programId: string) => {
        ProgramRepo.getSingleProgram(programId).then((res) => {
            setCanHaveComprehensions(res.data.canHaveComprehensions);
        });
    };

    const getStudentAllCourses = () => {
        studentApiClient.getAllStudentCourseBatches().then((res) => {
            setCourse(res.data);
            console.log('data',res.data)
        }).catch((err) => {
            console.log(err);
        });
    }
    const getAllChapters = () => {
        studentApiClient.getAllProgramChapters(parseInt(params?.programId ?? '')).then((res: any) => {
            const allTopics = res.data.flatMap((chapter: any) => chapter.topics);
            setChapters(res.data);
            setTopics(allTopics);
            setLoading(false);
        }).catch(() => {
            console.log("#30");
        });
    };

    const toggleSelectAll = () => {
        if (selectAll) {
            setSelectedCardIds([]);
        } else {
            const freeTopicIds = topics
                .filter((topic) => {
                    const isTopicFree = chapters.some((chapter) =>
                        chapter.topics?.some(
                            (t) => t.id.toString() === topic.id.toString() && chapter.subscriptionType === 'FREE'
                        )
                    );
                    console.log('isTopicFree',isTopicFree)
                    return isTopicFree;
                })
                .map((topic) => topic.id);
            setSelectedCardIds(freeTopicIds);
        }
        setSelectAll(!selectAll);
    };

    const toggleCardSelection = (cardId: number) => {
        const isFree = chapters.some((chapter) =>
            chapter.topics?.some(
                (topic) => topic.id.toString() === cardId.toString() && chapter.subscriptionType === 'FREE'
            )
        );
        if (isFree) {
            setSelectedCardIds((prevSelected) => {
                const updatedSelected = prevSelected.includes(cardId)
                    ? prevSelected.filter((id) => id !== cardId)
                    : [...prevSelected, cardId];
                const allTopicsSelected = topics.every((topic) => updatedSelected.includes(topic.id));
                setSelectAll(allTopicsSelected);
                return updatedSelected;
            });
        }
    };

    const handleNumberOfQuestions = (number: string) => {
        setNumberOfQuestions(number);
    };

    const handleNumberOfComp = (number : number) => {
        setNumberOfComp(number);
    }

    const createTest = () => {
        if (ofComp) {
            studentApiClient.createCompTest(Number(params.courseBatchId), selectedCardIds,
                Number(params.programId), selectedOption, numberOfComp).then((res) => {
                navigate(`../${selectedOption}/${res.data.id}/comp-instructions`)
            }).catch(() => {
            })
        }else {
            studentApiClient
                .createTest(Number(params.courseBatchId), selectedCardIds, Number(params.programId), selectedOption, studentRank.percentage, parseInt(numberOfQuestions, 10))
                .then((res) => {
                    navigate(`../${selectedOption}/${res.data.id}/instructions`);
                })
                .catch((error) => {
                    console.error("Error creating test:", error);
                });
        }
    };

    const getStudentRankForAllCourses = () => {
        studentApiClient.getStudentRank(params.courseBatchId ?? 0 as any).then((res) => {
            setStudentRank(res.data)
        }).then((error) => {
            console.log(error)
        })
    }

    const handleOptionClick = (options: string) => {
        console.log('options',options)
        if (options === 'TEST')
            setSelectedOption(QuizMode.TEST);
        else if (options === 'PRACTICE') {
            setSelectedOption(QuizMode.PRACTISE)
        }
    };

    const openLockModal = () => {
        setShowLockModal(true);
    }


    return <div>
        <div>
            {loading ? <StudentChapterShimmer count={10}/> : <div>
                {topics.length > 0 ? <div className="container">
                    <div className={'row topicHeader mb-2 mt-4'}>
                        <div className={'col-md-6'}>
                            <div className='d-flex flex-column'>
                                <h6 className={'fw-bold'}>Choose Topics</h6>
                                <div className={'d-flex'}>
                                    <TabSelector option={selectedOption} handleOptionClick={handleOptionClick}/>
                                    {
                                        canHaveComprehensions ?
                                            <div
                                                className={`d-flex align-items-center justify-content-between bg-white py-1 px-3 rounded-4 border ms-4 h-50 mt-1`}>
                                                <input
                                                    type="checkbox"
                                                    className="inputCheckbox me-3"
                                                    checked={ofComp}
                                                    onChange={(e) => setOfComp(e.target.checked)}
                                                />
                                                <span>Only comprehensions</span>
                                            </div> : ''
                                    }
                                </div>
                            </div>
                        </div>
                        <div className={'col-md-6 d-flex justify-content-end'}>
                            <div className={`d-flex align-items-center justify-content-between bg-white me-4`}
                                 onClick={toggleSelectAll}>
                                <span className="chapter-label py-2">Select All Topics</span>
                                <input
                                    type="checkbox"
                                    className="inputCheckbox me-3"
                                    checked={selectAll || selectedCardIds.length === topics.filter((topic) => {
                                        return chapters?.some((chapter) =>
                                            chapter.topics?.some((t) => t.id.toString() === topic.id.toString() && chapter.subscriptionType === 'FREE')
                                        );
                                    }).length }
                                    onChange={toggleSelectAll}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="row multi-selector mt-2">

                        {topics?.map((card, index) => (
                            <div className={`col-md-4 mb-4`} key={card.id}>
                                <StudentTopicCard data={card} selected={selectedCardIds.includes(card.id)}
                                                  toggleSelection={() => toggleCardSelection(card.id)}
                                                  disabled={
                                                      !chapters.find((chapter) =>
                                                          chapter.topics?.some((topic) => {
                                                              return topic.id.toString() === card.id.toString() && chapter.subscriptionType === 'FREE';
                                                          })
                                                      )
                                                  }
                                                  openLockModal={openLockModal}
                                />
                            </div>
                        ))}
                    </div>
                    <div className="sticky-bottom-btns text-end mb-4">
                        <button className={"btn btn-success text-white me-3"} onClick={() => setShow(true)} disabled={!(selectedCardIds.length > 0)}>
                            {selectedOption === QuizMode.PRACTISE ? "Start Practice Test" : "Start Test"}
                        </button>
                    </div>
                </div> : <EmptyState/>
                }
            </div>
            }
        </div>
        <StudentNoOfQuestionsModal
            show={show}
            onHide={() => setShow(false)}
            createTest={() => createTest()}
            numberOfQuestions={handleNumberOfQuestions}
            ofComp={ofComp}
            numberOfComp={handleNumberOfComp}
        />
        <LockModal show={showLockModal} onHide={() => setShowLockModal(false)} courseId = {params.courseBatchId ?? ''}/>
    </div>
}
export default StudentTopicFreeTrail;