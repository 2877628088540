import {useAxios} from "../../../configs/AxiosClient";

export const useAdminProfileApiClient = () =>  {
    const axiosClient = useAxios();

    const getAdminProfile = () => {
        return axiosClient.get(`/admin/me`);
    }

    return {getAdminProfile};
}