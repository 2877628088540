import React from "react";
import CardShimmer from "../../../../components/shimmer/CardShimmer";
import Skeleton from "react-loading-skeleton";

const StudentChapterShimmer=(props:{count:number})=>{
    function generateShimmerCards(count:number) {
        const shimmerCards = [];

        for (let i = 0; i < count; i++) {
            shimmerCards.push(<CardShimmer key={i} />);
        }

        return shimmerCards;
    }
    return<div className={'container'}>
        <div>
            <Skeleton height={50}/>
        </div>
        <div className={' d-flex container flex-wrap '}>{generateShimmerCards(props.count)}</div>
    </div>
}
export default StudentChapterShimmer;