import {Modal, ModalBody} from "react-bootstrap";
import React, {FormEvent, useEffect, useState} from "react";
import {CourseRepo} from "../../../../repos/CourseRepo";
import {AllToasts} from "../../../toast/AllToasts";
import Button from "../../../button/Button";
import {
    ImageUpload
} from "../../../../modules/teacher/components/createAssignment/createQuestion/ImageUpload/ImageUpload";


export type CoursePayload = {
    name?: string,
    description: string,
    amount: number,
    annualAmount: number,
    freeTrialPeriod: number,
    program: [],
    imageCid?: string,
    programDetails?: string

}
const CreateCourse = (props: { show: boolean, onCancel: () => void, editId?: string }) => {
    const [payload, setPayload] = useState<CoursePayload>(
        {
            name: "",
            description: "",
            amount: 0,
            annualAmount: 0,
            freeTrialPeriod: 0,
            program: [],
            imageCid: '',
            programDetails: ''
        }
    );
    const [loading, setLoading] = useState<boolean>(false);
    const [uploadedFileCid, setUploadedFileCid] = useState<string>('')
    const [imageUrl, setImageUrl] = useState<string>('')
    const [imageUploadVisible, setImageUploadVisible] = useState<boolean>(false);

    const handleForm = (e: FormEvent) => {
        e.preventDefault();
        if (props.editId) {
            updateCourse(props.editId, payload);
        } else {
            createCourse(payload);
        }
    };

    const createCourse = (payload: CoursePayload) => {
        setLoading(true);
        CourseRepo.createCourse(payload)
            .then(() => {
                setLoading(false);
                const clearedPayload: CoursePayload = {
                    name: "",
                    description: "",
                    program: [],
                    amount: 0,
                    annualAmount: 0,
                    freeTrialPeriod: 0,
                    imageCid: '',
                    programDetails: ''
                };
                setImageUrl('');
                setPayload(clearedPayload);
                props.onCancel();
                AllToasts.success('Course added successfully');
            }).catch((e) => {
            setLoading(false);
            AllToasts.failure('Fail to add');
            console.log("error", e);
        });
    };

    const updateCourse = (courseId: string, payload: CoursePayload) => {
        setLoading(true);
        CourseRepo.updateCourses(courseId, payload)
            .then(() => {
                setLoading(false);
                const clearedPayload: CoursePayload = {
                    name: "",
                    description: "",
                    program: [],
                    amount: 0,
                    annualAmount: 0,
                    freeTrialPeriod: 0,
                    imageCid: '',
                    programDetails: ''
                };
                setImageUrl('');
                setPayload(clearedPayload);
                props.onCancel();

                AllToasts.success('Course edited successfully');
            }).catch((e) => {
            setLoading(false);
            AllToasts.failure('Fail to edit');
            console.log("error", e);
        });
    };

    const getSingleCourse = (courseId: string) => {
        CourseRepo.getSingleCourse(courseId).then(res => {
            setPayload({
                name: res.data.name,
                description: res.data.description,
                program: [],
                amount: res.data.amount,
                annualAmount: res.data.annualAmount,
                freeTrialPeriod: res.data.freeTrialPeriod,
                programDetails: res.data.programDetails,
            });
            setImageUrl(res.data.imageUrl);
        })
    }

    useEffect(() => {
        if (props.editId) {
            console.log("71", props.editId);
            getSingleCourse(props.editId);
        } else {
            setPayload({
                name: '',
                description: '',
                program: [],
                amount: 0,
                annualAmount: 0,
                freeTrialPeriod: 0,
                imageCid: '',
                programDetails: ''
            })
        }
    }, [props.editId]);

    const onClose = () => {
        setPayload({
            name: "",
            description: "",
            amount: 0,
            annualAmount: 0,
            freeTrialPeriod: 0,
            program: [],
            imageCid: '',
            programDetails: ''
        })
        setImageUrl('');
        props.onCancel();
        setImageUploadVisible(false);
    }

    const handleToggleImageUpload = () => {
        setImageUploadVisible(!imageUploadVisible);
    };

    const handleOnUploadedFileCid = (cid: string) => {
        setPayload((prev) => ({...prev, imageCid: cid}));
        setUploadedFileCid(cid);
    }

    const handleImage = (url: string) => {
        setImageUrl(url)
    }


    return <div>
        <Modal show={props.show} onHide={onClose} size="lg">
            <Modal.Header closeButton className="border-0 pb-0">
                <h5>{props.editId ? 'Edit Course' : "Add New Course"}</h5>
            </Modal.Header>

            <ModalBody className="modal_body mx-2">
                <form onSubmit={(e) => handleForm(e)}>
                    <div className="form-group">
                        <label htmlFor="exampleInputName">Course <span className='text-danger'>*</span></label>
                        <input type="text"
                               className="form-control"
                               id="exampleInputName"
                               aria-describedby="nameHelp"
                               onChange={(e) =>
                                   setPayload((prevState) => ({
                                       ...prevState,
                                       name: e.target.value
                                   }))}
                               value={payload?.name}
                               placeholder="Enter name"
                               name="name"
                               required/>
                    </div>

                    <div className="form-group mt-2">
                        <label htmlFor="description">Description</label>
                        <textarea className="form-control"
                                  onChange={(e) =>
                                      setPayload((prevState) =>
                                          ({...prevState, description: e.target.value}))}
                                  value={payload?.description}
                                  id="description"
                                  placeholder="Description"
                                  name="description"
                                  rows={4}
                        ></textarea>
                    </div>

                    <div className="form-group mt-2">
                        <label htmlFor="programDetails">Description</label>
                        <textarea className="form-control"
                                  onChange={(e) =>
                                      setPayload((prevState) =>
                                          ({...prevState, programDetails: e.target.value}))}
                                  value={payload?.programDetails}
                                  id="programDetails"
                                  placeholder="Program Details"
                                  name="programDetails"
                                  rows={4}
                        ></textarea>
                    </div>

                    <div className="form-group mt-2">
                        <label htmlFor="amount">Amount <span> (Rs)</span></label>
                        <input type="number" className="form-control"
                               onChange={(e) =>
                                   setPayload((prevState) =>
                                       ({...prevState, amount: e.target.valueAsNumber}))}
                               value={payload?.amount}
                               id="amount"
                               placeholder="Amount"
                               name="amount"
                        />
                    </div>

                    <div className="form-group mt-2">
                        <label htmlFor="annualAmount">Annual Amount <span> (Rs)</span></label>
                        <input type="number" className="form-control"
                               onChange={(e) =>
                                   setPayload((prevState) =>
                                       ({...prevState, annualAmount: e.target.valueAsNumber}))}
                               value={payload?.annualAmount}
                               id="annualAmount"
                               placeholder="Annual Amount"
                               name="annualAmount"
                        />
                    </div>

                    <div className="form-group mt-2">
                        <label htmlFor="freeTrialPeriod">Free Trial Period <span> (days)</span></label>
                        <input type="number" className="form-control"
                               onChange={(e) =>
                                   setPayload((prevState) =>
                                       ({...prevState, freeTrialPeriod: e.target.valueAsNumber}))}
                               value={payload?.freeTrialPeriod}
                               id="freeTrialPeriod"
                               placeholder="Free Trial Period"
                               name="freeTrialPeriod"
                        />
                    </div>

                    <div className={'m-2 mt-2'}>{imageUrl ?
                        <img src={imageUrl} className={'img-fluid'}
                             style={{maxWidth: '700px', maxHeight: '700px'}}/> : ""}
                    </div>

                    <div className='mb-2'>
                        <button
                            type={'button'}
                            onClick={handleToggleImageUpload}
                            className='btn btn-outline-primary'>
                            {imageUploadVisible ? 'Close' : 'Upload Image'}
                        </button>
                    </div>
                    {imageUploadVisible && (
                        <ImageUpload onUploadedFileCid={handleOnUploadedFileCid}
                                     imageUrl={imageUrl}
                                     image={(url) => handleImage(url)}/>
                    )}

                    <div className={'d-flex mt-3'}>
                        <div className={'flex-fill'}></div>
                        <div>
                            <button className="btn btn-secondary me-2" onClick={onClose}>Cancel</button>
                            <Button label={props.editId ? 'Edit Course' : 'Add Course'} loading={loading}
                                    cssName={'btn  btn-primary'}/>

                        </div>
                    </div>
                </form>
            </ModalBody>
        </Modal>
    </div>
}
export default CreateCourse;