import '../../../student/screen/login/LoginStudent.scss';
import {useNavigate} from "react-router-dom";
import React, {ChangeEvent, FormEvent, useState} from "react";
import {useAdminApi} from "../../../../api/AdminApiClient";
import {InputUtils} from "../../../../utils/InputUtils";
import {SessionManager} from "../../../../configs/SessionManager";
import {LoginScope} from "../../../../dto/Payload";
import logo from "../../../../assets/images/aiDronaLogo.png";
import loginScrnImg1 from "../../../../assets/images/Rectangle 98.png";

interface AdminLoginForInput {
    username: string,
    password: string,
    scope: LoginScope
}

const AdminLogin = () => {
    const navigate = useNavigate();
    const [otpSent, setOtpSent] = useState(false);
    const [payload, setPayload] = useState<AdminLoginForInput>({password: '', username: '', scope: LoginScope.Admin});
    const adminApi = useAdminApi();
    const [error, setError] = useState<string>();
    const [otpButtonDisabled, setOtpButtonDisabled] = useState<boolean>(false);
    const [loginButtonDisabled, setLoginButtonDisabled] = useState<boolean>(false);
    const sendOtp = () => {
        setError(undefined);
        setOtpButtonDisabled(true);
        adminApi.sendOtp(payload.username)
            .then(res => {
                setOtpSent(true);
            })
            .catch(err => {
                console.error(err);
                setError("Error sending OTP.");
            })
            .finally(() => {
                setOtpButtonDisabled(false);
            });
    }
    const login = () => {
        setError(undefined);
        setLoginButtonDisabled(true);
        adminApi.login(payload)
            .then(res => {
                SessionManager.createSession(res.data.access_token, '', res.data);
                navigate("/admin");
            })
            .catch(err => {
                console.error(err);
                setError(`Login Error: ${err.message}`);
            })
            .finally(() => {
                setLoginButtonDisabled(false);
            });
    }
    const handleChange = (event: ChangeEvent<HTMLDivElement>) => {
        const inp = InputUtils.parseInput(event);
        setPayload((prevState) => ({...prevState, [inp.name]: inp.value}));
    }
    const submitForm = (evt: FormEvent) => {
        evt.preventDefault();
        if (otpSent) {
            login();
        } else {
            sendOtp();
        }
    }


    return (
        <div id={'main_wrapper'} className="loginScreen_wrapper">
            <div className={'container-fluid'}>
                <div className={'row min-vh-100'}>
                    <div className={'col-md-6'}>
                        <div className={'login-wrap tc_wrap'}>
                            <div className={'form-card'}>
                                <img onClick={() => navigate('/')}
                                     style={{width: '79px', height: '65px', cursor: 'pointer'}} src={logo}
                                     alt="Logo"/>
                                <div className={'login_info_wrap'}>
                                    <h3 className='mb-4 fw-bold'>Welcome to Ai Drona</h3>
                                    <h6 className='mb-4 text-black-50'>Login to your Admin account</h6>
                                    <div className="app-routes">
                                        <form className='mt-5 w-100' onSubmit={submitForm}>
                                            {error ?
                                                <div className={`alert alert-danger`} role="alert">{error}</div> : null}
                                            <div className={`mb-3`}>
                                                <label className='form-label' htmlFor={'phoneNumber'}>Phone Number:
                                                    7252016461 <span className='text-danger'>*</span></label>
                                                <input id={'phoneNumber'} placeholder={`Phone number`}
                                                       className='form-control'
                                                       type='mobile' name={"username"} onChange={handleChange}
                                                       maxLength={10} minLength={10}
                                                       pattern={`[0-9]{10}`}
                                                       onInvalid={(e) => e.currentTarget.setCustomValidity("Please enter a 10-digit phone number.")}
                                                       onInput={(e) => e.currentTarget.setCustomValidity("")}
                                                       required
                                                />
                                            </div>
                                            {otpSent ? <div className={`mb-2`}>
                                                <label className='form-label' htmlFor={'otp'}>
                                                    OTP <span className='text-danger'>*</span>
                                                </label>
                                                <input
                                                    id={'otp'} placeholder={`OTP`} className='form-control' type='text'
                                                    name={"password"}
                                                    onChange={handleChange} maxLength={6} minLength={6}
                                                    pattern={`[0-9]{6}`}
                                                    onInvalid={(e) => e.currentTarget.setCustomValidity("Please enter a 6-digit OTP.")}
                                                    onInput={(e) => e.currentTarget.setCustomValidity("")}
                                                    required
                                                />
                                            </div> : null}
                                            <div className={'loginBtn mt-5'}>
                                                <button
                                                    className='btn btn-otp w-100 text-white'
                                                    disabled={otpButtonDisabled || loginButtonDisabled}
                                                    onClick={submitForm}
                                                >
                                                    {otpSent ? "Login Now" : "Send OTP"}
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={'col-md-6 right-side_tc'}>
                        <img src={loginScrnImg1} height={580} alt="Login Screen"/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdminLogin;
