import React, {useEffect, useRef} from 'react';
import Chart, {ChartConfiguration} from 'chart.js/auto';

interface PerformanceChartProps {
    width: number;
    height: number;
    subjectName: string;
    percentage: number;
    programIds: number[];
    backgroundColor: string;
    percentageColor: string;
    subjectColor: string;
    onClick?: (programIds: number[], subjectName: string) => void;
}

const PerformanceChart: React.FC<PerformanceChartProps> = ({
                                                               width,
                                                               height,
                                                               subjectName,
                                                               percentage,
                                                               programIds,
                                                               backgroundColor,
                                                               percentageColor,
                                                               subjectColor,
                                                               onClick,
                                                           }) => {
    const chartRef = useRef<HTMLCanvasElement | null>(null);
    const chartInstanceRef = useRef<Chart | null>(null);

    const total = 100;
    const score = percentage;

    useEffect(() => {
        // Destroy the previous chart instance, if it exists
        if (chartInstanceRef.current) {
            chartInstanceRef.current.destroy();
        }

        if (chartRef.current) {
            const ctx = chartRef.current.getContext('2d');

            if (ctx) {
                const chartData = total > 0 ? [score, total - score] : [0, 0];
                const centerTextDoughnut = {
                    id: 'centerTextDoughnut',
                    afterDatasetsDraw(chart: any, args: any, pluginOptions: any) {
                        const {ctx} = chart;
                        ctx.font = 'bold 14px sans-serif';
                        ctx.textAlign = 'center';
                        ctx.textBaseline = 'middle';
                        const text = `${percentage}%`;
                        ctx.fillStyle = percentageColor;
                        const x = chart.getDatasetMeta(0).data[0].x;
                        const y = chart.getDatasetMeta(0).data[0].y;
                        ctx.fillText(text, x, y);
                    },
                };

                const chartConfig: ChartConfiguration = {
                    type: 'doughnut',
                    data: {
                        labels: [`${percentage}%`, ''],
                        datasets: [
                            {
                                data: chartData,
                                backgroundColor: total > 0 ? [backgroundColor, '#E4E4E4'] : ['#0F9D57', 'lightgray'],
                                borderRadius: 5,
                                borderWidth: 0
                            },
                        ],
                    },
                    options: {
                        cutout: '85%' as any,
                        plugins: {
                            legend: {
                                position: 'bottom',
                                display: false,
                            },
                            tooltip: {
                                callbacks: {
                                    // Customize tooltip to include programIds
                                    label: (tooltipItem: any) => {
                                        if (tooltipItem.datasetIndex === 0) {
                                            return `${tooltipItem.label} `;
                                        }
                                        return '';
                                    },
                                },
                                enabled: true,
                                intersect: false,
                                mode: 'index',
                            },
                        },
                        onClick: (event: any, elements: any) => {
                            // Handle click event and pass the programIds to the callback
                            if (onClick && elements[0]) {
                                const clickedProgramIds = programIds;
                                onClick(clickedProgramIds, subjectName);
                            }
                        },
                    } as any,
                    plugins: [centerTextDoughnut]
                };

                chartInstanceRef.current = new Chart(ctx, chartConfig);
            }
        }
    }, [total, score, width, height, subjectName, percentage, programIds, onClick, backgroundColor, percentageColor, subjectColor]);

    return (
        <div>
            {/*<div*/}
            {/*    className="d-flex flex-column align-items-center justify-content-center m-auto "*/}
            {/*    style={{width: width, height: height }}*/}
            {/*>*/}
            {/*    /!*<canvas ref={chartRef}></canvas>*!/*/}
            {/*</div>*/}
            {/*<div className="d-flex align-items-center justify-content-center">{percentage}%</div>*/}
            <div className="d-flex align-items-center justify-content-center mt-2"
                 style={{color: subjectColor, cursor: 'pointer'}}>
                <h6 className="text-capitalize"
                    style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                    {subjectName}
                </h6>
            </div>

        </div>
    );
};

export default PerformanceChart;
