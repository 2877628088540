import React, {useEffect, useState, useRef} from "react";
import {useStudentApiClient} from "../../../apiClients/StudentApiClient";
import {TestPayload} from "../PreviousTest";
import Pagination from "../../../../../components/pagination/Pagination";
import {useParams} from "react-router-dom";
import debounce from "lodash/debounce";
import AssignmentTable from "./TestTable";

export enum AssignmentMode {
    TEST = 'TEST',
    ASSIGNMENT = 'ASSIGNMENT',
    PRACTICE = 'PRACTICE',

}

const AllTest = () => {
    const params = useParams();
    const studentApiClient = useStudentApiClient();
    const [test, setTestPayload] = useState<TestPayload[]>([]);
    const [pageSize, setPageSize] = useState<number>(10);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [totalElements, setTotalElements] = useState<number>(0);
    const [selectedOption, setSelectedOption] = useState<AssignmentMode>(AssignmentMode.TEST);
    const [previousAssignment, setPreviousAssignment] = useState<any>()

    const debouncedGetPreviousTests = useRef(
        debounce((type?: string, testType?: string, size?: number, page?: number) => {
            studentApiClient.getPreviousTest(params.courseBatchId ?? null as any, testType, size, page).then(res => {
                setTestPayload(res.data.content);
                setCurrentPage(res.data.pageable.pageNumber)
                setTotalPages(res.data.totalPages)
                setTotalElements(res.data.totalElements)
                setPageSize(res.data.pageable.pageSize);
            }).catch((err) => {
                console.log(err);
            });
        }, 50)).current;

    const getPreviousTestForStudent = (courseId: number, testType?: string, page?: number, size?: number) => {
        studentApiClient.getStudentPreviousTest(Number(params.courseBatchId), testType, page, size).then((res) => {
            setPreviousAssignment(res.data.content);
            setCurrentPage(res.data.pageable.pageNumber)
            setTotalPages(res.data.totalPages)
            setTotalElements(res.data.totalElements)
            setPageSize(res.data.pageable.pageSize);
        }).catch((err) => {
            console.log(err);
        });
    };

    useEffect(() => {
        if (params['*'] === 'Assignment') {
            setSelectedOption(AssignmentMode.ASSIGNMENT);
            getPreviousTestForStudent(Number(params.courseBatchId));
        } else if (params['*'] === 'Practice') {
            setSelectedOption(AssignmentMode.PRACTICE);
            debouncedGetPreviousTests('PRACTICE', 'PRACTICE');
        } else if (params['*'] === 'Test') {
            setSelectedOption(AssignmentMode.TEST,);
            debouncedGetPreviousTests('TEST', 'TEST');
        }
    }, [params, debouncedGetPreviousTests]);

    const handleOptionClick = (options: string) => {
        if (options === AssignmentMode.TEST) {
            setSelectedOption(AssignmentMode.TEST);
            debouncedGetPreviousTests('TEST', 'TEST');
        } else if (options === AssignmentMode.ASSIGNMENT) {
            setSelectedOption(AssignmentMode.ASSIGNMENT,);
            getPreviousTestForStudent(Number(params.courseBatchId));
        } else if (options === AssignmentMode.PRACTICE) {
            setSelectedOption(AssignmentMode.PRACTICE);
            debouncedGetPreviousTests('PRACTICE', 'PRACTICE');
        }
    };
    const generatePagination = () => {
        if (totalPages > 0) {
            return (
                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    totalElements={totalElements}
                    onPageChange={(page) => {
                        if (selectedOption === AssignmentMode.TEST) {
                            debouncedGetPreviousTests('TEST', 'TEST', pageSize, page);
                        } else if (selectedOption === AssignmentMode.ASSIGNMENT) {
                            getPreviousTestForStudent(Number(params.courseBatchId), undefined, page, pageSize);
                        } else if (selectedOption === AssignmentMode.PRACTICE) {
                            debouncedGetPreviousTests('PRACTICE', 'PRACTICE', pageSize, page);
                        }
                    }}
                />
            );
        }
        return null;
    };

    return <div>
        <div className={'p-2'}>
            <div className={'h-100 container d-flex flex-column'}>
                <div className={'mt-3'}>
                    <div className={'d-flex justify-content-between'}>
                        <h6 className={'fw-bold'}>Previous {params['*'] === 'assignment' ? 'Assignment' : 'Test'}</h6>
                        <div className={'d-flex justify-content-center'}>
                            <button
                                onClick={() => handleOptionClick(AssignmentMode.TEST)}
                                className={`px-3 text-center ${selectedOption === 'TEST' ? 'btn-add text-white' : 'bg-light text-black'}`}>
                                Test
                            </button>
                            <button
                                onClick={() => handleOptionClick(AssignmentMode.ASSIGNMENT)}
                                className={`px-3 text-center ${selectedOption === 'ASSIGNMENT' ? 'btn-add text-white' : 'bg-light text-black'}`}>
                                Assignment
                            </button>
                            <button
                                onClick={() => handleOptionClick(AssignmentMode.PRACTICE)}
                                className={`px-3 text-center ${selectedOption === 'PRACTICE' ? 'btn-add text-white' : 'bg-light text-black'}`}>
                                Practice test
                            </button>
                        </div>
                    </div>
                    <div className={'table-responsive my-4'}>
                        <AssignmentTable
                            data={selectedOption === 'TEST' ? test : (selectedOption === 'ASSIGNMENT' ? previousAssignment : test)}
                            selectedOption={selectedOption}/>
                    </div>
                </div>
                {generatePagination()}
            </div>
        </div>
    </div>
};

export default AllTest;
