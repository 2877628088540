import "./header.scss";
import {Navbar} from "react-bootstrap";
import {logOut} from "../../../configs/AxiosClient";
import {ConfirmationDialog} from "../../confirmationDialog/ConfirmationDialog";
import React, {useEffect, useState} from "react";
import {SessionManager} from "../../../configs/SessionManager";
import {useTeacherApiClient} from "../../../modules/teacher/apiClients/TeacherApiClient";
import {useStudentApiClient} from "../../../modules/student/apiClients/StudentApiClient";
import {useAdminProfileApiClient} from "../../../modules/admin/apiClients/AdminProfileApiClient";
import {useNavigate} from "react-router-dom";
import {useAuth} from "../../../context/UserContext";
import {
    useDataEntryQuestionsApiClient
} from "../../../modules/dataEntryExecutive/apiClients/UseDataEntryQuestionsApiClient";

export const Header = (props: { onClick?: (isCollapsed: boolean) => void, collapsedSidebar?: boolean }) => {
    const AdminProfileApiClient = useAdminProfileApiClient();
    const dataEntryQuestionApiClient = useDataEntryQuestionsApiClient();
    const studentApiClient = useStudentApiClient();
    const teacherApiClient = useTeacherApiClient();
    const navigate = useNavigate();
    const {saveUserData} = useAuth();
    const {userData} = useAuth();
    const [imageError, setImageError] = useState(false);
    const [showDelete, setShowDelete] = useState<boolean>(false);
    const [image, setImage] = useState<any>()


    useEffect(() => {
        setImage(userData.imageUrl)
    }, [userData]);

    useEffect(() => {
        const user: any = SessionManager.getSessionUser();
        if (user.role == 'Student') {
            studentApiClient.getStudentProfile().then(res => {
                SessionManager.setSessionUser(res.data.user);
                saveUserData(res.data.user);
            }).catch(err => {
                console.log('err', err)
            })
        } else if (user.role == 'Teacher') {
            teacherApiClient.getTeacherProfile().then(res => {
                SessionManager.setSessionUser(res.data.user);
                saveUserData(res.data.user);
            }).catch(err => {
                console.log('err', err)
            })
        } else if (user.role == 'Admin') {
            AdminProfileApiClient.getAdminProfile().then(res => {
                SessionManager.setSessionUser(res.data.user);
                saveUserData(res.data.user);
            }).catch(err => {
                console.log('err', err)
            })
        } else if (user?.user?.role == 'DataEntryExecutive') {
            dataEntryQuestionApiClient.getDataEntryExecutiveProfile().then(res => {
                SessionManager.setSessionUser(res.data.user);
                saveUserData(res.data.user)
            }).catch(err => {
                console.log('err', err)
            })
        }
    }, []);

    const onAction = (isConfirm: boolean) => {
        if (!isConfirm) {
            setShowDelete(false)
        } else {
            logOut();
        }
    }

    const getInitials = (data: any) => {
        if (data && typeof data === 'string') {
            let initials: any = data.split('');
            if (initials.length >= 2)
                return initials[0].substring(0, 1).toUpperCase() + initials[1].substring(0, 1).toUpperCase();
        }
        return '';
    }

    const handleLogoutClick = () => {
        setShowDelete(true);
    };

    const handleImageError = () => {
        setImageError(true);
    };

    return <header>
        {showDelete && (
            <div className="overlay">
                <ConfirmationDialog
                    title={'Logout'}
                    description={'Are you sure you want to logout?'}
                    show={showDelete}
                    onAction={onAction}
                    onClose={() => setShowDelete(false)}
                />
            </div>
        )}

        <Navbar id={'header'}
                className={`${props.collapsedSidebar ? 'bg-white shadow-sm' : 'headerCollapsed bg-white shadow-sm'}`}>
            <div className={`${props.collapsedSidebar ? 'container-fluid' : 'container-fluid'}`}>
                <Navbar.Brand>
                    <button className="btn btn-link btn-sm order-1 order-lg-0 ps-0" id="sidebarToggle" type={"button"}
                            onClick={() => {
                                props.onClick!(!props.collapsedSidebar);
                                console.log('#21', props.collapsedSidebar);
                            }}>
                        <span></span>
                    </button>
                    Hi!{' '}
                    {SessionManager.isLoggedIn()
                        ? SessionManager.getSessionUser()?.name
                        : ''}
                </Navbar.Brand>

                <Navbar.Collapse className="justify-content-end">
                    {SessionManager.getSessionUser()?.role === 'Unknown' ? "" :
                        <Navbar.Text className={'d-flex align-items-center'}>
                            <i className="bi bi-bell-fill me-3"></i>
                            {imageError ?
                                <div className={'initials'} style={{cursor: "pointer"}} onClick={() =>
                                    navigate(`/${SessionManager.getSessionUser()?.role.toLowerCase()}/profile`)
                                }>{getInitials(SessionManager.getSessionUser()?.name)}</div>
                                :
                                <img

                                    src={
                                        image
                                        ?? 'https://upload.wikimedia.org/wikipedia/commons/a/ac/No_image_available.svg'
                                    }
                                    onError={handleImageError}
                                    onClick={() =>
                                        navigate(`/${SessionManager.getSessionUser()?.role.toLowerCase()}/profile`)
                                    }
                                    className="teacherimage border me-2"
                                    alt=""
                                    style={{cursor: 'pointer'}}
                                />
                            }
                        </Navbar.Text>
                    }
                    <button className="btn" onClick={handleLogoutClick}>
                        <i className="bi bi-box-arrow-right"></i>
                    </button>
                </Navbar.Collapse>
            </div>
        </Navbar>
    </header>
}