import React, {useEffect, useState} from "react";
import Pagination from "../../../../../components/pagination/Pagination";
import {InitialDataState, NetworkState, PromiseData} from "../../../../../modals/PromiseData";
import {CourseRepo} from "../../../../../repos/CourseRepo";
import {EmptyState} from "../../../../../components/emptyState/EmptyState";
import AssignmentShimmer from "../../../../../components/shimmer/assignment/AssignmentShimmer";
import {useNavigate} from "react-router-dom";
import {useAdminApiClient} from "../../../../admin/apiClients/AdminApiClient";
import {ProgramRepo} from "../../../../../repos/ProgramRepo";
import {BatchAssignment} from "../../courseListing/course/assignment/BatchAssignment";
import {Courses} from "../../../../../dto/Courses";
import {SelectedQuestionType} from "../createTest/createAssignment/CreateAssignment";

interface Course {
    value: string;
    label: string;
}

interface AssignmentList {
    id:number,
    name: string,
    courses: Courses[],
}

export const TeacherListAssignments = () => {
    const [assignments, setAssignments] = useState<PromiseData<AssignmentList[]>>(InitialDataState);
    const [compAssignments, setCompAssignments] = useState<PromiseData<AssignmentList[]>>(InitialDataState);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(10);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [totalElements, setTotalElements] = useState<number>(0);
    const [query, setQuery] = useState<string>("");
    const [filter, setFilter] = useState<boolean>(false);
    const [courses, setCourses] = useState<Courses[]>([]);
    const [selectedCourseId, setSelectedCourseId] = useState<number | undefined>(undefined);
    const [programs, setPrograms] = useState<{ id: string, name: string }[]>([]);
    const [selectedProgramId, setSelectedProgramId] = useState<number | undefined>(undefined);
    const [filterApplied, setFilterApplied] = useState<boolean>(false);
    const [showEditor, setShowEditor] = useState<boolean>(false);
    const [assignmentId, setAssignmentId] = useState<number | undefined>(0);
    const [courseId, setCourseId] = useState<number | undefined>(undefined);
    const [selectedQuestionType, setSelectedQuestionType] = useState<SelectedQuestionType>(SelectedQuestionType.General);
    const navigation = useNavigate();
    const adminApiClient = useAdminApiClient();

    useEffect(() => {
        if (!filterApplied) {
            if (selectedQuestionType === SelectedQuestionType.Comprehension) {
                getAllAssignmentsComprehension(currentPage, pageSize, query, selectedCourseId, selectedProgramId, true);
            } else if (selectedQuestionType === SelectedQuestionType.General) {
                getAllAssignment(currentPage, pageSize, query, selectedCourseId, selectedProgramId);
            }
        } else {
            applyFilters();
        }
    }, [filterApplied]);

    useEffect(() => {
        getAllCourses();
    }, []);

    useEffect(() => {
        if (selectedCourseId !== undefined || selectedCourseId !== '') {
            getPrograms()
        }
    }, [selectedCourseId]);

    useEffect(() => {
        const delayTimer = setTimeout(() => {
            if (selectedQuestionType === SelectedQuestionType.Comprehension) {
                getAllAssignmentsComprehension(0, pageSize, query, selectedCourseId, selectedProgramId, true);
            } else if (selectedQuestionType === SelectedQuestionType.General) {
                getAllAssignment(0, pageSize, query, selectedCourseId, selectedProgramId);
            }
        }, 300);
        return () => {
            clearTimeout(delayTimer);
        };
    }, [query]);

    const getAllCourses = (page?: number, size?: number, query?: string) => {
        adminApiClient
            .getAllCourses(page, size, query)
            .then((res: any) => {
                setCourses(res?.data);
            })
            .catch((err: any) => {
                console.log(err);
            });
    };

    const getPrograms = () => {
        if (selectedCourseId !== undefined) {
            ProgramRepo.getAllPrograms(selectedCourseId)
                .then((res) => {
                    setPrograms(res?.data);
                })
                .catch((err) => {
                    console.log('Err', err);
                });
        } else {
            setPrograms([]);
        }
    };


    const getAllAssignment = (page: number, size: number, query?: string, course?: number, program?: number, comp?: boolean) => {
        setAssignments({state: NetworkState.Loading});
        CourseRepo.getAllAssignmentOfCourse(page, size, query, course, program, comp)
            .then((res) => {
                setAssignments({state: NetworkState.Success, data: res?.data?.content});
                setCurrentPage(res?.data?.number);
                setTotalPages(res?.data?.totalPages);
                setTotalElements(res?.data?.totalElements);
                setPageSize(size);
                setCourseId(res.data?.content?.course?.id);
            })
            .catch((err) => {
                console.log("Err", err);
                setAssignments({state: NetworkState.Failed, data: err.message});
            });
    };

    const getAllAssignmentsComprehension = (page: number, size: number, query?: string, course?: number, program?: number, comp?: boolean) => {
        setCompAssignments({state: NetworkState.Loading});
        CourseRepo.getAllAssignmentOfCourse(page, size, query, course, program, comp)
            .then((res) => {
                setCompAssignments({state: NetworkState.Success, data: res?.data?.content});
                setCurrentPage(res?.data?.number);
                setTotalPages(res?.data?.totalPages);
                setTotalElements(res?.data?.totalElements);
                setPageSize(size);
                setCourseId(res.data?.content?.course?.id);
            })
            .catch((err) => {
                console.log("Err", err);
                setCompAssignments({state: NetworkState.Failed, data: err.message});
            });
    };

    const applyFilters = () => {
        setFilterApplied(true);
        if (selectedCourseId !== undefined) {
            if (selectedQuestionType === SelectedQuestionType.Comprehension) {
                getAllAssignmentsComprehension(0, pageSize, query, selectedCourseId, selectedProgramId, true);
            } else if (selectedQuestionType === SelectedQuestionType.General) {
                getAllAssignment(0, pageSize, query, selectedCourseId, selectedProgramId);
            }
        }
    };

    const handleToggle = () => {
        setFilter(!filter);
    };

    const handleCourseChange = (option: any) => {
        setSelectedCourseId(option.target.value)
    };

    const handleProgramChange = (option: any) => {
        setSelectedProgramId(option.target.value)
    };

    const renderColumns = () => {
        const columns = [];
        for (let i = 0; i < 10; i++) {
            columns.push(
                <tr key={i}>
                    <td><AssignmentShimmer/></td>
                </tr>
            );
        }
        return columns;
    };

    const handleQuestionSelectClick = (options: SelectedQuestionType) => {
        setSelectedQuestionType(options);
        // setSelectedCourse([])
        setCurrentPage(0);
        setTotalPages(0)
        if (options === SelectedQuestionType.Comprehension) {
            getAllAssignmentsComprehension(0, pageSize, query, selectedCourseId, selectedProgramId, true);
        } else if (options === SelectedQuestionType.General) {
            getAllAssignment(0, pageSize, query, selectedCourseId, selectedProgramId);
        }
    };

    return (
        <div>
            <div className="pt-4 m-auto container">
                <div className="row page-titles mb-3">
                    <div className="col-sm-6 p-md-0">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 list-unstyled ps-0">
                                <li className="breadcrumb-item"><a href="/teacher"
                                                                   className="text-decoration-none text-secondary"> Dashboard </a>
                                </li>
                                <li className="breadcrumb-item text-black" aria-current="page">Assignment List</li>
                            </ol>
                        </nav>
                    </div>
                    <div className="col-sm-6 justify-content-sm-end d-flex">
                        <button className="float-end btnNewAdd"
                                onClick={() => navigation(`/teacher/createTest`)}>Create New
                            Assignment
                        </button>
                    </div>
                </div>
                <div className={"card border-0 shadow-sm"}>
                    <div className={'card-head shadow-sm p-3 p-sm-20 p-md-25'}>
                        <div className={"d-md-flex justify-content-between align-items-center"}>
                            <div className="form-group searchBar position-relative">
                                <input
                                    type={"search"}
                                    className="form-control w-100 formControl"
                                    placeholder={"Search here ..."}
                                    onChange={(event) => setQuery(event.target.value)}
                                />
                                <button className={'bg-transparent transition p-0 border-0'}><i
                                    className="bi bi-search"></i></button>
                            </div>
                            <div className={"d-flex align-items-center"}>

                                <div className={"ms-3 cursor-pointer"} onClick={handleToggle}>
                                    <button><span
                                        className='d-flex align-items-center justify-content-center border border-2 p-2 rounded-3'>
                                        <i className="bi bi-funnel me-2"></i> Filters
                                    </span></button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"card-body"}>
                        {filter && (
                            <div className={'row border-bottom mb-4'}>
                                <h4 className='mb-2'>Filters</h4>
                                <div className="d-flex align-items-center justify-content-start mb-3">
                                    <div className='col-4 me-2'>
                                        <select className={'form-select rounded-2 p-2'} onChange={handleCourseChange}>
                                            <option value="">Select Course</option>
                                            {courses.map((course) => (
                                                <option key={course.id} value={course.id}>{course.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className='col-4 me-2'>
                                        <select className={'form-select rounded-2 p-2'} onChange={handleProgramChange}>
                                            <option value="">Select Programs</option>
                                            {programs.map((program) => (
                                                <option key={program.id} value={program.id}>{program.name}</option>
                                            ))}
                                        </select>
                                    </div>

                                    <div className={'col d-flex justify-content-end'}>
                                        <button className="btnRemove"
                                                onClick={applyFilters}
                                        >
                                            Apply Filter
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className='mb-2'>
                            <button
                                onClick={() => handleQuestionSelectClick(SelectedQuestionType.General)}
                                className={` p-2 text-center me-2 ${selectedQuestionType === SelectedQuestionType.General ? 'btnNewAdd text-white' : 'btnRemove'}`}
                                style={{cursor: 'pointer'}}>
                                General
                            </button>
                            <button
                                onClick={() => handleQuestionSelectClick(SelectedQuestionType.Comprehension)}
                                className={` p-2 text-center me-2 ${selectedQuestionType === SelectedQuestionType.Comprehension ? 'btnNewAdd text-white' : 'btnRemove'}`}
                                style={{cursor: 'pointer'}}>
                                Comprehension
                            </button>
                        </div>
                        <div>
                            {selectedQuestionType === SelectedQuestionType.General &&
                                <div>
                                    {assignments.state === NetworkState.Loading ? (
                                        renderColumns()
                                    ) : assignments.state === NetworkState.Success ? (
                                        (assignments?.data?.length ?? 0) > 0 ? (
                                            <table className="table table-hover">
                                                <thead>
                                                <tr>
                                                    <th scope="col">Assignment Name</th>
                                                    <th scope="col">Course Name</th>
                                                    <th scope="col">Assign batch</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {assignments.data?.map((assignment: any) => (
                                                    <tr key={assignment?.id}>
                                                        <td onClick={() => navigation(`/teacher/assignments/${assignment.id}`)}
                                                            style={{cursor: 'pointer'}}>
                                                            {assignment?.name ? assignment?.name : "---"}
                                                        </td>
                                                        <td>{assignment?.course?.name}</td>
                                                        <td
                                                            onClick={() => {
                                                                setAssignmentId(assignment?.id)
                                                                setCourseId(assignment?.course?.id);
                                                                setShowEditor(true);
                                                            }}
                                                            className="text-dark" style={{cursor: 'pointer'}}>
                                                            <i className="bi bi-pencil-square fs-5"/>
                                                        </td>
                                                        <td onClick={() => navigation(`/teacher/assignment/${assignment.id}/${assignment.name}`)}>
                                                            <i className="bi bi-arrow-right-circle"></i>
                                                        </td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </table>
                                        ) : (
                                            <div><EmptyState/></div>
                                        )
                                    ) : (
                                        <div>Failed to load</div>
                                    )}
                                </div>
                            }
                            <div>
                                {selectedQuestionType === SelectedQuestionType.Comprehension &&
                                    <div>
                                        {compAssignments.state === NetworkState.Loading ? (
                                            renderColumns()
                                        ) : compAssignments.state === NetworkState.Success ? (
                                            (compAssignments.data?.length ?? 0) > 0 ? (
                                                <table className="table table-hover">
                                                    <thead>
                                                    <tr>
                                                        <th scope="col">Assignment Name</th>
                                                        <th scope="col">Course Name</th>
                                                        <th scope="col">Assign batch</th>
                                                        <th scope="col">Action</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {compAssignments.data?.map((assignment: any) => (
                                                        <tr key={assignment.id}>
                                                            <td
                                                                onClick={() => navigation(`/teacher/assignments/${assignment.id}/${selectedQuestionType}`)}
                                                                style={{cursor: 'pointer'}}>
                                                                {assignment?.name ? assignment?.name : "---"}
                                                            </td>
                                                            <td>{assignment?.course?.name}</td>
                                                            <td
                                                                onClick={() => {
                                                                    setAssignmentId(assignment?.id)
                                                                    setCourseId(assignment.course?.id);
                                                                    setShowEditor(true);
                                                                }}
                                                                className="text-dark" style={{cursor: 'pointer'}}>
                                                                <i className="bi bi-pencil-square fs-5"/>
                                                            </td>
                                                            <td onClick={() => navigation(`/teacher/assignment/${assignment.id}/${assignment.name}`)}>
                                                                <i className="bi bi-arrow-right-circle"></i>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                    </tbody>
                                                </table>
                                            ) : (
                                                <div><EmptyState/></div>
                                            )
                                        ) : (
                                            <div>Failed to load</div>
                                        )}
                                    </div>
                                }
                            </div>

                        </div>

                        {showEditor && (
                            <BatchAssignment
                                assignmentId={assignmentId}
                                courseId={courseId ?? 0}
                                // id={selectedCourse && selectedCourse[0].value ? selectedCourse[0].value : null}
                                id={courseId}
                                show={true}
                                onCancel={() => {
                                    setShowEditor(false);
                                    if (selectedQuestionType === SelectedQuestionType.Comprehension) {
                                        getAllAssignmentsComprehension(currentPage, pageSize, query, selectedCourseId, selectedProgramId, true);
                                    } else if (selectedQuestionType === SelectedQuestionType.General) {
                                        getAllAssignment(currentPage, pageSize, query,selectedCourseId,selectedProgramId);
                                    }
                                }}
                                title={"Assign to batch"}
                                assignments={assignments}
                            />
                        )}
                        {selectedQuestionType === SelectedQuestionType.General && totalPages > 1 && (
                            <Pagination currentPage={currentPage} totalPages={totalPages}
                                        onPageChange={(page) => getAllAssignment(page, pageSize, query, selectedCourseId, selectedProgramId)}
                                        totalElements={totalElements}/>
                        )}
                        {selectedQuestionType === SelectedQuestionType.Comprehension && totalPages > 1 && (
                            <Pagination currentPage={currentPage} totalPages={totalPages}
                                        onPageChange={(page) => getAllAssignmentsComprehension(page, pageSize, query, selectedCourseId, selectedProgramId, true)}
                                        totalElements={totalElements}/>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};
