import Select from "react-dropdown-select";
import {SubjectsData} from "../../../../admin/components/AdminSubjects";
import React, {ChangeEvent} from "react";
import {Questions} from "../../../../teacher/components/modals/CreateiUpdateQuestion";
import {QuestionType} from "../../../../../modals/quizquestionData/Modal";

export interface SelectDropDownProps {
    subjects: SubjectsData[];
    topics: any[]
    handleSubjectChange: (selectedSubject: { label: string, value: string }[]) => void
    selectedSubject: { value: string, label: string }[]
    show: boolean
    handleTopicsChange: (selectedTopics: { label: string, value: number }[]) => void
    selectedOptionTopic: { value: string, label: string }[]
    handleDropDownSelect: (event: ChangeEvent<HTMLSelectElement>) => void
    handleChange: (event: any) => void
    payload: Questions
    selectedQuestionType: QuestionType | undefined;
    setShow: () => void;
}

export const SelectDropDown = (props: SelectDropDownProps) => {
    return <div>
        <div className='d-flex'>
            <div className="mb-2 w-50 me-4">
                <label className="form-label" htmlFor={'subjects'}>Subject<span
                    className='ms-1 text-danger'>*</span></label>
                <Select
                    key={props.subjects.map((res) => res.id).toString()}
                    options={props.subjects?.map((subject: SubjectsData) => ({
                        value: subject.id.toString(),
                        label: subject.name
                    }))}
                    onChange={(selectedSubject) => props.handleSubjectChange(selectedSubject)}
                    values={props.selectedSubject || []}
                    placeholder="Select Subjects"
                    required
                    disabled={!props.show}
                />
            </div>
            <div className="mb-2 w-50 me-4">
                <label className="form-label" htmlFor={'topics'}>Topics<span
                    className='ms-1 text-danger'>*</span></label>
                <Select
                    key={props.topics.map((res) => res.id).toString()}
                    options={props.topics?.map((topic: any) => ({
                        value: topic.id.toString(),
                        label: topic.name
                    }))}
                    onChange={(selectedTopics) => props.handleTopicsChange(selectedTopics)}
                    values={props.selectedOptionTopic || []}
                    placeholder="Select Topics"
                    multi
                    required
                    disabled={!props.show}
                />
            </div>
            <div className="mb-2 d-flex align-items-center w-50">
                <div className='d-flex flex-row align-items-center w-100'>
                    <div className='w-100'>
                        <label className="form-label" htmlFor={'type'}>Question Type<span
                            className='ms-1 text-danger'>*</span></label>
                        <select disabled={!props.show} required={true}
                                id="type"
                                className={`form-select w-100 ${props.show ? 'bg-white' : ' bg-light'}`}
                                onChange={(event) => {
                                    props.handleDropDownSelect(event);
                                    props.handleChange(event);
                                }}
                                value={props.payload.type ?? props.selectedQuestionType} name="type">
                            <option value="">Select</option>
                            <option value={'MultiSelect'}>MultiSelect</option>
                            <option value={'OneAnswer'}>One Answer</option>
                            <option value={'TrueFalse'}>True/False</option>
                            <option value={'OneWord'}>One Word</option>
                        </select>
                    </div>
                </div>
                <div className='mt-4 ms-3'>
                    <i onClick={() => props.setShow()} className='bi bi-pencil-square fs-5 cursorPointer'/>
                </div>
            </div>
        </div>
    </div>
}