import QuestionList from "../../components/createAssignment/createTest/questionList/QuestionList";
import {InitialDataState, NetworkState, PromiseData} from "../../../../modals/PromiseData";
import React, {useEffect, useState} from "react";
import {TeacherCreateQuestions} from "../../modal/CreateQuestions";
import {useTeacherApiClient} from "../../apiClients/TeacherApiClient";
import {useNavigate, useParams} from "react-router-dom";
import Pagination from "../../../../components/pagination/Pagination";
import {EmptyState} from "../../../../components/emptyState/EmptyState";
import Skeleton from "react-loading-skeleton";
import {Passage} from "../../../../dto/CreateAssignmentQuestionListing";
import {
    CreateAssignmentQuesListing
} from "../../components/createAssignment/createTest/createAssignmentComprehensionList/CreateAssignmentQuesListing";

export const AssignmentQuestionListing = () => {
    const [question, setQuestion] = useState<PromiseData<TeacherCreateQuestions[]>>(InitialDataState);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(10);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [totalElements, setTotalElements] = useState<number>(0);
    const [expandedQuestion, setExpandedQuestion] = useState<{ id: number | null; expanded: boolean }>({
        id: null, expanded: false,
    });
    const [query, setQuery] = useState<string>('');
    const [comprehensionQue, setComprehensionQue] = useState<Passage[]>([])
    const teacherApiClient = useTeacherApiClient();
    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (params.Comprehension === 'Comprehension') {
            getAllFilteredCompQuestions(currentPage,pageSize,[],[parseInt(params?.assignmentId ?? '')])
        } else {
            getAllQuestions(currentPage, pageSize, [], query, true, [],
                undefined, undefined, undefined, undefined, undefined, undefined, undefined, [parseInt(params?.assignmentId ?? '')])
        }
    }, [currentPage, pageSize]);

    const getAllQuestions = (page?: number, size?: number, sort?: string[], query?: string, includeActive?: boolean,
                             testId?: number[], courseId?: number, flagged?: true, programId?: number
        , chapterId?: number[], topicId?: number[], subject?: number[], verified?: string, assignmentId?: number[]) => {
        setQuestion({state: NetworkState.Loading});
        teacherApiClient
            .getAllQuestions(page, size, [], query, includeActive, testId, courseId, flagged, programId, chapterId, topicId, subject, verified, assignmentId
            )
            .then((res) => {
                setQuestion({state: NetworkState.Success, data: res.data.content});
                setCurrentPage(res.data.number);
                setTotalPages(res.data.totalPages);
                setTotalElements(res.data.totalElements);
                setPageSize(pageSize);
            })
            .catch((err) => {
                setQuestion({state: NetworkState.Failed, message: err.message});
                console.error(err);
            });
    };

    const getAllFilteredCompQuestions = (page?: number, size?: number, sort?: [], assignmentId?: number[]) => {
        teacherApiClient.getAllFilteredComprehensions(page, size, sort, assignmentId).then((res) => {
            setComprehensionQue(res.data.content)
            setPageSize(res.data.pageable.pageSize);
            setTotalPages(res.data.totalPages);
            setCurrentPage(res.data.pageable.pageNumber);
            setTotalElements(res.data.totalElements);
        }).then((err) => {
            console.log(err)
        })
    }

    const handleQuestionClick = (questionId: number) => {
        if (expandedQuestion.id === questionId) {
            setExpandedQuestion({id: null, expanded: false});
        } else {
            setExpandedQuestion({id: questionId, expanded: true});
        }
    };
    const renderColumns = () => {
        const columns = [];
        for (let i = 0; i < 10; i++) {
            columns.push(
                <table key={i}>
                    <tbody>
                    <tr>
                        <Skeleton height={60} width={1200}/>
                    </tr>
                    </tbody>
                </table>
            );
        }
        return columns;
    };
    return <div className={"pt-4 m-auto container"}>
        <div className="row page-titles mx-0 shadow-sm ">
            <div className="col-sm-6 p-md-0">
                <h4>Assignment - {parseInt(params?.assignmentId ?? '')}</h4>
            </div>
            <div className="col-sm-6 d-flex justify-content-end">
                <h6 onClick={() => navigate('/teacher/assignments')} style={{cursor: 'pointer'}}>
                    <i className="bi bi-arrow-bar-left me-2"></i>Back
                </h6>
            </div>
        </div>
        <div>
            {params.Comprehension === 'Comprehension' ?
                <div>
                    <CreateAssignmentQuesListing
                        key={comprehensionQue?.map((res) => res.id).toString()}
                        comprehensionQue={comprehensionQue}
                        getAllFilteredCompQuestions={getAllFilteredCompQuestions}
                        // selectedOption={selectedOption}
                    />
            </div>
            :
            <div>
                {
                    question.data?.length ?? 0 > 0 ? (
                        <div className={'mt-4'}>
                            {question.state === NetworkState.Loading ? (
                                renderColumns()
                            ) : question.state === NetworkState.Success ? (
                                <QuestionList
                                    questions={question.data as TeacherCreateQuestions[]}
                                    handleQuestionClick={handleQuestionClick}
                                    getAllQuestionsCreateAssignment={getAllQuestions}
                                    expandedQuestion={expandedQuestion}
                                    currentPage={currentPage}
                                    pageSize={pageSize}
                                />
                            ) : (
                                <EmptyState/>
                            )}
                        </div>
                    ) : (
                        question.state === NetworkState.Loading ? (
                            renderColumns()
                        ) : (
                            <EmptyState/>
                        )
                    )
                }
            </div>
            }
        </div>

        {totalPages > 1 && (
            <div className='me-sm-2'>
                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={(page) => {
                        if (params.Comprehension === 'Comprehension') {
                            getAllFilteredCompQuestions(page, pageSize, [], [parseInt(params?.assignmentId ?? '')]);
                        } else {
                            getAllQuestions(page, pageSize, [], query, undefined, [], undefined, undefined, undefined, undefined, undefined, undefined, undefined, [parseInt(params?.assignmentId ?? '')]);
                        }
                    }}
                    totalElements={totalElements}
                />
            </div>
        )}
    </div>
}
export default AssignmentQuestionListing;