import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import AddTopic from "./AddTopic";
import {ChapterRepo} from "../../../../../../repos/ChapterRepo";
import {AllToasts} from "../../../../../toast/AllToasts";
import Button from "../../../../../button/Button";
import {EmptyState} from "../../../../../emptyState/EmptyState";
import {ConfirmationDialog} from "../../../../../confirmationDialog/ConfirmationDialog";
import {CourseRepo} from "../../../../../../repos/CourseRepo";
import {ProgramRepo} from "../../../../../../repos/ProgramRepo";
import {EditTopicModal} from "./EditTopicModal";
import {SessionManager} from "../../../../../../configs/SessionManager";

export const CourseTopics = () => {
    const params = useParams();
    const navigate = useNavigate();
    const [topics, setTopics] = useState<any>([]);
    const [showEditor, setShowEditor] = useState<boolean>(false);
    const [confirmation, setConfirmation] = useState<boolean>(false);
    const [targetTopic, setTargetTopic] = useState<string>('');
    const [data, setData] = useState<any>('');
    const [courseData, setCourseData] = useState<any>('');
    const [programData, setProgramData] = useState<any>('');
    const [editTopic, setEditTopic] = useState<boolean>(false);
    const [role,setRole] = useState<string>('')

    useEffect(() => {
        getCourseData(params.courseId ?? '');
    }, [params.courseId]);

    useEffect(() => {
        getProgramId(params.programId ?? '');
    }, [params.programId]);

    useEffect(() => {
        getAllTopics(params?.chapterId || '');
        getChapterData(params?.chapterId || '');
    }, [params?.chapterId]);

    const getCourseData = (courseId: string) => {
        CourseRepo.getSingleCourse(courseId).then((res: any) => {
            setCourseData(res.data);
        })
    }

    const getProgramId = (programId: string) => {
        ProgramRepo.getSingleProgram(programId).then((res) => {
            setProgramData(res.data);
        });
    };

    const getChapterData = (chapterId: string) => {
        ChapterRepo.getSingleChapter(chapterId).then((res: any) => {
            setData(res.data);
        })
    }
    const getAllTopics = (chapterId: string) => {
        ChapterRepo.getAllChapterTopics(chapterId).then((res: any) => {
            setTopics(res.data);
        }).catch((err: any) => {
            console.log('Err', err);
        })
    }

    const onAction = (isConfirm: boolean) => {
        if (!isConfirm) {
            setConfirmation(false)
        } else {
            deleteTopic();
        }
    }

    const deleteTopic = () => {
        ChapterRepo.deleteChapterTopic(params?.chapterId || '', targetTopic).then(() => {
            AllToasts.success('Item deleted successfully.');
            getAllTopics(params?.chapterId || '');
        }).catch(() => {
            AllToasts.failure('Fail to delete.');
        }).finally(() => {
            setConfirmation(false);
        })
    }

    useEffect(() => {
        let role = SessionManager.getSessionUser()?.role ?? ''
        setRole(role);
    }, [SessionManager]);

    return <div className={'container-fluid page-content'}>
        <div className="row page-titles mx-0 shadow-sm">
            <div className="col-sm-6 p-md-0">
                <h4>Topics</h4>
            </div>
            {role === 'Admin' ?
                <div className="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item d-flex text-primary" aria-current="page"
                            onClick={() => navigate(`/admin/courses/${params.courseId}`)}
                            style={{cursor: 'pointer'}}>{courseData?.name}</li>
                        <li className="breadcrumb-item d-flex text-primary" aria-current="page"
                            onClick={() => navigate(`/admin/courses/${params.courseId}/programs/${params.programId}`)}
                            style={{cursor: 'pointer'}}>{programData?.name}</li>
                        <li className="breadcrumb-item d-flex " aria-current="page">{data?.name}</li>
                    </ol>
                </div>
                :
                <div className="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item d-flex text-primary" aria-current="page"
                            onClick={() => navigate(`/teacher/courses/${params.courseId}`)}
                            style={{cursor: 'pointer'}}>{courseData?.name}</li>
                        <li className="breadcrumb-item d-flex text-primary" aria-current="page"
                            onClick={() => navigate(`/teacher/courses/${params.courseId}/programs/${params.programId}`)}
                            style={{cursor: 'pointer'}}>{programData?.name}</li>
                        <li className="breadcrumb-item d-flex " aria-current="page">{data?.name}</li>
                    </ol>
                </div>
            }
        </div>
        <div className=" p-md-0 justify-content-end mt-2 mt-sm-0 d-flex">
            <Button label={'+ Add Topics'} cssName={'btn btn-primary text-white rounded-1 btn-add'} type={'button'}
                    onClick={() =>
                        setShowEditor(true)
                    }/>
        </div>

        <div className={'card mt-4 border-0 shadow-sm p-2'}>
            <div className={"card-body"}>
                <table className={`table table-hover`}>
                    <thead className={'thead'} style={{color: 'black'}}>
                    {topics?.length ?
                        <tr className={''}>
                            <th scope="col">Topic</th>
                            <th scope="col">Created By</th>
                            <th scope="col">Created Date</th>
                            <th scope="col">Action</th>
                        </tr>
                        :
                        <EmptyState/>
                    }
                    </thead>
                    <tbody>
                    {
                        topics?.map((topic: any) => (
                            <tr className={'m-2'}>
                                <td>{topic?.name ?? " "}</td>
                                <td>{topic?.createdBy ?? " "}</td>
                                <td>{topic?.createdDate ?? " "}</td>
                                <td>
                                    <button onClick={() => {
                                        setTargetTopic(topic.id);
                                        setEditTopic(true)
                                    }}>
                                        <i className={'bi bi-pencil'}></i>
                                    </button>
                                    <button onClick={() => {
                                        setTargetTopic(topic.id);
                                        setConfirmation(true);
                                    }}><i className="bi bi-trash text-danger ms-2"></i></button>

                                </td>
                            </tr>))}
                    </tbody>

                </table>
            </div>
        </div>

        <ConfirmationDialog title={'Delete Topic'} description={"Are you sure you want to delete this?"}
                            show={confirmation} onAction={onAction}
                            onClose={() => setConfirmation(false)}/>
        <EditTopicModal show={editTopic} topicId={Number(targetTopic) ?? 0} onHide={() => {
            setEditTopic(false)
            getAllTopics(params?.chapterId || '');
        }}/>
        {showEditor === true &&
            <AddTopic id={params?.chapterId || ''} show={true} onSuccess={() => {
                setShowEditor(false);
                getAllTopics(params?.chapterId || '');
            }} onCancel={() => {
                setShowEditor(false);
            }} title={'Add New Topic'}/>}

    </div>
}