import {Route, Routes} from "react-router-dom";
import React from "react";
import {DataEntryDashboard} from "./dashboard/DataEntryDashboard";
import {Header} from "../../../components/common/header/Header";
import Sidebar from "../../../components/common/sidebar/Sidebar";
import {CreateQuestion} from "./components/createQuestions/CreateQuestion";

export const DataEntryExecutiveIndex = () =>{
    return <div className='bg-light h-100'>
        <Header/>
        <Routes>
            <Route path={"/"} element={<DataEntryDashboard />} />
            <Route path={"/createQuestion"} element={<CreateQuestion />} />
            <Route path={"/editQuestion/:questionId"} element={<CreateQuestion />} />
        </Routes>
    </div>
}