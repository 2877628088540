import axios, {AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import {SessionManager} from "./SessionManager";
import {SpringToken} from "../modals/Model";
import {AccountRepo} from "./AccountRepo";
import {API_BASE_URL} from "../common/AppConstants";


let isRefreshing:boolean = false,
    failedQueue: { resolve: (value: unknown) => void; reject: (reason?: any) => void; }[] = [];
const axiosClient: AxiosInstance = axios.create({
    baseURL: API_BASE_URL,
    adapter: require('axios/lib/adapters/http')
});

axiosClient.interceptors.request.use((config:AxiosRequestConfig | any) : Promise<AxiosRequestConfig> => {
        // config.headers[client-id] ='100'
        if(SessionManager.isLoggedIn()) {
            config.headers['Authorization'] =`Bearer ${SessionManager.getToken()}`;
        }
        return config;
    },
    error => error
)

axiosClient.interceptors.response.use(function (response : AxiosResponse) {
    return response;
}, async function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
        console.log('AxiosClient 50Line',isRefreshing);
        if (isRefreshing) {
            return new Promise((resolve, reject) => {
                failedQueue.push({resolve, reject})
            }).then(token => {
                originalRequest.headers.Authorization = 'bearer ' + token;
                failedQueue = [];
                isRefreshing = false;
                return axiosClient(originalRequest);
            }).catch(err => {
                return Promise.reject(err);
            });
        }
        originalRequest._retry = true;
        isRefreshing = true;
        return await AccountRepo.refreshAccessToken(SessionManager.getRefreshToken() || "").then((res: SpringToken) => {
            SessionManager.updateSession(res.access_token, res.refresh_token);
            originalRequest.headers.Authorization = 'bearer ' + res.access_token;
            processQueue(null, res.access_token);
            return axiosClient(originalRequest);
        }).catch((refreshError: AxiosError) => {
            /* When the Referesh tokken expired */
            if (401 === refreshError.response?.status) {
                processQueue(refreshError, null);
                logOut();
                return Promise.reject(refreshError);
            }
            return Promise.reject(refreshError);
        }).finally(() => { isRefreshing = false; });
    }
    return Promise.reject(error);
});

export enum APIs {
    ADMIN_SEND_OTP,
    ADMIN_LOGIN,
}

export const useAxios = () => {
    return axiosClient;
}

const processQueue = (error: AxiosError | null, token: string | null) => {
    failedQueue.forEach(callback => {(error) ? callback.reject(error) : callback.resolve(token)});
    failedQueue = [];
}

export const logOut = () => {
    SessionManager.clearSession();
    window.location.reload();
    // window.history.pushState(null, "", "/");
}
