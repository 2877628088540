import React, {useEffect, useState} from 'react';
import {useAdminApiClient} from '../../apiClients/AdminApiClient';
import {AllToasts} from '../../../../components/toast/AllToasts';
import {useNavigate, useParams} from 'react-router-dom';
import ReadMore from "./ReadMore";
import moment from "moment/moment";
import './StudentEvaluationCommentsModal.scss'

export interface CommentPayload {
    comment: string;
}

interface AdminGetComment {
    id: number,
    comment: string
    createdDate: string
}

export const StudentEvalutionCommentsModal = () => {
    const [comment, setComment] = useState<CommentPayload>({comment: ''});
    const [previousComments, setPreviousComments] = useState<AdminGetComment[]>([]);
    const [displayedCommentsCount, setDisplayedCommentsCount] = useState(5);
    const adminApiClient = useAdminApiClient();
    const params = useParams();

    useEffect(() => {
        getAllComment(Number(params.studentId), Number(params.courseId));
    }, [params]);

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const {name, value} = event.target;
        setComment((prevState) => ({...prevState, [name]: value}));
    };

    const createComment = (studentId: number, courseId: number, payload: CommentPayload) => {
        adminApiClient
            .createCommentForStudent(studentId, courseId, payload)
            .then((res) => {
                AllToasts.success('Submitted');
                setComment({comment: ''});
                getAllComment(studentId, courseId);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const getAllComment = (studentId: number, courseId: number) => {
        adminApiClient
            .getAllComments(studentId, courseId)
            .then((res) => {
                setPreviousComments(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleSubmission = () => {
        createComment(Number(params.studentId), Number(params.courseId), comment);
    };

    const handleLoadMore = () => {
        setDisplayedCommentsCount(prevCount => prevCount + 5);
    };

    return (
        <div className={'container'}>
            <div className='card shadow-sm px-2 pt-2 mt-2 d-flex flex-row align-items-center justify-content-between'
                 style={{borderColor: '#EFF0F6'}}>
                <h5 className='mt-2 mb-3'>Comment</h5>
                <h6 className='me-2' onClick={() => window.history.back()} style={{cursor: 'pointer'}}>
                    <i className="bi bi-arrow-bar-left me-2"></i>Back
                </h6></div>
            <div className='card shadow-sm px-2 pt-2 mt-2' style={{borderColor: '#EFF0F6'}}>
            <div>
                    <div className="mb-3">
                        <label htmlFor="comment" className="form-label">
                            <h6>Add Comments:</h6>
                        </label>
                        <textarea
                            name='comment'
                            className="form-control bg-light"
                            id='comment'
                            rows={4}
                            value={comment.comment}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div>
                    <div className="d-flex align-items-center justify-content-end mb-2">
                        <button
                            className="btn btn-primary"
                            onClick={handleSubmission}
                            disabled={!comment.comment.trim()}
                        >
                            Submit
                        </button>
                    </div>
                </div>
            </div>
            <div className='mt-4'>
                {previousComments.length > 0 && (
                    <div className='card  shadow-sm px-2 pt-2' style={{borderColor: '#EFF0F6'}}>
                        <table>
                            <thead>
                            <tr>
                                <th className={'previousComment'}>Previous Comments</th>
                                <th className={'createdDate'}>Created Date</th>
                            </tr>
                            </thead>
                            <tbody>
                            {previousComments.slice(0, displayedCommentsCount).map((prevComment) => (
                                <tr key={prevComment.id}>
                                    <td>
                                        <div className="card previousCommentCard shadow-sm px-2 pt-2"
                                             style={{borderColor: '#EFF0F6', marginBottom: '10px'}}>
                                            <ReadMore>
                                                {prevComment.comment}
                                            </ReadMore>
                                        </div>
                                    </td>
                                    <td>{moment(prevComment.createdDate).format('DD/MM/YYYY')}</td>
                                </tr>
                            ))}
                            {previousComments.length > displayedCommentsCount && (
                                <tr>
                                    <td>
                                        <div className="d-flex justify-content-center mt-3 mb-3 ms-xxl-5">
                                            <button className="btn btn-primary" onClick={handleLoadMore}>Load More
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            )}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        </div>
    );
};
