import React, {useEffect, useState} from "react";
import {useStudentApiClient} from "../../../../../student/apiClients/StudentApiClient";
import "./ReportDropdown.css";

export interface FlagData {
    id: number;
    name: string;
}

interface ReportDropdownProps {
    onSelectOption: (selectedFlag: FlagData) => void;
    showDropdown: boolean;
    flagData: FlagData[];
}

const ReportDropdown: React.FC<ReportDropdownProps> = ({onSelectOption, showDropdown, flagData}) => {
    const [flags, setFlags] = useState<FlagData[]>([{id: 0, name: '',}]);
    const studentApiClient = useStudentApiClient();

    const fetchFlags = () => {
        studentApiClient.getFlags()
            .then((res) => {
                setFlags(res.data);
            })
            .catch((error) => {
                console.error('Error fetching flags', error);
            });
    };

    useEffect(() => {
        // Check if flagData is undefined or an empty array before making the API call
        if (flagData === undefined || flagData.length === 0) {
            fetchFlags();
        }
    }, [flagData]);

    //
    // const onSelectFlag = (flag: FlagData) => {
    //     console.log('Flag selected:', flag);
    //     onSelectOption(flag);
    // };

    return (
        <div className="report-dropdown-container">
            {showDropdown && (
                <div className="dropdown">
                    <div className="dropdown-list">
                        {flagData ?
                            (
                                flagData?.map((flag) => (
                                    <div
                                        key={flag.id}
                                        onClick={() => {
                                            onSelectOption(flag)
                                        }}
                                        className="dropdown-item"
                                    >
                                        {flag.name}
                                    </div>
                                ))
                            ) : flags.map((flag) => (
                                <div
                                    key={flag.id}
                                    onClick={() => {
                                        onSelectOption(flag)
                                    }}
                                    className="dropdown-item"
                                >
                                    {flag.name}
                                </div>
                            ))}

                    </div>
                </div>
            )}
        </div>
    );
};

export default ReportDropdown;
