import React, {useEffect, useState} from "react";
import './Quiz.css';
import "./Question.scss"
import {PostData, PostDataSelect, Question, ViewType} from "../../../../../modals/quizquestionData/Modal";
import {QuizQuestion} from "./QuizQuestion";
import {QuestionData} from "../quizStepper/QuizStepper";
import {useStudentApiClient} from "../../../apiClients/StudentApiClient";
import {AllToasts} from "../../../../../components/toast/AllToasts";
import ReportDropdown, {FlagData} from "../../../../admin/components/subjects/subjectCard/subjectTopic/ReportDropdown";
import {Dropdown, Offcanvas} from "react-bootstrap";
import {QuizMode} from "../../../../../modals/quizquestionData/Modal";
import {MathX} from "../../../../../components/math/MathX";
import SubmitButton from "../submitButton/SubmitButton";
import {useParams} from "react-router-dom";
import {buildStyles, CircularProgressbar} from "react-circular-progressbar";

export interface FlagPayload {
    flagReasonId: number;
}

export interface QuestionsProps {
    currentQuestionIndex: number,
    question: Question;
    nextQuestion: (questionId: number) => void;
    previousQuestion: () => void;
    onSummaryClick?: () => void;
    showSummary?: boolean;
    selectedOptionId?: number[],
    answer?: string,
    currentComprehensionText?: string
    currentComprehensionIndex?: number
    currentComprehensionImage?: string
    questionData?: QuestionData[],
    onBookMark?: (questionId: number) => void
    onLiked?: (questionId: number) => void,
    highlightedQuestionId?: number,
    onValidate?: (question: Question) => void,
    totalQuestionCount?: number,
    quizView: string,
    reviewScreen?: string,
    exit?: () => void,
    isExplanationVisible?: boolean,
    onPostDataChange?: (postData: PostData) => void,
    onTestSubmit?: () => void,
    attemptedOption?: number
    setShowExplanation?: (isVisible: boolean) => void;
}

const Questions = (props: QuestionsProps) => {
    const [showSidebar, setShowSidebar] = useState<boolean>(false);
    const [showFlagDropdown, setShowFlagDropdown] = useState<boolean>(false);
    const [selectedFlagReason, setSelectedFlagReason] = useState<number | null>(null);
    const studentApiClient = useStudentApiClient();
    // const [currentOption, setCurrentOption] = useState<PostDataSelect>()
    const [showExplanation, setShowExplanation] = useState<boolean>(false);
    const [attemptedQuestions, setAttemptedQuestions] = useState<PostDataSelect[]>([]);
    const [flags, setFlags] = useState<FlagData[]>([{id: 0, name: '',}]);
    const [likedQuestions, setLikedQuestions] = useState<number[]>([]);
    const params = useParams()

    useEffect(() => {
        if (props.quizView === ViewType.REVIEW) {
            setShowExplanation(true)
        } else if (!props.isExplanationVisible) {
            setShowExplanation(false)
        }
    }, [params, showExplanation, props.isExplanationVisible]);

    useEffect(() => {
        fetchFlags();
    }, []);


    useEffect(() => {
        const initialFlagPayload: FlagPayload = {
            flagReasonId: 1,
        };
        submitFlags(props.question.id, initialFlagPayload);
    }, []);

    useEffect(() => {
        if (selectedFlagReason !== null) {
            const payload: FlagPayload = {
                flagReasonId: selectedFlagReason,
            };
            submitFlags(props.question.id, payload);
        }
    }, [selectedFlagReason]);

    const handleSelectOption = (selectedFlag: FlagData) => {
        setSelectedFlagReason(selectedFlag.id);
    };

    const submitFlags = (questionId: number, payload: FlagPayload) => {
        if (selectedFlagReason != null) {
            payload.flagReasonId = selectedFlagReason;
            studentApiClient.submitFlag(String(questionId), payload)
                .then((res) => {
                    setShowFlagDropdown(false);
                    AllToasts.success('Flag Raised Successfully');
                    setSelectedFlagReason(null);
                })
                .catch(() => {
                    AllToasts.failure('Failed to Raise the Flag')
                });
        }
    };

    const fetchFlags = () => {
        studentApiClient.getFlags()
            .then((res) => {
                setFlags(res.data);
            })
            .catch((error) => {
                console.error('Error fetching flags', error);
            });
    };

    const handleNext = (id: number) => {
        setShowExplanation(false);
        props.nextQuestion(id)
        //review and on last question then exit
        if (props.quizView === ViewType.REVIEW && props.currentQuestionIndex === (props.questionData?.length ?? 0) - 1) {
            if (props.exit)
                props.exit();
        }
    }

    const handleLikeToggle = (questionId: any) => {
        if (likedQuestions.includes(questionId)) {
            // Question is liked, so unlike it
            unlikeQuestion(questionId);
            setLikedQuestions((prevLiked) => prevLiked.filter((id) => id !== questionId));
        } else {
            // Question is not liked, so like it
            likeQuestion(questionId);
            setLikedQuestions((prevLiked) => [...prevLiked, questionId]);
        }
    };

    const likeQuestion = (questionId: number) => {
        studentApiClient.likeQuestion(questionId).then((res) => {
        }).catch((err) => console.log(err));
    };

    const unlikeQuestion = (questionId: number) => {
        studentApiClient.unLikeQuestion(questionId).then((res) => {
        }).catch((err) => console.log(err));
    };
    const handleSidebarToggle = () => {
        setShowSidebar(!showSidebar);
    };
    const formatText = (text:string) => {
        if (!text) return "No question available";
        return text.split('\n').map((item, index) => (
            <span key={index}>
                {item}
                <br />
            </span>
        ));
    };
    const totalQuestions = props.questionData?.length ?? 0;
    const value = props.currentQuestionIndex + 1;
    const percentage = (value / totalQuestions) * 100;

    return (
        <div className='question_wrap bg-white px-2 py-2 rounded'>
            <div className="">
                <div className={'flex-column'}>
                    <div className={'d-flex py-2'}>
                        <div className={'flex-fill'}>
                            <h4 className={'d-flex align-items-center'}>
                                <div className={'me-3 progress_bar'} style={{ width: '100px', height: '100px' }}>
                                    <CircularProgressbar
                                        value={percentage}
                                        className={"fw-medium"}
                                        text={`${value}/${totalQuestions}`}
                                        styles={buildStyles({
                                            rotation: 0,
                                            strokeLinecap: 'butt',
                                            textSize: '30px',
                                            pathTransitionDuration: 0.5,
                                            pathColor: `#3498DB`,
                                            textColor: '#3498DB',
                                            trailColor: '#d6d6d6',
                                            backgroundColor: '#3498DB',
                                        })}
                                    />
                                </div>
                                <strong>QUESTION
                                    {/*{props.currentQuestionIndex + 1}/{props?.questionData?.length ?? 0}*/}
                                </strong>
                            </h4>
                        </div>
                        {props.currentComprehensionText &&
                            <div className='d-flex align-items-center justify-content-center cursorPointer text-primary'
                                 onClick={handleSidebarToggle}>C{props.currentComprehensionIndex ? props.currentComprehensionIndex + 1 : 1} Passage
                            </div>
                        }
                        <div className="d-flex align-items-center">
                            <Dropdown
                                show={showFlagDropdown}
                                onClick={() => setShowFlagDropdown(true)}
                                className={'choice_dropdown no-caret'}
                                onToggle={(isOpen: boolean, meta: any) => {
                                    if (!isOpen) {
                                        setShowFlagDropdown(false);
                                    }
                                }}
                            >
                                <Dropdown.Toggle id="dropdown-basic" className={'bg-transparent border-0 '}  >
                                    <i className="bi bi-question-circle  fs-4 cursor-pointer"></i>
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {showFlagDropdown && (
                                        <ReportDropdown
                                            onSelectOption={handleSelectOption}
                                            showDropdown={showFlagDropdown}
                                            flagData={flags}
                                        />
                                    )}
                                </Dropdown.Menu>
                            </Dropdown>

                            {
                                props?.showSummary ?? false ?
                                    <div className={'instruction_icon'}>
                                        <i className="bi bi-file-earmark-text fs-4 cursor-pointer me-3"
                                           onClick={props.onSummaryClick}></i>
                                    </div> : <div></div>
                            }
                            {props.questionData && props.onBookMark ? <div className={'d-flex'}>
                                    <div className={'instruction_icon'}>
                                        <i className="bi bi-file-earmark-text fs-4 cursor-pointer me-3"
                                           onClick={props.onSummaryClick}></i>
                                    </div>
                                    <div className={'bookmark_icon'}>
                                        <i
                                            className={`bi ${props.questionData[props.currentQuestionIndex].bookMark ? "bi-bookmark-fill bookmarkIcon" : "bi-bookmark"} fs-4 cursor-pointer`}
                                            onClick={() => {

                                                if (props.onBookMark)
                                                    props.onBookMark(props.question.id)
                                            }}
                                        ></i>
                                    </div>
                                    <div className={'fav_icon'}>
                                        <i className={`bi ${props.questionData[props.currentQuestionIndex].like ? "bi-heart-fill likeIcon" : "bi-heart"} fs-4 ms-2`}
                                           onClick={() => {
                                               if (props.onLiked)
                                                   props?.onLiked(props.question.id)
                                           }}></i>
                                    </div>

                                </div>
                                : ''}

                            <div className='d-flex'>
                                {params['*'] === `PRACTICE/${params.testId}` || params['*'] === `TEST/${params.testId}` || params['*'] === `ASSIGNMENT/${params.testId}` ||
                                params['*'] === `PRACTICE/${params.testId}/comp` || params['*'] === `TEST/${params.testId}/comp` || params['*'] === `ASSIGNMENT/${params.testId}/comp`
                                    ? "" :
                                    <div className={'fav_icon'} style={{cursor: 'pointer'}}>
                                        <i
                                            className={`bi ${likedQuestions.includes(props.question.id) ? "bi-heart-fill text-danger likeIcon" : "bi-heart"} fs-4 ms-2`}
                                            onClick={() => {
                                                if (props.question.id) {
                                                    handleLikeToggle(props.question.id);
                                                } else {
                                                    console.error("props.question.id is undefined");
                                                }
                                            }}
                                        ></i>

                                    </div>
                                }
                            </div>

                        </div>
                    </div>
                    <div className={'mt-3 d-flex flex-column ms-1'}>
                        {props.question?.text ?
                            <span key={props.question.id}
                                  style={{wordBreak: 'break-word'}}><MathX>{formatText(props.question?.text)}</MathX></span> : null}
                        <div>
                            {props.question?.imageUrl &&
                                <img className={'mt-2'} style={{maxHeight: "700px", maxWidth: "700px"}}
                                     src={props.question.imageUrl} alt={'NA'}/>}
                        </div>
                    </div>
                </div>
                <div className='pt-2'>
                    <QuizQuestion key={props.question.id} selectedOptionId={props.selectedOptionId}
                                  question={props.question}
                                  highlightedQuestionId={props.highlightedQuestionId} quizView={props.quizView}
                                  onPostDataChange={(postData) => {
                                      if (props.onPostDataChange) {
                                          props.onPostDataChange(postData)
                                          setAttemptedQuestions((prev) =>
                                              ([...prev, postData]))
                                      }
                                  }} answer={props?.answer ?? ''}
                        // handleSelectOptions={}
                                  attemptedOption={props?.attemptedOption ?? -1}/>
                </div>
            </div>

            {/*logic for handle Mock screen*/}
            <div>
                {(props.quizView === QuizMode.PRACTISE || params['*'] === `TEST/review/${params.resultId}` || params['*'] === `Test/review/${params.resultId}`
                        || params['*'] === `Practise/review/${params.resultId}` || params['*'] === `PRACTICE/review/${params.resultId}`
                        || params['*'] === `ASSIGNMENT/review/${params.resultId}` || params['*'] === `Assignment/review/${params.resultId}`
                    )
                    && (
                        <div className={'position-relative'}>
                            <div className={'d-flex mt-3'}>
                                <div className={'flex-fill'}>
                                    <div>
                                        {props.question.explanation && (
                                            <div>
                                                {showExplanation && (
                                                    <h6 className={`explanation-header`}>Explanation</h6>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className={'col-2'}>
                                    {(props.quizView === ViewType.REVIEW)
                                        ? "" :
                                        <button
                                            type={'button'}
                                            className={'mb-2'}
                                            id={(attemptedQuestions.findIndex(
                                                (attemptedQuestion) => attemptedQuestion.question.id === props.question.id
                                            ) !== -1) ? 'validateBtn' : 'faded'}
                                            onClick={() => {
                                                if (props.onValidate) {
                                                    props.onValidate(props.question);
                                                    setShowExplanation(true);
                                                }
                                            }}
                                            disabled={!((attemptedQuestions.findIndex((attemptedQuestion) =>
                                                attemptedQuestion.question.id === props.question.id)) !== -1)}>
                                            Validate
                                        </button>
                                    }
                                </div>
                            </div>
                            <div>
                                {props.question.explanation && (
                                    <div>
                                        {showExplanation && (
                                            <div className={'position-relative explanation-box'}>
                                                <p><MathX>{formatText(props.question.explanation)}</MathX></p>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
            </div>

            {/*{props.question.explanation && (*/}
            {/*    <div>*/}
            {/*        {(props.reviewScreen === 'review') ?*/}
            {/*            <div className={'position-relative'}>*/}
            {/*                <div className={'d-flex mt-3'}>*/}
            {/*                    <div className={'flex-fill'}>*/}
            {/*                        <h6 className={`explanation-header`}>Explanation</h6>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*                <div className={'position-relative explanation-box'}>*/}
            {/*                    <p>{props.question?.explanation}</p>*/}
            {/*                </div>*/}
            {/*            </div> : null*/}
            {/*        }*/}
            {/*    </div>*/}
            {/*)}*/}

            <div className='questionBtn sticky-bottom-btns mt-5 d-flex align-items-center'>
                    <button
                        type='button'
                        className='ms-3 btn btn-outline me-3' style={{borderColor:'#3498DB', color:'#3498DB'}}
                        onClick={() => {
                            setShowExplanation(false);
                            props.previousQuestion();
                        }}
                        disabled={props.currentQuestionIndex === 0}>
                        <i className="bi bi-arrow-left me-2"></i>Previous
                    </button>

                    {(((props.currentQuestionIndex + 1 === props.questionData?.length) ) && (props.quizView !== ViewType.REVIEW)) && (
                           <SubmitButton onClick={() => {
                               if (props.onTestSubmit)
                                   props.onTestSubmit();
                           }}
                                         currentQuestionIndex={props.currentQuestionIndex}
                                         questionData={props.questionData}/>
                    )}

                {(props.quizView === ViewType.REVIEW && props.currentQuestionIndex + 1 === props.questionData?.length) && (
                    <button
                        type='button'
                        className={`btn btn-primary me-3`}
                        style={{ backgroundColor: '#27ae60', borderColor: '#27ae60' }}
                        onClick={() => {
                            if (props.exit) {
                                props.exit();
                            }
                        }}
                    >
                        Exit
                    </button>
                )}

                {props.currentQuestionIndex + 1 !== props.questionData?.length && (
                    <button
                        type='button'
                        className={`btn btn-primary me-3`}
                        style={{ backgroundColor: '#3498DB', borderColor: '#3498DB' }}
                        onClick={() => handleNext(props.question.id)}
                        disabled={props.quizView !== ViewType.REVIEW ? props.currentQuestionIndex === ((props.questionData?.length ?? 0) - 1) : false}
                    >
                        {props.quizView === ViewType.REVIEW ?
                            (props.currentQuestionIndex === ((props.questionData?.length ?? 0) - 1) ? 'Exit' : 'Next') :
                            (props.currentQuestionIndex + 1 === props.questionData?.length) ? null : "Next Question"
                        }
                        {props.currentQuestionIndex + 1 === props.questionData?.length &&
                        (props.currentQuestionIndex + 1 === props.questionData?.length) ?
                            null :
                            (props.currentQuestionIndex + 1 === props.questionData?.length) ?
                                <i className="bi bi-check-lg ms-2"></i> :
                                <i className="bi bi-arrow-right ms-2"></i>
                        }
                    </button>
                )}
            </div>
            <Offcanvas
                placement="end"
                show={showSidebar}
                onHide={() => setShowSidebar(false)}
                style={{width: '40%'}}
            >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>Comprehension</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className={'d-flex flex-column'}>
                        {props.currentComprehensionText &&
                            props.currentComprehensionText
                                ?.split(/\. /)
                                .filter(passage => passage.trim())
                                .map((passage, index) => (
                                    <p key={index}>{passage}.</p>
                                ))
                        }
                        {props.currentComprehensionImage && (
                            <img
                                src={props.currentComprehensionImage}
                                className='img-fluid'
                                style={{
                                    maxWidth: "100%",
                                    height: "auto",
                                    maxHeight: "500px",
                                    width: "600px",
                                    objectFit:'contain'
                                }}
                            />
                        )}
                    </div>
                    <div className={'d-flex align-items-center justify-content-end '}>
                        <button type={'button'} className={'btn btn-primary'}
                                onClick={() => setShowSidebar(false)}>Close
                        </button>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    );
};

export default Questions;
