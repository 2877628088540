import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTeacherApiClient } from "../../../../apiClients/TeacherApiClient";
import { CourseRepo } from "../../../../../../repos/CourseRepo";
import { BatchAssignment } from "./BatchAssignment";
import moment from "moment";
import { InitialDataState, NetworkState, PromiseData } from "../../../../../../modals/PromiseData";
import { EmptyState } from "../../../../../../components/emptyState/EmptyState";
import AssignmentShimmer from "../../../../../../components/shimmer/assignment/AssignmentShimmer";

export const Assignments = () => {
    const [assignments, setAssignments] = useState<PromiseData<any[]>>(InitialDataState);
    const params = useParams();
    const [showEditor, setShowEditor] = useState<boolean>(false);
    const [assignmentId, setAssignmentId] = useState<number | undefined>(0);
    const [courseId, setCourseId] = useState<number | undefined>(0);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(10);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [query, setQuery] = useState<string>("");

    useEffect(() => {
        getAllAssignment(currentPage, pageSize, query, params?.id as any);
    }, [currentPage, pageSize, query, params?.id]);

    const getAllAssignment = (page: number, size: number, query?: string, course?: number) => {
        setAssignments({ state: NetworkState.Loading });
        CourseRepo.getAllAssignmentOfCourse(page, size, query, course)
            .then((res) => {
                setAssignments({ state: NetworkState.Success, data: res.data.content });
                setCurrentPage(res.data.number);
                setTotalPages(res.data.totalPages);
                setPageSize(size);
            })
            .catch((err) => {
                console.log("Err", err);
                setAssignments({ state: NetworkState.Failed, data: err.message });
            });
    };

    const renderColumns = () => {
        const columns = [];
        for (let i = 0; i < 8; i++) {
            columns.push(
                <tr key={i}>
                    <td><AssignmentShimmer /></td>
                </tr>
            );
        }
        return columns;
    };

    const handlePageChange = (newPage: number) => {
        if (newPage >= 0 && newPage < totalPages) {
            setCurrentPage(newPage);
        }
    };

    return (
        <div className="">
            <div className="card border-0 shadow-sm">
                <div className="card-body">
                    <table className="table table-hover">
                        <thead className="thead" style={{ color: "black" }}>
                        <tr>
                            <th scope="col">Assignment</th>
                            <th scope="col">Created Date</th>
                            <th scope="col">Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        {assignments.state === NetworkState.Loading ? (
                            renderColumns()
                        ) : assignments.state === NetworkState.Success ? (
                            assignments.data?.map((assignment: any) => (
                                <tr key={assignment.id}>
                                    <td>{assignment?.name ?? "---"}</td>
                                    <td>
                                        {assignment?.createdDate
                                            ? moment(assignment?.createdDate).format("Do MMM")
                                            : "---"}
                                    </td>
                                    <td>
                                        <button
                                            onClick={() => {
                                                setAssignmentId(assignment.id);
                                                setCourseId(assignment.course.id)
                                                setShowEditor(true);
                                            }}
                                            className="text-dark">
                                            <i className="bi bi-plus-square-dotted"></i>
                                        </button>
                                    </td>
                                </tr>
                            ))
                        ) : null}
                        {assignments.state === NetworkState.Success &&
                            assignments.data?.length === 0 && <EmptyState />}
                        </tbody>
                    </table>
                </div>
            </div>

            {showEditor && (
                <BatchAssignment
                    assignmentId={assignmentId}
                    courseId={courseId}
                    show={true}
                    onCancel={() => {
                        setShowEditor(false);
                        getAllAssignment(currentPage, pageSize, query, params?.id as any);
                    }}
                    title={"Assign to batch"}
                />
            )}

            {assignments.state === NetworkState.Success && totalPages > 1 && (
                <div className="pagination">
                    {Array.from({ length: totalPages }, (_, index) => (
                        <button
                            key={index}
                            className={`page-item ${currentPage === index ? "active" : ""}`}
                            onClick={() => handlePageChange(index)}
                        >
                            {index + 1}
                        </button>
                    ))}
                </div>
            )}
        </div>
    );
};
