import React from "react";


export const EmptyState = () => {


    return <div className={'container'}>
        <div className={'row bg-white py-2 rounded mx-1 chapterHeader align-items-center'}>
            <div className={'mt-5 empty_wrap'}><img className={'w-100'}
                src={'https://cdn.dribbble.com/users/837373/screenshots/19400228/media/714312f1b47a877348dc2976eb541173.png?resize=400x300&vertical=center'}/></div>
        </div>

    </div>
}