import React, {useEffect, useState} from "react";
import {InitialDataState, NetworkState, PromiseData} from "../../../../modals/PromiseData";
import {useTeacherApiClient} from "../../apiClients/TeacherApiClient";
import Skeleton from "react-loading-skeleton";
import QuestionList from "../createAssignment/createTest/questionList/QuestionList";
import {TeacherCreateQuestions} from "../../modal/CreateQuestions";
import {EmptyState} from "../../../../components/emptyState/EmptyState";
import Pagination from "../../../../components/pagination/Pagination";
import {useSubjectsApiClient} from "../../../admin/apiClients/SubjectsApiClient";
import CreateUpdateQuestions from "../createAssignment/createQuestion/CreateUpdateQuestions";
import {Subject, SubjectTopic} from "../modals/CreateiUpdateQuestion";
import {useParams} from "react-router-dom";
import {
    CreateAssignmentQuesListing
} from "../createAssignment/createTest/createAssignmentComprehensionList/CreateAssignmentQuesListing";
import {Passage} from "../../../../dto/CreateAssignmentQuestionListing";
import {SelectedQuestionType} from "../createAssignment/createTest/createAssignment/CreateAssignment";

export enum VerificationData {
    UnVerified = 'UnVerified',
    Verified = 'Verified'
}

export const Verification = () => {
    const [questions, setQuestions] = useState<PromiseData<TeacherCreateQuestions[]>>(InitialDataState);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(10);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [totalElements, setTotalElements] = useState<number>(0);
    const [query, setQuery] = useState<string>('')
    const [subjects, setSubjects] = useState<Subject[]>([]);
    const [editQuestionModal, setEditQuestionModal] = useState<boolean>(false);
    const [selectedOption, setSelectedOption] = useState<VerificationData>(VerificationData.UnVerified);
    const [expandedQuestion, setExpandedQuestion] = useState<{ id: number | null; expanded: boolean }>({
        id: null,
        expanded: true,
    });
    const [selectedSubject, setSelectedSubject] = useState<{ label: string, value: number }[]>([])
    const [selectedTopic, setSelectedTopic] = useState<{ label: string, value: number }[]>([])
    const [topics, setTopics] = useState<SubjectTopic[]>([]);
    const [subjectId, setSelectedSubjectId] = useState<number | undefined>(undefined)
    const [topicId, setSelectedTopicId] = useState<number[]>([]);
    const [selectedQuestionType, setSelectedQuestionType] = useState<string>(SelectedQuestionType.General);
    const [comprehensionQue, setComprehensionQue] = useState<PromiseData<Passage[]>>(InitialDataState)
    const teacherApiClient = useTeacherApiClient();
    const subjectsApiClient = useSubjectsApiClient();
    const params = useParams();

    useEffect(() => {
        getAllSubjects();
    }, []);

    useEffect(() => {
        if (selectedQuestionType === SelectedQuestionType.General) {
            getAllQuestions(currentPage, pageSize, [], query, subjectId, topicId, selectedOption)
        } else if (selectedQuestionType === SelectedQuestionType.Comprehension) {
            getAllQuestionsForComprehensions(topicId, subjectId, selectedOption, query, currentPage, pageSize)
        }
    }, [selectedQuestionType, selectedOption,]);

    useEffect(() => {
        const delayTimer = setTimeout(() => {
            if (selectedQuestionType === SelectedQuestionType.General) {
                getAllQuestions(0, pageSize, [], query, subjectId, topicId, selectedOption)
            } else if (selectedQuestionType === SelectedQuestionType.Comprehension) {
                getAllQuestionsForComprehensions(topicId, subjectId, selectedOption, query, 0, pageSize)
            }
        }, 400);
        return () => {
            clearTimeout(delayTimer);
        };
    }, [query,selectedOption]);

    const getAllSubjects = () => {
        subjectsApiClient.getSubjects().then((res) => {
            setSubjects(res.data);
        });
    };

    const getAllQuestions = (
        page?: number,
        size?: number,
        sort?: string[],
        query?: string,
        subjectId?: number,
        topicIds?: number[],
        verified?: VerificationData
    ) => {
        setQuestions({state: NetworkState.Loading});
        teacherApiClient
            .getVerifiedAndUnverifiedQuestions(page, size, sort, query, subjectId, topicIds, verified)
            .then((res) => {
                setQuestions({state: NetworkState.Success, data: res.data.content});
                setCurrentPage(res.data.number);
                setTotalPages(res.data.totalPages);
                setTotalElements(res.data.totalElements);
                setPageSize(pageSize);
            })
            .catch((err) => {
                setQuestions({state: NetworkState.Failed, message: err.message});
                console.error(err);
            });
    };

    const getAllQuestionsForComprehensions = (topicIds?: number[], subjectId?: number, verification?: VerificationData, query?: string, page?: number, size?: number) => {
        setComprehensionQue({state: NetworkState.Loading})
        teacherApiClient.getAllVerificationComprehensions(topicIds, subjectId, verification, query, page, size).then((res) => {
            setComprehensionQue({state: NetworkState.Success, data: res.data.content});
            setPageSize(res.data.pageable.pageSize);
            setTotalPages(res.data.totalPages);
            setCurrentPage(res.data.pageable.pageNumber);
            setTotalElements(res.data.totalElements);
        }).catch((err) => {
            console.log(err)
            setComprehensionQue({state: NetworkState.Failed, message: err.message})
        })
    }

    const renderColumns = () => {
        const columns = [];
        for (let i = 0; i < 10; i++) {
            columns.push(
                <table key={i}>
                    <tbody>
                    <tr>
                        <Skeleton height={60} width={1200}/>
                    </tr>
                    </tbody>
                </table>
            );
        }
        return columns;
    };

    const handleQuestionClick = (questionId: number) => {
        if (expandedQuestion.id === questionId) {
            setExpandedQuestion({id: null, expanded: false});
        } else {
            setExpandedQuestion({id: questionId, expanded: true});
        }
    };

    const handleOptionClick = (option: VerificationData) => {
        setSelectedOption(option);
        getAllQuestions(0, pageSize, [], '', subjectId, topicId, option)
    };

    const handleSubjectSelect = (subject: { label: string, value: number }[]) => {
        setSelectedTopicId([])
        setSelectedSubject(subject);
        const selectedValue = subject.map((res) => res.value)[0];
        setSelectedSubjectId(isNaN(selectedValue) ? undefined : selectedValue);
        setSelectedTopic([]);
        const id = subject.map((res) => res.value)[0];
        const isTopicSelected = subjects.find((subject: any) => subject.id === id);
        setTopics(isTopicSelected?.topics || []);
    }

    const handleTopicSelect = (topics: { label: string, value: number }[]) => {
        setSelectedTopic(topics);
        const selectedValues = topics.map((res) => res.value);
        setSelectedTopicId(isNaN(selectedValues[0]) ? [] : selectedValues);
    };

    const handleApplyFilters = () => {
        if (selectedQuestionType === SelectedQuestionType.General) {
            getAllQuestions(0, pageSize, [], query, subjectId, topicId, selectedOption)
        } else if (selectedQuestionType === SelectedQuestionType.Comprehension) {
            getAllQuestionsForComprehensions(topicId, subjectId, selectedOption, query, 0, pageSize)
        }
    };

    const handleSelectedOptionChange = (option: VerificationData) => {
        setSelectedOption(option);
    };

    const handleQuestionSelectClick = (options: string) => {
        setExpandedQuestion({id: null, expanded: false});
        setSelectedQuestionType(options);
        setCurrentPage(0); // Reset page to 0 when changing question type
        if (options === SelectedQuestionType.Comprehension) {
            getAllQuestionsForComprehensions(topicId, subjectId, selectedOption, query, 0, pageSize); // Pass the selected query
        } else if (options === SelectedQuestionType.General) {
            getAllQuestions(0, pageSize, [], query, subjectId, topicId, selectedOption); // Pass the selected query
        }
    };

    const renderComprehensionContent = () => {
        if (comprehensionQue.state === NetworkState.Loading) {
            return renderColumns();
        } else if (comprehensionQue.state === NetworkState.Success) {
            if (comprehensionQue.data && comprehensionQue.data.length > 0) {
                return (
                    <CreateAssignmentQuesListing
                        key={comprehensionQue?.data?.map((res) => res?.id).toString()}
                        comprehensionQue={comprehensionQue.data}
                        getAllQuestionsForComprehensions={getAllQuestionsForComprehensions}
                        selectedOption={selectedOption}
                        subjectId={subjectId}
                        topicId={topicId}
                        currentPage={currentPage}
                        pageSize={pageSize}
                    />
                );
            } else {
                return <EmptyState/>;
            }
        } else {
            return <EmptyState/>;
        }
    };

    return (
        <div className={"pt-4 m-auto container"}>
            <div className="row page-titles mx-0 shadow-sm bg-white shadow-sm mb-3 rounded-2 w-100">
                <div className=" d-flex align-items-center justify-content-between w-75">
                    <div className={'mt-4'}>
                        <h4>{params['*'] === `program/${params.programId}/likedQuestions` ? "Liked Questions" : "Verification"}</h4>
                    </div>
                    <div className='d-flex flex-row align-items-center'>
                        <div className='d-flex mt-2 me-2 align-items-center'>
                            <button
                                onClick={() => handleOptionClick(VerificationData.Verified)}
                                className={` p-2 text-center me-2 ${selectedOption === VerificationData.Verified ? 'btnNewAdd text-white' : 'btnRemove'}`}
                                style={{cursor: 'pointer'}}>
                                Verified
                            </button>
                            <button
                                onClick={() => handleOptionClick(VerificationData.UnVerified)}
                                className={` p-2 text-center me-2 ${selectedOption === VerificationData.UnVerified ? 'btnNewAdd text-white' : 'btnRemove'}`}
                                style={{cursor: 'pointer'}}>
                                UnVerified
                            </button>
                        </div>
                    </div>
                </div>
                <div className={'w-25'}>
                    <button
                        className={`float-end btnNewAdd mt-3`}
                        onClick={() => {
                            setEditQuestionModal(true)
                        }}>
                        Insert Questions <i className="bi bi-plus-lg"></i>
                    </button>
                </div>
            </div>
            <div className={"card border-0 shadow-sm"}>
                <div className={"card-body"}>
                    <div className={"d-md-flex justify-content-between align-items-center"}>
                        <div className="form-group searchBar position-relative">
                            <input
                                type={"search"}
                                className="form-control w-100 formControl"
                                placeholder={"Search here ..."}
                                onChange={(event) => setQuery(event.target.value)}
                            />
                            <button className={'bg-transparent transition p-0 border-0'}><i
                                className="bi bi-search"></i>
                            </button>
                        </div>
                        <div className='d-flex align-items-center justify-content-end w-100'>
                            <div className={'me-2 w-25'}>
                                <select
                                    className={`form-control w-100`} // Added w-100 class for full width
                                    onChange={(event) => handleSubjectSelect([{
                                        label: event.target.options[event.target.selectedIndex].text,
                                        value: parseInt(event.target.value, 10)
                                    }])}
                                    value={selectedSubject.length > 0 ? selectedSubject[0].value : ''}
                                >
                                    <option value="">Select Subject</option>
                                    {subjects.map((subject) => (
                                        <option key={subject.id} value={subject.id}>{subject.name}</option>
                                    ))}
                                </select>
                            </div>

                            <div className={'me-2 w-25'}>
                                <select
                                    className={`form-control w-100`} // Added w-100 class for full width
                                    onChange={(event) => handleTopicSelect([{
                                        label: event.target.options[event.target.selectedIndex].text,
                                        value: parseInt(event.target.value, 10)
                                    }])}
                                    value={selectedTopic.length > 0 ? selectedTopic[0].value : ''}
                                >
                                    <option value="">Select Topics</option>
                                    {topics.map((topic) => (
                                        <option key={topic.id} value={topic.id}>{topic.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div>
                                <button
                                    className={`btn createQuestionButton text-white ms-2`}
                                    onClick={handleApplyFilters}
                                >
                                    Apply Filters
                                </button>
                            </div>
                        </div>

                    </div>
                    <div>
                        <hr/>
                    </div>
                </div>
                <div>
                    <div className=' container'>
                        <div className='mb-2'>
                            <button
                                onClick={() => handleQuestionSelectClick(SelectedQuestionType.General)}
                                className={` p-2 text-center me-2 ${selectedQuestionType === SelectedQuestionType.General ? 'btnNewAdd text-white' : 'btnRemove'}`}
                                style={{cursor: 'pointer'}}>
                                General
                            </button>
                            <button
                                onClick={() => handleQuestionSelectClick(SelectedQuestionType.Comprehension)}
                                className={` p-2 text-center me-2 ${selectedQuestionType === SelectedQuestionType.Comprehension ? 'btnNewAdd text-white' : 'btnRemove'}`}
                                style={{cursor: 'pointer'}}>
                                Comprehension
                            </button>
                        </div>
                        <div>
                            {selectedQuestionType === SelectedQuestionType.General &&
                                <div>
                                    {questions.state === NetworkState.Loading ? (
                                        renderColumns()
                                    ) : questions.state === NetworkState.Success ? (
                                        <QuestionList key={questions?.data?.map(res => res.id).toString()}
                                                      questions={questions?.data as TeacherCreateQuestions[]}
                                                      getVerifiedAndUnverifiedQuestions={getAllQuestions}
                                                      expandedQuestion={expandedQuestion}
                                                      currentPage={currentPage}
                                                      pageSize={pageSize}
                                                      handleQuestionClick={handleQuestionClick}
                                                      selectedOption={selectedOption}
                                                      subjectId={subjectId}
                                                      topicId={topicId}
                                        />
                                    ) : (
                                        <EmptyState/>
                                    )}
                                </div>
                            }
                            {selectedQuestionType === SelectedQuestionType.Comprehension &&
                                <div>
                                    {renderComprehensionContent()}
                                </div>
                            }
                        </div>
                    </div>
                    <CreateUpdateQuestions show={editQuestionModal} currentPage={currentPage}
                                           pageSize={pageSize}
                                           selectedOption={selectedOption}
                                           subjectId={subjectId}
                                           topicId={topicId}
                                           onSelectedOptionChange={(option) => handleSelectedOptionChange(option)}
                                           onHide={() => {
                                               setEditQuestionModal(false);
                                           }}
                                           onSuccess={() => {
                                               getAllQuestions(currentPage, pageSize, [], '', subjectId, topicId, selectedOption);
                                               setEditQuestionModal(false);
                                           }}
                    />
                </div>
                {selectedQuestionType === SelectedQuestionType.General && questions.data && questions.data.length > 0 && totalPages > 1 &&
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        totalElements={totalElements}
                        onPageChange={(page) => getAllQuestions(page, pageSize, [], query, subjectId, topicId, selectedOption)}
                    />}
                {selectedQuestionType === SelectedQuestionType.Comprehension && comprehensionQue.data && totalPages > 1 &&
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        totalElements={totalElements}
                        onPageChange={(page) => getAllQuestionsForComprehensions(topicId, subjectId, selectedOption, query, page, pageSize)}
                    />}

            </div>
        </div>
    );
};
