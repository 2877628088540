import React from "react";
import {MathJax} from "better-react-mathjax";
import {MathX} from "../../../../../components/math/MathX";

interface FormulaData {
    title: string;
    markdown: string;
}

export const MathJaxCheatSheet: React.FC = () => {
    const cheatSheetData: FormulaData[] = [
        {
            title: "Variables",
            markdown: "x_1, y_1, z_{3,4}",
        },
        {
            title: "Square",
            markdown: "a^2, x^y, 2^{n-1}",
        },
        {
            title: "Square Root",
            markdown: "sqrt{9}, sqrt{x}, xsqrt[n]",
        },
        {
            title: "Logarithm",
            markdown: "logx, log_{2}x",
        },
        {
            title: "Fraction",
            markdown: "frac{1}{2}, left(-frac{1}{2}right)^n",
        },
        {
            title: "Infinity",
            markdown: "infty",
        },
        {
            title: "Absolute Value",
            markdown: "|x|, |frac{x}{2}|, lfloor x rfloor, lceil x rceil"
        },
        {
            title: "Arithmetic Operation",
            markdown: "2times 3, 6div 3",
        },
        {
            title: "Factorial",
            markdown: "n!",
        },
        {
            title: "Trigonometric Functions",
            markdown: "sintheta, costheta, tantheta",
        },
        {
            title: "Greater or Less",
            markdown: "a\\gt b, a\\geq b, a\\lt b, a\\leq b",
        },
        {
            title: "Equation",
            markdown: "a=b, a\\neq b, a\\approx b",
        },
        {
            title: "Times Dot",
            markdown: "a\\cdot b=ab\n",
        },
        {
            title: "Divide Fraction",
            markdown: "a/b = \\frac{a}{b}"
        },
        {
            title: "Trinomial Equation",
            markdown: "a^2 + b^2 = c^2",
        },
        {
            title: "Matrix Parentheses",
            markdown: "$$\\begin{pmatrix} a & b \\\\ c & d \\end{pmatrix}$$",
        },
        {
            title: "Matrix Brackets",
            markdown: "$$\\begin{bmatrix} a & b \\\\ c & d \\end{bmatrix}$$",
        },
        {
            title: "Matrix Equation",
            markdown: "$$\\begin{vmatrix} a & b \\\\ c & d \\end{vmatrix}=ad-bc$$",
        },
        {
            title: "Set",
            markdown: "$$x\\in A, A\\ni x, x\\notin A$$\n",
        },
        {
            title: "Subset",
            markdown: "$$A\\subset B, A\\subseteq B, A \\not \\subset B$$\n",
        },
        {
            title: "Intersection & Union",
            markdown: "A\\cap B, A\\cup B, \\overline{A}",
        },
        {
            title: "Quadratic formula",
            markdown: "x = \\frac{-b \\pm \\sqrt{b^2 - 4ac}}{2a}",
        },
        {
            title: "Binomial",
            markdown: "\\sqrt{3x-1}+(1+x)^2",
        },
        {
            title: "Differentiation",
            markdown: "f', f^{(n)}, D_x f",
        },
        {
            title: "Integral",
            markdown: "int_0^1 f(x) dx",
        },
        {
            title: "Integral Large",
            markdown: "int_{-\\infty }^{\\infty}f(x)dx",
        },
        {
            title: "Max Sample",
            markdown: "$$\\max(a,b)=\\begin{cases}a&(a\\geqq b)\\\\b&(a\\lt b)\\end{cases}$$\n",
        },
    ];

    const bigONotations: { name: string;  markdown: string }[] = [
        {
            name: "Big O",
            markdown: "mathcal{O} or O",
        },
        {
            name: "Big Omega",
            markdown: "Omega",
        },
        {
            name: "Big Theta",
            markdown: "Theta$",
        },
        {
            name: "Small O",
            markdown: "o",
        },
        {
            name: "Small Omega",
            markdown: "omega",
        },
        {
            name:"On the order of",
            markdown:"sim"
        }
    ];

    const complexityNotations: { name: string; markdown: string }[] = [
        {
            name: "Constant Time",
            markdown: "O(1)",
        },
        {
            name: "Logarithmic Time",
            markdown: "O(log n)",
        },
        {
            name: "Linear Time",
            markdown: "O(n)",
        },
        {
            name: "Linear Logarithmic Time",
            markdown: "O(n \\log n)",
        },
        {
            name: "Quadratic Time",
            markdown: "O(n^2)",
        },
        {
            name: "Cubic Time",
            markdown: "O(n^3)",
        },
        {
            name: "Exponential Time",
            markdown: "O(2^n)",
        },
        {
            name: "Factorial Time",
            markdown: "O(n!)",
        },
        {
            name: "Constant Space",
            markdown: "O(1)",
        },
        {
            name: "Linear Space",
            markdown: "O(n)",
        },
    ];
    const warningMessage1 = "Enclose all the formulas with backticks (` `)";
    const warningMessage2 = "Formulas starts and ends  with ($$ $$) should not be enclosed within backticks (` `)";
    return (
        <div>
            <h4 className='mb-2'>MathJax Cheat Sheet</h4>
            <div className="warning"><span className='text-danger'>*</span>{warningMessage1}</div>
            <div className="warning"><span className='text-danger'>*</span>{warningMessage2}</div>

            <table className='table'>
                <thead>
                <tr>
                    <th>Title</th>
                    <th>Formula</th>
                    <th>Markdown</th>
                </tr>
                </thead>
                <tbody>
                {cheatSheetData.map((item, index) => (
                    <tr key={index}>
                        <td>{item.title}</td>
                        <td>
                            {item.title === 'Matrix Parentheses' ||
                            item.title === 'Matrix Brackets' ||
                            item.title === 'Matrix Equation' ||
                            item.title === 'Set' ||
                            item.title === 'Subset' ||
                            item.title === 'Max Sample'   ? (
                                <MathX>{item.markdown}</MathX>
                            ) : (
                               <MathX>`{item.markdown}`</MathX>
                            )}
                        </td>
                        <td>{item.markdown}</td>
                    </tr>
                ))}
                </tbody>
            </table>
            <hr/>

            <div>
                <h2 className='mb-2'>Big O and Related Notations</h2>
                <table className='table'>
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Notation</th>
                        <th>Markdown</th>
                    </tr>
                    </thead>
                    <tbody>
                    {bigONotations.map((item, index) => (
                        <tr key={index}>
                            <td>{item.name}</td>
                            <td><MathX>`{item.markdown}`</MathX></td>
                            <td>{item.markdown}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
            <hr/>

            <div>
                <h2 className='mb-2'>Common Time and Space Complexity</h2>
                <table className='table'>
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Notation</th>
                        <th>Markdown</th>
                    </tr>
                    </thead>
                    <tbody>
                    {complexityNotations.map((item, index) => (
                        <tr key={index}>
                            <td>{item.name}</td>
                            <td><MathX>`{item.markdown}`</MathX></td>
                            <td>{item.markdown}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};
