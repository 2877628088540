import React, {Fragment, useEffect, useState} from "react";
import {useAdminApiClient} from "../apiClients/AdminApiClient";
import { Modal, ModalBody} from "react-bootstrap";
import {StudentData} from "./BatchDetails";
import {useParams} from "react-router-dom";
import {AllToasts} from "../../../components/toast/AllToasts";
import LoaderButton from "../../../components/button/Button";
import Pagination from "../../../components/pagination/Pagination";
import {TableShimmer} from "../../../components/shimmer/Tableshimmer/TableShimmer";
import {EmptyState} from "../../../components/emptyState/EmptyState";
import {BatchAssignment} from "../../teacher/components/courseListing/course/assignment/BatchAssignment";

type BatchProps={
    show:boolean,
    batchId: string
    notInBatch: string
    onSuccess: ()=> void
    onHide: () => void;
    onCancle : ()=> void;
    selectedStudentId: string | null;
}
export interface BatchData {
    studentIds:[]
    studentId:number
}
export const AddStudentBatch=(props:BatchProps)=>{
    const params = useParams();
    const [studentName, setStudentName] = useState("");
    const [studentEmail, setStudentEmail] = useState("");
    const [parentContact, setParentContact] = useState("");
    const [searchQuery, setSearchQuery] = useState("");
    const [pageSize, setPageSize] = useState<number>(10);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [students,setStudents] = useState<any[]>([]);
    const [totalElements, setTotalElements] = useState<number>(0);
    const [selectedStudents, setSelectedStudents] = useState<any[]>([]);
    const[loading,setLoading]=useState<boolean>(false);
    const [studentAddError, setStudentAddError] = useState<string | null>(null);
    const batchesApiClient = useAdminApiClient();

    const resetForm = () => {
        setSelectedStudents([]);
        setStudentName("");
        setStudentEmail("");
        setParentContact("");
    };

    useEffect(() => {
        if (!props.show) {
            resetForm();
        }
    }, [props.show]);
    const getStudents = (notInBatch:string, page?:number, size?:number, query?:string) => {
        batchesApiClient.getAllStudents(props.notInBatch, page, size,query)
            .then(res=>{
                console.log('data', res.data.content)
                setStudents(res.data.content);
                setCurrentPage(res.data.number);
                setTotalPages(res.data.totalPages)
                setTotalElements(res.data.totalElements)
            })
            .catch((error)=>{
                console.error('Error fetching students:', error);
            });
    }
    useEffect(() => {
        if(props.show)
        getStudents(props.notInBatch, currentPage, pageSize, searchQuery);
    }, [props.show,currentPage, searchQuery]);
    const renderColumns = () => {
        const columns = [];
        for (let i = 0; i < 8; i++) {
            columns.push(
                <tr>
                    <td><TableShimmer/></td>
                    <td><TableShimmer/></td>
                    <td><TableShimmer/></td>
                    <td><TableShimmer/></td>
                </tr>
            );
        }
        return columns;
    };


    const addStudent = (batchId: string, payload: BatchData) => {
        setStudentAddError(null);
        batchesApiClient.addStudentToBatch(batchId, payload)
            .then((res) => {
                console.log('Success #24');
                props.onSuccess();
                props.onHide();
                AllToasts.success('Student Added successfully.');
                console.log('data', res.data);
            })
            .catch((error) => {
                if (error.response && error.response.status === 400) {
                    const errorMessage = error.response.data.detail;
                    setStudentAddError('Student already exist in different batch with same course');
                } else {
                    console.error('Error adding student:', error);
                }
            });
    }


    const handleSubmit = (event: any) => {
        event.preventDefault();

        if (selectedStudents.length > 0) {
            const selectedStudentIds = selectedStudents.map(student => student.id);

            const payload: any = {
                studentIds: selectedStudentIds,
            };

            addStudent(props.batchId, payload);
        }
    }

//     const handleSubmit = (event: any) => {
//         event.preventDefault();
//
//         // if (selectedStudents.length > 0) {
//             // const selectedStudentId = selectedStudents[0].id;
//             //     const selectedStudentId = selectedStudents.map(student => student.id);
//             //
//             //     const payload = {
//             //         studentIds: selectedStudentId,
//             //     };
//             //
//             //     addStudent(payload);
//             // } else {
//             // }
//           addStudent(props.batchId,BatchData)
//         // }
// };

    useEffect(() => {
        if (!props.show) {
            resetForm();
        }
    }, [props.show]);
    const handleStudentSelection = (student: StudentData) => {
        const isSelected = selectedStudents.some((s) => s.id === student.id);
        if (isSelected) {
            setSelectedStudents(selectedStudents.filter((s) => s.id !== student.id));
        } else {
            setSelectedStudents([...selectedStudents, student]);
        }
    };

    return(
        <div>
                <Modal show={props.show} onHide={() => {
                    props.onHide();
                    setStudentAddError(null);
                }} className='modal-xl'>
                <Modal.Header closeButton className="border-0 pb-0">
                    <h5>Add Students To Batch</h5>
                </Modal.Header>
                <ModalBody className="modal_body mx-2">

                    <div className="form-group has-search searchBar float-start w-75">
                        <i className="bi bi-search form-control-feedback formFeedback"></i>
                        <input
                            type="search"
                            className="w-50 form-control formControl w-50"
                            placeholder="Search"
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                    </div>

                    <form onSubmit={(e) => handleSubmit(e)}>
                        {students && students.length > 0 ? (
                            <div className={'table-responsive'}>
                                <table className="table mt-3 p-5">
                                    <thead>
                                    <tr>
                                        <th scope="col"></th>
                                        <th scope="col"> Student Id.</th>
                                        <th scope="col"> Name</th>
                                        <th scope="col">Email</th>
                                        <th scope="col">Mobile No.</th>

                                    </tr>
                                    </thead>
                                    <tbody>
                                    {loading ? (
                                        renderColumns()
                                    ) : (
                                        students.map((res: StudentData, index) => (
                                            <Fragment key={res.id}>
                                                <tr key={res.id}>
                                                    <td>
                                                        <input
                                                            type="checkbox"
                                                            checked={selectedStudents.some((student) => student.id === res.id)}
                                                            onChange={() => handleStudentSelection(res)}
                                                        />
                                                    </td>
                                                    <td>{res.id}</td>
                                                    <td>{res.name}</td>
                                                    <td>{res.email}</td>
                                                    <td>{res.parentContact}</td>

                                                </tr>
                                            </Fragment>
                                        )))}
                                    </tbody>

                                </table>
                            </div>
                        ) : (
                            <EmptyState/>
                        )}
                        <Pagination
                            currentPage={currentPage}
                            totalPages={totalPages}
                            totalElements={totalElements}
                            onPageChange={(page) => getStudents(params.id ?? '', page, pageSize, searchQuery)}
                        />

                        <div className="d-flex justify-content-end mt-3">
                            <button
                                type="button"
                                className="btn btn-secondary me-2"
                                onClick={() => {
                                    props.onCancle();
                                    setStudentAddError(null);
                                }}
                            >
                                Cancel
                            </button>
                            <LoaderButton label={'Add'} loading={loading} cssName={'btn btn-sm btn-create'}/>
                        </div>
                        <div>
                            {studentAddError && (
                                <div className="alert alert-danger">{studentAddError}</div>
                            )}
                        </div>
                    </form>
                </ModalBody>
                </Modal>

        </div>
    )
}
