import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useStudentApiClient} from "../../../apiClients/StudentApiClient";
import moment from "moment/moment";
import Pagination from "../../../../../components/pagination/Pagination";


export const StudentAllAssignments = () => {

    const [assignments, setAssignments] = useState<any[]>();
    const params = useParams();
    const studentApiClient = useStudentApiClient();
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(10);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [totalElements, setTotalElements] = useState<number>(0);
    const [query, setQuery] = useState<string>("");
    const navigate = useNavigate();
    useEffect(() => {
        getAllAssignmentByCourseId(params.courseBatchId ?? 0 as any, "Pending", query, pageSize, currentPage);
    }, [currentPage, pageSize, query]);
    const getAllAssignmentByCourseId = (course: number,status:string,query?:string,size?:number,page?:number) => {
        studentApiClient.getLatestTestByCourseId(course,status,query,size,page).then((res) => {
            setAssignments(res.data.content);
            setCurrentPage(res.data.number);
            setTotalPages(res.data.totalPages)
            setTotalElements(res.data.totalElements)
        }).catch((err) => {
            console.log(err);
        });
    };
    const handlePageChange = (newPage: number) => {
        setCurrentPage(newPage);
    };

    return <div className={'p-2'}>
        <div className={'h-100 container d-flex flex-column'}>
            <div className={''}>
                <h4>Latest Assignments</h4>
                <div className={'m-4'}>
                    <table className={`table table-hover studentTable `}>
                        <thead className={'thead'} style={{color:'black'}}>
                        {assignments?.length  ?
                            <tr>
                                <th scope="col" >Name</th>
                                <th scope="col" >Due Date</th>
                                <th scope="col" >Assignment</th>
                            </tr>
                            : <tr></tr>
                        }
                        </thead>
                        <tbody>
                        {
                            assignments?.map((assignment:any) => (
                                <tr key={assignment.id}>
                                    <td>{assignment?.name ?? "--" }</td>
                                    <td>{assignment?.dueDate ? moment(assignment?.dueDate).format(" Do MMM yyyy") : <span className={'ms-5 text-center'}>{ '---'}</span>}</td>
                                    <td> <button className='btn-add rounded-2 btn-sm' onClick={()=> navigate(`../ASSIGNMENT/${assignment.id}/instructions`)}>Attempt Now</button></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
            {totalPages == 1 ? null :
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                        totalElements={totalElements}
                    />
            }
    </div>
}