import {Route, Routes, useParams} from "react-router-dom";
import { Header } from "../../components/common/header/Header";
import { SessionManager } from "../../configs/SessionManager";
import {StudentHome} from "./screen/home/StudentHome";
import {StudentDashboard} from "./screen/dashboard/StudentDashboard";
import {Container} from "react-bootstrap";
import Profile from "../../components/common/Profile/Profile";
import CourseDetails from "./screen/home/courses/CourseDetails";

export const StudentIndex = () => {
    const params = useParams();

    return (
        <Container fluid className="ps-0 pe-0 h-100">
            {SessionManager.getSessionUser()?.role === 'Student' && params['*'] === 'profile' && <Header/>}
            {SessionManager.getSessionUser()?.role === 'Student' && params['*'] === '' && <Header/>}
            {/* <!-- ========== Start Page Content ========== --> */}
            <Routes>
                <Route path={"/"} element={<StudentHome />} />
                <Route path={"/course-details/:courseId"} element={<CourseDetails />} />
                <Route path="/course-batch/:courseBatchId/*" element={<StudentDashboard />} />
                <Route path={"/profile"} element={<Profile />} />
            </Routes>
            {/* <!-- ========== End Page Content ========== --> */}
        </Container>
    );
};



