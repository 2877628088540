import React, {useEffect, useState} from "react";
import "./StudentDashboard.scss"
import {Student} from "../../../../dto/Student";
import {useStudentApiClient} from "../../../student/apiClients/StudentApiClient";
import Pagination from "../../../../components/pagination/Pagination";
import CustomSwitcher from "../../../../components/common/course/courseView/CustomSwitcher";
import CreateStudent from "./CreateStudent";
import UpdateStudent from "./UpdateStudent";
import {AllToasts} from "../../../../components/toast/AllToasts";
import {AdminResetStudentPassword} from "../../../../dto/AdminResetStudentPassword";
import ChangePasswordModal from "./ChangePasswordModal";
import StudentBatchDetail from "../student/StudentBatchDetail";
import {useNavigate} from "react-router-dom";


export const StudentDashboardHome = () => {
    const navigate = useNavigate();
    const useStudentApi = useStudentApiClient()
    const [query, setQuery] = useState<string>('')
    const [show, setShow] = useState<boolean>(false);
    const [showBatches, setShowBatches] = useState<boolean>(false);
    const [updateShow, setUpdateShow] = useState<boolean>(false);
    const [students, setStudents] = useState<Student[]>([])
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(10);
    const [totalElements, setTotalElements] = useState<number>(0);
    const [studentId, setStudentId] = useState<number | undefined>(0);
    const [isPasswordModalOpen, setIsPasswordModalOpen] = useState<boolean>(false);
    const [studentToEdit, setStudentToEdit] = useState<Student | null>(null);
    const [studentIds, setStudentIds] = useState<number>(0)

    useEffect(() => {
        getStudents()
    }, []);

    useEffect(() => {
        const delayTimer = setTimeout(() => {
            getStudents(0, pageSize, query);
        }, 500);
        return () => {
            clearTimeout(delayTimer);
        };
    }, [query]);

    const getStudents = (page?: number, size?: number, query?: string) => {
        useStudentApi.getStudent(page, pageSize, query).then((res) => {
            setStudents(res.data.content);
            setCurrentPage(res.data.number);
            setTotalPages(res.data.totalPages);
            setTotalElements(res.data.totalElements);
            setPageSize(res.data.pageSize)
        }).catch((err) => {
            console.log(err)
        })
    }

    const softDeleteStudent = (id: any) => {
        console.log('id', id)
        useStudentApi.softDeleteStudent(id ?? 0)
            .then((res) => {
                AllToasts.success("Student disabled successfully");
                getStudents();
            })
            .catch((err) => {
                console.error(err);
            });
    };

    const restoreStudent = (id: number) => {
        useStudentApi.restoreStudent(id ?? 0)
            .then((res) => {
                AllToasts.success("Student activated successfully");
                getStudents();
            })
            .catch((err) => {
                console.error(err);
            });
    };

    const changeFlag = (studentId: any) => {
        useStudentApi.changeFlag(studentId ?? 0).then(() => {
            AllToasts.success('Student subscription changed successfully');
            getStudents();
        })
    }

    const handleStudentFlagChange = (studentId: number) => {
        setStudents(prevStudents => {
            return prevStudents.map(student => {
                if (student.id === studentId) {
                    return {
                        ...student,
                        flag: student.flag === 'Paid' ? 'Free' : 'Paid',
                    };
                }
                return student;
            });
        });
        changeFlag(studentId);
        getStudents()
    };

    const handleEditPassword = (newPassword: string, confirmNewPassword: string) => {
        if (newPassword === confirmNewPassword) {
            const studentIdToEdit = studentToEdit?.user.id;
            if (studentIdToEdit) {
                const changePasswordRequest: AdminResetStudentPassword = {
                    password: newPassword,
                    confirmPassword: confirmNewPassword,
                };
                useStudentApi.resetPassword(studentIdToEdit, changePasswordRequest)
                    .then((response) => {
                        AllToasts.success("Password changed successfully");
                        setIsPasswordModalOpen(false);
                    })
                    .catch((error) => {
                        AllToasts.failure("Error changing password");
                        setIsPasswordModalOpen(false);
                        console.log((error))
                    });
            }
        } else {
            AllToasts.failure("Passwords do not match");
        }
    };

    return <div className='container-fluid page-content'>
        <div className="row page-titles">
            <div className="col-sm-6 p-md-0">
                <div className="welcome-text">
                    <h4>All Student</h4>
                </div>
            </div>
            <div className="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item d-flex" aria-current="page">Home</li>
                    <li className="breadcrumb-item text-primary">All Student</li>
                </ol>
            </div>
        </div>
        <div className='card border-0 shadow-sm'>
            <div className={"card-body"}>
                <div className={'d-md-flex justify-content-between align-items-center'}>
                    <div className="col-md-6 px-0">
                        <div className="form-group searchBar position-relative">
                            <input
                                type={"search"}
                                className="form-control w-100 formControl"
                                placeholder={"Search here ..."}
                                onChange={(event) => setQuery(event.target.value)}
                            />
                            <button className={'bg-transparent transition p-0 border-0'}><i
                                className="bi bi-search"></i></button>
                        </div>
                    </div>
                    <div className="orderHeader1">
                        <button className={"btn btn-primary text-white rounded-1 btn-add"}
                                onClick={() => setShow(true)}><i className="bi bi-plus-lg"></i> Add new
                        </button>
                    </div>
                </div>
                <div className={'table-responsive'}>
                    <table className={`table studentTable mt-4`}>
                        <thead className={'thead'} style={{color: 'black'}}>
                        <tr>
                            <th scope="col-1">UserId</th>
                            <th scope="col-1">Name</th>
                            <th scope="col-2">Batches</th>
                            <th scope="col-2">Mobile Number</th>
                            <th scope="col-2">Email</th>
                            <th scope="col-2">Action</th>
                            <th scope="col-2">Change Password</th>
                            <th scope="col-1">Subscription</th>
                            <th scope="col-1">StudentRecord</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            students?.map((student) => (
                                <tr key={student.id}>
                                    <td style={{width: '6%'}}>{student?.user?.username ?? "--"}</td>
                                    <td style={{width: '13%'}}>{student?.name ?? "--"}</td>
                                    <td style={{width: '13%'}}>
                                    <span className={'btn btn-sm bg-success-light me-2 rounded-circle'}
                                          onClick={() => {
                                              setStudentId(student?.id);
                                              setShowBatches(true);
                                          }}>
                                      <a>View batches</a>
                                    </span>
                                    </td>
                                    <td style={{width: '13%'}}>{student?.mobile ?? "--"}</td>
                                    <td style={{width: '13%'}}>{student?.email ?? "--"}</td>
                                    <td style={{width: '13%'}}>
                                        <div className={'actions d-flex'}>
                                            <a className={'btn btn-sm bg-success-light me-2 rounded-circle'}
                                               onClick={() => {
                                                   setStudentId(student?.id);
                                                   setUpdateShow(true);
                                               }}>
                                                <i className="bi bi-pen"></i>
                                            </a>
                                            <div style={{width: '200%'}}>

                                                <CustomSwitcher active={student?.active === true}
                                                                activeLabel={'active'}
                                                                inactiveLabel={'inactive'}
                                                                onChange={() => {
                                                                    if (student?.active) {
                                                                        softDeleteStudent(student?.id)
                                                                    } else {
                                                                        restoreStudent(student?.id)
                                                                    }
                                                                }}/>
                                            </div>

                                        </div>
                                    </td>
                                    <td style={{width: '13%'}}>
                                        <div className={'actions d-flex'}>
                                            <a className={'btn btn-sm bg-success-light me-2 rounded-circle'}
                                               onClick={() => {
                                                   setIsPasswordModalOpen(true);
                                                   setStudentIds(student?.user.id);
                                               }}>
                                                <i className="bi bi-pass"></i>
                                            </a>
                                        </div>
                                    </td>
                                    <td style={{width: '13%'}}>
                                        <CustomSwitcher
                                            active={student.flag === 'Paid'}
                                            activeLabel={'Paid'}
                                            inactiveLabel={'Free'}
                                            onChange={() => handleStudentFlagChange(student.id)}
                                        />
                                    </td>
                                    <td>
                                        <i
                                            className="bi bi-arrow-right-circle"
                                            style={{cursor: 'pointer'}}
                                            onClick={() => {
                                                navigate(`/admin/studentEvaluation/${student.id}`)
                                            }}
                                        ></i>
                                    </td>
                                </tr>
                            ))
                        }
                        </tbody>
                    </table>
                </div>
                <Pagination currentPage={currentPage} totalPages={totalPages} totalElements={totalElements}
                            onPageChange={(page) => getStudents(page, pageSize)}/>
            </div>
        </div>
        <StudentBatchDetail studentId={studentId ?? 0}
                            show={showBatches}
                            onCancel={() => {
                                setShowBatches(false)

                            }}/>

        <CreateStudent
            show={show}
            onHide={() => {
                setShow(false);
                getStudents();

            }}
            onCancel={() => {
                setShow(false)
                getStudents();

            }}
            onSuccess={() => {
                getStudents();
                getStudents();
                setShow(false);
            }}
        />
        <UpdateStudent
            studentId={studentId ?? 0}
            show={updateShow}
            onHide={() => {
                setUpdateShow(false);
            }}
            onCancel={() => setShow(false)}
            onSuccess={() => {
                setStudentId(undefined)
                getStudents();
                setUpdateShow(false);
            }}
        />
        <ChangePasswordModal
            studentIds={studentIds}
            show={isPasswordModalOpen}
            onHide={() => setIsPasswordModalOpen(false)}
            onSave={handleEditPassword}
        />
    </div>
}
export default StudentDashboardHome;