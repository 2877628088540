import {Button, Modal} from "react-bootstrap";
import React from "react";
import {useNavigate} from "react-router-dom";

export const CoursePurchaseModal=(props:{show:boolean,onHide:()=>void,courseId:number})=>{
    const navigate=useNavigate();
    return(
        <Modal show={props.show} onHide={props.onHide} centered backdrop={"static"}>
            <Modal.Header>
                <Modal.Title>Wohooo! Purchase successful</Modal.Title>
            </Modal.Header>
            <Modal.Body>Start your learning today!</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => {
                    props.onHide();
                    navigate(`/student`);
                }}>
                    Later
                </Button>
                <Button variant="primary" onClick={() => {
                    props.onHide();
                    navigate(`/student/course-batch/${props.courseId}`)
                }}>
                    Start Now
                </Button>
            </Modal.Footer>
        </Modal>
    )
}