import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import AddChapter from "./AddChapter";
import {ChapterRepo} from "../../../../../repos/ChapterRepo";
import {AllToasts} from "../../../../toast/AllToasts";
import {EmptyState} from "../../../../emptyState/EmptyState";
import {ConfirmationDialog} from "../../../../confirmationDialog/ConfirmationDialog";
import {CourseRepo} from "../../../../../repos/CourseRepo";
import {ProgramRepo} from "../../../../../repos/ProgramRepo";
import CustomSwitcher from "../../courseView/CustomSwitcher";
import moment from "moment/moment";
import {Chapter} from "../../../../../dto/Chapters";
import {SessionManager} from "../../../../../configs/SessionManager";

export const Chapters = () => {
    const [chapters, setChapters] = useState<Chapter[]>([]);
    const [showEditor, setShowEditor] = useState<boolean>(false);
    const params = useParams();
    const [targetChapter, setTargetChapter] = useState<string>('');
    const [confirmation, setConfirmation] = useState<boolean>(false);
    const navigate = useNavigate();
    const [data, setData] = useState<any>('');
    const [programData, setProgramData] = useState<any>('');
    const [role,setRole] = useState<string>('');

    useEffect(() => {
        let role = SessionManager.getSessionUser()?.role ?? ''
        setRole(role);
    }, [SessionManager]);

    useEffect(() => {
        getCourseData(params?.programId ?? '');
        getProgramId(params?.programId || '');
        getAllChapters(params?.programId || '');
    }, []);

    const getCourseData = (courseId: string) => {
        CourseRepo.getSingleCourse(courseId).then((res: any) => {
            setData(res.data);
        }).then((err) => {
            console.log(err)
        })
    }

    const getProgramId = (programId: string) => {
        ProgramRepo.getSingleProgram(programId).then((res) => {
            setProgramData(res.data);
        }).catch((err) => {
            console.log(err)
        })
    };

    const getAllChapters = (programId: string) => {
        ChapterRepo.getAllChapters(programId).then((res) => {
                console.log('Chapter',res);
            setChapters(res.data);
        }).catch((err) => {
            console.log(err)
        })
    }

    const deleteChapter = (chapterId: string) => {
        ChapterRepo.deleteChapter(chapterId).then(() => {
            AllToasts.success('Item deleted successfully.');
            getAllChapters(params?.programId || '');
        }).catch(() => {
            AllToasts.failure('Fail to delete.');
        }).finally(() => {
            setConfirmation(false);
        })
    }

    const onAction = (isConfirm: boolean) => {
        if (!isConfirm) {
            setConfirmation(false)
        } else {
            deleteChapter(targetChapter);
        }
    }

    const handleSubscriptionType = (chapterId: number, subscriptionType: string) => {
        ChapterRepo.updateSubscriptionType(chapterId, subscriptionType)
            .then(() => {
                const updatedChapters = chapters.map((chapter: any) => {
                    if (chapter.id === chapterId) {
                        return {
                            ...chapter,
                            subscriptionType: subscriptionType
                        };
                    }
                    return chapter;
                });
                setChapters(updatedChapters);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    console.log('parmas',params)

    return <div className={'container-fluid page-content'}>
        <div className="row page-titles mx-0 shadow-sm">
            <div className={'d-flex justify-content-between'}>
                <div className="col-sm-6 p-md-0">
                    <h4>Unit</h4>
                </div>
                {role === 'Admin' ?
                    <div className="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item d-flex text-primary " aria-current="page"
                                onClick={() => navigate(`/admin/courses/${params.courseId}`)}
                                style={{cursor: 'pointer'}}>{data?.name}</li>
                            <li className="breadcrumb-item d-flex " aria-current="page">{programData?.name}</li>
                        </ol>
                    </div> :

                    <div className="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item d-flex text-primary " aria-current="page"
                                onClick={() => navigate(`/teacher/courses/${params.courseId}`)}
                                style={{cursor: 'pointer'}}>{data?.name}</li>
                            <li className="breadcrumb-item d-flex " aria-current="page">{programData?.name}</li>
                        </ol>
                    </div>}
                    </div>
                    </div>
                    <div className=" p-md-0 justify-content-end mt-2 mt-sm-0 d-flex">
                    <button className="btn btn-primary mt-3 text-white rounded-1 btn-add"
                    onClick={() => {
                    setShowEditor(true);
                    setTargetChapter('');
                }
                }><i className="bi bi-plus"></i>Add Chapter
            </button>
        </div>


        <div className={'card mt-3 border-0 shadow-sm p-2'}>
            <div className={"card-body"}>
                <table className={`table table-hover`}>
                    <thead className={'thead'} style={{color: 'black'}}>
                    {chapters?.length ?
                        <tr className={''}>
                            <th scope="col">Unit</th>
                            <th scope="col">Created By</th>
                            <th scope="col">Created Date</th>
                            <th>Subscription Type</th>
                            <th scope="col">Action</th>
                        </tr>
                        : <EmptyState/>
                    }
                    </thead>
                    <tbody>
                    {
                        chapters?.map((chapter: any) => (
                            <tr className={'m-2'}>
                                <td onClick={() => navigate(`chapter/${chapter?.id}/topics`)}
                                    className={'text-primary text-decoration-underline'}
                                    style={{cursor: "pointer"}}>{chapter?.name ?? " "}</td>
                                <td>{chapter?.createdBy?.name ?? " "}</td>
                                <td>{moment(chapter?.createdDate).format('DD/MM/YYYY') ?? " "}</td>
                                <td>
                                    <CustomSwitcher
                                        active={chapter.subscriptionType === "PAID"}
                                        onChange={() => {
                                            const newSubscriptionType = chapter.subscriptionType === "PAID" ? "FREE" : "PAID";
                                            handleSubscriptionType(chapter.id, newSubscriptionType);
                                        }}
                                        activeLabel={'PAID'}
                                        inactiveLabel={"FREE"}
                                    />
                                </td>
                                <td>
                                    <button onClick={() => {
                                        setShowEditor(true);
                                        setTargetChapter(chapter);
                                    }}><i className="bi bi-pencil text-success"></i></button>
                                    <button onClick={() => {
                                        setTargetChapter(chapter.id);
                                        setConfirmation(true);
                                    }}>
                                        <i className="bi bi-trash text-danger ms-2"></i>
                                    </button>
                                </td>
                            </tr>))}
                    {/*{chapters && chapters?.map((chapter: any, index: number) => (*/}
                    {/*    <tr key={index} className={'m-2'}>*/}
                    {/*        <td onClick={() => navigate(`chapter/${chapter?.id}/topics`)}*/}
                    {/*            className={'text-primary text-decoration-underline'}*/}
                    {/*            style={{cursor: "pointer"}}>{chapter?.name ?? " "}</td>*/}
                    {/*        <td>{chapter?.createdBy?.name ?? " "}</td>*/}
                    {/*        <td>{moment(chapter?.createdDate).format('DD/MM/YYYY') ?? " "}</td>*/}

                    {/*        <td>*/}
                    {/*            <button onClick={() => {*/}
                    {/*                setShowEditor(true);*/}
                    {/*                setTargetChapter(chapter);*/}
                    {/*            }}><i className="bi bi-pencil text-success"></i></button>*/}
                    {/*            <button onClick={() => {*/}
                    {/*                setTargetChapter(chapter.id);*/}
                    {/*                setConfirmation(true);*/}
                    {/*            }}>*/}
                    {/*                <i className="bi bi-trash text-danger ms-2"></i>*/}
                    {/*            </button>*/}
                    {/*        </td>*/}
                    {/*    </tr>))}*/}
                    </tbody>
                </table>
            </div>
        </div>

        <ConfirmationDialog title={'Delete Chapter'} description={"Are you sure you want to delete this?"}
                            show={confirmation} onAction={onAction}
                            onClose={() => setConfirmation(false)}/>

        {showEditor === true &&
            <AddChapter id={params?.programId || ''} show={true} defaultValue={targetChapter} onSucces={() => {
                setShowEditor(false);
                getAllChapters(params.programId || '');
            }} onCancel={() => {
                setShowEditor(false);
            }}
                        title={targetChapter ? 'Update' : 'Add New Chapter'}/>}

    </div>
}
export default Chapters;