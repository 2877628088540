import "./Sidebar.scss";
import {MenuItem} from "../../../modals/MenuItem";
import {SidebarMenuItem} from "./menuitem/SidebarMenuItem";
import navbarimg from '../../../assets/images/aiDronaLogo.png';
import {useState} from "react";
import {useNavigate, useParams} from "react-router-dom";

export const Sidebar = (props: {
    moduleName?: string;
    menuItems: MenuItem[],
    collapsedSidebar?: boolean,
    onClose?: (newState: boolean) => void
}) => {
    const [activeMenu, setActiveMenu] = useState<MenuItem>();

    const onMenuChange = (menuItem: MenuItem) => {
        setActiveMenu(menuItem);
    }
    const navigate = useNavigate();
    const handleImageClick = () => {
        if (props.moduleName === "Admin") {
            navigate("/admin");
        } else if (props.moduleName === "Teacher") {
            navigate("/teacher");
        }
    };

    return <div className={`${props.collapsedSidebar ? 'sidebar' : 'sidebarCollapsed'}`}>
        <div className={`navbar-menu h-100`}>
            <div className="navbar-brand-box mb-4 mt-4">
                <button className={'logo'} onClick={handleImageClick}><img style={{width: 'auto', height: '65px'}}
                                                                           src={navbarimg} alt="Logo"/></button>
                <div className={`dialogCloseButton`} onClick={() => props.onClose!(!props.collapsedSidebar)}><i
                    className="bi bi-x-lg"></i></div>
            </div>
            <div className="navbar-content-wrapper" id="sidebarMenu" key={activeMenu?.id || 'dashboard'}>
                {props.menuItems.map((menuItem: MenuItem, index: number) => (
                    <SidebarMenuItem
                        key={menuItem.id}
                        menuItem={menuItem}
                        moduleName={props.moduleName}
                        itemIndex={index}
                        hasParent={false}
                        onActive={onMenuChange}
                        collapsedSidebar={props.collapsedSidebar}
                        onClose={() => props.onClose!(!props.collapsedSidebar)}
                    />
                ))}
            </div>
        </div>
    </div>
}

export default Sidebar;
