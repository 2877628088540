import React, {useEffect, useState} from "react";
import {InitialDataState, NetworkState, PromiseData} from "../../../../modals/PromiseData";
import QuestionList from "../createAssignment/createTest/questionList/QuestionList";
import {EmptyState} from "../../../../components/emptyState/EmptyState";
import Skeleton from "react-loading-skeleton";
import {useTeacherApiClient} from "../../apiClients/TeacherApiClient";
import Pagination from "../../../../components/pagination/Pagination";
import {FlaggedData} from "../modals/CreateiUpdateQuestion";
import {TeacherCreateQuestions} from "../../modal/CreateQuestions";
import {useSubjectsApiClient} from "../../../admin/apiClients/SubjectsApiClient";
import {VerificationFilter} from "../verification/VerificationFilter";
import {QuestionListComprehension} from "../createAssignment/createTest/questionList/QuestionListComprehension";
import {ComprehensionData} from "../../../../dto/comprehensionFlaggedQuestions";
import {SelectedQuestionType} from "../createAssignment/createTest/createAssignment/CreateAssignment";

export enum AuditMode {
    Teacher = 'Teacher',
    Student = 'Student'
}

export enum Resolution {
    UnResolved = 'UnResolved',
    Resolved = 'Resolved'
}

interface SubjectsData {
    id: string,
    name: string,
}

export const Audit = () => {
    const [question, setQuestion] = useState<PromiseData<FlaggedData[]>>(InitialDataState);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(10);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [totalElements, setTotalElements] = useState<number>(0);
    const [selectedOption, setSelectedOption] = useState<AuditMode>(AuditMode.Teacher);
    const [selectedQuestionType, setSelectedQuestionType] = useState<string>(SelectedQuestionType.General);
    const [subjects, setSubjects] = useState<SubjectsData[]>();
    const [query, setQuery] = useState<string>('')
    const [filter, setFilter] = useState<boolean>(false);
    const [flaggedQuestion, setFlaggedQuestion] = useState<PromiseData<ComprehensionData[]>>(InitialDataState);
    const [filteredSubjectIds, setFilteredSubjectIds] = useState<number[]>([]);
    const [expandedQuestion, setExpandedQuestion] = useState<{ id: number | null; expanded: boolean }>({
        id: null,
        expanded: false
    });
    const [sort, setSort] = useState<string>('');
    const subjectsApiClient = useSubjectsApiClient();
    const teacherApiClient = useTeacherApiClient();

    useEffect(() => {
        if (filteredSubjectIds.length > 0) {
            getAllFlagQuestions(query, [], [], filteredSubjectIds, selectedOption, Resolution.UnResolved, currentPage, pageSize, sort);
        }
    }, [filteredSubjectIds, selectedOption, filteredSubjectIds, sort]);

    useEffect(() => {
        getAllSubjects();
    }, []);

    useEffect(() => {
        const delayTimer = setTimeout(() => {
            if (selectedQuestionType === SelectedQuestionType.Comprehension) {
                getAllFlaggedComprehensions(0, pageSize, filteredSubjectIds, [], query, sort, selectedOption);
            } else {
                getAllFlagQuestions(query, [], [], filteredSubjectIds, selectedOption, Resolution.UnResolved, 0, pageSize, sort);
            }
        }, 400);
        return () => {
            clearTimeout(delayTimer);
        };
    }, [query]);

    useEffect(() => {
        if (selectedQuestionType === SelectedQuestionType.Comprehension) {
            getAllFlaggedComprehensions(currentPage, pageSize, filteredSubjectIds, [], query, sort, selectedOption);
        } else if (selectedQuestionType === SelectedQuestionType.General) {
            getAllFlagQuestions(query, [], [], filteredSubjectIds, selectedOption, Resolution.UnResolved, currentPage, pageSize, sort);
        }
    }, [selectedQuestionType, selectedOption,filteredSubjectIds,sort]);

    const getAllSubjects = () => {
        subjectsApiClient.getSubjects().then((res) => {
            setSubjects(res.data);
        });
    };
    const handleSortChange = (options: string) => {
        setSort(options)
    }

    const getAllFlaggedComprehensions = (page?: number, size?: number, subjects?: number[], sort?: string[], query?: string, sortField?: string, markedBy?: AuditMode) => {
        setFlaggedQuestion({state: NetworkState.Loading});
        teacherApiClient.getAllFlaggedAuditComprehensions(page, size, subjects, sort, query, sortField, markedBy)
            .then((res) => {
                setFlaggedQuestion({state: NetworkState.Success, data: res.data.content});
                setCurrentPage(res.data.content?.pageable?.pageNumber);
                setTotalPages(res.data?.content?.totalPages);
                setTotalElements(res.data?.content?.totalElements);
                setPageSize(res.data?.content?.pageable?.pageSize);
            })
            .catch((err) => {
                console.log(err);
                setFlaggedQuestion({state: NetworkState.Failed, message: err.message});
            });
    };

    const getAllFlagQuestions = (query?: string, student?: number[], teacher?: number[], subject?: number[],
                                 markedBy?: AuditMode, status?: string, page?: number, size?: number, sortField?: string) => {
        setQuestion({state: NetworkState.Loading});
        teacherApiClient
            .getAllFlagQuestions(query, student, teacher, subject, markedBy, status, page, size, sortField)
            .then((res) => {
                setQuestion({state: NetworkState.Success, data: res.data.content});
                setCurrentPage(res.data.number);
                setTotalPages(res.data.totalPages);
                setTotalElements(res.data.totalElements);
                setPageSize(pageSize);
            })
            .catch((err) => {
                setQuestion({state: NetworkState.Failed, message: err.message});
                console.error(err);
            });
    };

    const handleOptionClick = (options: string) => {
        // setFilter(false);
        // setFilteredSubjectIds([]);
        setExpandedQuestion({id: null, expanded: false});
        if (options === AuditMode.Teacher) {
            setSelectedOption(AuditMode.Teacher);
        } else if (options === AuditMode.Student) {
            setSelectedOption(AuditMode.Student);
        }
        setTotalPages(0)
        setCurrentPage(0)
        setTotalElements(0)
        setQuestion(InitialDataState);
        setFlaggedQuestion(InitialDataState);
    };

    const handleQuestionSelectClick = (options: SelectedQuestionType) => {
        // setFilter(false);
        setExpandedQuestion({id: null, expanded: false});
        setSelectedQuestionType(options);
        setTotalPages(0)
        setCurrentPage(0)
        setTotalElements(0)
        if (options === SelectedQuestionType.Comprehension) {
            getAllFlaggedComprehensions(0, pageSize, filteredSubjectIds, [], query, sort, selectedOption);
        } else if (options === SelectedQuestionType.General) {
            getAllFlagQuestions(query, [], [], filteredSubjectIds, selectedOption, Resolution.UnResolved, 0, pageSize, sort);
        }
    };

    const renderColumns = () => {
        const columns = [];
        for (let i = 0; i < 10; i++) {
            columns.push(
                <table key={i}>
                    <tbody>
                    <tr>
                        <Skeleton height={60} width={1200}/>
                    </tr>
                    </tbody>
                </table>
            );
        }
        return columns;
    };

    const handleQuestionClick = (questionId: number) => {
        if (expandedQuestion.id === questionId) {
            setExpandedQuestion({id: null, expanded: false});
        } else {
            setExpandedQuestion({id: questionId, expanded: true});
        }
    };

    const handleToggle = () => {
        setFilter(!filter);
    };

    const handleApplyFilter = () => {
        if (selectedQuestionType === SelectedQuestionType.Comprehension) {
            getAllFlaggedComprehensions(0, pageSize, filteredSubjectIds, [], query, sort, selectedOption);
        } else if (selectedQuestionType === SelectedQuestionType.General) {
            getAllFlagQuestions(query, [], [], filteredSubjectIds, selectedOption, Resolution.UnResolved, 0, pageSize, sort);
        }
    };

    const renderQuestionContent = () => {
        if (question.state === NetworkState.Loading) {
            return renderColumns();
        } else if (question.state === NetworkState.Success) {
            const questions = question.data as TeacherCreateQuestions[];
            if (questions.length === 0) {
                return <EmptyState/>;
            }
            return (
                <QuestionList
                    key={questions.map(res => res.id).toString()}
                    questions={questions}
                    getAllQuestions={getAllFlagQuestions}
                    expandedQuestion={expandedQuestion}
                    currentPage={currentPage}
                    pageSize={pageSize}
                    handleQuestionClick={handleQuestionClick}
                    selectedOption={selectedOption}
                    filteredSubjectIds={filteredSubjectIds}
                />
            );
        } else if (question.state === NetworkState.Failed) {
            return <div>Error: Failed to load questions</div>;
        } else {
            return <div>Error: Failed to load questions</div>;
        }
    };

    const renderComprehensionContent = () => {
        if (flaggedQuestion.state === NetworkState.Loading) {
            return renderColumns();
        } else if (flaggedQuestion.state === NetworkState.Success) {
            const flaggedQuestions = flaggedQuestion.data;
            if (!flaggedQuestions || flaggedQuestions.length === 0) {
                return <EmptyState/>;
            }
            return (
                <QuestionListComprehension
                    key={flaggedQuestions?.map((res) => res.id).toString()}
                    flaggedQuestion={flaggedQuestions}
                    getAllCompFlaggedQues={() => getAllFlaggedComprehensions(currentPage, pageSize, [], [], query, sort, selectedOption)}
                    // unResolvedQuestionIds={unResolvedQuestionIds}
                    selectedOption={selectedOption}
                />
            );
        } else if (flaggedQuestion.state === NetworkState.Failed) {
            return <div>Error: Failed to load flagged questions</div>;
        } else {
            return null;
        }
    };

    const handleFilteredSubjectIds = (selectedValues: string[]) => {
        setFilteredSubjectIds(selectedValues?.map((id) => parseInt(id, 10)));
    }

    return <div className={"pt-4 m-auto container"}>
        <div className="row page-titles mx-0 shadow-sm bg-white rounded-2 mb-3">
            <div className="d-flex align-items-center justify-content-between m-2">
                <div>
                    <h4>Audit</h4>
                </div>
                <div>
                    <button
                        onClick={() => handleOptionClick(AuditMode.Teacher)}
                        className={` p-2 text-center me-2 ${selectedOption === 'Teacher' ? 'btnNewAdd text-white' : 'btnRemove'}`}
                        style={{cursor: 'pointer'}}>
                        Teacher
                    </button>
                    <button
                        onClick={() => handleOptionClick(AuditMode.Student)}
                        className={` p-2 text-center me-2 ${selectedOption === 'Student' ? 'btnNewAdd text-white' : 'btnRemove'}`}
                        style={{cursor: 'pointer'}}>
                        Student
                    </button>
                </div>
            </div>
        </div>
        <div className={"card border-0 shadow-sm"}>
            <div className={"card-body"}>
                <div className={"d-md-flex justify-content-between align-items-center"}>
                    <div className="form-group searchBar position-relative">
                        <input
                            type={"search"}
                            className="form-control w-100 formControl"
                            placeholder={"Search here ..."}
                            onChange={(event) => setQuery(event.target.value)}
                        />
                        <button className={'bg-transparent transition p-0 border-0'}><i className="bi bi-search"></i>
                        </button>
                    </div>
                    <div>
                        <div className={"me-3 border ps-4 pe-4 pt-2 pb-2"} onClick={handleToggle}>
                            <span className='d-flex align-items-center justify-content-center'
                                  style={{cursor: 'pointer'}}>
                                <i className="bi bi-funnel"></i> Filters
                            </span>
                        </div>
                    </div>
                </div>
                {filter ? (
                    <div>
                        <VerificationFilter
                            label="Select Subjects"
                            options={subjects || []}
                            selectedValues={filteredSubjectIds}
                            onSelectionChange={handleFilteredSubjectIds}
                            onApplyFilter={handleApplyFilter}
                            onSortChange={handleSortChange}
                        />
                    </div>
                ) : ""}
                <div>
                    <hr/>
                </div>
                <div>
                    <div>
                        <button
                            onClick={() => handleQuestionSelectClick(SelectedQuestionType.General)}
                            className={` p-2 text-center me-2 ${selectedQuestionType === SelectedQuestionType.General ? 'btnNewAdd text-white' : 'btnRemove'}`}
                            style={{cursor: 'pointer'}}>
                            General
                        </button>
                        <button
                            onClick={() => handleQuestionSelectClick(SelectedQuestionType.Comprehension)}
                            className={` p-2 text-center me-2 ${selectedQuestionType === SelectedQuestionType.Comprehension ? 'btnNewAdd text-white' : 'btnRemove'}`}
                            style={{cursor: 'pointer'}}>
                            Comprehension
                        </button>
                    </div>
                    {selectedQuestionType === SelectedQuestionType.Comprehension &&
                        <div>
                            {renderComprehensionContent()}
                            {selectedQuestionType === SelectedQuestionType.Comprehension &&
                                <div>
                                    {totalPages > 0 &&
                                        <Pagination
                                            currentPage={currentPage}
                                            totalPages={totalPages}
                                            totalElements={totalElements}
                                            onPageChange={(page) => {
                                                setCurrentPage(page);
                                                getAllFlaggedComprehensions(page, pageSize, filteredSubjectIds, [], query, sort, selectedOption);
                                            }}
                                        />
                                    }
                                </div>
                            }
                        </div>
                    }
                    <div>
                        {selectedQuestionType === SelectedQuestionType.General &&
                            <div>
                                {selectedOption === 'Teacher' && (
                                    <div className='mt-4'>
                                        {renderQuestionContent()}
                                    </div>
                                )}
                                {selectedOption === 'Student' && (
                                    <div className='mt-4'>
                                        {renderQuestionContent()}
                                    </div>
                                )}
                            </div>
                        }
                    </div>
                </div>
                {selectedQuestionType === SelectedQuestionType.General && totalPages > 1 &&
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        totalElements={totalElements}
                        onPageChange={(page) => getAllFlagQuestions(query, [], [], filteredSubjectIds, selectedOption, Resolution.UnResolved, page, pageSize, sort)}
                    />
                }
            </div>
        </div>
    </div>
}

