import {useNavigate, useParams} from "react-router-dom";
import React from "react";

const CompInstruction = () => {
    const params = useParams();
    const navigate = useNavigate()
    const comprehensionData = [
        {topic: 'Sum of two numbers', comprehensionNo: 1, maxMarks: 20},
        {topic: 'Algebra', comprehensionNo: 2, maxMarks: 25},
        {topic: 'Chemistry', comprehensionNo: 3, maxMarks: 30},
    ];

    return (
        <div className="container py-4 instruction-wrap">
            <div className={'bg-white py-2 px-3 rounded instruction_title'}>
                <h5 className={'fw-bold'}>General Instructions</h5>
            </div>

            <div className={'mt-4 bg-white px-3 rounded py-3 instruction_body'}>
                <p className={'mb-2'}>
                    1. The test consists of 7 comprehensions comprising the following sections
                </p>
                <div className={'margns'}>
                    <table className="table table-borderless question-table border">
                        <thead className={'tableHead'}>
                        <tr className={'table-secondary'}>
                            <th scope="col">Topic</th>
                            <th scope="col">No. of Comprehensions</th>
                            <th scope="col">Max Marks</th>
                            <th scope="col">Total Comprehensions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {comprehensionData?.map((comprehension) => (
                            <tr key={comprehension.comprehensionNo}>
                                <td>{comprehension.topic}</td>
                                <td>{comprehension.comprehensionNo}</td>
                                <td>{comprehension.maxMarks}</td>
                                <td className={'text-center'}>-</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    <div>
                        <p className={'mb-2'}>2. The clock has been set at the server and the countdown timer at the top
                            right corner of
                            your screen will display the time remaining for you to complete the exam. When the clock
                            runs out the exam ends by default - you are not required to end or submit your exam</p>
                        <p className={'mb-2'}>3. The palette at the right of screen shows one of the following
                            statuses
                            of each of the comprehensions numbered:</p>
                    </div>
                    <div className={'mb-2'}>
                        <div className={'p-1 d-flex align-items-center'}>
                            <p className={`status-icon unanswered pt-1 me-2 text-center`}>1</p>
                            <span className={'status-title'}>You have not visited the comprehension yet.</span>
                        </div>
                        <div className={'p-1 d-flex align-items-center'}>
                            <p className={`status-icon pending pt-1 me-2 text-center text-white`}>2</p>
                            <span className={'status-title'}>You have not answered any of the questions.</span>
                        </div>
                        <div className={'p-1 d-flex align-items-center'}>
                            <p className={`status-icon done pt-1 me-2 text-center text-white`}>3</p>
                            <span className={'status-title'}>You have answered few questions</span>
                        </div>
                        <div className={'p-1 d-flex align-items-center'}>
                            <p className={`status-icon markForReview pt-1 me-2 text-center text-white`}>4</p>
                            <span className={'status-title'}>You have not answered question but marked review.</span>
                        </div>
                    </div>
                    <div>
                        <p>4. For multiple choice type question :</p>
                        <ul>
                            <li><p>To select your answer, click on one of the option buttons</p></li>
                            <li><p>To change your answer, click the another desired option button</p></li>
                            <li><p>To save your answer, you MUST click on<strong> Save & Next</strong></p></li>
                            <li><p>To deselect a chosen answer, click on the chosen option again or click on the Clear
                                Response button</p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="sticky-bottom-btns">
                    <button className={'btn btnGreen me-3 text-white'}
                            onClick={() => navigate(`../${params.testType}/${params.testId}/comp`)}>Continue
                    </button>
                </div>
            </div>
        </div>
    );
};
export default CompInstruction;