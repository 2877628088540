import {QuestionData, StepperProps} from "./QuizStepper";
import React, {useEffect, useState} from "react";
import "./QuizStepper.scss"

const ReviewStepper = (props: StepperProps) => {
    const [questions, setQuestions] = useState<QuestionData[]>([]);

    useEffect(() => {
        setQuestions(props.questionData);

    }, [props.questionData]);

    const numRows = Math.ceil(questions.length / 5);
    const numCols = 5; // Number of columns


    return (
        <div className="stepper">
            {Array.from({length: numRows}, (_, rowIndex) => (
                <div className="stepper-row" key={rowIndex}>
                    {Array.from({length: numCols}, (_, colIndex) => {
                        const index = rowIndex * numCols + colIndex;
                        const status = questions[index]?.status ?? null;

                        const step = questions[index];
                        //aply break
                        if (!step) {
                            return null;
                        }
                        // const statusClass = status === "Unattempted" || status === "Wrong" ? "incorrect" : "correct";
                        const statusClass = status === "Unattempted" ? 'bg-white' : ''  || status === "Wrong" ? "incorrect" : "correct"
                        return (
                            <div
                                onClick={() => props.onClick(index)}
                                key={index}
                                className={`step ${statusClass}  ${props.currentStep === index ? "current" : ""}`}
                            >
                                <label className="radio-label">
                                    <span className="radio-number">{index + 1}</span>
                                </label>
                            </div>
                        );
                    })}
                </div>
            ))}
        </div>
    );


}
export default ReviewStepper;