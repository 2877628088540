import './TeacherProfile.css'
import {useTeacherApiClient} from "../../../apiClients/TeacherApiClient";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
interface TeacherProfileProps {
    name:string,
    mobile: string,
    email: string,
    address: string,
    active:boolean
}

const TeachersProfile=()=>{
        const [teacherPayload, setTeacherPayload] = useState<TeacherProfileProps>({
            name:'',
            mobile: '',
            email: '',
            address: '',
            active:true,
        });
        const teacherApiClient = useTeacherApiClient();
        const { id } = useParams();
        useEffect(() => {
            getTeacherById(id as any);
        }, [id]);

        const getTeacherById = (teacherId: number) => {
            teacherApiClient.getTeacherById(teacherId).then((res) => {
                setTeacherPayload(res.data);
                console.log(res.data);
            }).catch((error) => {
                console.error("Error fetching teacher data:", error);
            });
        }

    return<div className='m-4 p-4'>
        <div>
            <h3>Teacher Profile</h3>
        </div>
        <div className=''>
            <div className='mt-5'>
                <img
                    src={'https://img.freepik.com/free-vector/plain-background-with-female-teacher-with-stick_1308-42614.jpg?w=2000'}
                    className="teacherimg border" alt=""/>
            </div>
            <div className='mt-5'>
                <div className={"mt-3"}>
                    <p><span className={"h6"}> Name : </span> {teacherPayload.name}</p>
                </div>
                <div className={"mt-3"}>
                    <p><span className={"h6"}> Mobile : </span> {teacherPayload.mobile}</p>
                </div>
                <div className={"mt-3"}>
                    <p><span className={"h6"}> Email : </span> {teacherPayload.email}</p>
                </div>
                <div className={"mt-3"}>
                    <p><span className={"h6"}>Is active : {teacherPayload.active ? "True" : "False"}</span></p>
                </div>
                <div className={"mt-3"}>
                    {/*<p><span className={"h6"}> Created Date:</span>{teacherPayload.date}</p>*/}
                </div>
                <div className={"mt-3"}>
                    <p><span className={"h6"}> Address : </span>{teacherPayload.address}</p>
                </div>
            </div>
        </div>

    </div>
}
export default TeachersProfile;
