import React, {useEffect, useState} from "react";
import {useTeacherApiClient} from "../../apiClients/TeacherApiClient";
import AddEditTeacher from "../../../../components/modal/teachers/AddEditTeacherModal";
import Pagination from "../../../../components/pagination/Pagination";
import {useNavigate} from "react-router-dom";
import {ConfirmationDialog} from "../../../../components/confirmationDialog/ConfirmationDialog";
import './teacherProfile/TeacherProfile.css'
import CustomSwitcher from "../../../../components/common/course/courseView/CustomSwitcher";
import {AllToasts} from "../../../../components/toast/AllToasts";

interface TableData {
    id: number | undefined,
    name: string,
    mobile: string,
    email: string,
    address: string,
    active: boolean
}

const AdminTeachers = () => {
    const [show, setShow] = useState<boolean>(false);
    const [teacherPayload, setTeacherPayload] = useState<TableData[]>([]);
    const [teacherId, setTeacherId] = useState<number | undefined>(0)
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(10);
    const [totalElements, setTotalElements] = useState<number>(0);
    const [showDelete, setShowDelete] = useState<boolean>(false);
    const [query, setQuery] = useState<string>('')
    const teachersApiClient = useTeacherApiClient()
    useEffect(() => {
        getAllTeachers()
    }, [])

    useEffect(() => {
        const delayTimer = setTimeout(() => {
            getAllTeachers(pageSize, 0)
        }, 500)
        return () => {
            clearTimeout(delayTimer)
        }
    }, [query]);

    const getAllTeachers = (size?: number, page?: number) => {
        teachersApiClient.getTeachers(size, page, query).then((res) => {
            setCurrentPage(res.data.number);
            setTotalPages(res.data.totalPages);
            setTotalElements(res.data.totalElements);
            setTeacherPayload(res.data.content);
        });
    };

    const deleteAccount = (id: any) => {
        teachersApiClient.softDeleteTeacher(id ?? 0)
            .then(() => {
                AllToasts.success('Teacher Deactivated');
                getAllTeachers();
            })
            .catch((error) => {
                console.error('Error deactivating  Teacher:', error);
            })
    };
    const restoreTeachers = (id: any) => {
        teachersApiClient.restoreTeacher(id ?? 0)
            .then(() => {
                AllToasts.success('Teacher Activated');
                getAllTeachers();
            })
            .catch((error) => {
                console.error('Error activating Teacher:', error);
            })
    };

    const onAction = (isConfirm: boolean) => {
        if (!isConfirm) {
            setShowDelete(false)
        } else {
            deleteAccount(teacherId ?? 0);
        }
    }

    const navigate = useNavigate();

    return <div className='container-fluid page-content'>
        <div className="row page-titles">
            <div className="col-sm-6 p-md-0">
                <div className="welcome-text">
                    <h4>All Teachers</h4>
                </div>
            </div>
            <div className="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item d-flex" aria-current="page">Home</li>
                    <li className="breadcrumb-item text-primary">All Teachers</li>
                </ol>
            </div>
        </div>
        <div className={'card border-0 shadow-sm'}>
            <div className={'card-body'}>
                <div className={'d-md-flex justify-content-between align-items-center'}>
                    <div className="col-md-6 px-0">
                        <div className="form-group searchBar position-relative">
                            <input
                                type={"search"}
                                className="form-control w-100 formControl"
                                placeholder={"Search here ..."}
                                onChange={(event) => setQuery(event.target.value)}
                            />
                            <button className={'bg-transparent transition p-0 border-0'}>  <i className="bi bi-search"></i></button>
                        </div>
                    </div>

                    <div>
                        <button className={"btn btn-primary text-white rounded-1 btn-add"}
                                onClick={() => setShow(true)}><i className="bi bi-plus-lg"></i> Add new
                        </button>
                    </div>
                </div>
                <div className={'table-responsive'}>
                    <table className="table studentTable mt-4">
                        <thead>
                        <tr>
                            <th scope="col-1">Sr.No</th>
                            <th scope="col">Name</th>
                            <th scope="col">Mobile</th>
                            <th scope="col">Email</th>
                            <th scope="col">Address</th>
                            <th scope="col">Actions</th>
                        </tr>
                        </thead>

                        <tbody>
                        {teacherPayload?.map((res, index) => (
                            <tr key={res.id}>
                                <td>{index + 1}</td>
                                <td className={'text-capitalize'}>{res.name}</td>
                                <td>{res.mobile}</td>
                                <td>{res.email}</td>
                                <td className={'text-capitalize'}>{res.address}</td>
                                <td>
                                    <div className={'actions d-flex'}>

                                        <a className={'btn btn-sm bg-success-light me-2 rounded-circle'}
                                           onClick={() => navigate(`/admin/teachers/${res.id}/profile`)}><i
                                            className="bi bi-eye"></i></a>
                                        <a className={'btn btn-sm bg-success-light me-2 rounded-circle'}
                                           onClick={() => {
                                               setTeacherId(res.id);
                                               console.log('id', res.id)
                                               setShow(true)
                                           }}>
                                            <i className="bi bi-pen"></i>
                                        </a>
                                        <CustomSwitcher active={res.active} onChange={() => {
                                            if (res.active) {
                                                deleteAccount(res.id)
                                            } else {
                                                restoreTeachers(res.id)
                                            }
                                        }}/>
                                    </div>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
                <Pagination currentPage={currentPage} totalPages={totalPages} totalElements={totalElements}
                            onPageChange={(page) => getAllTeachers(pageSize, page)}/>
            </div>
        </div>


        <AddEditTeacher
            teacherId={teacherId ?? 0}
            show={show}
            onHide={() => {
                setShow(false);
                setTeacherId(undefined);
            }}
            onCancel={() => {
                setShow(false)
                setTeacherId(undefined);
            }}
            onSuccess={() => {
                setTeacherId(undefined);
                getAllTeachers();
                setShow(false);
            }}
        />

        <ConfirmationDialog title={'Delete Account'} description={"Are you sure you want to delete this?"}
                            show={showDelete} onAction={onAction}
                            onClose={() => setShowDelete(false)}/>


    </div>
}
export default AdminTeachers;