import "./confirmation_modal.scss";

type ConfirmationDialogProps = {
    title: string,
    description?: string,
    show: boolean,
    onAction: (isConfirmed: boolean) => void
    onClose?: () => void,
}
export const ConfirmationDialog = (props: ConfirmationDialogProps) => {
    return props.show?<div className="confirmationModalArea" onClick={()=> {
        if (props.onClose)
            props.onClose()
    }}>
        <div className="confirmationModal" onClick={(event)=> event.stopPropagation()}>
            <div className={"dialogHeader"}>
                <h3 className={'align-self-center m-0'}>{props.title}</h3>
                <span className={`dialogCloseButton`} onClick={()=> {
                    if (props.onClose)
                        props.onClose()
                }}>X</span>
            </div>
            {
                props.description?<div className={"dialogBody flex-fill"}>
                    <div>
                        <p>
                            {props.description}
                        </p>
                    </div>
                </div>:null
            }
            <div className={"dialogFooter"}>
                <button type={"button"} className={"me-2 btn btn-sm btn-secondary"} onClick={()=> props.onAction(false)}>Cancel</button>
                <button type={"button"} className={"btn btn-sm btn-danger"} onClick={()=> props.onAction(true)}>Confirm</button>
            </div>
        </div>

    </div>:null;
}