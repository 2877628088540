import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useAdminApiClient} from "../../apiClients/AdminApiClient";
import {Accordion, Card} from "react-bootstrap";
import {StudentPercentage, StudentQuestionDetails} from "../../../../components/modal/chart/StudentAssignmentDropDown";
import StudentTestStatisticsChart from "../../../../components/chart/StudentTestStatisticsChart";
import {EmptyState} from "../../../../components/emptyState/EmptyState";
import {useStudentApiClient} from "../../../student/apiClients/StudentApiClient";
import {assignmentStatistic, StudentAttemptedQuestion} from "../../../../dto/StudentDashBoard";
import {Course, Topic, UserData} from "../../../../dto/StudentEvaluation";

export const StudentEvalution = () => {
    const [openIndex, setOpenIndex] = useState<number | null>(0);
    const [questionsData, setQuestionsData] = useState<StudentAttemptedQuestion>({
        totalQuestionAttempted: 0,
        totalCorrectlyAttemptedQuestions: 0,
    });
    const [assignmentData, setAssignmentData] = useState<assignmentStatistic>({
        totalAssignments: 0,
        totalAssignmentsCompleted: 0,
    });
    const [studentRank, setStudentRank] = useState<{ rank: number }>({rank: 0});
    const [courses, setCourses] = useState<Course[]>([]);
    const [selectedCourseId, setSelectedCourseId] = useState<number | null>(null);
    const [selectedProgram, setSelectedProgram] = useState<any[]>([]);
    const [strongAndWeakTopics, setStrongAndWeakTopics] = useState<Topic[]>([])
    const [programId, setSelectedProgramId] = useState<number>(0);
    const [weakTopics, setWeakTopics] = useState<Topic[]>([])
    const [strongTopics, setStrongTopics] = useState<Topic[]>([]);
    const [userData, setUserData] = useState<UserData>()
    const [questionDetail, setQuestionDetail] = useState<StudentQuestionDetails>({
        attemptedQuestions: 0,
        skippedQuestions: 0,
        totalQuestions: 0,
        correctlyAttempted: 0,
        wronglyAttempted: 0
    })
    const [studentTestData, setStudentTestData] = useState<StudentPercentage>({myPercentage: 0, highestPercentage: 0})
    const [studentPracticeData, setStudentPracticeData] = useState<StudentPercentage>({
        myPercentage: 0,
        highestPercentage: 0
    })
    const [studentAssignmentData, setStudentAssignmentData] = useState<StudentPercentage>({
        myPercentage: 0,
        highestPercentage: 0
    })
    const studentApiClient = useStudentApiClient();
    const params = useParams();
    const adminApiClient = useAdminApiClient();
    const navigate = useNavigate();

    useEffect(() => {
        getAllCourses(Number(params.studentId));
        getStudentById(Number(params.studentId))
    }, [params.studentId]);

    useEffect(() => {
        if (programId && programId !== 0 && selectedCourseId) {
            getStudentTestPercentage(Number(params.studentId), selectedCourseId ?? 0, programId)
            getStudentPracticePercentage(Number(params.studentId), selectedCourseId ?? 0, programId)
            getStudentAssignmentPercentage(Number(params.studentId), selectedCourseId ?? 0, programId)
        }
    }, [params.studentId, selectedCourseId, programId]);

    useEffect(() => {
        if (selectedCourseId) {
            setOpenIndex(0);
            getProgramId(selectedProgram[0]?.program.id);
        }
    }, [selectedCourseId, selectedProgram]);

    useEffect(() => {
        if (selectedCourseId) {
            getAssignmentAttempted(Number(params.studentId), selectedCourseId);
            getQuesitonAttempted(Number(params.studentId), selectedCourseId);
        }
    }, [params, selectedCourseId]);

    useEffect(() => {
        if (programId && programId !== 0 && selectedCourseId) {
            getWeakAndStrongTopicsForStudent(Number(params.studentId), selectedCourseId ?? 0, programId)
        }
    }, [selectedCourseId, programId, params.studentId]);

    useEffect(() => {
        if (programId && programId !== 0 && selectedCourseId)
            getStudentDetailsOfQuestions(Number(params.studentId), programId)
    }, [params.studentId, programId]);

    useEffect(() => {
        if (selectedCourseId) {
            getStudentPrograms(Number(params.studentId), selectedCourseId);
            getStudentOverAllRank(Number(params.studentId), selectedCourseId);
        }
    }, [params.studentId, selectedCourseId])

    useEffect(() => {
        const weakTopics = strongAndWeakTopics.slice(0, 5).filter((res) => res.topicEvaluation === "Weak_Topic");
        setWeakTopics(weakTopics)
        const StrongTopic = strongAndWeakTopics.slice(0, 5).filter((res) => res.topicEvaluation === "Strong_Topic");
        setStrongTopics(StrongTopic)
    }, [strongAndWeakTopics]);

    useEffect(() => {
        setSelectedCourseId(courses.map((res) => res.id)[0])
    }, [courses]);

    const getQuesitonAttempted = (studentId: number, courseId: number) => {
        adminApiClient.getQuestionsAttemptedByStudent(studentId, courseId).then((res) => {
            setQuestionsData(res.data);
        }).catch((err) => {
            console.log(err);
        });
    };

    const getAssignmentAttempted = (studentId: number, courseId: number) => {
        adminApiClient.getAssigmentAttemptedByStudent(studentId, courseId).then((res) => {
            setAssignmentData(res.data);
        }).catch((err) => {
            console.log(err);
        });
    };

    const getStudentOverAllRank = (studentId: number, courseId: number) => {
        adminApiClient.getOverallStudentRank(studentId, courseId).then((res) => {
            setStudentRank(res.data);
        }).catch((err) => {
            console.log(err);
        });
    };

    const getAllCourses = (studentId: number) => {
        adminApiClient.getAllStudentCourses(studentId).then((res) => {
            setCourses(res.data);
        }).catch((err) => {
            console.log(err);
        });
    };

    const getWeakAndStrongTopicsForStudent = (studentId: number, courseId: number, programId: number) => {
        adminApiClient.getTopicEvaluationForStudent(studentId, courseId, programId).then((res) => {
            setStrongAndWeakTopics(res.data)
        }).catch((err) => {
            console.log(err)
        })
    }

    const getStudentDetailsOfQuestions = (studentId: number, programId: number) => {
        adminApiClient.getStudentQuestionDetails(studentId, programId).then((res) => {
            setQuestionDetail(res.data)
        }).catch((err) => {
            console.log(err)
        })
    }

    const getStudentTestPercentage = (studentId: number, courseId: number, programId: number) => {
        adminApiClient.getStudentTestPercentage(studentId, courseId, programId).then((res) => {
            setStudentTestData(res.data)
        }).catch((err) => {
            console.log(err)
        })
    }

    const getStudentAssignmentPercentage = (studentId: number, courseId: number, programId: number) => {
        adminApiClient.getStudentAssignmentPercentage(studentId, courseId, programId).then((res) => {
            setStudentAssignmentData(res.data)
        }).catch((err) => {
            console.log(err)
        })
    }

    const getStudentPracticePercentage = (studentId: number, courseId: number, programId: number) => {
        adminApiClient.getStudentPracticePercentage(studentId, courseId, programId).then((res) => {
            setStudentPracticeData(res.data)
        }).catch((err) => {
            console.log(err)
        })
    }

    const getStudentPrograms = (studentId: number, courseId: number) => {
        adminApiClient.getProgramForStudent(studentId, courseId).then((res) => {
            setSelectedProgram(res.data)
        }).catch((err) => {
            console.log(err)
        })
    }

    const getStudentById = (studentId: number) => {
        studentApiClient.getStudentById(studentId).then((res) => {
            setUserData(res.data)
        }).catch((err) => {
            console.log(err)
        })
    }

    const handleCourseChange = (event: any) => {
        const selectedId = event.target.value === '---' ? null : event.target.value;
        setSelectedCourseId(selectedId);
    };

    const getProgramId = (programId: number) => {
        setSelectedProgramId(programId)
    }

    const toggleAccordion = (index: number) => {
        setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    return <div className="container mt-2">
        <div className='text-center m-3'><h4>Student Evaluation</h4></div>
        {courses.length > 0 ?
            <div className="card  bg-light ">
                <div className='d-flex align-items-center justify-content-between'>
                    <div className='ps-4 pt-4 d-flex'>
                        <h6 className='me-3'>Name : {userData?.name}</h6>
                        <h6 className='me-3'>UserID : {userData?.user?.username}</h6>
                        <h6 className='me-3'>Email : {userData?.user?.email ?? "---"}</h6>
                        <h6 className='me-3'>Phone Number : {userData?.user?.mobile ?? '---'}</h6>
                    </div>
                    <div className='me-3 mt-2' style={{cursor:'pointer'}} onClick={()=> navigate('/admin/students')}>
                        <i className="bi bi-arrow-bar-left me-2 fs-5"></i>Back
                    </div>
                </div>
                <div className="card-header bg-light d-flex flex-row align-items-center justify-content-between">
                    <div className="w-50">
                        <label className="form-label m-2">Course</label>
                        <div>
                            <select className="form-select m-2" onChange={handleCourseChange}
                                    value={selectedCourseId || '---'}>
                                {courses
                                    .sort((a, b) => a.name.localeCompare(b.name))
                                    .map((res, index) => (
                                        <option key={index} value={res.id}>
                                            {res?.name || '---'}
                                        </option>
                                    ))}
                            </select>
                        </div>
                    </div>
                    <div className="w-50 ms-5 me-2">
                        <label className="form-label m-2"></label>
                        <div className="d-flex align-items-center justify-content-center">
                            <button className='btn btn-primary'
                                    onClick={() => navigate(`/admin/studentEvaluation/${params.studentId}/course/${selectedCourseId}/comment`)}>Notify
                            </button>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="d-flex ">
                        <div
                            className="w-25 p-1 me-2 d-flex flex-column align-items-center justify-content-center bg-primary text-white rounded ">
                            <span>{studentRank.rank ?? 0}</span>
                            <span>OverAll Rank</span>
                        </div>
                        <div
                            className="w-25 me-2 d-flex flex-column align-items-center justify-content-center bg-primary text-white rounded">
                            <span>{questionsData?.totalCorrectlyAttemptedQuestions}/{questionsData?.totalQuestionAttempted}</span>
                            <span>Questions Completed</span>
                        </div>
                        <div
                            className="w-25 d-flex flex-column align-items-center justify-content-center bg-primary text-white rounded">
                            <span>{assignmentData?.totalAssignmentsCompleted}/{assignmentData?.totalAssignments}</span>
                            <span>Assignment Completed</span>
                        </div>
                    </div>
                </div>
                <div className=" ms-3 me-3 mt-2 mb-2">
                    {selectedProgram?.length > 0 ? selectedProgram?.sort((a, b) => a.program?.name?.localeCompare(b.program?.name)).map((res, index) => (
                            <Accordion className='mb-2' key={index} activeKey={openIndex === index ? '0' : undefined}
                                       onClick={() => getProgramId(res.program.id)}>
                                <Card style={{backgroundColor: 'white'}}>
                                    <Accordion style={{backgroundColor: 'white'}}
                                               as={Card.Header}
                                               eventKey="0"
                                               onClick={() => toggleAccordion(index)}>
                                        <div className='d-flex align-items-center justify-content-between'>
                                            <h6>{res?.program.name}: {res?.percentage}%</h6>
                                            <div>
                                                <i className={`bi bi-chevron${openIndex === index ? '-up' : '-down'}`}></i>
                                            </div>
                                        </div>
                                    </Accordion>
                                    <Accordion.Collapse eventKey="0">
                                        <Card.Body>
                                            <div className={'row row-cols-12'}>
                                                <div className='col-4'>
                                                    <div className="card-body table-bordered">
                                                        <table className="table table-striped">
                                                            <thead>
                                                            <tr>
                                                                <th>StatName</th>
                                                                <th>Count</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            <tr>
                                                                <td>Total Questions</td>
                                                                <td>{questionDetail?.totalQuestions}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Attempted</td>
                                                                <td>{questionDetail?.attemptedQuestions}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Correct</td>
                                                                <td>{questionDetail?.correctlyAttempted}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Wrong</td>
                                                                <td>{questionDetail?.wronglyAttempted}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Skipped</td>
                                                                <td>{questionDetail?.skippedQuestions}</td>
                                                            </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div className='col-4'>
                                                    <div className="card-body table-bordered mt-2">
                                                        <div className='mb-2 ms-1'><b>Score (Self vs Class average)</b>
                                                        </div>
                                                        <StudentTestStatisticsChart testData={studentTestData}
                                                                                    assignmentData={studentAssignmentData}
                                                                                    practiceData={studentPracticeData}
                                                                                    width={150} height={200}/>
                                                    </div>
                                                </div>
                                                <div className='col'>
                                                    <div className="card-body table-bordered">
                                                        <table className="table table-striped">
                                                            <thead>
                                                            <tr className='col-12'>
                                                                <th className='col-6'>Weak Topic</th>
                                                                <th className='col-6'>Strong Topic</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {Array.from({length: 5})?.map((_, index) => (
                                                                <tr key={index}>
                                                                    <td>{weakTopics?.map((res) => res?.name)[index] ?? '---'}</td>
                                                                    <td>{strongTopics?.map((res) => res?.name)[index] ?? '---'}</td>
                                                                </tr>
                                                            ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                        )) :
                        <EmptyState/>
                    }
                </div>
            </div> :
            <EmptyState/>
        }
    </div>
};