import React, {useEffect, useState} from "react";
import {AddQuestionModal} from "../components/modal/addQuestionModal/AddQuestionModal";
import {useDataEntryQuestionsApiClient} from "../../apiClients/UseDataEntryQuestionsApiClient";
import {Questions, SubjectTopic} from "../../../teacher/components/modals/CreateiUpdateQuestion";
import {MathX} from "../../../../components/math/MathX";
import {EmptyState} from "../../../../components/emptyState/EmptyState";
import {AllToasts} from "../../../../components/toast/AllToasts";
import {DeleteQuestionModal} from "../components/modal/deleteQuestionModal/DeleteQuestionModal";
import {useNavigate} from "react-router-dom";
import './DataEntryDashboard.css'
import {useSubjectsApiClient} from "../../../admin/apiClients/SubjectsApiClient";
import {SubjectsData} from "../../../admin/components/AdminSubjects";
import Pagination from "../../../../components/pagination/Pagination";
import {Dropdown} from "react-bootstrap";
import Select from "react-dropdown-select";
import ValueType from 'react-select';
import {isDisabled} from "@testing-library/user-event/dist/utils";

export const DataEntryDashboard = () => {
    const navigate = useNavigate();
    const [subjects, setSubjects] = useState<SubjectsData[]>([])
    const dataEntryQuestionApiClient = useDataEntryQuestionsApiClient()
    const [addQuestionModal, setAddQuestionModal] = useState<boolean>(false);
    const [questions, setQuestions] = useState<Questions[]>([]);
    const [expandedQuestion, setExpandedQuestion] = useState<{ id: number | null; expanded: boolean }[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
    const [questionId, setQuestionId] = useState<number | undefined>(undefined);
    const subjectsApiClient = useSubjectsApiClient();
    const [selectedType, setSelectedType] = useState<string>('Draft');
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [totalElements, setTotalElements] = useState<number>(0);
    const pageSize = 5;
    const [subject, setSubject] = useState<SubjectsData | undefined>();
    const [topics, setTopics] = useState<SubjectTopic[]>([]);
    const [selectedTopics, setSelectedTopics] = useState<{ value: number; label: string; }[]>([]);
    const [topicIds, setTopicIds] = useState<number[]>([]);
    const [statsData, setStatsData] = useState([]);
    const [statsCurrentPage, setStatsCurrentPage] = useState<number>(0);
    const [statsTotalPages, setStatsTotalPages] = useState<number>(0);
    const [statsTotalElements, setStatsTotalElements] = useState<number>(0);
    const statsPageSize = 15;
    const [fromDate, setFromDate] = useState<any>('');
    const [toDate, setToDate] = useState<any>('');

    useEffect(() => {
        getFilteredQuestions(0, pageSize, selectedType);
    }, [selectedType]);

    useEffect(() => {
        getAllSubjects();
    }, [])

    useEffect(() => {
        getStatsDashboard(0, statsPageSize);
    }, []);

    useEffect(() => {
        clearFilters();
    }, [selectedType]);


    const getFilteredQuestions = (page ?: number, size ?: number, status ?: string, subjectId ?: number, topicIds ?: number[], fromDate ?: string, toDate ?: string) => {
        setLoading(true);
        dataEntryQuestionApiClient.getFilteredQuestions(page, size, status, fromDate, toDate, subjectId, topicIds).then((res) => {
            setQuestions(res.data.content);
            setCurrentPage(res.data.number);
            setTotalPages(res.data.totalPages);
            setTotalElements(res.data.totalElements);
            setExpandedQuestion(res.data.map((question: Questions) => ({id: question.id, expanded: true})));
            setLoading(false)
        }).catch((err) => {
            console.log(err);
            setLoading(false)
        });
    };

    const deleteQuestions = (questionStorageId: number) => {
        dataEntryQuestionApiClient.deleteQuestions(questionStorageId).then((res) => {
            AllToasts.success('Question Deleted Successfully')
            getFilteredQuestions();
        }).catch((err) => {
            console.log(err)
            AllToasts.failure(`Error ${err.response.data.message}`)
        })
    }

    const handleQuestionClick = (questionId: number) => {
        const updatedExpansionState = expandedQuestion.map((question) => {
            if (question.id === questionId) {
                return {...question, expanded: !question.expanded};
            }
            return question;
        });
        setExpandedQuestion(updatedExpansionState);
    };

    const handleSubmit = () => {
        const allQuestionId = questions.map((res) => res.id)
        dataEntryQuestionApiClient.changeQuestionStatus(allQuestionId, 'Submitted').then((res) => {
            AllToasts.success('Submitted successfully');
            getFilteredQuestions();
            getStatsDashboard();
        }).catch((err) => {
            console.log(err)
        })
    }

    const getAllSubjects = () => {
        subjectsApiClient.getSubjects().then((res) => {
            setSubjects(res.data);
        });
    };

    const clearFilters = () => {
        setSelectedTopics([]);
        setTopics([]);
        setTopicIds([]);
        setSubject(undefined);
        getFilteredQuestions(0, pageSize, selectedType);
        setToDate('');
        setFromDate('');
    }

    const options = topics.map(topic => ({
        value: topic.id,
        label: topic.name,
    }));

    const handleChange = (selectedItems: { value: number; label: string; }[]) => {
        setSelectedTopics(selectedItems);
        const selectedIds = selectedItems.map(item => item.value);
        setTopicIds(selectedIds);
        console.log(topicIds);
    };

    const handleApplyFilter = () => {
        getFilteredQuestions(0, pageSize, 'Submitted', subject?.id, topicIds, fromDate, toDate);
    }

    const getStatsDashboard = (page ?: number, size ?: number) => {
        dataEntryQuestionApiClient.getStatsDashboard(page, size).then(res => {
            setStatsData(res.data.content);
            setStatsCurrentPage(res.data.number);
            setStatsTotalPages(res.data.totalPages);
            setStatsTotalElements(res.data.totalElements);
        })
    }

    return (
        <div className="container mt-2">
            <div className={'row'}>
                <div
                    className={`d-flex flex-row align-items-center justify-content-between card border-0 shadow-sm p-3 ${selectedType === 'Submitted' ? 'w-100' : 'w-100'}`}>
                    <div>
                        <button
                            onClick={() => setSelectedType('Draft')}
                            className={`p-2 text-center me-2 ${selectedType === 'Draft' ? 'bg-secondary text-white' : 'bg-light text-black'}`}
                            style={{cursor: 'pointer'}}>
                            Draft
                        </button>
                        <button
                            onClick={() => setSelectedType('Submitted')}
                            className={`p-2 text-center ${selectedType === 'Submitted' ? 'bg-secondary text-white' : 'bg-light text-black'}`}
                            style={{cursor: 'pointer'}}>
                            Submitted
                        </button>
                    </div>

                    {
                        selectedType === 'Draft' ?
                            <button className="addQuestionButton" onClick={() => setAddQuestionModal(true)}><i
                                className="bi bi-plus-circle me-1"></i>Add
                                Question
                            </button> : ''
                    }
                </div>
                {
                    selectedType === 'Submitted' ?
                        <div className={'card mt-2 border-0 shadow-sm'}>
                            <div className={'card-body d-flex justify-content-between align-items-end'}>
                                <div className={'row d-flex'}>
                                    <div className='col-3'>
                                        <label className={'form-label'}>Select Subject</label>
                                        <Dropdown id={`${subject?.id}`}>
                                            <Dropdown.Toggle
                                                style={{
                                                    width: "auto",
                                                    maxWidth: "300px",
                                                    backgroundColor: "white",
                                                    color: "black"
                                                }}>
                                                {subject?.name ?? 'Select Subject'}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu
                                                style={{maxHeight: '200px', overflowY: 'auto', width: "300px"}}>
                                                {subjects?.map((subject) => (
                                                    <Dropdown.Item
                                                        key={subject.id}
                                                        onClick={() => {
                                                            setSubject(subject);
                                                            setTopics(subject.topics);
                                                        }}
                                                    >
                                                        {subject.name}
                                                    </Dropdown.Item>
                                                ))}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                    <div className={'col-3'}>
                                        <label className={'form-label'}>Select Topics</label>
                                        <Select className={`border-primary rounded-2`} style={{
                                            width: "auto", maxWidth: "300px",
                                            height: "100%",
                                            backgroundColor: "white",
                                            color: "black",
                                            fontSize: "20px"
                                        }} placeholder={"Select topics"}
                                                values={selectedTopics}
                                                options={options}
                                                multi
                                                disabled={!subject}
                                                onChange={handleChange}/>
                                    </div>
                                    <div className={'col-3'}>
                                        <label className={'form-label'}>From Date</label>
                                        <div>
                                            <input
                                                className={`border-primary rounded-2 text-center form-control`}
                                                type={'date'}
                                                value={fromDate}
                                                name={'fromDate'}
                                                onChange={event => setFromDate(event.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className={'col-3'}>
                                        <label className={'form-label'}>To Date</label>
                                        <div>
                                            <input
                                                className={`border-primary rounded-2 text-center form-control`}
                                                type={'date'}
                                                value={toDate}
                                                name={'toDate'}
                                                onChange={event => {
                                                    setToDate(event.target.value);
                                                }}
                                                disabled={!fromDate}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className={'d-flex align-items-center'}>
                                    <button className={'btnRemove me-3'}
                                            onClick={() => {
                                                clearFilters();
                                            }}
                                            disabled={!subject && !fromDate}
                                    >
                                        Clear
                                    </button>
                                    <button className="btnSubmit"
                                            onClick={() => {
                                                handleApplyFilter();
                                            }}
                                            disabled={!subject && !fromDate}
                                    >
                                        Apply Filter
                                    </button>
                                </div>
                            </div>
                        </div> : ''
                }
            </div>
            {loading ? ''
                : questions.length > 0 ?
                    <div className={'d-flex mt-3 g-3 '}>
                        <div
                            className={`card border-0 shadow-sm p-3 me-5 ${selectedType === 'Draft' ? 'w-100' : 'w-75'}`}>
                            {questions?.map((res) => (
                                <div className='p-3 m-1' style={{backgroundColor: "ghostwhite"}} key={res.id}>
                                    <div className='d-flex'>
                                        <div className={`col-10 ${selectedType === 'Draft' ? 'w-75' : 'w-100'}`}>
                                            <div>
                                                <span><b>Subject :</b> </span>
                                                <span className='me-5 col-2'>
                                         {Array.from(new Set(res.topics?.map((topic) => {
                                             const subject = subjects.find(sub => sub.topics.find(t => t.id === topic.id));
                                             return subject?.name;
                                         }))).join(', ')}
                                        </span>
                                                <span><b>Topics :</b> </span>
                                                <span
                                                    className='me-5 col-2'>{res.topics?.map((res) => res.name.length > 50 ? `${res.name.substring(0, 50)}...` : res.name).join(' ,  ')}</span>
                                                <span><b>Date :</b> </span>
                                                <span
                                                    className='me-2 col-1'>{res.createdDate?.substring(0, 10)}</span>
                                            </div>
                                            <div>
                                                <span><b>Question : </b></span>
                                                <span className='col-3'
                                                      onClick={() => handleQuestionClick(res.id)}>{res?.text}</span>
                                            </div>
                                        </div>
                                        {
                                            selectedType === 'Draft' ?
                                                <div className='col-2 d-flex align-items-center justify-content-end'>
                                                    <i className='bi bi-pencil-square fs-5 me-3 cursorPointer'
                                                       onClick={() => navigate(`/dataEntry/editQuestion/${res.id}`)}/>
                                                    <i className='bi bi-trash fs-5 text-danger cursorPointer'
                                                       onClick={() => {
                                                           setQuestionId(res.id)
                                                           setShowDeleteModal(true)
                                                       }}/>
                                                </div> : ''
                                        }
                                    </div>
                                    <hr/>
                                    <div className="row d-flex w-100">
                                        {res?.options?.map((option: any, index: number) => (
                                            <div className="col-6" key={option.id}>
                                                <div className="d-flex align-items-center w-100"
                                                     style={{backgroundColor: "#F9F9F9"}}>
                                                    <div key={option.id} className="d-flex align-items-center">
                                                        <div className={'d-flex'}>
                                                            <span className="me-2"
                                                                  style={{width: "80px"}}> Option {index + 1}:</span>
                                                            <span
                                                                className="me-2"><MathX>{option?.text}</MathX></span>
                                                        </div>
                                                        <span>
                                                    {option?.correct ? (
                                                        <span className="icon-circle text-success fs-5">
                                                            <i className="bi bi-check2 text-white"></i>
                                                        </span>
                                                    ) : (
                                                        ""
                                                    )}
                                                </span>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        {res?.oneWordAnswer?.length > 0 ?
                                            <div>Option: {res?.oneWordAnswer ?? ''}</div> : ""}
                                    </div>
                                    <hr/>
                                </div>
                            ))}
                            {
                                selectedType === 'Draft' ?
                                    <div className={'d-flex justify-content-between align-items-center mt-2'}>
                                        <div>
                                            <h6 className='text-danger'><span>*</span>Mandatory - Submit above
                                                question(s)
                                                for
                                                Admin
                                                Verification</h6>
                                        </div>
                                        <div>
                                            <button className='btn btn-success' onClick={() => {
                                                handleSubmit();
                                                setSelectedType('Submitted')
                                            }}>Submit
                                            </button>
                                        </div>
                                    </div> : ''
                            }
                            {
                                questions.length > 0 ?
                                    <Pagination currentPage={currentPage} totalPages={totalPages}
                                                totalElements={totalElements}
                                                onPageChange={(page) => getFilteredQuestions(page, pageSize, 'Submitted', subject?.id, topicIds ?? undefined, fromDate ?? undefined, toDate ?? undefined)}/> : ''
                            }

                        </div>
                        {
                            selectedType === 'Submitted' ?
                                <div className={`card border-0 shadow-sm w-25`}>
                                    <div className={'card-body'}>
                                        <div className={'card-header'}>
                                            <h3 style={{textAlign: "center"}}>Stats</h3>
                                        </div>
                                        <div>
                                            <table className={'table'}>
                                                <thead className={'thead-dark'}>
                                                <tr>
                                                    <th scope={'col'} style={{textAlign: "center"}}>Date</th>
                                                    <th scope={'col'} style={{textAlign: "center"}}>Questions</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    statsData.map((data) => (
                                                        <tr style={{textAlign: "center"}}>
                                                            <td>{data[0]}</td>
                                                            <td>{data[1]}</td>
                                                        </tr>
                                                    ))
                                                }
                                                </tbody>
                                            </table>
                                            {
                                                statsData.length > 0 ?
                                                    <Pagination currentPage={statsCurrentPage}
                                                                totalPages={statsTotalPages}
                                                                totalElements={statsTotalElements}
                                                                onPageChange={(page) => getStatsDashboard(page, pageSize)}/> : ''
                                            }
                                        </div>
                                    </div>
                                </div> : ''
                        }

                    </div>
                    : <EmptyState/>}

            <AddQuestionModal show={addQuestionModal} onHide={() => setAddQuestionModal(false)}/>
            <DeleteQuestionModal show={showDeleteModal} onHide={() => setShowDeleteModal(false)} onSuccess={() => {
                deleteQuestions(questionId ?? 0)
                setShowDeleteModal(false)
                getFilteredQuestions();
            }}/>
        </div>
    );
};
