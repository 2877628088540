import React from 'react';

interface ButtonProps {
    label: string;
    loading?: boolean;
    cssName: string;
    form?: string;
    style?:string;
    onClick?:()=>void;
    type?: 'submit' | 'reset' | 'button';
    disabled?: boolean;
}
class Button extends React.Component<ButtonProps, any> {
    render() {
        return (
            <>
                {
                    this.props.loading ?
                        <button
                            type={'submit'}
                            form={this.props.form}
                            disabled={this.props.disabled}
                            onClick={this.props.onClick}
                            className={this.props.cssName}
                            style={{height: "38px", backgroundColor: "#0d6efd", color: "white"}}>
                            <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                            {this.props.label}
                        </button> : <button
                            type={this.props.type}
                            form={this.props.form}
                            disabled={this.props.disabled}
                            onClick={this.props.onClick}
                            className={this.props.cssName}
                            style={{height: "38px",background:"#0d6efd",  border: "0px solid #9ac8a2", color: "white"}}>
                            <span></span>
                            {this.props.label}
                        </button>
                }
            </>
        )
    }
}

export default Button;