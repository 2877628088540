import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import { ChartConfiguration } from 'chart.js';

interface ChartDataItem {
    id: string;
    label: string;
    percentage: number;
    topicEvaluation: string;
    value: number;
}

interface AssignmentStatisticsChartProps {
    width?: number;
    height?: number;
    chartData?: ChartDataItem[];
    testData: { myPercentage: number; highestPercentage: number };
    practiceData: { myPercentage: number; highestPercentage: number };
    assignmentData: { myPercentage: number; highestPercentage: number };
}

const StudentTestStatisticsChart: React.FC<AssignmentStatisticsChartProps> = ({
                                                                                  width,
                                                                                  height,
                                                                                  chartData = [],
                                                                                  testData,
                                                                                  practiceData,
                                                                                  assignmentData,
                                                                              }) => {
    const chartRef = useRef<HTMLCanvasElement | null>(null);
    const chartInstanceRef = useRef<Chart | null>(null);

    useEffect(() => {
        if (chartInstanceRef.current) {
            chartInstanceRef.current.destroy();
        }

        if (chartRef.current && chartData.length > 0) {
            const ctx = chartRef.current.getContext('2d');
            if (ctx) {
                const labels = chartData.map(item => item.label);
                const data = chartData.map(item => item.percentage);
                // const backgroundColors = chartData.map(() => 'rgb(22,93,255)');
                const backgroundColors = data.map((percentage) => {
                    if (percentage >= 0 && percentage <= 30) {
                        return 'rgb(255,111,111)';
                    } else if (percentage >= 31 && percentage <= 60) {
                        return 'rgb(255,115,0)';
                    } else if (percentage >= 61 && percentage <= 100) {
                        return 'rgb(28,174,28)';
                    } else {
                        return 'rgb(201,203,207)';
                    }
                });
                const chartConfig: ChartConfiguration = {
                    type: 'bar',
                    data: {
                        labels: labels,
                        datasets: [{
                            label: '',
                            data: data,
                            backgroundColor: backgroundColors,
                            borderRadius: 5,
                            maxBarThickness:30
                        },
                        ],
                    },
                    options: {
                        maintainAspectRatio: false,
                        indexAxis: 'y',
                        plugins: {
                            legend: {
                                display:false,

                            },

                        },
                        scales: {

                            x: {
                                beginAtZero: true,
                                max: 100,
                                grid: {
                                    display: false,
                                },
                                ticks: {
                                    stepSize: 20,
                                    callback: function (value) {
                                        return value.toString() + '%';
                                    },
                                },
                            },
                            y: {
                                grid: {
                                    display: false,
                                },
                            },
                        },
                        responsive: true,
                    },
                };

                chartInstanceRef.current = new Chart(ctx, chartConfig);
            }
        }
    }, [width, height, chartData]);

    return (
        <div>
            <div className='' style={{height : '270px' }}>
                <canvas style={{ margin: '0 auto' }} className='m-0 p-0' ref={chartRef} width={width} height={height}></canvas>
                <div className='d-flex justify-content-center align-items-center my-3 '>
                    <div className=' d-flex align-items-center' style={{ color:'#1CAE1CFF'}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 15 14" fill="none">
                            <g clip-path="url(#clip0_135_20)">
                                <path d="M1.9165 11.0833H13.5832V12.25H1.9165V11.0833ZM1.9165 2.91666L4.83317 4.66666L7.74984 1.16666L10.6665 4.66666L13.5832 2.91666V9.91666H1.9165V2.91666Z" fill="#156515"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_135_20">
                                    <rect width="14" height="14" fill="white" transform="translate(0.75)"/>
                                </clipPath>
                            </defs>
                        </svg>
                       <h6 className='m-1'> Strong hold</h6>
                    </div>
                    <div className='mx-4 d-flex align-items-center' style={{ color:'#ff7300'}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 15 14" fill="none">
                            <g clip-path="url(#clip0_135_26)">
                                <path d="M7.66309 1.49399C10.9768 1.49399 13.6631 4.18028 13.6631 7.49399C13.6631 10.8077 10.9768 13.494 7.66309 13.494C4.34938 13.494 1.66309 10.8077 1.66309 7.49399C1.66309 4.18028 4.34938 1.49399 7.66309 1.49399Z" stroke="#FF7300" stroke-width="1.2" stroke-linecap="round"/>
                                <path d="M4.99609 9.49402H7.66276H10.3294" stroke="#FF7300" stroke-width="1.2" stroke-linecap="round"/>
                                <path d="M5.66276 6.82733C6.03095 6.82733 6.32943 6.37962 6.32943 5.82733C6.32943 5.27505 6.03095 4.82733 5.66276 4.82733C5.29457 4.82733 4.99609 5.27505 4.99609 5.82733C4.99609 6.37962 5.29457 6.82733 5.66276 6.82733Z" fill="#FF7300"/>
                                <path d="M9.66276 6.82733C10.031 6.82733 10.3294 6.37962 10.3294 5.82733C10.3294 5.27505 10.031 4.82733 9.66276 4.82733C9.29457 4.82733 8.99609 5.27505 8.99609 5.82733C8.99609 6.37962 9.29457 6.82733 9.66276 6.82733Z" fill="#FF7300"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_135_26">
                                    <rect width="14" height="14" fill="white" transform="translate(0.75)"/>
                                </clipPath>
                            </defs>
                        </svg>
                        <h6 className='m-1'> Could do Better</h6>
                    </div>
                    <div className='d-flex align-items-center' style={{ color:'#FF6F6FFF'}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 15 14" fill="none">
                            <g clip-path="url(#clip0_135_34)">
                                <path d="M4.57667 9.05333L4.29667 9.42667L5.04333 9.98667L5.32333 9.61333L4.57667 9.05333ZM10.1767 9.61333L10.4567 9.98667L11.2033 9.42667L10.9233 9.05333L10.1767 9.61333ZM7.75 13.0667C4.39947 13.0667 1.68333 10.3506 1.68333 7H0.75C0.75 10.866 3.884 14 7.75 14V13.0667ZM13.8167 7C13.8167 10.3506 11.1006 13.0667 7.75 13.0667V14C11.616 14 14.75 10.866 14.75 7H13.8167ZM7.75 0.933333C11.1006 0.933333 13.8167 3.64947 13.8167 7H14.75C14.75 3.134 11.616 0 7.75 0V0.933333ZM7.75 0C3.884 0 0.75 3.134 0.75 7H1.68333C1.68333 3.64947 4.39947 0.933333 7.75 0.933333V0ZM4.48333 5.6H5.41667V4.66667H4.48333V5.6ZM10.0833 5.6H11.0167V4.66667H10.0833V5.6ZM10.9233 9.05333C9.33667 6.93777 6.16333 6.93777 4.57667 9.05333L5.32333 9.61333C6.53667 7.99556 8.96333 7.99556 10.1767 9.61333L10.9233 9.05333Z" fill="#FF6F6F"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_135_34">
                                    <rect width="14" height="14" fill="white" transform="translate(0.75)"/>
                                </clipPath>
                            </defs>
                        </svg>
                        <h6 className='m-1'> Weak</h6>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default StudentTestStatisticsChart;
