import React, {useEffect, useState} from "react";
import "./Toast.css";

export interface ToastProps{
    id : string;
    destroy : () => void;
    title : string;
    isSuccess? : boolean;
    duration? : number;
    showLink?:boolean;
    copyLink?:string
}

const Toast : React.FC<ToastProps> = (props) => {
    const { destroy, title, duration =0, id} = props;
    const[show,setShow]=useState<boolean>(props?.showLink??false)

    useEffect(()=> {
        if(!duration) return;

        const timer = setTimeout (()=> {
            destroy();
        }, duration);

        return ()=> clearTimeout(timer);
    },[destroy, duration]);
    function copyLink (): any {
        navigator.clipboard.writeText(props?.copyLink ?? '');
    }
    return (
        <div>
            <div className={props.isSuccess ?? true ? "toast-header" : "toast-header-failed"}>
                <div className="success-icon">
                    <i className={`bi ${props?.isSuccess ?? true ? "bi-check" : "bi bi-x"}`}/>
                </div>
                <div><b className="toast-content">{title}</b></div>
                <div className="close-icon">
                    <button onClick={destroy}><i className="bi bi-x"></i></button>
                </div>
            </div>
            {show?
                <div className={`mt-3`}>
                    <button className={`btn btn-primary`} onClick={copyLink}>copy link</button>
                    <button className={`btn btn-outline-primary ms-4 `} > <a className={`viewTask`} href={props.copyLink} target="_blank">View task</a></button>
                </div>:" "
            }
        </div>
    )
};

export default Toast;
