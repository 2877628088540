import React, {useEffect, useState} from "react";
import {useSubjectsApiClient} from "../apiClients/SubjectsApiClient";
import {useNavigate} from "react-router-dom";
import Card from "./subjects/subjectCard/subjectsCard";
import AddEditSubject from "./subjects/modal/AddEditSubject";

 export  interface Topic {
    id: number;
    name: string;
    totalQuestions: number;
    active: boolean;
}

export interface SubjectsData {
    id: number;
    name: string;
    topics: Topic[];
    active: boolean;
}
const AdminSubject = () => {
    const navigate = useNavigate()
    const [subjects, setSubjects] = useState<SubjectsData[]>([]);
    const subjectsApiClient = useSubjectsApiClient()
    const [show, setShow] = useState<boolean>(false);


    useEffect(() => {
        getAllSubjects();
    }, [])

    const getAllSubjects = () => {
        subjectsApiClient.getSubjects().then((res) => {
            setSubjects(res.data);
        });
    };


    return (
        <div className={'container-fluid page-content'}>
            <div className="row page-titles">
                <div className="col-sm-6 p-md-0">
                    <div className="welcome-text">
                        <h4>All Subjects</h4>
                    </div>
                </div>
                <div className="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
                    <button className={'btn btn-primary text-white rounded-1 btn-add'}  onClick={() => setShow(true)}>
                        <i className="bi bi-plus"></i>
                        Add new
                    </button>
                </div>
            </div>
            <div className={'container d-flex flex-column p-2'}>
                    <div className={'row row-cols-1 row-cols-md-5'}>
                        {subjects?.sort((a,b)=> a?.name.localeCompare(b?.name))?.map(data => (
                            <Card key={data.id} data={data} reload={()=>getAllSubjects()}/>
                        ))}
                    </div>
                </div>
            <AddEditSubject onCancel={() => setShow(false)}
                        onHide={() => setShow(false)}
                        show={show}
                        onSuccess={() => {
                            setShow(false);
                            getAllSubjects();
                        }}
            />
        </div>

    );

}

export default AdminSubject;