import {Modal, ModalBody, ModalHeader} from "react-bootstrap";
import React, {useEffect, useRef, useState} from "react";
import {useTeacherApiClient} from "../../../modules/teacher/apiClients/TeacherApiClient";
import {AllToasts} from "../../toast/AllToasts";
import {SessionManager} from "../../../configs/SessionManager";
import {useStudentApiClient} from "../../../modules/student/apiClients/StudentApiClient";
import {useAuth} from "../../../context/UserContext";
export interface ImageModalProps {
    show:boolean,
    onHide:()=> void
    id:number
    uploadStatus:(status:string) => void
}

export const ImageModal = (props:ImageModalProps) => {
    const imageChooser = useRef<HTMLInputElement>(null);
    const [dragEnter, setDragEnter] = useState<boolean>(false);
    const [fileLoading, setFilePickerLoading] = useState<boolean>(false);
    const [imageURL, setImageURL] = useState('');
    const [file, setFile] = useState<File>();
    const [fileName, setFileName] = useState<string | undefined>(undefined);
    const [uploadStatus, setUploadStatus] = useState<string | null>(null);
    const [uploadedFileCid, setUploadedFileCid] = useState<string>('');
    const [uploadFile,seUploadFile] = useState<boolean>(false)
    const teacherApiClient = useTeacherApiClient()
    const {saveUserData} = useAuth();
    const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.stopPropagation();
        e.preventDefault();
        const droppedFile = e.dataTransfer.files[0];
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            const fileURL = URL.createObjectURL(droppedFile);
            setImageURL(fileURL);
            setDragEnter(false);
        }
    };

    const handleFiles = (event: any) => {
        const selectedFile = event.target.files[0];
        if (event.target.files && event.target.files.length > 0) {
            setFile(selectedFile);
            setFileName(selectedFile.name);
            event.target.value = null;
            const reader = new FileReader();
            reader.onload = (e) => {
                const imageSrc = e.target?.result as string;
                setImageURL(imageSrc as string);
            };
            reader.readAsDataURL(selectedFile);
        }
    };

    const handleDragAndLeaveEvent = (e: React.DragEvent<HTMLDivElement>) => {
        e.stopPropagation();
        e.preventDefault();
        if (e.type === 'dragover' || e.type === 'dragenter') {
            setDragEnter(true);
        } else if (e.type === 'dragleave') {
            setDragEnter(false);
        }
    }

    const handleUpload = () => {
        if (!file) {
            return;
        }
        setUploadStatus('Uploading...');
        teacherApiClient.uploadProfile(props.id, file)
            .then((res) => {
                const uploadedFileCid = res.data.cid;
                setUploadedFileCid(uploadedFileCid);
                setUploadStatus('Upload successful');
                props.uploadStatus('Upload successful');
                AllToasts.success("Image Uploaded Successfully");
                setFile(undefined);
                setFileName('');
                setImageURL('');
            })
            .catch((err) => {
                console.error('Error uploading file:', err);
                setUploadStatus('Error uploading file');
                AllToasts.failure("Image Failed to Upload");
            });
    };

    const removeImage = () => {
        setFile(undefined);
        setFileName('');
        setImageURL('')
    };

    return <Modal show={props.show} onHide={props.onHide}>
        <ModalHeader>
            <div className={'flex-fill'}><h4>Add profile photo</h4></div>
            <div className={'cursor-pointer'} onClick={props.onHide}><i className="bi bi-x fs-4 "></i></div>
        </ModalHeader>
        <ModalBody>
            <div className="card w-100 h-100 d-flex align-items-center justify-content-center  p-5 mb-2 mt-2"
                 onClick={() => imageChooser.current?.click()} onDragOver={handleDragAndLeaveEvent} onDragLeave={handleDragAndLeaveEvent}
                 onDrop={handleDrop}>
                {dragEnter || fileLoading ?
                    <div className="d-flex flex-column p-4">
                        <div className=" text-center flex-fill position-relative">
                            <div className={`styledLabel ${(dragEnter) ? "dark" : ""}`}
                                 onClick={() => imageChooser.current?.click()}>
                                {dragEnter ? <p>Drop file here</p> : ''}
                                {(fileLoading) ? <div>Loading...</div> : ''}
                            </div>
                        </div>
                    </div> : <div className="align-items-center">
                        {imageURL ? (
                            <div className='d-flex flex-row'>
                                <img className='w-25 h-25' src={imageURL} alt="Selected Image"/>
                                <i className='bi bi-x-lg fs-5 float-end p-1 '
                                   onClick={removeImage}></i>
                            </div>
                        ) : (<div>
                                <input ref={imageChooser} hidden onChange={handleFiles}
                                       type="file" accept="image/jpg,image/jpeg,image/png"
                                       name="images_uploads" id="images_uploader"
                                       className="visusally-hidden"/><br/>
                                <p>Files Supported: .JPG, .JPEG,.PNG</p>
                                <p>Or Drag and Drop a file</p>
                                <label className={"text-upload-focused mt-1"}>
                                    Click to add image
                                </label>
                            </div>
                        )}
                    </div>
                }
                <input ref={imageChooser} type="file" hidden
                       accept="image/jpg,image/jpeg,.image/png" onChange={handleFiles} id="fileID"/>
            </div>
            <div className='d-flex align-items-center justify-content-end'>
                <button className={"btn btn-primary"} type="button" onClick={() => {handleUpload(); props.onHide(); }}>
                    Save
                </button>
            </div>
        </ModalBody>
    </Modal>
}