import React from "react";
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";

interface PassageModalProps {
    show: boolean;
    onHide: () => void;
    currentComprehensionText: string;
    currentComprehensionImage: string;
}

export const PassageModal = (props: PassageModalProps) => {
    return (
        <div className={"w-100"}>
            <Modal size={"xl"} show={props.show} onHide={() => props.onHide()}>
                <ModalHeader closeButton>
                    <Modal.Title>Comprehension</Modal.Title>
                </ModalHeader>
                <ModalBody style={{ maxHeight: "70vh", overflowY: "auto" }}>
                    <div className={"d-flex flex-column"}>
                        {props.currentComprehensionText &&
                            props.currentComprehensionText
                                ?.split(/\. /)
                                .filter((passage) => passage.trim())
                                .map((passage, index) => (
                                    <p key={index}>{passage}.</p>
                                ))}
                        {props.currentComprehensionImage && (
                            <img
                                src={props.currentComprehensionImage}
                                className="img-fluid"
                                style={{
                                    maxWidth: "100%",
                                    height: "auto",
                                    maxHeight: "500px",
                                    width: "600px",
                                    objectFit:'contain'
                                }}
                            />
                        )}
                    </div>
                </ModalBody>
                <Modal.Footer>
                    <div className="d-flex align-items-center justify-content-end">
                        <button className={"btn btn-primary"} onClick={() => props.onHide()}>
                            Continue
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    );
};
