import {ChangeEvent} from "react";

export class InputUtils {
    static parseInput = (event: ChangeEvent<HTMLElement>) => {
        let name = "";
        let value: any = "";

        if (event.target instanceof HTMLInputElement) {
            name = event.target.name;
            if (event.target.type === "checkbox") {
                value = event.target.checked;
            } else
                value = event.target.value;
        } else  if (event.target instanceof HTMLTextAreaElement) {
            name = event.target.name;
            value = event.target.value;
        } else if (event.target instanceof HTMLSelectElement) {
            name = event.target.name;
            value = event.target.selectedOptions;
        } else if (event.target instanceof HTMLSelectElement) {
            name = event.target.name;
            value = event.target.selectedOptions;
        } else {
            name = event.target.id;
        }

        return {name: name, value: value};
    }
}