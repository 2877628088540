import {Route, Routes} from "react-router-dom";
import {MenuItem} from "../../modals/MenuItem";
import {TeacherDashboard} from "./components/createAssignment/TeacherDashboard";
import {Header} from "../../components/common/header/Header";
import {Footer} from "../../components/common/footer/footer";
import CreateAssignment from "./components/createAssignment/createTest/createAssignment/CreateAssignment";
import Sidebar from "../../components/common/sidebar/Sidebar";
import store from "../../redux/store/store";
import Profile from "../../components/common/Profile/Profile";
import {Provider} from "react-redux";
import {CourseCommon} from "../../components/common/course/Course";
import {CourseView} from "../../components/common/course/courseView/CourseView";
import {CourseTopics} from "../../components/common/course/program/chapters/topics/Topics";
import Chapters from "../../components/common/course/program/chapters/Chapters";
import {Assignments} from "./components/courseListing/course/assignment/Assignments";
import {TeacherListAssignments} from "./components/createAssignment/Assignment/TeacherListAssignments";
import AssignmentQuestionListing from "./screen/assignment/AssignmentQuestionListing";
import {Audit} from "./components/audit/Audit";
import React, {useState} from "react";
import {Verification} from "./components/verification/Verification";
import {AssignmentBatchListing} from "./components/createAssignment/Assignment/AssignmentBatchListing";

export const TeacherIndex = () => {
    const [sidebarCollapsed, setSidebarCollapsed] = useState<boolean>(true);
    const menuItems: MenuItem[] = [
        {
            id: 'Teacher',
            name: 'Dashboard',
            path: '/teacher',
        }, {
            id: 'Courses',
            name: 'Courses',
            path: 'courses',
        },
        {
            id: 'Assignments',
            name: 'Assignments',
            path: 'assignments',
        },
        {
            id: 'Audit',
            name: 'Audit',
            path: 'audit'
        },
        {
            id: 'Verification',
            name: 'Verification',
            path: 'Verification'
        },
        // {
        //     id:'Questions',
        //     name:'Questions',
        //     path:'Questions'
        // }
    ];

    return <div className='wrapper'>
        <Provider store={store}>
            {/* <!-- ========== Left SideBar Starts ========== --> */}
            <Sidebar moduleName={'Teacher'} menuItems={menuItems} collapsedSidebar={sidebarCollapsed}
                     onClose={(newState) => setSidebarCollapsed(newState)}/>
            {/* <!-- ========== Left SideBar Ends ========== --> */}

            <div className={'page-wrapper'}>
                {/* <!-- ========== Header Starts ========== --> */}
                <Header collapsedSidebar={sidebarCollapsed} onClick={(newState) => setSidebarCollapsed(newState)}/>
                {/* <!-- ========== Header Ends ========== --> */}

                {/* <!-- ========== Start Page Content ========== --> */}
                <div className={`${sidebarCollapsed ? 'main_content_wrap' : 'collapsed_main_wrap'}`}>
                    <Routes>
                        <Route path={"/"} element={<TeacherDashboard/>}/>
                        <Route path={"/createTest"} element={<CreateAssignment/>}/>
                        <Route path={"/profile"} element={<Profile/>}/>
                        <Route path={"/courses"} element={<CourseCommon/>}/>
                        {/*<Route path={"/courses/:id"} element={<TeacherCourseViewPage/>}/>*/}
                        <Route path={`/courses/:courseId`} element={<CourseView/>}/>
                        <Route path={`/courses/:courseId/programs/:programId`} element={<Chapters/>}/>
                        <Route path={`/courses/:courseId/programs/:programId/chapter/:chapterId/topics`}
                               element={<CourseTopics/>}/>
                        <Route path={`/courses/:courseId/assignments`} element={<Assignments/>}/>
                        <Route path={`/assignments`} element={<TeacherListAssignments/>}/>
                        <Route path={`/assignments/:assignmentId`} element={<AssignmentQuestionListing/>}/>
                        <Route path={`/assignments/:assignmentId/:Comprehension`} element={<AssignmentQuestionListing/>}/>
                        <Route path={`/assignment/:assignmentId/:assignmentName`} element={<AssignmentBatchListing/>}/>
                        <Route path={`/audit`} element={<Audit/>}/>
                        {/*<Route path={`/CustomQueryTitle`} element={<CustomQueryTitle/>}/>*/}

                        {/*<Route path={"/courses"} element={<TeacherCourses/>}/>*/}
                        {/*<Route path={"/courses/:id"} element={<TeacherCourseViewPage/>}/>*/}
                        {/*<Route path={`/courses/:id/assignments`} element={<Assignments/>}/>*/}
                        {/*<Route path={`/courses/:id`} element={<Content/>}/>*/}

                        <Route path={"/:id/createTest"} element={<CreateAssignment/>}/>
                        <Route path={"Verification"} element={<Verification/>}/>
                        {/*<Route path={"Questions"} element={<QuestionsListing/>}/>*/}
                    </Routes>
                </div>
                {/* <!-- ========== End Page Content ========== --> */}

                {/* <!-- ========== Footer Start ========== --> */}
                <Footer/>
                {/* <!-- ========== end Footer ========== --> */}
            </div>
        </Provider>

    </div>
}