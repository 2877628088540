import Modal from "react-bootstrap/Modal";
import {ModalBody, ModalFooter, ModalHeader} from "react-bootstrap";
import {TopicAnalysisData} from "./StudentAssignmentDropDown";

export interface TopicsAnalysisModalProps {
    show: boolean;
    onHide: () => void;
    data: TopicAnalysisData[]
    courseId:number
    programId:number
    currentPage:number
    pageSize:number
    totalPages:number
    totalElements:number
    fetchStudentEvaluationForTopics:(courseId:number,programId:number,page?:number,pageSize?:number) => void
}

export const TopicsAnalysisModal = (props: TopicsAnalysisModalProps) => {

    return (
        <Modal show={props.show} onHide={props.onHide} className="modal-lg">
            <ModalHeader closeButton>
                <h5>Topics</h5>
            </ModalHeader>
            <ModalBody>
                <table className="table table-hover">
                    <thead>
                    <tr>
                        <th>Sr.No</th>
                        <th>Topics</th>
                        <th>Percentage Score</th>
                    </tr>
                    </thead>
                    <tbody>
                    {props.data?.map((res: any, index: number) => (
                        <tr>
                            <th>{index + 1}</th>
                            <td>{res?.name}</td>
                            <td>{res?.percentage}%</td>
                        </tr>
                    ))}

                    </tbody>
                </table>
                {/*<Pagination currentPage={props.currentPage} totalPages={props.totalPages}*/}
                {/*            onPageChange={(page)=>props.fetchStudentEvaluationForTopics(props.courseId, props.programId,page,props.pageSize)}*/}
                {/*            totalElements={props.totalElements}/>*/}
                <div className='d-flex float-end'>
                    <button className='btn btn-primary' onClick={() => props.onHide()}>
                        Close
                    </button>
                </div>
            </ModalBody>
        </Modal>
    );
};
