import React, {FormEvent, Fragment, useEffect, useState} from "react";
import {Modal, ModalBody} from "react-bootstrap";
import Button from "../../../../../../components/button/Button";
import {AllToasts} from "../../../../../../components/toast/AllToasts";
import {useStudentApiClient} from "../../../../../student/apiClients/StudentApiClient";
import "./batchAssignment.css";
import {EmptyState} from "../../../../../../components/emptyState/EmptyState";
import Pagination from "../../../../../../components/pagination/Pagination";

export interface BatchIdPayload {
    batchIds: number[];
    dueDate: string
}

interface Batch {
    id: number;
}

export const BatchAssignment = (props: {
    show: boolean,
    onCancel: () => void,
    title?: string,
    id?: string | number,
    assignmentId: number | undefined
    courseId?: number
    assignments?: any
}) => {
    const [payload, setPayload] = useState<BatchIdPayload>({
        batchIds: [],
        dueDate: ""
    });
    const [dueDate, setDueDate] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [query, setQuery] = useState<string>('')
    const [batches, setBatch] = useState<any[]>([]);
    const [selectedBatches, setSelectedBatches] = useState<number[]>([]);
    const useStudentApi = useStudentApiClient();
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(5);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [totalElements, setTotalElements] = useState<number>(0);
    const [batchIds, setBatchIds] = useState<number[]>([]);

    useEffect(() => {
        setSelectedBatches(batchIds);
    }, [batchIds]);

    useEffect(() => {
        setPayload({batchIds: selectedBatches, dueDate});
    }, [selectedBatches, dueDate]);

    useEffect(() => {
        handleGetAssignment(props.assignmentId ?? 0)
    }, [props.assignmentId]);

    useEffect(() => {
        getAllBatch(Number(props?.courseId), currentPage, pageSize, query);
    }, [props.courseId]);

    useEffect(() => {
        const delayTimer = setTimeout(() => {
            getAllBatch(Number(props?.courseId), 0, pageSize, query);
        }, 300);
        return () => {
            clearTimeout(delayTimer);
        }
    }, [query, props.courseId]);

    const handleForm = (e: FormEvent) => {
        e.preventDefault();
        setLoading(true);
        setPayload({...payload, batchIds: selectedBatches});
        useStudentApi.assignTestToBatch(props.assignmentId ?? 0, payload).then(() => {
            setLoading(false);
            props.onCancel();
            AllToasts.success('Assigned successfully');
        }).catch((e) => {
            setLoading(false);
            AllToasts.failure('Fail to add');
            console.log("error", e)
        })
    }

    const getAllBatch = (courseId: number, page?: number, size?: number, query?: string) => {
        useStudentApi.getAllBatch(courseId, page, size, query).then((res: any) => {
            setBatch(res.data?.content)
            setCurrentPage(res.data.pageable.pageNumber)
            setPageSize(res.data.pageable.pageSize)
            setTotalPages(res.data.totalPages);
            setTotalElements(res.data.totalElements);
        }).catch((e) => {
            console.log("error", e)
        })
    }

    const handleGetAssignment = (assignmentId: number) => {
        useStudentApi.getAllAssignmentQuestions(assignmentId).then(res => {
            setBatchIds(res.data.batches.map((batch: Batch) => batch.id));
        }).catch(error => {
            console.log(error);
        })
    }

    const handleCheckboxChange = (selectedId: number, e: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = e.target.checked;
        setSelectedBatches(prevSelectedBatches => {
            if (isChecked && !prevSelectedBatches.includes(selectedId)) {
                return [...prevSelectedBatches, selectedId];
            } else if (!isChecked) {
                return prevSelectedBatches.filter(id => id !== selectedId);
            } else {
                return prevSelectedBatches;
            }
        });
    };

    const handleQuestionClick = (batchId: number) => {
        const index = payload.batchIds.indexOf(batchId);
        if (index !== -1) {
            const updatedBatchIds = [...payload.batchIds];
            updatedBatchIds.splice(index, 1);
            setPayload({...payload, batchIds: updatedBatchIds});
        } else {
            setPayload({...payload, batchIds: [...payload.batchIds, batchId]});
        }
    };


    return (<div>
            <Modal show={props.show} onHide={() => props.onCancel()}>
                <Modal.Header closeButton className="border-0 pb-0">
                    <h5>{props.title}</h5>
                </Modal.Header>
                <ModalBody className="modal_body mx-2">
                    <div className={`d-flex justify-content-between py-3 top-bar `}>
                        <>
                            <div className="input-group pe-4">
                                <input type={"search"} className="form-control"
                                       onChange={(event) => setQuery(event.target.value)}/>
                                <span className="input-group-text bi bi-search"/>
                                {/*<p className={`small text-secondary ms-4 mt-0`}>{courses.length} Courses</p>*/}
                            </div>
                        </>
                    </div>

                    {batches?.length ?

                        <form onSubmit={(e) => handleForm(e)} id={`create-product-form`}>
                            <div className='bg-white pb-4'>
                                {batches?.map((batch?: any) => (<Fragment>
                                        <div key={batch.id}>
                                            <div>
                                                <div
                                                    className={`container d-flex align-items-center p-3 ${payload.batchIds === batch.id && 'expanded-question'}`}
                                                    style={{backgroundColor: '#F9F9F9'}}>
                                                    <div className='me-4'>
                                                        <input
                                                            type="checkbox"
                                                            name="batchId"
                                                            checked={selectedBatches.includes(batch.id)}
                                                            onChange={(e) => handleCheckboxChange(batch.id, e)}
                                                        />

                                                    </div>
                                                    {/*{!props.id && <p className={'text-danger'}>Please selected courseId</p>}*/}
                                                    <div className='flex-grow-1'
                                                         onClick={() => handleQuestionClick(batch.id)}>
                                                        {batch.name || 'No Batch available'}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Fragment>

                                ))}
                                {totalPages > 1 &&
                                    <div className='d-flex align-items-center justify-content-center mt-2 mb-2 h-5'>
                                        <Pagination currentPage={currentPage} totalPages={totalPages}
                                                    totalElements={totalElements}
                                                    onPageChange={(page) => getAllBatch(Number(props?.id), page, pageSize, query)}
                                        />
                                    </div>
                                }
                                <div className="col-md-6">
                                    <h6 className="mt-3">Due date</h6>
                                    <input
                                        type="datetime-local"
                                        className="form-control"
                                        value={dueDate}
                                        onChange={(e) => setDueDate(e.target.value)}
                                        required
                                    />
                                </div>
                            </div>

                            <div className={'d-flex mt-3'}>
                                <div className={'flex-fill'}></div>
                                <div>
                                    <button className="btn btn-secondary me-2" onClick={props.onCancel}>Cancel</button>
                                    <Button label={'Save'} loading={loading}
                                            cssName={'btn btn-primary'}/>

                                </div>
                            </div>
                        </form> :
                        <EmptyState/>}
                </ModalBody>
            </Modal>


        </div>
    )
}