import React, {useEffect, useState} from "react";
import {useStudentApiClient} from "../../apiClients/StudentApiClient";
import "./StudentTopics.scss"
import {useParams} from "react-router-dom";
import StudentTopicFreeTrail from "./StudentTopicFreeTrail";
import StudentTopicPaid from "./StudentTopicPaid";


export interface Topics {
    id: number;
    name: string;
}

const StudentTopics = () => {
    const studentApiClient = useStudentApiClient();
    const params = useParams();
    const [course, setCourse] = useState<any[]>([]);
    const [subscriptionType, setSubscriptionType] = useState<string>('');

    useEffect(() => {
        console.log('subscriptionType', subscriptionType);
    }, [subscriptionType]);

    useEffect(() => {
        const SelectedCourse = course.filter((res) => res.id.toString() === params.courseBatchId?.toString());
        console.log('SelectedCourse:', SelectedCourse);
        if (SelectedCourse.length > 0) {
            setSubscriptionType(SelectedCourse.map((res)=> res.typeOfCourse)[0]);
        }
    }, [course, params.courseBatchId]);

    useEffect(() => {
        studentProfile();
    }, []);

    const studentProfile = () => {
        studentApiClient.getAllStudentCourseBatches().then((res) => {
            setCourse(res.data);
            console.log('data',res.data)
        }).catch((err) => {
            console.log(err);
        });
    }

    return (
        <div>

            {subscriptionType === "PAID" &&
                <StudentTopicPaid/>}
            {subscriptionType === 'FREE_TRIAL' &&
                <StudentTopicFreeTrail/>
            }
        </div>
    );
};

export default StudentTopics;