import {useAxios} from "../configs/AxiosClient";


export class CourseRepo {
    static axiosClient = useAxios();

    static createCourse(payload: any) {
        return CourseRepo.axiosClient.post('/courses', payload);
    }

    static getAllCourses(query?: string){
        return CourseRepo.axiosClient.get('/courses',{
            params: {
                query: query,
            }
        })
    }

    static updateCourses(defaultCourseId:string,payload:any){
        return CourseRepo.axiosClient.put(`/courses/${defaultCourseId}`,payload);
    }

    static getTopicById(topicId:number) {
        return CourseRepo.axiosClient.get(`/topics/${topicId}`)
    }

    static updateTopic(topicId: number, payload:any) {
        return CourseRepo.axiosClient.put(`/topics/${topicId}`,payload);
    }

    static getSingleCourse(courseId:string){
        return CourseRepo.axiosClient.get(`/courses/${courseId}`);
    }
    static getAllAssignmentOfCourse(page: number, size: number, query?: string, course?: number,program?:number,comp?:boolean){
        return CourseRepo.axiosClient.get(`/assignments`,{
            params: {
                page:page,
                size:size,
                query:query,
                course:course,
                program:program,
                comp:comp
            },
        });
    }

    static softDeleteCourse(courseId : string){
        return CourseRepo.axiosClient.delete(`/course/${courseId}/active`);
    }

    static undoSoftDeleteCourse(courseId : string){
        return CourseRepo.axiosClient.put(`/course/${courseId}/active`);
    }

    static getRecentlyViewedCoursesByStudent = (courseId:number)=> {
        return CourseRepo.axiosClient.get(`/students/courses/${courseId}/recently-viewed`)
    }
}