import './index.scss';
import React from 'react';
import ReactDOM from 'react-dom/client';
import "bootstrap/dist/css/bootstrap.css";
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";
import {BrowserRouter} from "react-router-dom";
import App from "./App";

const recaptcha = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
recaptcha.render(
    <BrowserRouter>
        <GoogleReCaptchaProvider
            reCaptchaKey="6LfmDDwoAAAAADZ-jpPxr7cwaiEp3temJPVnP-Zh"
            scriptProps={{
                async: false, // optional, default to false,
                defer: false, // optional, default to false
                appendTo: 'head', // optional, default to "head", can be "head" or "body",
                nonce: undefined // optional, default undefined
            }}>
            <App/>
        </GoogleReCaptchaProvider>
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
