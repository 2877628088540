import React from "react";
import { TestPayload } from "../PreviousTest";
import { useNavigate } from "react-router-dom";

interface AssignmentTableProps {
    data: TestPayload[];
    selectedOption: string;
}

const AssignmentTable: React.FC<AssignmentTableProps> = ({ data, selectedOption }) => {
    const navigate = useNavigate();

    return (
        <table className={`table table-hover studentTable table-bordered`}>
            <thead className={'thead'} style={{ color: 'black' }}>
            <tr>
                <th scope="col">{selectedOption === 'ASSIGNMENT' ? 'Program Name' : 'Test Name'}</th>
                <th scope="col">Program Name</th>
                <th scope="col">Total Marks</th>
                <th scope="col">Obtained Marks</th>
                <th scope="col">Review test</th>
            </tr>
            </thead>
            <tbody>
            {data?.map((item: TestPayload) => (
                <tr key={item.id}>
                    <td>{selectedOption === 'ASSIGNMENT' ? item.programName : item.testName || "--"}</td>
                    <td>{item.programName || "--"}</td>
                    <td>{item.totalMarks || " "}</td>
                    <td>{item.obtainedMarks || " "}</td>
                    <td>
                        <button
                            className='btn-add rounded fw-light text-white'
                            onClick={() => navigate(`../${selectedOption}/review/${item.id}`)}>
                            Review
                        </button>
                    </td>
                </tr>
            ))}
            </tbody>
        </table>
    );
};

export default AssignmentTable;
