import {Modal, ModalBody} from "react-bootstrap";
import React, {FormEvent, useEffect, useState} from "react";
import Select from "react-dropdown-select";
import {ChapterRepo} from "../../../../../../repos/ChapterRepo";
import {AllToasts} from "../../../../../toast/AllToasts";
import Button from "../../../../../button/Button";
import {Subject, SubjectTopic} from "../../../../../../modules/teacher/components/modals/CreateiUpdateQuestion";


export type TopicPayload = {
    topicIds: number[],
};
const AddTopic = (props: { show: boolean, onCancel: (shouldRefresh?: boolean) => void, id: string, defaultValue?: any, title?: string, onSuccess: () => void }) => {
    const [payload, setPayload] = useState<TopicPayload>({topicIds: [],})
    const [subjects, setSubjects] = useState<Subject[]>([]);
    const [topics, setTopics] = useState<SubjectTopic[]>([]);
    const [selectedSubject, setSelectedSubject] = useState< { label: string, value: number }[]>([])
    const [selectedTopic, setSelectedTopic] = useState< { label: string, value: number }[]>([])
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        getAllSubjects();
    }, []);

    // const getAllTopics=(subjectId:string)=>{
    //     ChapterRepo.getAllSubjectTopics(subjectId).then((res)=>{
    //         setTopics(res.data.content);
    //     })
    // }


    const getAllSubjects = () => {
        ChapterRepo.getAllSubjects().then((res) => {
            // console.log('subjects',res.data)
            // const _sub: any = []res.data.map((re: any) => {_sub.push({label: re.name, value: re.id})})
            setSubjects(res.data);
        })
    }

    const handleSubjectSelect = (subject: { label: string, value: number }[]) => {
        setSelectedSubject(subject)
        setSelectedTopic([])
        const id = subject.map((res) => res.value)[0];
        const isTopicSelected = subjects.find((subject: any) => subject.id === id)
        setTopics(isTopicSelected?.topics || [])
        console.log(isTopicSelected?.topics)
        // getAllTopics(id);
    }
    const handleTopicSelect = (topics: { label: string, value: number }[]) => {
        setSelectedTopic(topics)
        const ids = topics.map((value: any) => value.value);
        setPayload({topicIds: ids});
    };

    const handleInput = (e: any) => {
        let name = e.target.name;
        let value = e.target.value;
        setPayload({...payload, [name]: value});
    }

    const handleForm = (e: FormEvent, chapterId: string, payload: any) => {
        e.preventDefault();
        setLoading(true);
        setPayload({topicIds: [],});
        ChapterRepo.addTopic(chapterId, payload).then(() => {
            setLoading(false);
            AllToasts.success('Topic Added Successfully')
            props.onSuccess();
            setPayload({topicIds: [],});
        }).catch((e) => {
            setLoading(false);
            AllToasts.failure('Fail to add');
            props.onCancel();
            console.log("error", e)
        })
    }

    const onClose = () => {
        props.onCancel();
        setPayload({
            topicIds: [],
        });
    }

    return <div>
        <Modal show={props.show} onHide={onClose}>
            <Modal.Header closeButton className="border-0 pb-0">
                <h5>{props.title ?? ''}</h5>
            </Modal.Header>
            <ModalBody className="modal_body mx-2">
                <form onSubmit={(e) => handleForm(e, props.id, payload)} id={`create-program-form`}>
                    <div className="form-group mt-2">
                        <label className={''}>Select Subject <span className='text-danger'>*</span></label>
                        <Select className={`w-100`} placeholder={"Select"}
                                values={selectedSubject || []}
                                options={subjects.map((subject: any) => ({label: subject.name, value: subject.id}))}
                                onChange={(subject: any) => handleSubjectSelect(subject)}/>

                    </div>

                    <div className="form-group mt-2">
                        <label htmlFor="exampleInputSubjects">Select Topic</label>
                        <Select className={`w-100`} placeholder={"select"}
                                values={selectedTopic || []}
                                options={topics.map((topic: any) => ({label: topic.name, value: topic.id}))}
                                onChange={(topic: any) => handleTopicSelect(topic)} multi/>
                    </div>

                    <div className={'d-flex mt-3'}>
                        <div className={'flex-fill'}></div>
                        <div>
                            <button type={`button`} className="btn btn-secondary me-2" onClick={onClose}>Cancel</button>
                            <Button label={props.defaultValue ? 'Update' : 'Add Topic'} type={'submit'}
                                    loading={loading} cssName={'btn btn-sm btn-create'}/>

                        </div>
                    </div>
                </form>
            </ModalBody>
        </Modal>
    </div>
}
export default AddTopic;