import React, { createContext, useContext, useState, ReactNode } from 'react';
import { Role } from "../dto/User";
import {SessionManager} from "../configs/SessionManager";

interface AuthContextProps {
    userData: User;
    saveUserData: (userData: User) => void;
}

export interface User {
    id?: string;
    name?: string;
    mobile?: string;
    email?: string;
    role: Role;
    imageUrl?: string;
    imageCid?: string;
    user?:User
}

export const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export const UserProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [userData, setUserData] = useState<User>({
        id: "",
        name: "",
        mobile: "",
        email: "",
        role: Role.Unknown,
        imageUrl: SessionManager.getSessionUser()?.imageUrl,
        imageCid: "",
    });

    const saveUserData = (userData: User) => {
        setUserData(userData);
    };

    return (
        <AuthContext.Provider value={{ userData, saveUserData }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};