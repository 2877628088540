import {ResponseBack} from "../../../utils/Type";
import {useState} from "react";


export const LoadMorePagination=(props:{currentPage:number,totalPage:number,change:ResponseBack,loading:boolean})=>{
    const [hover, setHover] = useState(false);


    const loadMore=()=>{
        if(props.loading){
            return<div><i className="bi bi-arrow-repeat"></i>
                {/*<FontAwesomeIcon className={'fa-spin me-1'} icon={faSpinner}/>*/}
            </div>
        }else{
            return <div className='d-flex align-items-center justify-content-center'>
                <button className={'btn btn-outline-primary  mt-5 mb-5 '}   style={{
                    border: '2px solid #3498DB',
                    color: hover ? 'white' : '#3498DB',
                    backgroundColor: hover ? '#3498DB' : 'transparent',
                }}
                        onMouseEnter={() => setHover(true)}
                        onMouseLeave={() => setHover(false)}onClick={(e)=>{
                    e.preventDefault();
                    props.change({});
                }
                }>Load More</button>
            </div>

        }
    }


    return <div>
        {loadMore()}
    </div>
}
export default LoadMorePagination;