import React, {useEffect, useState} from 'react';
import {CourseCard} from "./CourseCard/CourseCard";

import {useLocation, useNavigate, useParams, useSearchParams} from "react-router-dom";
import {Courses} from "../../../dto/Courses";
import CourseCardShimmer from "../../shimmer/course/CourseCardShimmer";
import CreateCourse from "./createCourse/CreateCourse";
import {CourseRepo} from "../../../repos/CourseRepo";


export const CourseCommon = () => {
    const [query, setQuery] = useState<string>('')
    const [show, setShow] = useState<boolean>(false);
    const [updateShow, setUpdateShow] = useState<boolean>(false);
    const [courses, setCourses] = useState<Courses[]>([])
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(10);
    const [totalPages, setTotalPages] = useState<number>(0);
    const navigate = useNavigate();
    const [totalElements, setTotalElements] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);
    const [editId, setEditId] = useState<string | undefined>(undefined);
    const [authorities, setAuthorities] = useState<boolean>(false);


    useEffect(() => {
        setLoading(true);
        const delayTimer = setTimeout(() => {
            getCourses();
            setLoading(false);
        }, 500);
        return () => {
            clearTimeout(delayTimer);
        };
    }, [query]);

    useEffect(() => {
        if (localStorage.getItem("SESSION_USER") && JSON.parse(localStorage.getItem("SESSION_USER")!).role === 'Admin') {
            setAuthorities(true);
        }
    }, []);

    const getCourses = () => {
        CourseRepo.getAllCourses(query).then((res: any) => {
            setCourses(res.data);
            setCurrentPage(res.data.number)
            setPageSize(res.data.size)
            setTotalPages(res.data.totalPages);
            setTotalElements(res.data.totalElements);
        }).catch((err) => {
            console.log(err)
        })
    }

    const handleEditClick = (courseId: string) => {
        console.log("49", courseId);
        setEditId(courseId);
        setShow(true);
    }

    return <div className={'container-fluid page-content'}>
        <div className="row page-titles">
            <div className="col-sm-6 p-md-0 mb-2">
                <div className="col-sm-6 p-md-0">
                    <div className="welcome-text">
                        <h4>All Courses</h4>
                    </div>
                </div>
            </div>
            <div className="col-sm-6 p-md-0 justify-content-sm-end mt-2 mt-sm-0 d-flex">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item d-flex" aria-current="page">Home</li>
                    <li className="breadcrumb-item ">All Courses</li>
                </ol>
            </div>
        </div>

        <div className='card border-0 shadow-sm'>
            <div className={'card-body'}>
                <div className="d-flex">
                    <div className="col-md-6 px-0">
                        <div className="form-group searchBar position-relative">
                            <input
                                type={"search"}
                                className="form-control w-100 formControl"
                                placeholder={"Search here ..."}
                                onChange={(event) => setQuery(event.target.value)}
                            />
                            <button className={'bg-transparent transition p-0 border-0'}><i
                                className="bi bi-search"></i>
                            </button>
                        </div>
                    </div>
                    <div className="col-md-6 d-flex align-items-center justify-content-end">
                        <button className={"btn btn-primary text-white rounded-1 btn-add"}
                                onClick={() =>
                                    setShow(true)
                                }><i className="bi bi-plus-lg"></i> Add new
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div className={''}>
            {loading ? <div className={'row row-cols-1 row-cols-md-4 m-4'}><CourseCardShimmer/></div> :
                <div className={'container-fluid'}>
                    <div className={'row'}>
                        {courses?.map((data: any) =>
                            (
                                <CourseCard key={data.id} course={data}
                                            onEditClick={() => handleEditClick(data.id)}
                                            onRefresh={() => getCourses()}
                                            authorities={authorities}/>
                            ))
                        }
                    </div>
                </div>}
            <CreateCourse show={show} onCancel={() => {
                setShow(false);
                setEditId(undefined);
                getCourses();
            }} editId={editId}/>
        </div>

    </div>
}