import {Modal, ModalBody, ModalHeader} from "react-bootstrap";
import {useAdminApiClient} from "../../../apiClients/AdminApiClient";
import {useEffect, useState} from "react";
import {EmptyState} from "../../../../../components/emptyState/EmptyState";
import Pagination from "../../../../../components/pagination/Pagination";

interface CouponStatsProps {
    show: boolean;
    onHide: () => void;
    couponId?: string
}

interface CouponCountResponse {
    code: string,
    count: number
}

interface CouponStatsResponse {
    studentName: string,
    appliedOn: string,
    courseName: string
}

const StatsModal = (props: CouponStatsProps) => {
    const adminApiClient = useAdminApiClient();
    const [countData, setCountData] = useState<CouponCountResponse | undefined>(undefined);
    const [statsData, setStatsData] = useState<CouponStatsResponse[] | undefined>(undefined);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [totalElements, setTotalElements] = useState<number>(0);
    const pageSize = 10;

    useEffect(() => {
        getCouponCount(props?.couponId);
        getCouponStats(props.couponId ?? '0');
    }, []);


    const getCouponCount = (couponId ?: string) => {
        adminApiClient.getCouponCount(couponId).then(response => {
            setCountData(response.data);
        })
    }

    const getCouponStats = (couponId: string, page ?: number, size ?: number) => {
        adminApiClient.getCouponStats(couponId, page, size).then(response => {
            setStatsData(response.data.content);
            setCurrentPage(response.data.pageable.pageNumber);
            setTotalPages(response.data.totalPages);
            setTotalElements(response.data.totalElements);
        })
    }

    return (
        <div>
            <Modal show={props.show} onHide={props.onHide} size={'lg'}>
                <ModalHeader closeButton={true}>
                    <div className={'d-flex justify-content-center align-items-center w-100'}>
                        <h3>Coupon Stats</h3>
                    </div>
                </ModalHeader>
                <ModalBody>
                    {
                        countData && countData?.count > 0 ?
                            <div className={'text-center'}>
                                <div className={'d-flex justify-content-center align-items-center'}>
                                    <div className={'d-flex justify-content-between align-items-center w-75 text-center'}>
                                        <div>
                                            <h5>Coupon Code</h5>
                                            <p>{countData.code}</p>
                                        </div>
                                        <div>
                                            <h5>Times Applied</h5>
                                            <p>{countData.count}</p>
                                        </div>
                                    </div>
                                </div>
                                <hr/>
                                <div className={'w-100'}>
                                    <table className={'table table-striped'}>
                                        <thead>
                                        <tr>
                                            <th scope="col">Sr No</th>
                                            <th scope="col">Student</th>
                                            <th scope="col">Date</th>
                                            <th scope="col">Course</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            statsData?.map((stat, index) => (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{stat.studentName}</td>
                                                    <td>{stat.appliedOn.split('T')[0]}</td>
                                                    <td>{stat.courseName}</td>
                                                </tr>
                                            ))
                                        }
                                        </tbody>
                                    </table>
                                    {totalPages > 1 &&
                                        <Pagination currentPage={currentPage}
                                                    totalPages={totalPages}
                                                    onPageChange={(page) => getCouponStats(props.couponId || '0', page, pageSize)}
                                                    totalElements={totalElements}
                                        />}
                                </div>
                            </div> : <EmptyState/>
                    }
                </ModalBody>
            </Modal>
        </div>
    );
}
export default StatsModal;