import Skeleton from "react-loading-skeleton";


export const CourseCardShimmer=()=>{



    return <div className={'row row-cols-3 w-100'}>
        <Skeleton className={'col-4 m-2 p-2'} height={150} width={400}></Skeleton>
        <Skeleton className={'col-4 m-2 p-2'} height={150} width={400}></Skeleton>
        <Skeleton className={'col-4 m-2 p-2'}height={150} width={400}></Skeleton>
        <Skeleton className={'col-4 m-2 p-2'} height={150} width={400}></Skeleton>
        <Skeleton className={'col-4 m-2 p-2'} height={150} width={400}></Skeleton>
        <Skeleton className={'col-4 m-2 p-2'} height={150} width={400}></Skeleton>
        <Skeleton className={'col-4 m-2 p-2'} height={150} width={400}></Skeleton>
        <Skeleton className={'col-4 m-2 p-2'} height={150} width={400}></Skeleton>
        <Skeleton className={'col-4 m-2 p-2'} height={150} width={400}></Skeleton>
        <Skeleton className={'col-4 m-2 p-2'} height={150} width={400}></Skeleton>
        <Skeleton className={'col-4 m-2 p-2'} height={150} width={400}></Skeleton>
        <Skeleton className={'col-4 m-2 p-2'} height={150} width={400}></Skeleton>
        <Skeleton className={'col-4 m-2 p-2'} height={150} width={400}></Skeleton>
    </div>
}
export default CourseCardShimmer;