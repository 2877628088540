import "./QuizStatusIndicator.scss"
import {QuestionData} from "../quizStepper/QuizStepper";
import {useEffect} from "react";

export const QuizStatusIndicator=(props:{questionData: QuestionData[]})=>{
    return <div className={'indicatorContainer d-grid grid_auto'}>
        <div className={'d-flex align-items-center'}>
            <div className={'indicator-step answered'}>
                {/*{props.questionData.filter((item) =>*/}
                {/*    item.selectedChoice && item.selectedChoice.length > 0).length + props.questionData.filter((item) => item.answer).length}*/}
            </div>
            <div className={'py-2 indicator_label'}>Answered</div>
        </div>
        <div className={'d-flex align-items-center'}>
            <div className={'indicator-step notAnswered'}>
                {/*    {((props.questionData.length) - ((props.questionData.*/}
                {/*filter((item)=>item.selectedChoice && item.selectedChoice?.length > 0).length) + (props.questionData.filter((item)=>item.answer).length)))}*/}
            </div>
            <div className={'py-2 indicator_label'} style={{paddingRight: "35px"}}>Skipped</div>
        </div>
        <div className={'d-flex align-items-center'}>
            <div className={'indicator-step'}>
                {/*{Math.max((props.questionData.length - props.questionData*/}
                {/*.filter(item => item.attempted === true).length) - 1, 0)}*/}
            </div>
            <div className={'py-2 indicator_label'}>Not visited</div>
        </div>
        <div className={'d-flex align-items-center'}>
            <div className={'indicator-step bookMark'}>
                {/*{props.questionData.filter((item) => item.bookMark).length}*/}
            </div>
            <div className={'py-2 indicator_label'}>Marked for Review</div>
        </div>
    </div>
}
export default QuizStatusIndicator;