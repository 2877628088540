import {Route, Routes} from "react-router-dom";
import {MenuItem} from "../../modals/MenuItem";
import AdminGroup from "./components/AdminGroup";
import BatchDetails from "./components/BatchDetails";
import AdminTeachers from "./components/teacher/AdminTeachers";
import AdminDashboard from "./components/AdminDashboard";
import {Header} from "../../components/common/header/Header";
import {Footer} from "../../components/common/footer/footer";
import Sidebar from "../../components/common/sidebar/Sidebar";
import TeacherProfile from "./components/teacher/teacherProfile/TeacherProfile";
import AdminSubjects from "./components/AdminSubjects";
import Profile from "../../components/common/Profile/Profile";
import StudentDashboardHome from "./components/studentOnboard/StudentDashboardHome";
import Topics from "./components/subjects/subjectCard/subjectTopic/Topics";
import {CourseCommon} from "../../components/common/course/Course";
import {Chapters} from "../../components/common/course/program/chapters/Chapters";
import {CourseTopics} from "../../components/common/course/program/chapters/topics/Topics";
import {CourseView} from "../../components/common/course/courseView/CourseView";
import {BulkUpload} from "./components/Imports/BulkUpload";
import {useState} from "react";
import './admin.scss';
import {StudentEvalution} from "./components/studentEvauationScreen/StudentEvalution";
import {StudentEvalutionCommentsModal} from "./components/studentEvauationScreen/StudentEvalutionCommentsModal";
import {CreateDataExecutive} from "./components/dataExecutive/CreateDataExecutive";
import {PaymentScreen} from "./components/payments/PaymentScreen";
import {CouponScreen} from "./components/coupon/CouponScreen";
import NoticeBoard from "./components/notice/NoticeBoard";

export const AdminIndex = () => {
    const [sidebarCollapsed, setSidebarCollapsed] = useState<boolean>(true);
    const menuItems: MenuItem[] = [
        {
            id: 'Admin',
            name: 'Dashboard',
            path: '/admin',
        }, {
            id: 'Students',
            name: 'Student',
            path: 'students',
        }, {
            id: 'Teachers',
            name: 'Teacher',
            path: 'teachers',
        }, {
            id: 'Course',
            name: 'Courses',
            path: 'courses',
        }, {
            id: 'Batches',
            name: 'Batches',
            path: 'batches',
        }, {
            id: 'Subjects',
            name: 'Subjects',
            path: 'subjects'
        },
        {
            id: 'Imports',
            name: 'Imports',
            path: 'imports'
        },
        {
            id: 'Payments',
            name: 'Payments',
            path: 'payment'
        },
        {
            id: 'DataExecutive',
            name: 'DataExecutive',
            path: 'dataExecutive'
        },
        {
            id: 'Coupon',
            name: 'Coupon Mgmt',
            path: 'coupon'
        },
        {
            id: 'Notice',
            name: 'Notice',
            path: 'notice'
        }
    ];

    return <div className='wrapper'>
        {/* <!-- ========== Left SideBar Starts ========== --> */}
        <Sidebar moduleName={"Admin"} menuItems={menuItems} collapsedSidebar={sidebarCollapsed}
                 onClose={(newState) => setSidebarCollapsed(newState)}/>
        {/* <!-- ========== Left SideBar Ends ========== --> */}

        <div className={'page-wrapper'}>
            {/* <!-- ========== Header Starts ========== --> */}
            <Header collapsedSidebar={sidebarCollapsed} onClick={(newState) => setSidebarCollapsed(newState)}/>
            {/* <!-- ========== Header Ends ========== --> */}

            {/* <!-- ========== Start Page Content ========== --> */}
            <div className={`${sidebarCollapsed? 'main_content_wrap': 'collapsed_main_wrap'}`} >
                <Routes>
                    <Route path={"/"} element={<AdminDashboard/>}/>
                    <Route path={"/students"} element={<StudentDashboardHome/>}/>
                    <Route path={"/courses"} element={<CourseCommon/>}/>
                    <Route path={`/courses/:courseId`} element={<CourseView/>}/>
                    <Route path={`/courses/:courseId/programs/:programId`} element={<Chapters/>}/>
                    <Route path={`/courses/:courseId/programs/:programId/chapter/:chapterId/topics`}
                           element={<CourseTopics/>}/>
                    <Route path={"/batches"} element={<AdminGroup/>}/>
                    <Route path={"/batches/:id/:name"} element={<BatchDetails/>}/>
                    <Route path={"/teachers"} element={<AdminTeachers/>}/>
                    <Route path={"/teachers/:id/profile"} element={<TeacherProfile/>}/>
                    <Route path={"/profile"} element={<Profile/>}/>
                    <Route path={"/subjects"} element={<AdminSubjects/>}/>
                    <Route path={"/subjects/:id/topics"} element={<Topics/>}/>
                    <Route path={"/imports"} element={<BulkUpload/>}/>
                    <Route path={"/studentEvaluation/:studentId"} element={<StudentEvalution/>}/>
                    <Route path={"/studentEvaluation/:studentId/course/:courseId/comment"} element={<StudentEvalutionCommentsModal/>}/>
                    <Route path={'/payment'} element={<PaymentScreen/>}/>
                    <Route path={"/dataExecutive"} element={<CreateDataExecutive/>}/>
                    <Route path={"/coupon"} element={<CouponScreen/>}/>
                    <Route path={"/notice"} element={<NoticeBoard/>}/>
                </Routes>
            </div>
            {/* <!-- ========== End Page Content ========== --> */}
            {/* <!-- ========== Footer Start ========== --> */}
            <Footer/>
            {/* <!-- ========== end Footer ========== --> */}
        </div>
    </div>
}