import {Modal, ModalBody} from "react-bootstrap";
import React, {ReactHTML, useEffect, useState} from "react";
import {useStudentApiClient} from "../../../student/apiClients/StudentApiClient";
import {AllToasts} from "../../../../components/toast/AllToasts";
import Button from "../../../../components/button/Button";
import {compact} from "lodash";

export type EditStudentProps = {
    show: boolean,
    onSuccess: () => void
    onHide: () => void;
    onCancel: () => void;
};

export type StudentPayload = {
    username: string;
    name: string;
    mobile?: string | null;
    email: string;
    password: string;
    confirmPassword: string;
    streetAddress: string;
    classSchedule: string;
    schoolName: string;
    createUser: boolean;
    flag : string;
}


const CreateStudent = (props: EditStudentProps) => {
    const useStudentApi = useStudentApiClient()
    const MIN_MOBILE_LENGTH = 10;
    const MAX_MOBILE_LENGTH = 10;
    const [mobileValid, setMobileValid] = useState<boolean>(true);
    const [mobileError, setMobileError] = useState<string | null>(null);
    const [userIdError, setUserIdError] = useState<string | null>(null);
    const [userIdValid, setUserIdValid] = useState<boolean>(true);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [passwordsMatch, setPasswordsMatch] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);
    const [studentPayload, setStudentPayload] = useState<StudentPayload>({
        username: '',
        name: '',
        mobile: '' || null,
        email: '',
        password: '',
        confirmPassword: '',
        streetAddress: '',
        classSchedule: '',
        schoolName: '',
        createUser: true,
        flag:','
    });

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const name = e.target.name;
        const value = e.target.type === 'checkbox' ? (e.target.checked ? e.target.value : '') : e.target.value;
        const updatedValue = name === 'mobile' && value === '' ? null : value;
        setStudentPayload((prevState) => ({ ...prevState, [name]: updatedValue }));
    };




    const createStudent = (payload: StudentPayload) => {
        setLoading(true);
        setUserIdError(null);
        setMobileError(null);
        setMobileValid(true);
        setUserIdValid(true);
        useStudentApi.addStudent(payload)
            .then((res) => {
                props.onCancel();
                props.onSuccess();
                setStudentPayload({
                    username: '',
                    name: '',
                    mobile: '',
                    email: '',
                    password: '',
                    confirmPassword: '',
                    streetAddress: '',
                    classSchedule: '',
                    schoolName: '',
                    createUser: true,
                    flag:''
                });
                setPasswordsMatch(true);
                setLoading(false);
                AllToasts.success('Student added successfully');
                console.log('Success #29');
                console.log(res.data);
            })
            .catch((error) => {
                setLoading(false);
                if (
                    error.response?.data?.reason === "The user name entered is not available") {
                    setUserIdError(`The user name entered is not available.`);
                }
                if (
                    error.response?.data?.reason === "userName: size must be between 3 and 2147483647") {
                    setUserIdError(`Size must be more than 3.`);
                }
                if (error.response?.data?.reason === "User already exists") {
                    setMobileError('Mobile number already exists');
                }
                console.error('Error creating student:', error);
            });
    };


    const handleSubmit = (event: any) => {
        event.preventDefault();
        if (studentPayload.mobile && studentPayload.mobile.length > MAX_MOBILE_LENGTH) {
            setMobileValid(false);
            return;
        }

        if (studentPayload.password !== studentPayload.confirmPassword) {
            setPasswordsMatch(false);
            return;
        }
        createStudent(studentPayload)
    }
    const onClose = () => {
        props.onCancel();
        setStudentPayload({
            username: '',
            name: '',
            mobile: '',
            email: '',
            password: '',
            confirmPassword: '',
            streetAddress: '',
            classSchedule: '',
            schoolName: '',
            createUser: true,
            flag:''
        });
    }


    return <div>
        <Modal show={props.show} onHide={() => onClose()} size="lg">
            <Modal.Header closeButton className="border-bottom">
                <div className={'flex-fill'}><h5>Add student</h5></div>
            </Modal.Header>
            <ModalBody className="modal_body mx-2">
                <form onSubmit={(e) => handleSubmit(e)}>
                    <div className='ms-2 '>
                        <h5>Login details</h5>
                    </div>
                    <hr/>
                    <div className="form-group m-2">
                        <label>UserId <i className="bi bi-asterisk"
                                         style={{color: 'red', fontSize: '10px'}}></i></label>
                        <input
                            type="text"
                            name={'username'}
                            className={`form-control ${
                                (!userIdValid || userIdError) ? 'is-invalid' : ''
                            }`}
                            placeholder="Enter userId"
                            onChange={(e) => handleChange(e)}
                            value={studentPayload.username}
                            required
                        />
                        {userIdError && (
                            <div className="invalid-feedback">{userIdError}</div>
                        )}
                    </div>
                    <div className="form-group m-2 ">
                        <label>Password <i className="bi bi-asterisk"
                                           style={{color: 'red', fontSize: '10px'}}></i></label>
                        <div style={{position: 'relative'}}>
                            <input
                                type={showPassword ? "text" : "password"}
                                name={'password'}
                                className="form-control"
                                placeholder="Enter password"
                                onChange={(e) => handleChange(e)}
                                value={studentPayload.password}
                                required
                            />
                            <span
                                className={`password-toggle-icon ${showPassword ? "visible" : ""}`}
                                onClick={togglePasswordVisibility}
                                style={{
                                    position: 'absolute',
                                    top: '50%',
                                    right: '10px',
                                    transform: 'translateY(-50%)'
                                }}>
                            <i className="bi bi-eye"></i>
                        </span>
                        </div>

                    </div>
                    <div className="form-group m-2 ">
                        <label> Confirm Password <i className="bi bi-asterisk"
                                                    style={{color: 'red', fontSize: '10px'}}></i></label>
                        <div style={{position: 'relative'}}>
                            <input
                                type={showConfirmPassword ? "text" : "password"}
                                className="form-control"
                                placeholder="Confirm password"
                                onChange={(e) => handleChange(e)}
                                name={'confirmPassword'}
                                value={studentPayload.confirmPassword}
                                required
                            />
                            <span
                                className={`password-toggle-icon ${showConfirmPassword ? "visible" : ""}`}
                                onClick={toggleConfirmPasswordVisibility}
                                style={{
                                    position: 'absolute',
                                    top: '50%',
                                    right: '10px',
                                    transform: 'translateY(-50%)'
                                }}>
                                 <i className="bi bi-eye"></i>
                            </span>
                        </div>
                        {!passwordsMatch && (
                            <div className="alert alert-danger">
                                Passwords do not match.
                            </div>
                        )}
                    </div>

                    <div className='ms-2 mt-3 '>
                        <h5>Personal details</h5>
                    </div>
                    <hr/>
                    <div className="form-group m-2  ">
                        <label>Name <i className="bi bi-asterisk" style={{color: 'red', fontSize: '10px'}}></i></label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Enter name"
                            name={'name'}
                            onChange={(e) => handleChange(e)}
                            value={studentPayload.name}
                            required
                        />
                    </div>
                    <div className="form-group m-2 ">
                        <label>Email</label>
                        <input
                            type="email"
                            name={'email'}
                            className="form-control"
                            placeholder="Enter email"
                            onChange={(e) => handleChange(e)}
                            value={studentPayload.email}
                        />
                    </div>

                    <div className="form-group m-2 ">
                        <label>Mobile</label>
                        <input
                            type="text"
                            name={'mobile'}
                            className={`form-control `}
                            placeholder="Enter mobile"
                            onChange={(e) => handleChange(e)}
                            value={studentPayload.mobile ?? ''}
                        />
                        {!mobileValid && (
                            <div className="invalid-feedback">
                                Mobile number must be between {MIN_MOBILE_LENGTH} characters.
                            </div>
                        )}
                        {mobileError && (
                            <div className="invalid-feedback">{mobileError}</div>
                        )}
                    </div>

                    <div className="form-group m-2  ">
                        <label>Address </label>
                        <input
                            type="text"
                            className="form-control"
                            name={'streetAddress'}
                            placeholder="Enter location"
                            onChange={(e) => handleChange(e)}
                            value={studentPayload.streetAddress}

                        />
                    </div>
                    <div className="form-group m-2  ">
                        <label>School name </label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Enter School name"
                            name={'schoolName'}
                            onChange={(e) => handleChange(e)}
                            value={studentPayload.schoolName}

                        />
                    </div>
                    <div className="form-group m-2  ">
                        <label>Class </label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Enter class"
                            name={'classSchedule'}
                            onChange={(e) => handleChange(e)}
                            value={studentPayload.classSchedule}
                        />
                    </div>
                    <div className="form-group m-2 ">
                        <label>Subscription</label>
                        <div className='d-flex align-items-center'>
                            <input
                                type="checkbox"
                                name="flag"
                                value="Paid"
                                checked={studentPayload.flag === 'Paid'}
                                onChange={(e) => handleChange(e)}
                                required
                            />
                            <label className='ms-1'>Paid</label>
                        </div>
                    </div>

                    <div className='m-3 d-flex justify-content-end'>
                        <Button label={'Add'} loading={loading} cssName={'btn btn-add text-white'}/>
                    </div>
                </form>
            </ModalBody>
        </Modal>
    </div>
}
export default CreateStudent;