import {Modal, ModalBody, ModalHeader} from "react-bootstrap";
import React, {useState} from "react";
import {useAdminApiClient} from "../../apiClients/AdminApiClient";
import {AllToasts} from "../../../../components/toast/AllToasts";
import {ValidateName} from "../../../student/screen/login/selfRegistration/SelfLogin";
import {useStudentApi} from "../../../../api/StudentApiClent";

interface CreateDataExecutiveModalProps {
    show: boolean,
    onHide: () => void
    onSuccess:()=>void
}

export interface User {
    username: string;
    name: string;
    email: string;
    mobile: string;
    password: string;
    confirmPassword: string;
}

export const CreateDataExecutiveModal = (props: CreateDataExecutiveModalProps) => {
    const adminApiClient = useAdminApiClient();
    const [error, setError] = useState<string>('');
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [payload, setPayload] = useState<User>({
        username: '',
        name: '',
        email: '',
        mobile: '',
        password: '',
        confirmPassword: '',
    });
    const studentApi = useStudentApi();
    const [userIdError, setUserIdError] = useState<string | null>(null);
    const [userIdValid, setUserIdValid] = useState<boolean>(true);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const {name, value} = e.target;
        setPayload(prevState => ({
            ...prevState,
            [name]: value
        }));

        if (name === 'confirmPassword' && value !== payload.password) {
            setError('Passwords do not match');
        } else {
            setError('');
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const createDataEntryExecutiveByAdmin = (payload: User) => {
        adminApiClient.createDataEntryExecutive(payload).then((res) => {
            AllToasts.success('Created Successfully');
            props.onSuccess();
        }).catch((err) => {
            console.log(err);
            AllToasts.failure(`Error ${err.response.data.reason}`);
        });
    };

    const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        createDataEntryExecutiveByAdmin(payload);
        setPayload({
            username: '',
            name: '',
            email: '',
            mobile: '',
            password: '',
            confirmPassword: '',
        });
        props.onHide();
        setError('');
    };

    const checkUsernameAvailability = (payload: User) => {
        if (payload.username !== '') {
            studentApi.validateUsername(payload).then((res) => {
                if (res.data.isValid === true) {
                    setUserIdValid(true);
                    setUserIdError('');
                } else {
                    setUserIdValid(false);
                    setUserIdError(res.data.message);
                }
            }).catch((error) => {
                console.error("Error checking username availability", error);
                if (error.response?.data?.reason === "The user name entered is not available") {
                    setUserIdError(`The user name entered is not available.`);
                }
            });
        }
    };

    const handleUsernameChange = (e: any) => {
        const newUsername = e.target.value;
        setPayload((prevState) => ({
            ...prevState,
            username: newUsername,
        }));
        setUserIdError('');
    };

    return (
        <Modal show={props.show} onHide={props.onHide} className={'modal-lg'}>
            <ModalHeader closeButton>
                Create DataEntry Executive
            </ModalHeader>
            <ModalBody>
                <form onSubmit={onFormSubmit}>
                    <div className={'mb-2'}>
                        <label className={'form-label'}>Username <span className={'text-danger'}>*</span></label>
                        <input className={`form-control ${
                            (!userIdValid || userIdError) ? 'is-invalid' : ''
                        }`} type="text" name="username" value={payload.username}
                               onChange={handleUsernameChange} onBlur={() => checkUsernameAvailability(payload)}
                               required/>
                        {userIdError && (
                            <div className="invalid-feedback">{userIdError}</div>
                        )}
                    </div>
                    <div className={"mb-2"}>
                        <label className={"form-label"}>
                            Password <span className={"text-danger"}>*</span>
                        </label>
                        <div className="input-group">
                            <input
                                className={"form-control"}
                                type={"password"}
                                name="password"
                                value={payload.password}
                                onChange={handleChange}
                                required
                            />
                            {/*<div*/}
                            {/*    className=""*/}
                            {/*    onClick={togglePasswordVisibility}*/}
                            {/*>*/}
                            {/*    {showPassword ? <FaEyeSlash /> : <FaEye />}*/}
                            {/*</div>*/}
                        </div>
                    </div>
                    <div className={"mb-2"}>
                        <label className={"form-label"}>
                            Confirm Password <span className={"text-danger"}>*</span>
                        </label>
                        <div className="input-group">
                            <input
                                className={"form-control"}
                                type={"password"}
                                name="confirmPassword"
                                value={payload.confirmPassword}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        {error && <div className="text-danger">{error}</div>}
                    </div>
                    <div className={'mb-2'}>
                        <label className={'form-label'}>Name <span className={'text-danger'}>*</span></label>
                        <input className={'form-control'} type="text" name="name" value={payload.name}
                               onChange={handleChange} required/>
                    </div>
                    <div className={'mb-2'}>
                        <label className={'form-label'}>Mobile <span className={'text-danger'}>*</span></label>
                        <input className={'form-control'} type="text" name="mobile" value={payload.mobile}
                               onChange={handleChange} required/>
                    </div>
                    <div className={'mb-2'}>
                        <label className={'form-label'}>Email <span className={'text-danger'}>*</span></label>
                        <input className={'form-control'} type="email" name="email" value={payload.email}
                               onChange={handleChange} required/>
                    </div>
                    <div className='d-flex align-items-center justify-content-end'>
                        <button className={'btn btn-secondary me-3'} type={'button'} onClick={props.onHide}>Close
                        </button>
                        <button className={'btn btn-success'} type="submit">Submit</button>
                    </div>
                </form>
            </ModalBody>
        </Modal>
    );
};
