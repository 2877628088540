import {useAxios} from "../../../configs/AxiosClient";
import {TableDataItem} from "../components/Imports/BulkUpload";

export const useAdminBulkUploadApiClient = () =>  {
    const axiosClient = useAxios();
    const BulkUploadCsv =(file: File) => {
        const formData = new FormData();
        formData.append('file', file);
             return axiosClient.post(`/questions/upload`, formData);
    };
    const getAllImports =()=> {
        return axiosClient.get(`/imports`)
    }
    return { BulkUploadCsv,getAllImports };
}
