import {useAxios} from "../../../configs/AxiosClient";
import {TopicPayload} from "../components/subjects/subjectCard/subjectTopic/CreateEditDeleteTopicModal";


export const useSubjectsApiClient = () => {
    const axiosClient = useAxios();

    const getSubjects = () => {
        return axiosClient.get(`/subjects`);
    }
    const getSubjectById = (id : string | number) => {
        return axiosClient.get(`/subjects/${id}`) ;
    }
    const createSubject = ( payload: any) => {
        return axiosClient.post(`/subjects`, payload);
    };

    const updateSubject = (id : number , payload : any) => {
        return axiosClient.put(`subjects/${id}` , payload) ;
    }


    const getTopicsById = (subjectId : string | number , size?: number, page?: number, query?: string) => {
        return axiosClient.get(`/subjects/${subjectId}/topics`, {
            params: {
                subjectId: subjectId,
                size: size,
                page: page,
                query: query,
            }
        })
    }

    const createTopic = (subjectId: string | number, payload: any) => {
        return axiosClient.post(`/subjects/${subjectId}/topics`, payload);
    };


    const updateTopic = (id : string | number , payload : TopicPayload) => {
        return axiosClient.put(`topics/${id}` , payload) ;
    }

    const getTopicById = (id : string | number) => {
        return axiosClient.get(`/topics/${id}`) ;
    }

    const deleteAccount = (topicId: string) => {
        return axiosClient.delete(`/topics/${topicId}/active`);
    };
const getAllLikedQuestions =(programId:number,page?:number,size?:number,query?:string)=> {
    return axiosClient.get(`/liked-questions/students`,{
        params:{
            programId:programId,
            page:page,
            size:size,
            query:query
        }
    })
}
    return {getSubjects , getSubjectById , getTopicsById , createTopic , updateTopic , getTopicById , deleteAccount,createSubject , updateSubject,getAllLikedQuestions}
}