import {Modal, ModalBody, ModalHeader} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {useAdminApiClient} from "../../apiClients/AdminApiClient";
import {AllToasts} from "../../../../components/toast/AllToasts";

interface DataExecutiveChangePasswordModalProps {
    show: boolean
    onHide: () => void
    executiveId: number
}

export interface ResetPasswordPayload {
    newPassword: string,
    confirmNewPassword: string
}

export const DataExecutiveChangePasswordModal = (props: DataExecutiveChangePasswordModalProps) => {
    const adminApiClient = useAdminApiClient();
    const [error, setError] = useState<string>('');
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [payload, setPayload] = useState<ResetPasswordPayload>({
        newPassword: '',
        confirmNewPassword: '',
    })

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const {name, value} = e.target;
        setPayload(prevState => ({
            ...prevState,
            [name]: value
        }));

        if (name === 'confirmNewPassword' && value !== payload.newPassword) {
            setError('Passwords do not match');
        } else {
            setError('');
        }
    };

    const togglePasswordVisibility = () => {
        setShowPassword(prevState => !prevState);
    };

    const resetPassword = () => {
        adminApiClient.dataEntryExecutiveResetPassword(props.executiveId, payload).then((res) => {
            AllToasts.success('Password Updated Successfully')
        }).catch((err)=>{
            console.log(err)
            AllToasts.failure(`Error ${err.response.data.details}`)
        })
    }

    const handleFormSumbit = (e:any) => {
        e.preventDefault()
        resetPassword()
        props.onHide();
        setPayload({
            newPassword: '',
            confirmNewPassword: '',
        })
    }


    return (
        <Modal show={props.show} onHide={() => props.onHide()}>
            <ModalHeader closeButton>
                <div>
                    <h6>Change Password</h6>
                </div>
            </ModalHeader>
            <ModalBody>
                <div>
                    <form onSubmit={handleFormSumbit}>
                        <div className={"mb-2"}>
                            <label className={"form-label"}>
                                Password <span className={"text-danger"}>*</span>
                            </label>
                            <div className="input-group">
                                <input
                                    className={"form-control"}
                                    type={"password"}
                                    name="newPassword"
                                    value={payload.newPassword}
                                    onChange={handleChange}
                                    required
                                />
                                <div
                                    className="input-group-append"
                                    // onClick={togglePasswordVisibility}
                                    // style={{cursor: "pointer"}}
                                >
                                    {/*{showPassword ? <span>Show</span> : <span>Hide</span>}*/}
                                </div>
                            </div>
                        </div>
                        <div className={"mb-2"}>
                            <label className={"form-label"}>
                                Confirm Password <span className={"text-danger"}>*</span>
                            </label>
                            <div className="input-group">
                                <input
                                    className={"form-control"}
                                    type={"password"}
                                    name="confirmNewPassword"
                                    value={payload.confirmNewPassword}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            {error && <div className="text-danger">{error}</div>}
                        </div>
                        <div className='d-flex align-items-center justify-content-end'>
                            <button type={'button'} onClick={() => props.onHide()}
                                    className={'btn btn-secondary me-2'}>Close
                            </button>
                            <button className={'btn btn-primary'}>Update</button>
                        </div>
                    </form>
                </div>
            </ModalBody>
        </Modal>
    );
}

