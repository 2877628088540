import React, { useEffect, useRef, useState } from "react";
import { useTeacherApiClient } from "../../../../apiClients/TeacherApiClient";
import { AllToasts } from "../../../../../../components/toast/AllToasts";

interface ImageUploadProps {
    onUploadedFileCid: (cid: string) => void;
    imageUrl: string;
    image?: (url: string) => void;
}

export const ImageUpload = (props: ImageUploadProps) => {
    const imageChooser = useRef<HTMLInputElement>(null);
    const [dragEnter, setDragEnter] = useState<boolean>(false);
    const [fileLoading, setFilePickerLoading] = useState<boolean>(false);
    const [imageURL, setImageURL] = useState('');
    const [file, setFile] = useState<File>();
    const [fileName, setFileName] = useState<string | undefined>(undefined);
    const [uploadStatus, setUploadStatus] = useState<string | null>(null);
    const [uploadedFileCid, setUploadedFileCid] = useState<string>('');
    const [uploadFile, setUploadFile] = useState<boolean>(false); // Corrected variable name
    const teacherApiClient = useTeacherApiClient();

    const debounce = (func: Function, delay: number) => {
        let timer: ReturnType<typeof setTimeout>;
        return (...args: any) => {
            clearTimeout(timer);
            timer = setTimeout(() => {
                func(...args);
            }, delay);
        };
    };

    const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.stopPropagation();
        e.preventDefault();

        const droppedFiles = e.dataTransfer.files;
        if (droppedFiles && droppedFiles.length > 0) {
            const droppedFile = droppedFiles[0];
            const fileURL = URL.createObjectURL(droppedFile);
            setImageURL(fileURL);
            props.image && props?.image(fileURL);
            setDragEnter(false);
            setFile(droppedFile);
        }
    };


    useEffect(() => {
        setImageURL(props.imageUrl)
    }, []);

    const handleFiles = (event: any) => {
        const selectedFile = event.target.files[0];
        if (event.target.files && event.target.files.length > 0) {
            setFile(selectedFile);
            setFileName(selectedFile.name);
            event.target.value = null;
            const reader = new FileReader();
            reader.onload = (e) => {
                const imageSrc = e.target?.result as string;
                setImageURL(imageSrc);
                props.image && props?.image(imageSrc);
            };
            reader.readAsDataURL(selectedFile);
        }
    };

    const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
        e.stopPropagation();
        e.preventDefault();
        setDragEnter(true);
    };

    const handleDragLeave = debounce((e: React.DragEvent<HTMLDivElement>) => {
        e.stopPropagation();
        e.preventDefault();
        setDragEnter(false);
    }, 1000);

    const handleUpload = () => {
        if (!file) {
            return;
        }
        setUploadStatus('Uploading...');
        setUploadFile(true); // Start spinner
        teacherApiClient.uploadImage(file)
            .then((res) => {
                const uploadedFileCid = res.data.cid;
                setUploadedFileCid(uploadedFileCid);
                setUploadStatus('Upload successful');
                AllToasts.success("Image Uploaded Successfully")
                props.onUploadedFileCid(uploadedFileCid);
            })
            .catch((err) => {
                console.error('Error uploading file:', err);
                setUploadStatus('Error uploading file');
                AllToasts.success("Image Failed to Upload")
            })
            .finally(() => {
                setUploadFile(false);
            });
    };

    const removeImage = () => {
        setFile(undefined);
        setFileName('');
        setImageURL('')
        props.image && props.image('')
    };

    return (
        <div>
            <div className="card w-100 h-100 d-flex align-items-center justify-content-center p-5 mb-2 mt-2"
                 onClick={() => imageChooser.current?.click()} onDragOver={handleDragEnter}
                 onDragLeave={handleDragLeave} onDrop={handleDrop}>
                {(dragEnter || fileLoading) ? (
                    <div className="d-flex flex-column p-4">
                        <div
                            className={`text-center flex-fill position-relative styledLabel ${dragEnter ? 'dark' : ''}`}
                            onClick={() => imageChooser.current?.click()}
                        >
                            {dragEnter ? <p>Drop file here</p> : ''}
                            {fileLoading ? <div>Loading...</div> : ''}
                        </div>
                    </div>
                ) : (
                    <div className="align-items-center">
                        {imageURL ? (
                            <div className="d-flex flex-row">
                                <img className="w-25 h-25" src={imageURL} alt="Selected Image"/>
                                <i className="bi bi-x-lg fs-5 float-end p-1" onClick={removeImage}></i>
                            </div>
                        ) : (
                            <div>
                                <input ref={imageChooser} hidden onChange={handleFiles}
                                       type="file" accept="image/jpg,image/jpeg,image/png"
                                       name="images_uploads" id="images_uploader"
                                       className="visually-hidden"/><br/>
                                <p>Files Supported: .JPG, .JPEG, .PNG</p>
                                <p>Or Drag and Drop a file</p>
                                <p>Click to Choose File</p>
                            </div>
                        )}
                    </div>
                )}
                <input ref={imageChooser} type="file" hidden
                       accept="image/jpg,image/jpeg,.image/png" onChange={handleFiles} id="fileID"/>
            </div>

            <div className="d-flex align-items-center justify-content-end mb-2">
                {uploadFile ? (
                    <button className="btn btn-sm text-white createQuestionButton" type="button" disabled>
                        <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                        Uploading...
                    </button>
                ) : (
                    <div className="d-flex align-items-center justify-content-end mb-2">
                        {uploadStatus !== 'Upload successful' && (
                            <button className="btn btn-sm text-white createQuestionButton" type="button"
                                    onClick={handleUpload}>
                                Upload Image
                            </button>
                        )}
                        {uploadStatus === 'Upload successful' && (
                            <button className="btn btn-sm text-white createQuestionButton" type="button">
                                Uploaded Successfully
                            </button>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

