import { useAxios} from "../configs/AxiosClient";

export class ProgramRepo{
    static axiosClient = useAxios();
    static createProgram(courseId:string,payload:any){
        return ProgramRepo.axiosClient.post(`/courses/${courseId}/programs`,payload);
    }

    static getAllPrograms(courseId:string | number){
        return ProgramRepo.axiosClient.get(`/courses/${courseId}/programs`);
    }

    static updateProgram = (programId:string,payload: any,) => {
        return ProgramRepo.axiosClient.put(`/programs/${programId}`, payload)
    }
    static getSingleProgram(programId:string){
        return ProgramRepo.axiosClient.get(`/programs/${programId}`);
    }
}