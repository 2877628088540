import {useAxios} from "../configs/AxiosClient";
import {LoginPayload} from "../dto/Payload";
import exp from "constants";
import {Token} from "../dto/Token";

export enum AdminApi {
    SEND_OTP,
    LOGIN
}
const AdminApisPaths = {
    [AdminApi.SEND_OTP]: '/auth/send-otp',
    [AdminApi.LOGIN]: '/auth/login'
}
export const useAdminApi = () => {
    const axios = useAxios();

    const sendOtp = (mobile: string) => {
        return axios.post(AdminApisPaths[AdminApi.SEND_OTP], {username: mobile});
    }
    const login = (payload: LoginPayload) => {
        return axios.post<Token>(AdminApisPaths[AdminApi.LOGIN], payload);
    }
    return {sendOtp, login}
}