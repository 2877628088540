import {Modal, ModalBody, ModalHeader} from "react-bootstrap";
import {useTeacherApiClient} from "../apiClients/TeacherApiClient";
import React, {ChangeEvent, FormEvent, useEffect, useState} from "react";
import {AllToasts} from "../../../components/toast/AllToasts";
import Button from "../../../components/button/Button";
import {SelectedQuestionType} from "../components/createAssignment/createTest/createAssignment/CreateAssignment";

interface CreateTestProps {
    show: boolean,
    onHide: () => void,
    onSuccess: () => void,
    questionId: any
    removeAllQuestions: () => void
    courseId: number
    programId: number
    selectedQuestionType?: string
}

export interface CreateTestData {
    name: string;
    summary: string;
    dueDate?: string
    questionId?: number[];
    comprehensionIds?: number[]
    programId: number
}

const CreateTestModal = (props: CreateTestProps) => {
    const [createTestPayload, setCreateTestPayload] = useState<CreateTestData>({
        name: '',
        summary: '',
        questionId: [],
        comprehensionIds: [],
        programId: 0
    })

    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string>('')
    const teacherApiClient = useTeacherApiClient()

    useEffect(() => {
        setCreateTestPayload(prevState => ({...prevState, questionId: props.questionId}));
    }, [props.questionId]);

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const name = event.currentTarget.name;
        const value = event.currentTarget.value;
        setCreateTestPayload((prevState) => ({...prevState, [name]: value}));
    }

    const createTest = (courseId: number, payload: CreateTestData) => {
        const _questionIds = props.questionId?.map((res: any) => res.id);
        const _payload: CreateTestData = {
            name: payload.name,
            summary: payload.summary,
            questionId: _questionIds,
            programId: props.programId,
        };
        teacherApiClient.createTest(props.courseId as any, _payload)
            .then((res) => {
                setCreateTestPayload({
                    name: '',
                    summary: '',
                    questionId: [],
                    programId: 0,
                });
                setLoading(false);
                props.onHide();
                props.removeAllQuestions();
                AllToasts.success('Assignment Created Successfully');
                setError('')
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
                setError(err.response.data.error)
                AllToasts.failure('Failed to Create Assignment');
            });
    };

    const createComprehensionTest = (courseId: number, payload: CreateTestData) => {
        const _questionIds = props.questionId?.map((res: any) => res.id);
        const _payload: CreateTestData = {
            name: payload.name,
            summary: payload.summary,
            comprehensionIds: _questionIds,
            programId: props.programId,
        };
        teacherApiClient.createAssignment(props.courseId as any, _payload)
            .then((res) => {
                setCreateTestPayload({
                    name: '',
                    summary: '',
                    comprehensionIds: [],
                    programId: 0,
                });
                setLoading(false);
                props.onHide();
                props.removeAllQuestions();
                AllToasts.success('Assignment Created Successfully');
                setError('')
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
                setError(err.response.data.error)
                AllToasts.failure('Failed to Create Assignment');
            });
    }

    const handleFormSubmit = (event: FormEvent) => {
        setLoading(true)
        event.preventDefault();
        if (props.selectedQuestionType === SelectedQuestionType.Comprehension) {
            createComprehensionTest(props.courseId as any, createTestPayload)
        } else if (props.selectedQuestionType === SelectedQuestionType.General) {
            createTest(props.courseId as any, createTestPayload)
        }
    }

    return <Modal show={props.show} onHide={props.onHide}>
        <ModalHeader>
            Create Test
        </ModalHeader>
        <ModalBody>
            <form onSubmit={handleFormSubmit}>
                <div className="mb-2">
                    <label className="form-label" htmlFor={'name'}>Test Name<span className='ms-1 text-danger'>*</span></label>
                    <input id="name" className="form-control" name="name" value={createTestPayload.name}
                           onChange={handleChange} required/>
                </div>
                <div className="mb-2">
                    <label className="form-label" htmlFor={'summary'}>Summary<span className='ms-1 text-danger'>*</span></label>
                    <input id="summary" className="form-control" name="summary" value={createTestPayload.summary}
                           onChange={handleChange} required/>
                </div>

                <div className='d-flex align-items-center justify-content-center'>
                    {/*<button type={'submit'} className='btn btn-primary btnCreateSubmit'>Submit</button>*/}
                    <Button label={'Submit'} loading={loading} cssName={'btn btn-primary btn-add'}/>
                </div>
                <div className='text-danger'>
                    {error}
                </div>
            </form>
        </ModalBody>
    </Modal>
}
export default CreateTestModal;