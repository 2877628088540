import React, {useEffect, useState} from 'react';
import './courseCard.scss';
import {useNavigate} from "react-router-dom";
import {CourseRepo} from "../../../../repos/CourseRepo";
import {AllToasts} from "../../../toast/AllToasts";
import CustomSwitcher2 from "../courseView/CustomSwitcher2";


export const CourseCard = (props: {
    course: any,
    onCancel?: () => void,
    show?: boolean,
    onEditClick?: () => void
    onRefresh: () => void
    authorities?: boolean
}) => {
    const [isActive, setIsActive] = useState<boolean>(props.course.active);
    const navigate = useNavigate();

    useEffect(() => {
        setIsActive(props.course.active)
    }, [props.course]);


    const handleSoftDelete = (courseId: string) => {
        CourseRepo.softDeleteCourse(courseId)
            .then((res) => {
                setIsActive(false);
                AllToasts.success("Course DeActivated");
                props.onRefresh();
            })
            .catch((error: any) => {
                setIsActive(props.course.active)
                AllToasts.failure(error.response?.data.reason);
            }).finally(() => {
            props.onRefresh();
        })
    }

    const handleUndoSoftDelete = (courseId: string) => {
        CourseRepo.undoSoftDeleteCourse(courseId)
            .then(() => {
                setIsActive(true);
                AllToasts.success("Course Activated");
                props.onRefresh();
            })
            .catch((error: any) => {
                AllToasts.failure(error.response?.data.reason);
            }).finally(() => {

        })
    }
    return <div className={`col-md-3 mb-4 course-card px-0 m-4`}>
        <div className="card shadow-sm border-0">
            <div className="card-body d-block justify-content-center align-items-center p-3">
                <div className={'d-flex justify-content-between align-items-center'}>
                    <h6 className="text-primary cursorPointer"
                        onClick={() => navigate(`${props.course.id}`)}>{props.course.name}</h6>
                    {
                        props.authorities &&
                        <div className={'d-flex justify-content-start align-items-center'}>
                            <CustomSwitcher2
                                active={isActive}
                                onChange={() => {
                                    if (props.course.active) {
                                        handleSoftDelete(props.course.id)
                                    } else {
                                        handleUndoSoftDelete(props.course.id)
                                    }
                                }}
                                activeLabel={''}
                                inactiveLabel={''}
                            />
                            <i className={'bi bi-pencil cursorPointer mt-1'}
                               onClick={props.onEditClick && props.onEditClick}></i>
                        </div>
                    }
                </div>
                <p className='mt-2 cursorPointer text-ellipsis'
                   onClick={() => navigate(`${props.course.id}`)}>{props.course.description && props.course.description.substring(0 , 75)+`${props.course.description && props.course.description.length > 75 ? ' ....' : ''}`}</p>
                {/*<p className="p-3">This is a wider card with supporting text below as a natural lead-in to*/}
                {/*    additional content. This content is a little bit longer.</p>*/}
            </div>
            {/*<div className="card-footer m-0">*/}
            {/*    <small className="text-muted">*/}
            {/*        <div className={'d-flex justify-content-center align-items-center'}>*/}
            {/*            <button className={'btn btn-outline-success'} onClick={()=>setShow(true)}>Manage Program</button>*/}
            {/*        </div>*/}
            {/*        </small>*/}
            {/*</div>*/}
        </div>
        {/*<Programs show={show} onCancel={()=>setShow(false)} id={props.course.id}/>*/}
    </div>
}
