import {useNavigate, useParams} from "react-router-dom";
import {useStudentApiClient} from "../../../apiClients/StudentApiClient";
import React, {useEffect, useState} from "react";
import '../quizQuestions/Quiz.css'
import {answer, PostDataSelect, Question, QuestionType, QuizMode} from "../../../../../modals/quizquestionData/Modal";
import QuizStepper, {QuestionData} from "../quizStepper/QuizStepper";
import TimeLeft from "../timeLeft/TimeLeft";
import Questions from "../quizQuestions/Questions";
import QuizStatusIndicator from "../quizIndicator/QuizStatusIndicator";
import SubmitButton from "../submitButton/SubmitButton";
import ResultModal from "../modal/ResultModal";
import Instruction from "../../instruction/Instruction";
import {EmptyState} from "../../../../../components/emptyState/EmptyState";
import {Comprehension, Test} from "../../../../../dto/ComprehensionTest";
import {PassageModal} from "./PassageModal";
import {Offcanvas} from "react-bootstrap";

const CompQuiz = () => {
    const params = useParams();
    const studentApiClient = useStudentApiClient();
    const navigate = useNavigate();
    const [programName, setProgramName] = useState<string>('');
    const [testName, setTestName] = useState<string>('')
    const [showWebInstruction, setShowWebInstruction] = useState<boolean>(false);
    const [currentQuizQuestion, setCurrentQuizQuestion] = useState(-1);
    const [quizQuestions, setQuizQuestions] = useState<Test[]>([])
    const [questionData, setQuestionData] = useState(new Map<number, QuestionData>());
    const [answers, setAnswers] = useState<answer[]>([])
    const [show, setShow] = useState<boolean>(false);
    const [highlightedQuestionId, setHighlightedQuestionId] = useState<number>(0)
    const [showExplanation, setShowExplanation] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [viewType, setViewType] = useState<string>('')
    const [showMobileSummary, setShowMobileSummary] = useState<boolean>(false);
    const [showMobileInstructions, setShowMobileInstructions] = useState<boolean>(false);
    const [questionStartTime, setQuestionStartTime] = useState<number | null>(null);
    const [questionTimeData, setQuestionTimeData] = useState<{ [key: number]: number }>({});
    const [testEvaluated, setTestEvaluated] = useState<boolean>(false);
    const [showComprehensionModal, setShowComprehensionModal] = useState(false);
    const [currentComprehensionText, setCurrentComprehensionText] = useState('');
    const [currentComprehensionIndex, setCurrentComprehensionIndex] = useState(0);
    const [comprehensionData, setComprehensionData] = useState<Comprehension[]>([]);
    const [currentComprehensionImage,setCurrentComprehensionImage] = useState<string>('');

    useEffect(() => {
        setQuestionStartTime(Date.now());
    }, [currentQuizQuestion]);

    useEffect(() => {
        if (params.testId) {
            switch (params.testType) {
                case QuizMode.ASSIGNMENT:
                    getAllAssignmentQuestions();
                    break;
                case QuizMode.TEST:
                    getAllTestQuestions();
                    break;
                case QuizMode.PRACTISE:
                    getAllMockTestQuestions();
                    break;
            }
        }
    }, [params.testId])

    const getAllTestQuestions = () => {
        studentApiClient.getAllTestComp(parseInt(params?.testId ?? '')).then((res: any) => {
            setLoading(false);
            setViewType(QuizMode.TEST);
            const alphabeticPattern = /[a-zA-Z]+/g;
            const matches = res.data.name.match(alphabeticPattern);
            const extractedAlphabetic = matches ? matches.join(' ') : null;
            setProgramName(extractedAlphabetic);
            setTestName(res.data.name);
            setComprehensionData(res.data.comprehensions);
            const allQuestions = res.data.comprehensions
                .map((comp: Comprehension, comprehensionIndex: number) => {
                    return comp.questions.map((question) => {
                        const {oneWordAnswer, ...rest} = question;
                        const updatedOptions = rest.options && rest.options?.map(option => {
                            const {correct, ...restOption} = option;
                            return restOption;
                        });
                        return {
                            ...rest,
                            options: updatedOptions,
                            comprehensionIndex: comprehensionIndex
                        };
                    });
                }).flat();
            setQuizQuestions(allQuestions);
            const _questionData = new Map<number, QuestionData>();
            allQuestions.forEach((question: Question) => {
                _questionData.set(question.id, {question: question});
            });
            setCurrentQuizQuestion(0);
            setQuestionData(_questionData);
            setCurrentComprehensionIndex(0);
            if (res.data.comprehensions.length > 0) {
                setShowComprehensionModal(true);
                setCurrentComprehensionText(res.data.comprehensions[currentComprehensionIndex].text);
                setCurrentComprehensionImage(res.data.comprehensions[currentComprehensionIndex].imageUrl);

            }
        }).catch(() => {
            console.log("#56");
        });
    }


    const getAllAssignmentQuestions = () => {
        studentApiClient.getAllAssignmentQuestions(parseInt(params?.testId ?? '')).then((res: any) => {
            setLoading(false);
            setViewType(QuizMode.TEST)
            const alphabeticPattern = /[a-zA-Z]+/g;
            const matches = res.data.name.match(alphabeticPattern);
            const extractedAlphabetic = matches ? matches.join(' ') : null;
            setProgramName(extractedAlphabetic);
            setTestName(res.data.name);
            setComprehensionData(res.data.comprehensions);
            const allQuestions = res.data.comprehensions
                .map((comp: Comprehension, comprehensionIndex: number) => {
                    return comp.questions.map((question) => {
                        const {oneWordAnswer, ...rest} = question;
                        const updatedOptions = rest.options && rest.options?.map(option => {
                            const {correct, ...restOption} = option;
                            return restOption;
                        });
                        return {
                            ...rest,
                            options: updatedOptions,
                            comprehensionIndex: comprehensionIndex
                        };
                    });
                }).flat();
            setQuizQuestions(allQuestions);
            const _questionData = new Map<number, QuestionData>();
            allQuestions.forEach((question: Question) => {
                _questionData.set(question.id, {question: question});
            });
            setCurrentQuizQuestion(0);
            setQuestionData(_questionData);
            setCurrentComprehensionIndex(0);
            if (res.data.comprehensions.length > 0) {
                setShowComprehensionModal(true);
                setCurrentComprehensionText(res.data.comprehensions[currentComprehensionIndex].text);
                setCurrentComprehensionImage(res.data.comprehensions[currentComprehensionIndex].imageUrl);

            }
        }).catch(() => {
            console.log("#56")
        })
    }

    const getAllMockTestQuestions = () => {
        studentApiClient.getAllMockTestQuestionsComp(parseInt(params?.testId ?? '')).then((res: any) => {
            setViewType(QuizMode.PRACTISE)
            setLoading(false);
            setQuizQuestions(res.data.comprehensions.questions);
            const alphabeticPattern = /[a-zA-Z]+/g;
            const matches = res.data.name.match(alphabeticPattern);
            const extractedAlphabetic = matches ? matches.join(' ') : null;
            setProgramName(extractedAlphabetic);
            setTestName(res.data.name);
            setComprehensionData(res.data.comprehensions);
            const allQuestions = res.data.comprehensions
                .map((comp: Comprehension, comprehensionIndex: number) => {
                    return comp.questions.map((question) => {
                        const {oneWordAnswer, ...rest} = question;
                        const updatedOptions = rest.options && rest.options?.map(option => {
                           return option;
                        });
                        return {
                            ...rest,
                            options: updatedOptions,
                            comprehensionIndex: comprehensionIndex
                        };
                    });
                }).flat();
            setQuizQuestions(allQuestions);
            const _questionData = new Map<number, QuestionData>();
            allQuestions.forEach((question: Question) => {
                _questionData.set(question.id, {question: question});
            });
            setCurrentQuizQuestion(0);
            setQuestionData(_questionData);
            setCurrentComprehensionIndex(0);
            if (res.data.comprehensions.length > 0) {
                setShowComprehensionModal(true);
                setCurrentComprehensionText(res.data.comprehensions[currentComprehensionIndex].text);
                setCurrentComprehensionImage(res.data.comprehensions[currentComprehensionIndex].imageUrl);

            }
        }).catch(() => {
            console.log("56")
        })
    }

    const handlePreviousQuestion = () => {
        setHighlightedQuestionId(-1);
        setShowExplanation(false);
        const previousQuestion = currentQuizQuestion - 1;
        const questionEndTime = Date.now();
        const currentQuestionId = quizQuestions[currentQuizQuestion].id;
        const timeSpentMilliseconds = questionEndTime - (questionStartTime || questionEndTime);
        const timeSpentSeconds = Math.floor(timeSpentMilliseconds / 1000);
        setQuestionTimeData(prevData => ({
            ...prevData,
            [currentQuestionId]: (prevData[currentQuestionId] || 0) + timeSpentSeconds
        }));
        setQuestionData((prevState) => {
            const _questionData = new Map(prevState);
            const qd = _questionData.get(quizQuestions[currentQuizQuestion].id);
            if (qd) {
                if (qd.attempted === undefined)
                    qd.attempted = true;
                _questionData.set(quizQuestions[currentQuizQuestion].id, qd);
            }
            return _questionData;
        });
        if (previousQuestion >= 0) {
            const previousComprehensionIndex = quizQuestions[previousQuestion].comprehensionIndex;
            if (previousComprehensionIndex !== currentComprehensionIndex) {
                setCurrentComprehensionIndex(previousComprehensionIndex ?? 0);
                setCurrentComprehensionText(comprehensionData[previousComprehensionIndex ?? 0]?.text ?? '');
                setCurrentComprehensionImage(comprehensionData[previousComprehensionIndex ?? 0]?.imageUrl ?? '');
                setShowComprehensionModal(true);
            }
            setCurrentQuizQuestion(previousQuestion);
        }
    };

    const handleNextQuestion = (questionId: number) => {
        const questionEndTime = Date.now();
        const nextQuestion = currentQuizQuestion + 1;
        const currentQuestionId = quizQuestions[currentQuizQuestion].id;
        const timeSpentMilliseconds = questionEndTime - (questionStartTime || questionEndTime);
        const timeSpentSeconds = Math.floor(timeSpentMilliseconds / 1000);
        setQuestionTimeData(prevData => ({
            ...prevData,
            [currentQuestionId]: (prevData[currentQuestionId] || 0) + timeSpentSeconds
        }));
        setHighlightedQuestionId(-1);
        setShowExplanation(false);
        setQuestionData((prevState) => {
            const _questionData = new Map(prevState);
            const qd = _questionData.get(questionId);
            if (qd) {
                qd.attempted = true;
                _questionData.set(questionId, qd);
            }
            return _questionData;
        });
        if (nextQuestion < quizQuestions.length) {
            const nextComprehensionIndex = quizQuestions[nextQuestion].comprehensionIndex;
            if (nextComprehensionIndex !== currentComprehensionIndex) {
                setCurrentComprehensionIndex(nextComprehensionIndex ?? 0);
                setCurrentComprehensionText(comprehensionData[nextComprehensionIndex ?? 0]?.text ?? '');
                setCurrentComprehensionImage(comprehensionData[nextComprehensionIndex ?? 0]?.imageUrl ?? '');
                setShowComprehensionModal(true);
            }
            setCurrentQuizQuestion(nextQuestion);
        } else {
            setQuestionTimeData(prevData => ({
                ...prevData,
                [currentQuizQuestion]: (prevData[currentQuizQuestion] || 0) + timeSpentSeconds
            }));
        }
    };


    const onOptionChange = (postData: PostDataSelect) => {
        setHighlightedQuestionId(-1);
        setQuestionData((prevState) => {
            const _questionData = new Map(prevState);
            const qd = _questionData.get(postData.question.id);
            if (qd) {
                if (postData.question.type === QuestionType.OneWord) {
                    qd.answer = postData.answer || "";
                } else {
                    qd.selectedChoice = postData.selectedOptions?.map((option) => option.id) ?? [];
                }
                _questionData.set(postData.question.id, qd);
            }
            return _questionData;
        });
    };

    const testEvaluation = () => {
        if (!testEvaluated) {
            const questionEndTime = Date.now();
            const timeSpentMilliseconds = questionEndTime - (questionStartTime || questionEndTime);
            const timeSpentSeconds = Math.floor(timeSpentMilliseconds / 1000);
            const currentQuestionId = quizQuestions[currentQuizQuestion]?.id;

            if (currentQuestionId) {
                setQuestionTimeData(prevData => ({
                    ...prevData,
                    [currentQuestionId]: (prevData[currentQuestionId] || 0) + timeSpentSeconds
                }));
            }

            if (params.testType === "TEST") {
                studentApiClient.testEvaluation(parseInt(params?.testId ?? ''), answers).then((res) => {
                    navigate(`../${params.testType}/${params.testId}/result/${res.data.id}`);
                }).catch((err) => {
                    console.log('err', err)
                });
            } else if (params.testType === "ASSIGNMENT") {
                studentApiClient.assignmentEvaluation(parseInt(params?.testId ?? ''), answers).then((res) => {
                    navigate(`../${params.testType}/${params.testId}/result/${res.data.id}`);
                }).catch((err) => {
                    console.log('err', err)
                });
            } else if (params.testType === "PRACTICE") {
                studentApiClient.testEvaluation(parseInt(params?.testId ?? ''), answers).then((res) => {
                    navigate(`../${params.testType}/${params.testId}/result/${res.data.id}`);
                }).catch((err) => {
                    console.log('err', err)
                });
            }
            setTestEvaluated(true);
        }
    };


    const onTestSubmit = (isTimeExpiry?: boolean): Promise<boolean> => {
        return new Promise<boolean>((resolve, reject) => {
            if (!isTimeExpiry) {
                setShow(true);
            }
            // setAnswers([]);
            questionData.forEach((item) => {
                if (item.selectedChoice || item.answer) {
                    if (item.question.type === QuestionType.OneAnswer) {
                        setAnswers((prev) => [
                            ...prev,
                            {
                                questionId: item.question.id,
                                type: item.question.type,
                                selectedOption: item.selectedChoice ? item.selectedChoice[0] : -1,
                                timeSpent: questionTimeData[item.question.id] || 0,
                            },
                        ]);
                    } else if (item.question.type === QuestionType.MultiSelect) {
                        setAnswers((prev) => [
                            ...prev,
                            {
                                questionId: item.question.id,
                                type: item.question.type,
                                selectedOptions: item.selectedChoice ? item.selectedChoice : [],
                                timeSpent: questionTimeData[item.question.id] || 0,
                            },
                        ]);
                    } else if (item.question.type === QuestionType.OneWord) {
                        setAnswers((prev) => [
                            ...prev,
                            {
                                questionId: item.question.id,
                                type: item.question.type,
                                answer: item.answer,
                                timeSpent: questionTimeData[item.question.id] || 0,
                            },
                        ]);
                    } else if (item.question.type === QuestionType.TrueFalse) {
                        setAnswers((prev) => [
                            ...prev,
                            {
                                questionId: item.question.id,
                                type: item.question.type,
                                selectedOption: item.selectedChoice ? item.selectedChoice[0] : -1,
                                timeSpent: questionTimeData[item.question.id] || 0,
                            },
                        ]);
                    }
                } else {
                    setAnswers((prev: any) => [
                        ...prev,
                        {
                            questionId: item.question.id,
                            type: item.question.type,
                            timeSpent: questionTimeData[item.question.id] || 0,
                        },
                    ]);
                }
            });

            resolve(true);
        });
    };

    const handleBookMark = (questionId: number) => {
        setQuestionData((prevState) => {
            const _questionData = new Map(prevState);
            const qd = _questionData.get(questionId);
            if (qd) {
                if (qd.bookMark === undefined)
                    qd.bookMark = true;
                else
                    qd.bookMark = !qd.bookMark;
                _questionData.set(questionId, qd);
            }
            return _questionData;
        });
    }

    const handleStepperNavigation = (index: number) => {
        setHighlightedQuestionId(-1);
        setQuestionData((prevState) => {
            const _questionData = new Map(prevState);
            const qd = _questionData.get(quizQuestions[currentQuizQuestion].id);
            if (qd) {
                qd.attempted = true;
                _questionData.set(quizQuestions[currentQuizQuestion].id, qd);
            }
            return _questionData;
        });

        if (quizQuestions[index].comprehensionIndex !== undefined && quizQuestions[index].comprehensionIndex !== currentComprehensionIndex) {
            setCurrentComprehensionIndex(quizQuestions[index].comprehensionIndex ?? 0);
            setCurrentComprehensionText(comprehensionData[quizQuestions[index].comprehensionIndex ?? 0]?.text ?? '');
            setCurrentComprehensionImage(comprehensionData[quizQuestions[index].comprehensionIndex ?? 0]?.imageUrl ?? '');
            // setShowComprehensionModal(true);
        } else {
            setCurrentQuizQuestion(index);
            setShowExplanation(false);
        }
    };




    const handleLikeQuestions = (questionId: number) => {
        setQuestionData((prevState) => {
            const _questionData = new Map(prevState);
            const qd = _questionData.get(questionId);
            if (qd) {
                if (qd.like === undefined) {
                    qd.like = true;
                    studentApiClient.likeQuestion(questionId).then((res) => {

                    }).catch((e) => {
                        console.log("#154", e);
                    })
                } else {
                    qd.like = !qd.like;
                    studentApiClient.unLikeQuestion(questionId).then(() => {
                    }).catch(() => {

                    })
                }
                _questionData.set(questionId, qd);
            }
            return _questionData;
        });
    }

    const validateAnswers = (question: Question) => {
        if (params.testType === QuizMode.PRACTISE) {
            setHighlightedQuestionId(question.id);
            setShowExplanation(true);
        }
    }

    const onSummaryClick = () => {
        setShowMobileSummary(true)
    }

    const onInstructionClick = () => {
        setShowMobileInstructions(true)
    }

    const onWebInstructionClick = () => {
        setShowWebInstruction(true)
    }


    return (
        <div>{(!loading) ?
            <div>
                <div className={'container py-4'}>
                    <div className={'row bg-white py-2 rounded mx-1 chapterHeader align-items-center '}>
                        <div className={'col-6 d-flex align-items-center'}>
                            <h5 className={'fw-bold'}>{programName} </h5>
                            <h5 className={'fw-bold ms-2'} style={{fontSize:'25px'}}> {'>'} </h5>
                            <h5 style={{color:'grey '}} className={'ms-2'}>{testName}</h5>
                        </div>
                        <div className={'col-6 timeMobile'}>
                            <div className={'instr'}>
                                <button className={'btn btn-outline-success'} onClick={onWebInstructionClick}>
                                    View Instructions
                                </button>
                                <i className="bi bi-info-circle-fill" onClick={onInstructionClick}></i>
                            </div>
                        </div>
                    </div>
                </div>
                {quizQuestions?.length > 0 ? <div className='stepperDiv mt-2 container'>
                    <div className='d-flex flex-fill align-items-baseline bg-white px-2 py-2 rounded'>
                        <div className='questionContainer col-8 me-3'>
                            {currentQuizQuestion > -1 ? (
                                <Questions
                                    questionData={Array.from(questionData.values())}
                                    question={quizQuestions[currentQuizQuestion]}
                                    nextQuestion={(questionId) => handleNextQuestion(questionId)}
                                    previousQuestion={handlePreviousQuestion}
                                    onSummaryClick={onSummaryClick}
                                    currentQuestionIndex={currentQuizQuestion}
                                    selectedOptionId={questionData.get(quizQuestions[currentQuizQuestion].id)?.selectedChoice || undefined}
                                    onBookMark={handleBookMark}
                                    onTestSubmit={onTestSubmit}
                                    onLiked={handleLikeQuestions}
                                    highlightedQuestionId={highlightedQuestionId}
                                    quizView={viewType}
                                    onValidate={(question) => validateAnswers(question)}
                                    isExplanationVisible={showExplanation}
                                    onPostDataChange={(postData: PostDataSelect) => onOptionChange(postData)}
                                    answer={questionData.get(quizQuestions[currentQuizQuestion].id)?.answer || undefined}
                                    currentComprehensionText={currentComprehensionText}
                                    currentComprehensionIndex={currentComprehensionIndex}
                                    currentComprehensionImage={currentComprehensionImage}

                                />
                            ) : null}
                        </div>
                        <div className='Stepper col'>
                            <div>
                                <TimeLeft duration={(questionData.size * 60) + (comprehensionData.length * 5) * 60} onTimeEnd={() => {
                                    onTestSubmit(true).then((res) => {
                                        testEvaluation();
                                    })
                                }}/>
                            </div>
                            <div className={''}>
                                <QuizStepper onClick={handleStepperNavigation}
                                             currentStep={currentQuizQuestion}
                                             questionData={Array.from(questionData.values())}/>
                            </div>
                            <div className={''}>
                                <QuizStatusIndicator questionData={Array.from(questionData.values())}/>
                            </div>

                            {/*<div className={'mt-3'}>*/}
                            {/*    <SubmitButton onClick={() => onTestSubmit(false)}*/}
                            {/*                  currentQuestionIndex={currentQuizQuestion}*/}
                            {/*                  questionData={Array.from(questionData.values())}/>*/}
                            {/*</div>*/}
                        </div>
                    </div>

                    <ResultModal show={show} onHide={() => setShow(false)} title={'Test Summary'}
                                 questionData={Array.from(questionData.values())}
                                 onSubmit={() => {
                                     testEvaluation();
                                     setShow(false)
                                 }}/>
                    <Offcanvas show={showWebInstruction} onHide={() => setShowWebInstruction(false)} placement={'end'}>
                        <Offcanvas.Header className={'border-bottom'} closeButton>
                            <Offcanvas.Title>Read General Instructions</Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <Instruction/>
                        </Offcanvas.Body>
                    </Offcanvas>
                    <Offcanvas show={showMobileInstructions} onHide={() => setShowMobileInstructions(false)}
                               placement={'bottom'}>
                        <Offcanvas.Header className={'border-bottom'} closeButton>
                            <Offcanvas.Title>Read General Instructions</Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <Instruction/>
                        </Offcanvas.Body>
                    </Offcanvas>

                    <Offcanvas show={showMobileSummary} onHide={() => setShowMobileSummary(false)} placement={'bottom'}>
                        <Offcanvas.Header closeButton className={'border-bottom'}>
                            <Offcanvas.Title>Submit Test now !</Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <QuizStatusIndicator questionData={Array.from(questionData.values())}/>
                            <QuizStepper onClick={handleStepperNavigation}
                                         currentStep={currentQuizQuestion}
                                         questionData={Array.from(questionData.values())}/>
                            <SubmitButton onClick={() => onTestSubmit(false)} currentQuestionIndex={currentQuizQuestion}
                                          questionData={Array.from(questionData.values())}/>
                        </Offcanvas.Body>
                    </Offcanvas>
                </div> : <EmptyState/>
                }
            </div> : null}
            <div>
                <PassageModal show={showComprehensionModal} onHide={()=>setShowComprehensionModal(false)}
                              currentComprehensionText={currentComprehensionText}
                              currentComprehensionImage={currentComprehensionImage}
                />
            </div>
        </div>
    );
};

export default CompQuiz;