import React, {useState, useEffect} from 'react';
import "./TimeLeft.scss"

function TimeLeftComponent(props: { duration: number, onTimeEnd: () => void }) {
    const [timeLeft, setTimeLeft] = useState(props.duration);
    const {minutes, seconds: remainingSeconds} = formatTime(timeLeft);

    useEffect(() => {
        if (timeLeft === 0) {
            props.onTimeEnd(); // Call the callback function when time ends
        }

        const interval = setInterval(() => {
            setTimeLeft(prevTimeLeft => Math.max(0, prevTimeLeft - 1));
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [timeLeft, props]);

    return (
        <div className={' timeContainer pb-4'}>
            <div className={'timer-header '}>
                <h5 className={'timeLeft mt-2'} style={{fontWeight: '600'}}>Timer</h5>
            </div>
            <div className={''}>
                <div className={'d-flex justify-content-center mt-3'}>
                    <svg width="80" height="81" viewBox="0 0 80 81" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_192_231)">
                            <path
                                d="M61.8 26.51L66.12 22.19L61.88 17.95L57.56 22.27C52.76 18.39 46.64 16.07 40 16.07C24.52 16.07 12 28.59 12 44.07C12 59.55 24.52 72.07 40 72.07C55.48 72.07 68 59.55 68 44.07C68 37.43 65.68 31.31 61.8 26.51ZM40 66.07C27.88 66.07 18 56.19 18 44.07C18 31.95 27.88 22.07 40 22.07C52.12 22.07 62 31.95 62 44.07C62 56.19 52.12 66.07 40 66.07Z"
                                fill="#FF7037"/>
                            <path d="M50 6.07001H30V12.07H50V6.07001Z" fill="#FF7037"/>
                            <path d="M43 28.07H37V48.07H43V28.07Z" fill="#FF7037"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_192_231">
                                <rect width="80" height="80" fill="white" transform="translate(0 0.0700073)"/>
                            </clipPath>
                        </defs>
                    </svg>
                </div>
                <div className={'d-flex justify-content-center '}>
                    <div className={'timer-Left m-2 '}>
                        <h3>{minutes}</h3>
                    </div>
                    <div className={' m-2'}>
                        <span style={{fontSize: '40px', color: '#6F6F6F'}}>:</span>
                    </div>
                    <div className={'timer-Left  m-2'}>
                        <h3> {remainingSeconds}</h3>
                    </div>
                </div>
                {/*<div className={'minutes'}>Minutes: </div>*/}
                {/*<div className={'seconds'}>Seconds: {remainingSeconds}</div>*/}
            </div>
            {/*<div className={'d-flex timer justify-content-between px-3'}>*/}
            {/*   */}
            {/*    <div className={'timeLeftNumber'}>*/}
            {/*        <div className={'minutes'}>Minutes: {minutes}</div>*/}
            {/*        <div className={'seconds'}>Seconds: {remainingSeconds}</div>*/}
            {/*    </div>*/}
            {/*    /!*<div className={'timeLeftNumber'}><i className="bi bi-clock me-2"></i> {formatTime(timeLeft)}</div>*!/*/}
            {/*</div>*/}

        </div>
    );
}

// Helper function to format time in mm:ss format
function formatTime(seconds: number) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return {
        minutes,
        seconds: remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds,
    };
}


export default TimeLeftComponent;
