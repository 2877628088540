import React, {useEffect, useState} from "react";
import {Modal, ModalBody, ModalHeader} from "react-bootstrap";
import {useSubjectsApiClient} from "../../../apiClients/SubjectsApiClient";
import Button from "../../../../../components/button/Button";
import {AllToasts} from "../../../../../components/toast/AllToasts";
import {CoursePayload} from "../../../../../components/common/course/createCourse/CreateCourse";

export type AddEditSubjectProps = {
    subjectId?: number,
    show: boolean,
    onSuccess: () => void
    onHide: () => void;
    onCancel: () => void;
};

export type SubjectPayload = {
    name?: string,
    subjectGroup?: string,
}

const AddEditSubject = (props: AddEditSubjectProps) => {
    const subjectsApiClient = useSubjectsApiClient()
    const [payload, setPayload] = useState<SubjectPayload>({name: '', subjectGroup: '',});
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setPayload({
            name: '',
            subjectGroup: '',
        })
        if (props.subjectId) {
            getSubjectById(props.subjectId);
        }
    }, [props.subjectId]);

    const handleSubmit = (event: any) => {
        event.preventDefault();
        if (props.subjectId) {
            updateSubject(props.subjectId, payload);
        } else {
            createSubject(payload);
        }
    };

    const createSubject = (payload: SubjectPayload) => {
        setLoading(true);
        subjectsApiClient.createSubject(payload)
            .then(() => {
                setLoading(false);
                const clearedPayload: SubjectPayload = {name: "", subjectGroup: ""};
                setPayload(clearedPayload);
                props.onSuccess();
                props.onHide();
                AllToasts.success('Subject added successfully');
            })
            .catch((error) => {
                console.error('Error creating subject:', error);
                setLoading(false);
                props.onHide();
                AllToasts.failure('Failed to add');
            });
    };

    const updateSubject = (id: number, payload: SubjectPayload) => {
        setLoading(true);
        subjectsApiClient.updateSubject(id, payload)
            .then((res) => {
                setLoading(false);
                props.onSuccess();
                props.onHide();
                AllToasts.success('Subject Updated successfully');
                console.log('Success #29');
                console.log(res.data);
            })
            .catch((error) => {
                console.error('Error creating subject:', error);
                setLoading(false);
                props.onHide();
                AllToasts.failure('Failed to update');
            });
    };
    const getSubjectById = (id: number) => {
        subjectsApiClient.getSubjectById(id).then((res) => {
            setPayload(res.data);
        });
    };

    const handleCancel = () => {
        props.onCancel();
    };


    return <div>
        <Modal show={props.show} onHide={props.onCancel}>
            <ModalHeader>
                <div className={'flex-fill'}><h5>{props.subjectId ? "Update subject" : "Add subject"}</h5></div>
                <div className={'cursor-pointer'} onClick={handleCancel}><i className="bi bi-x fs-4 "></i></div>
            </ModalHeader>
            <ModalBody>
                <form onSubmit={(e) => handleSubmit(e)}>
                    <div className="form-group m-2">
                        <div className='mb-2'>
                            <label htmlFor="exampleInputName">Name</label>
                            <input
                                type="text"
                                className="form-control mt-1"
                                id="exampleInputName"
                                name={'name'}
                                aria-describedby="nameHelp"
                                onChange={(e) =>
                                    setPayload((prevState) => ({
                                        ...prevState,
                                        name: e.target.value,
                                    }))
                                }
                                value={payload?.name}
                                placeholder="Enter name"
                                required
                            />
                        </div>
                        <div>
                            <label htmlFor="subjectGroup">Subject Group</label>
                            <input
                                type="text"
                                className="form-control mt-1"
                                name={'subjectGroup'}
                                id="exampleInputName"
                                aria-describedby="nameHelp"
                                onChange={(e) =>
                                    setPayload((prevState) => ({
                                        ...prevState,
                                        subjectGroup: e.target.value,
                                    }))
                                }
                                value={payload?.subjectGroup}
                                placeholder="Enter group name"
                            />
                        </div>
                    </div>
                    <div className={'d-flex mt-3'}>
                        <div className={'flex-fill'}></div>
                        <div>
                            <button type={"button"} className="btn btn-sm me-2 " onClick={handleCancel}>Cancel</button>
                            <Button label={props.subjectId ? "Update " : "Add "} loading={loading}
                                    cssName={'btn btn-sm btn-create'}/>
                        </div>
                    </div>
                </form>
            </ModalBody>
        </Modal>

    </div>
}
export default AddEditSubject;