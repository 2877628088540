import 'react-loading-skeleton/dist/skeleton.css'
import React from "react";
import "./CardShimmer.scss"
import Skeleton from "react-loading-skeleton";

export const CardShimmer = () => {
    return <div className={'col'}>
        <div className={'my-1'}>
            <Skeleton height={42} width={300}/>
        </div>
    </div>
}
export default CardShimmer;