import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import './programCard.scss';
import EditProgram from "../EditProgram/EditProgram";

export const ProgramCard=(props: {
    program: any,
    onEdit?: () => void,
    onDelete?: () => void,
    onCancel?: () => void,
    show?: boolean,
    id?: string
})=>{

    const params=useParams();
    const navigate=useNavigate();
    const [showEditProgram, setShowEditProgram] = useState<boolean>(false);
    useEffect(() => {
        console.log(params)
    }, [params]);

    return<div className={'program-card m-2 shadow-sm ms-3'} >
        <div className="card-body d-block justify-content-center align-items-center">
            <div className='d-flex align-items-center justify-content-between'>
                <div onClick={()=>navigate(`programs/${props.program.id}`)}>
                    <h5 className="text-primary" style={{ cursor: 'pointer' }}>
                        {props.program.name}
                    </h5>
                </div>
                <div >
                    <a className={'btn btn-sm bg-primary me-2 rounded-circle'}
                        onClick={() => {setShowEditProgram(true)}}
                    >
                        <i className="bi bi-pen"></i>
                    </a>
                </div>
            </div>
        </div>
        <EditProgram show={showEditProgram}
                     onCancel={()=>setShowEditProgram(false)}
                     id={props.program?.id}
                     onSuccess={() => {
                         setShowEditProgram(false);
                         if (props.onEdit) {
                             props.onEdit();
                         }
                     }}

        />
    </div>
}

export default ProgramCard;
