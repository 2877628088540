import {AxiosError} from 'axios';
import React, {useEffect, useState} from 'react';
import {Button, Modal} from 'react-bootstrap';
import {usePaymentApiClient} from '../../apiClients/PaymentApiClient';
import {
    OrderDetails,
    RazorpayOptions,
    RazorPayPaymentValidationDTO,
    RazorpayProps,
    RazorpayStatic
} from "../../../../modals/RazorPayModals";
import {RAZORPAY_IMAGE_URL, RAZORPAY_KEY, RAZORPAY_THEME_COLOR} from "../../../../config/Config";
import {useNavigate} from "react-router-dom";
import {SubscriptionForCourse} from "../../screen/home/courses/CourseDetails";
import {CoursePurchaseModal} from "../../../../components/modal/coursePurchase/CoursePurchaseModal";

declare global {
    interface Window {
        Razorpay: RazorpayStatic;
    }
}

const RazorpayComponent: React.FC<RazorpayProps> = ({
                                                        onSuccess, onFailure, custom_className, courseId,
                                                        paymentStatus, subscriptionType,subscription, newCoursePrice
                                                    }) => {
    const paymentApiClient = usePaymentApiClient();
    const [buttonState, setButtonState] = useState<{ isLoading: boolean; label: string }>({
        isLoading: false,
        label: "Buy now",
    });
    const [newOrderDetails, setNewOrderDetails] = useState<OrderDetails>();
    const navigate = useNavigate()
    useEffect(() => {
        console.log('buttonState',buttonState)
    }, [buttonState]);

    const [showModal , setShowModal] = useState<boolean>(false);
    const [paymentSuccess , setPaymentSuccess] = useState<boolean>(false);

    /**
     * Loads a script from the specified URL and returns a promise that resolves when the script is loaded successfully.
     * @param {string} src - The URL of the script to load.
     * @return {Promise<boolean>} - A promise that resolves to `true` when the script is loaded successfully, or rejects with an error if the script fails to load.
     */

    const loadRazorpayScript = (src: string): Promise<boolean> => {
        return new Promise((resolve, reject) => {
            if (document.querySelector(`script[src="${src}"]`)) {
                resolve(true);
                return;
            }
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                reject(new Error(`Failed to load script: ${src}`));
            };
            document.body.appendChild(script);
        });
    }

    /**
     * Initializes the Razorpay payment gateway with the specified configuration options.
     * @async
     * @param {any} options - The configuration options for the Razorpay payment gateway.
     * @return {Promise<any | null>} - A promise that resolves to a `Razorpay` instance if the initialization is successful, or `null` if it fails.
     */

    const initializeRazorpay = async (options: RazorpayOptions): Promise<any | null> => {
        try {
            if (!window.Razorpay) {
                await loadRazorpayScript("https://checkout.razorpay.com/v1/checkout.js");
            }
            return new window.Razorpay(options);
        } catch (error: unknown | any) {
            // Todo: Show error in Toast
            console.error("Error loading script:", error?.message);
            return null;
        }
    }

    /**
     * Displays the Razorpay payment gateway to the user with the specified order details.
     * @async
     * @param {RazorPayOrder} OrderDetails - The details of the order to be displayed in the payment gateway.
     * @return {Promise<void>} - A promise that resolves to `void` once the payment gateway is opened.
     */

    const displayRazorpay = async (updatedOrderDetails: OrderDetails): Promise<void> => {
        if (!updatedOrderDetails) {
            alert("Server error. Are you online?");
            return;
        }
        const options = createRazorpayOptions(updatedOrderDetails);
        const rzp = await initializeRazorpay(options);
        if (rzp) {
            rzp.open();
        } else {
            // Todo: Show error in Toast and set loading false
            console.error("Error initializing Razorpay.");
        }
    }

    /**
     * Creates a new order in the Razorpay payment gateway and returns an object
     * with configuration options for displaying the payment gateway to the user.
     * @param OrderDetails The details of the order to be created.
     * @returns An object containing configuration options for the Razorpay payment gateway.
     */

    const createRazorpayOptions = (orderDetails: OrderDetails): RazorpayOptions => {
        return {
            key: RAZORPAY_KEY,
            amount: orderDetails.amount,
            currency: orderDetails.currency,
            name: orderDetails.title ?? '',
            description: orderDetails?.description ?? '',
            order_id: orderDetails.orderId,
            image: RAZORPAY_IMAGE_URL,
            handler: (response: RazorPayPaymentValidationDTO) => {
                setButtonState({
                    isLoading: false,
                    label: "Start now"
                });
                setShowModal(true);
                setPaymentSuccess(true);
                onSuccess({...response, courseId});
            },
            prefill: {
                name: orderDetails.customerName ?? '',
                email: orderDetails.email ?? '',
                contact: newOrderDetails?.phone ?? null
            },
            notes: {
                address: orderDetails.address ?? '',
            },
            theme: {
                color: RAZORPAY_THEME_COLOR,
            },
            modal: {
                ondismiss: () => {
                    setButtonState({
                        isLoading: false,
                        label: "Buy now"
                    });
                    onFailure(null);
                }
            }
        };
    }

    /**
     * Posts an order to the server and displays the Razorpay payment gateway to the user.
     * @param {OrderDetails} - The details of the order to be posted to the server.
     * @return {void} - This function does not return anything.
     */

    const createRazorpayOrder = (courseId: number,payload:SubscriptionForCourse ,  newCoursePrice ?: number): void => {
        setButtonState(prevState => ({
            ...prevState,
            isLoading: true,
        }));
        if (newCoursePrice !== undefined){
            paymentApiClient.createOrderForCourser(courseId,payload , newCoursePrice).then((res) => {
                setButtonState(prevState => ({
                    ...prevState,
                    label: "Processing",
                    isLoading: true,
                }));
                setNewOrderDetails(prevState => ({
                    ...prevState,
                    amount: res.data.amount,
                    orderId: res.data.orderId,
                    currency: res.data.currency,
                    email: res.data.email,
                    address: res.data.address,
                    title: res.data.title,
                    customerName: res.data.customerName,
                    description: res.data.description,
                    createdAt: res.data.createdAt,
                    status: res.data.status,
                    orderType:res.data.orderType
                }));
                displayRazorpay(res.data);

            }).catch((err: AxiosError) => {
                setButtonState(prevState => ({
                    ...prevState,
                    label: "Buy Now",
                    isLoading: false,
                }));
                // Todo: Show error in Toast and set loading false
                console.error("Enrollment error", err);
            })
        }else {
            paymentApiClient.createOrderForCourser(courseId,payload).then((res) => {
                setButtonState(prevState => ({
                    ...prevState,
                    label: "Processing",
                    isLoading:true
                }));
                setNewOrderDetails(prevState => ({
                    ...prevState,
                    amount: res.data.amount,
                    orderId: res.data.orderId,
                    currency: res.data.currency,
                    email: res.data.email,
                    address: res.data.address,
                    title: res.data.title,
                    customerName: res.data.customerName,
                    description: res.data.description,
                    createdAt: res.data.createdAt,
                    status: res.data.status,
                    orderType:res.data.orderType
                }));
                displayRazorpay(res.data);

            }).catch((err: AxiosError) => {
                // Todo: Show error in Toast and set loading false
                console.error("Enrollment error", err);
                setButtonState(prevState => ({
                    ...prevState,
                    label: "Buy Now",
                    isLoading: false,
                }));
            })
        }
    }

    return (
        <>
            {/*{subscriptionType &&*/}
            {
                !paymentSuccess &&
                <button className={'btn default-btn w-75 butn butn__new'} onClick={() => {
                    createRazorpayOrder(courseId, subscription, newCoursePrice);
                }}>
                    {buttonState.isLoading ? (
                        <div className={'d-flex align-items-center justify-content-center'}>
                            <div className={'spinner-border me-2'}></div>
                            <div>{buttonState.label}</div>
                        </div>
                    ) : (
                        'Buy Now'
                    )}
                </button>
            }

            {/*}*/}
            <div>
                {/*{!subscriptionType  &&*/}
                {/*    // <div className='d-flex justify-content-evenly align-items-center'>*/}
                {/*    //     <div>*/}
                {/*    //         Payment successful!*/}
                {/*    //         /!*Click "Start now" to navigate to your Purchased course.*!/*/}
                {/*    //     </div>*/}
                {/*    //     <div>*/}
                {/*    //         <Button*/}
                {/*    //             className={custom_className}*/}
                {/*    //             onClick={() => {*/}
                {/*    //                 // createRazorpayOrder(courseId, subscription, newCoursePrice);*/}
                {/*    //                 navigate(`/student/course-batch/${courseId}`)*/}
                {/*    //             }}*/}
                {/*    //             variant="outline-primary"*/}
                {/*    //             disabled={buttonState.isLoading}*/}
                {/*    //         >*/}
                {/*    //             Start Now*/}
                {/*    //         </Button>*/}
                {/*    //     </div>*/}
                {/*    // </div>*/}
                    <div className={'w-50 h-50'}>
                        <CoursePurchaseModal show={showModal} onHide={()=>setShowModal(false)} courseId={courseId}/>
                    </div>
                {/*}*/}
            </div>
        </>

    );
};

export default RazorpayComponent;