import React from "react";
import {useNavigate} from "react-router-dom";
import './StudentAssignment.scss';

export interface AssignmentCardProps {
    assignmentData: any;
    assignmentName: string;
    buttonName: string;
    loading: boolean
}

const AssignmentCard = (props: AssignmentCardProps) => {
    const navigate = useNavigate();

    return (
        <div className={'col-lg-12 mb-3'}>
            <div className="card shadow-sm px-2 pt-2 h-100" style={{borderColor: '#EFF0F6'}}>
                <div className='card-header bg-transparent mx-3 px-0 d-flex'>
                    <p className='notice me-1 mb-0'></p>
                    <h6 className={'fw-bold mb-0'}>{props.assignmentName}</h6>
                    {props.assignmentData?.length > 0 && (
                        <div>
                            {props.assignmentName === "New Assignment" && (
                                <span
                                    onClick={() => {
                                        navigate(`Assignment`);
                                    }}
                                    style={{
                                        cursor: 'pointer',
                                        color: '#0F2552',
                                        fontSize: '14px',
                                        fontWeight: '500'
                                    }}>
                                    View Previous
                                </span>
                            )}
                            {props.assignmentName === " Practice Test" && (
                                <span
                                    onClick={() => {
                                        navigate(`Practice`);
                                    }}
                                    style={{
                                        cursor: 'pointer',
                                        color: '#0F2552',
                                        fontSize: '14px',
                                        fontWeight: '500'
                                    }}>
                                    View All
                                </span>
                            )}
                            {props.assignmentName === "Tests" && (
                                <span onClick={() => navigate(`Test`)}
                                      style={{
                                          cursor: 'pointer',
                                          color: '#0F2552',
                                          fontSize: '14px',
                                          fontWeight: '500'
                                      }}>
                                    View All
                                </span>
                            )}
                        </div>
                    )}
                </div>
                <div className="card-body w-100">
                    <ul className={'p-0 topic_list'}>
                        {props.loading ? (
                            ""
                        ) : props.assignmentData?.length > 0 ? (
                            props.assignmentData?.slice(0, 4).map((res: any, index: number) => (
                                <li key={res.id}
                                    className={'list-unstyled py-2 px-2 d-flex justify-content-between align-items-center'}>
                                    <div
                                        className={'list_name'}>{res.testName === "Anonymous" || res.testName === "" ? res.programName : res.testName ?? res.name}</div>
                                    <h6>
                                        {props.buttonName === "Attempt" && props.assignmentName === "New Assignment" ? (
                                            res?.ofComprehension ?
                                                <button className={'review_btn'}
                                                        onClick={() => {
                                                            navigate(`../ASSIGNMENT/${res.id}/comp-instructions`)
                                                        }}>
                                                    {props.buttonName}
                                                </button> :
                                                <button className={'review_btn'}
                                                        onClick={() => {
                                                            navigate(`../ASSIGNMENT/${res.id}/instructions`)
                                                        }}>
                                                    {props.buttonName}
                                                </button>
                                        ) : props.buttonName === "Review" && props.assignmentName === "Tests" ? (
                                            <button className={'review_btn'}
                                                    onClick={() => navigate(`../TEST/review/${res.id}`)}>
                                                {props.buttonName}
                                            </button>
                                        ) : (
                                            ""
                                        )}
                                        {props.buttonName === "Review" && props.assignmentName === "Practice Test" && (
                                            <button className={'review_btn'}
                                                    onClick={() => navigate(`../PRACTICE/review/${res.id}`)}>
                                                {props.buttonName}
                                            </button>
                                        )}
                                    </h6>
                                </li>
                            ))
                        ) : (
                            <div className={'d-flex justify-content-center my-auto h-100'}>
                                <h6>No results found !</h6>
                            </div>
                        )}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default AssignmentCard;
