import {createSlice} from "@reduxjs/toolkit";

interface Question {
    id: number;
    text: string;
}

interface QuestionState {
    selectedQuestions: Question[];
}

const initialState: QuestionState = {
    selectedQuestions: [],
};

const questionSlice = createSlice({
    name: "question",
    initialState,
    reducers: {
        addSelectedQuestion: (state: QuestionState, action: any) => {
            state.selectedQuestions.push(action.payload);
        },
        removeSelectedQuestion: (state: QuestionState, action: any) => {
            const updatedSelectedQuestions = state.selectedQuestions.filter(
                (question: any) => question.id !== action.payload
            );
            return {
                ...state,
                selectedQuestions: updatedSelectedQuestions
            };
        },
        removeAllQuestions: (state: QuestionState) => {
            state.selectedQuestions = [];
        },
    },
});

export const { addSelectedQuestion, removeSelectedQuestion,removeAllQuestions } = questionSlice.actions;
export default questionSlice.reducer;
