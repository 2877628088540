import React, {ChangeEvent, useEffect, useRef, useState} from "react";
import {Option, Questions} from "../../../modals/CreateiUpdateQuestion";
import DropdownSelect from "../dropDownSelect/DropDownSelect";
import {useTeacherApiClient} from "../../../../apiClients/TeacherApiClient";
import {ComprehensionPayload} from "../../../../../../dto/ComprehensionQuestion";
import './ComprehensionQuestion.css';
import {AllToasts} from "../../../../../../components/toast/AllToasts";
import {QuestionType} from "../../../../../../modals/quizquestionData/Modal";
import {ResolveQuestion} from "../resolveQuestion/ResolveQuestion";
import {useLocation, useParams} from "react-router-dom";
import {ImageUpload} from "../ImageUpload/ImageUpload";

interface ComprehensionQuestionProps {
    handleDropDownSelect: (event: ChangeEvent<HTMLSelectElement>) => void;
    handleRemoveOption: (index: number) => void;
    handleOptionCorrectChanges: (index: number, correct: boolean) => void;
    handleOptionCorrectChange: (index: number, correct: boolean) => void;
    handleOptionTextChange: (index: number, text: string) => void;
    selectedQuestionType: string;
    handleAddOption: () => void;
    options: Option[];
    handleChange: (event: any) => void;
    payload: Questions;
    questionIdCreatedQuestionRes: number | undefined
    onComprehensionIdChange: (comprehensionId: number) => void;
    questionId: number | undefined;
    createQuestion: () => void;
    getQuestionById: (id: number) => void
    setPayload: React.Dispatch<React.SetStateAction<Questions>>;
    setOptions: React.Dispatch<React.SetStateAction<Option[]>>
    updateQuestion: (id: number) => void
    showPreview: boolean
    setShowPreview: React.Dispatch<React.SetStateAction<boolean>>
    initialPayload: Questions
    passage: (text: string) => void
    passgeText: string | undefined
    setPassage: React.Dispatch<React.SetStateAction<string>>;
    passageId: number | undefined
    onSuccess: () => void
    setQuestionId: React.Dispatch<React.SetStateAction<number | undefined>>;
    setUpdateQuestionId: React.Dispatch<React.SetStateAction<number | undefined>>;
    flaggedQuestionId: number | undefined
    hasIssue: boolean | undefined
    flaggedIds: number[] | []
    allFlaggedQuestionIds: number[]
    setImageUrl?: (url:string) => void
    // setFlaggedQuestionId?:React.Dispatch<React.SetStateAction<number | undefined>>;
}

const emptyQuestion = {
    id: -1,
    text: '',
    imageCid: '',
    options: [{id: -1, text: '', correct: false}],
    explanation: '',
    difficultyLevel: '',
    oneWordAnswer: '',
    knowledge_memory: '',
    student_preparation: '',
    analytical_thinking: '',
    concept_application: '',
    concept_clarity: '',
    problem_solving_skill: '',
    numerical_accuracy: '',
    logic: '',
    time_management: '',
    totalScore: '',
    category: '',
    type: '' as QuestionType
}
export const UpdateComprehensionQuestion = (props: ComprehensionQuestionProps) => {
    const teacherApiClient = useTeacherApiClient();
    const location = useLocation();
    const params = useParams();
    const [comprehensionId, setComprehensionId] = useState<number | undefined>(undefined);
    const [payload, setPayload] = useState<ComprehensionPayload>({text: "", imageCid: ''});
    const [selectedQuestionId, setSelectedQuestionId] = useState<number | null>(null);
    const [questions, setQuestions] = useState<Questions[]>([emptyQuestion]);
    const [editable, setEditable] = useState<boolean>(true);
    const [textValue, setTextValue] = useState<string>("");
    const [currentStep, setCurrentStep] = useState(1);
    const [isQuestionCreated, setIsQuestionCreated] = useState<boolean>(false);
    const [counter, setCounter] = useState<number>(0);
    const [imageUploadVisible, setImageUploadVisible] = useState<boolean>(false);
    const [imageUrl, setImageUrl] = useState<string>('')
    const [uploadedFileCid, setUploadedFileCid] = useState<string>('');
    const inputRef = useRef<any>(null);
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (props.setPassage)
            props.setPassage(textValue)
    }, [textValue]);

    useEffect(() => {
        setCounter(counter + 1)
    }, []);

    // useEffect(() => {
    //     if(props.passageId)
    //         getSingleComprehensionQues(props.passageId)
    // }, [props.passageId]);

    useEffect(() => {
        if (props.questionId) {
            setQuestions([props.initialPayload]);
            setSelectedQuestionId(props.questionId);
        } else if (props.passageId)
            setComprehensionId(props.passageId);
        if (!isQuestionCreated && props.passageId)
            getSingleComprehensionQues(props.passageId);
    }, [props.questionId, props.passageId]);


    useEffect(() => {
        if (props.questionId) {
            setEditable(false);
            if (props.passgeText)
                setTextValue(props.passgeText);
        }
    }, [props.passgeText, props.questionId]);

    useEffect(() => {
        if (comprehensionId && comprehensionId !== undefined && comprehensionId !== 0) {
            if (!isQuestionCreated)
                getSingleComprehensionQues(comprehensionId);
        }
    }, [comprehensionId, props.questionIdCreatedQuestionRes]);


    const handleSaveAddQuestion = () => {
        if (editable) {
            if (!deepEqual(textValue, props.passgeText)) {
                handleSubmit();
            } else {
                setEditable(false);
            }
        } else {
            setEditable(true);
        }
    };

    const handleUpdateAddQuestion = () => {
        if (editable) {
            if (props.questionId) {
                if (!deepEqual(textValue, props.passgeText)) {
                    updateComprehensionQues(props.passageId ?? 0, payload);
                } else {
                    setEditable(false);
                }
            }
        } else {
            setEditable(true);
        }
    };

    const handleChange = (event: any) => {
        const {name, value} = event.currentTarget;
        setTextValue(value);
        setPayload((prevState) => ({...prevState, [name]: value}));
        props.passage(value)
    };

    const createComprehensionQues = (payload: ComprehensionPayload) => {
        setLoading(true)
        teacherApiClient.createComprehension(payload).then((res) => {
            setComprehensionId(res.data.id);
            props.onComprehensionIdChange(res.data.id);
            getSingleComprehensionQues(res.data.id);
            setEditable(false);
            setLoading(false)
            AllToasts.success('Comprehension Created Successfully');
        }).catch((err) => {
            console.log(err);
            AllToasts.failure(`Error ${err.response.data.detail}`);
            setLoading(false)
        });
    };

    const updateComprehensionQues = (comprehensionId: number, payload: ComprehensionPayload) => {
        setLoading(true)
        teacherApiClient.updateComprehension(comprehensionId, payload).then((res) => {
            if (!props.questionId) {
                setComprehensionId(res.data.id);
            }
            props.onComprehensionIdChange(res.data.id);
            getSingleComprehensionQues(res.data.id);
            setEditable(false);
            setLoading(false)
            AllToasts.success('Comprehension Updated Successfully');
        }).catch((err) => {
            console.log(err);
            AllToasts.failure(`Error ${err.response.data.detail}`);
            setLoading(false)
        });
    }

    const getSingleComprehensionQues = (comprehensionId: number) => {
        teacherApiClient.getSingleComprehension(comprehensionId).then((res) => {
            if (counter === 0) {
                const foundIndex = res.data.questions.findIndex((question: any) => question.id === props.questionId);
                if (foundIndex !== -1) {
                    setCurrentStep(foundIndex + 1);
                    setComprehensionId(res.data.id);
                    if(res.data.imageUrl)
                        props.setImageUrl && props.setImageUrl(res.data.imageUrl)

                    // Find the question with matching ID and update payload
                    const foundQuestion = res.data.questions[foundIndex];
                    if (foundQuestion) {
                        props.setPayload(foundQuestion);

                        // Map topics from res.data.topics
                        // const foundTopics = res.data.questions[foundIndex]?.topics?.map((topic: any) => ({
                        //     value: topic.id,
                        //     label: topic.name,
                        // }));
                        // console.log('foundTopics',foundTopics)
                        // props.setSelectedTopic(foundTopics);
                    }
                }
            }
            setImageUrl(res.data.imageUrl);
            if (res.data.imageCid) {
                setImageUploadVisible(true);
            }
            setQuestions(res.data.questions);
            setTextValue(res.data.text)
        }).catch((err) => {
            console.log(err);
        });
    };


    // Inside the handleQuestionClick function
    const handleQuestionClick = (step: number) => {
        const question = questions[step - 1];
        if (question) {
            setSelectedQuestionId(question.id);
            if (question.id > 0) {
                props.getQuestionById(question.id);
            } else if (question.id === -1) {
                props.setPayload(emptyQuestion);
                props.setOptions([])
            }
            props.setQuestionId(undefined)
            setCurrentStep(step);
        }
    };

    // Inside the handleCreateNewEmptyQuestion function
    const handleCreateNewEmptyQuestion = () => {
        const isEmptyQuestionPresent = questions.some(question => question.text === '');
        if (!isEmptyQuestionPresent) {
            setIsQuestionCreated(true);
            setSelectedQuestionId(-1);
            props.setPayload(emptyQuestion);
            props.setOptions([])
            const updatedQuestions = [...questions, emptyQuestion];
            setQuestions(updatedQuestions);
            setCurrentStep(updatedQuestions.length);
        } else {
            console.log('error');
            if (inputRef.current) {
                inputRef.current.classList.add('wobble');
                setTimeout(() => {
                    inputRef.current.classList.remove('wobble');
                }, 800);
            }
        }
    };


    const handleSubmit = () => {
        if (comprehensionId) {
            updateComprehensionQues(comprehensionId, payload)
        } else {
            createComprehensionQues(payload)
        }
    }

    const handleCreateQuestion = () => {
        if (selectedQuestionId && selectedQuestionId !== -1) {
            props.updateQuestion(selectedQuestionId);
        } else {
            props.createQuestion();
            setIsQuestionCreated(false)
        }
    };

    function deepEqual(obj1: any, obj2: any, path = '') {
        if (obj1 === obj2) {
            return true;
        }
        if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) {
            return false;
        }
        const keys1 = Object.keys(obj1);
        const keys2 = Object.keys(obj2);
        const commonKeys = keys1.filter(key => keys2.includes(key));
        for (let key of commonKeys) {
            const value1 = Array.isArray(obj1[key]) ? normalizeOptions(obj1[key]) : obj1[key];
            const value2 = Array.isArray(obj2[key]) ? normalizeOptions(obj2[key]) : obj2[key];
            if (!deepEqual(value1, value2, `${path}.${key}`)) {
                return false;
            }
        }
        return true;
    }

    function normalizeOptions(options: Option[]) {
        return options.map(option => ({
            ...option,
            imageCid: option.imageCid || null,
            url: option.url || null
        }));
    }

    const handleUpdateQuestions = () => {
        if (!deepEqual(props.payload, props.initialPayload)) {
            if (props.questionId) {
                props.updateQuestion(props.questionId);
                if (params['*'] !== 'audit') {
                    props.onSuccess();
                }
            }
        }
    };

    const ifFormEmpty = () => {
        if (props?.payload?.type === 'OneWord') {
            return props.payload.text.trim() === '' || props.payload.oneWordAnswer.trim() === '';
        } else {
            return (
                props.payload.text.trim() === '' ||
                props?.payload?.type?.trim() === '' ||
                !props.payload.options.some(option => option.text.trim() !== '' && option.correct === true)
            );
        }
    }

    const handleToggleImageUpload = () => {
        setImageUploadVisible(!imageUploadVisible);
    };
    const handleOnUploadedFileCid = (cid: string) => {
        setPayload({text: textValue, imageCid: cid})
        setUploadedFileCid(cid)
    }

    const handleImage = (url: string) => {
        setImageUrl(url)
        props.setImageUrl && props.setImageUrl(url)
    }
    return (
        <div>
            <div>
                <div>
                    <div className='d-flex align-items-center justify-content-between'>
                        <label className="form-label">Passage <span
                            className='ms-1 text-danger'>*</span></label>
                        <button
                            type={'button'}
                            onClick={handleToggleImageUpload}
                            className='btn btn-primary border-0 btn-sm mb-2 createQuestionButton'>
                            {imageUploadVisible ? 'Close' : 'Upload Image'}
                        </button>
                    </div>
                    {imageUploadVisible && (
                        <ImageUpload onUploadedFileCid={handleOnUploadedFileCid}
                                     imageUrl={imageUrl}
                                     image={(url) => handleImage(url)}/>
                    )}
                    <textarea
                        onClick={(e) => handleChange(e)}
                        name={"text"}
                        rows={5}
                        className="form-control"
                        value={textValue}
                        onChange={(e) => handleChange(e)}
                        disabled={!editable}
                        required
                    />
                    <div className="d-flex align-items-center justify-content-end mt-2">
                        <button
                            className={'btn-outline-success btn bg-white text-success btn-sm ms-2 me-3'}
                            type={'button'}
                            onClick={(e) => {
                                e.preventDefault();
                                props.setShowPreview(!props.showPreview);
                            }}>
                            {props.showPreview ? "Hide" : "Preview"}
                        </button>
                        {props.questionId ?
                            <button
                                className={`btn createQuestionButton text-white me-2 btn-sm`}
                                type="button"
                                onClick={handleUpdateAddQuestion}
                            >
                                {editable ? <div>
                                    <div className={'d-flex align-items-center justify-content-center'}>
                                        {loading &&
                                            <div className={'spinner-border spinner-border-sm me-2'}></div>
                                        }
                                        <div>Save Passage</div>
                                    </div>
                                </div> : "Edit Passage"}
                            </button>
                            :
                            <button
                                className={`btn createQuestionButton text-white me-2 btn-sm`}
                                type="button"
                                onClick={handleSaveAddQuestion}
                                disabled={payload.text === ''}
                            >
                                {editable ? <div>
                                    <div className={'d-flex align-items-center justify-content-center'}>
                                        {loading &&
                                            <div className={'spinner-border spinner-border-sm me-2'}></div>
                                        }
                                        <div>Save Passage</div>
                                    </div>
                                </div> : "Edit Passage"}
                            </button>
                        }
                    </div>
                </div>
            </div>
            <hr/>
            <div className="mt-2">
                <div className="d-flex align-items-center justify-content-between">
                    <label>Questions</label>
                </div>
                <div className='d-flex'>
                    <div className='card p-2 w-100'>
                        <div className="compstepper d-flex align-items-start w-100">
                            {Array.from({length: questions.length ? questions.length : 1}, (_, index) => index + 1)?.map((step, index) => (
                                <div key={index}>
                                    <div
                                        key={index}
                                        onClick={() => {
                                            handleQuestionClick(step);
                                            if (!deepEqual(props.payload, props.initialPayload) && props.payload.id !== -1) {
                                                props.updateQuestion(props.payload.id);
                                            }
                                        }}
                                        className={`compstep ${selectedQuestionId === step ? "compselected" : currentStep === step ? "compcurrent" : ""}`}
                                    >
                                        {step}
                                    </div>

                                </div>
                            ))}
                        </div>
                    </div>
                    <div className='d-flex align-items-center justify-content-end ms-3'>
                        <i
                            className='bi bi-plus-circle fs-5 cursorPointer'
                            onClick={handleCreateNewEmptyQuestion}
                        />
                    </div>

                </div>
                <div>
                    <div>
                        <label className="form-label">Question<span className='ms-1 text-danger'>*</span></label>
                        <textarea
                            className="form-control"
                            name={"text"}
                            value={props.payload.text}
                            onChange={(e) => props.handleChange(e)}
                        />
                    </div>
                    <div className="mb-2 d-flex align-items-center mt-2">
                        <div className="d-flex flex-row align-items-center w-100">
                            <div className="w-100">
                                <label className="form-label" htmlFor={"type"}>
                                    Question Type
                                    <span className="ms-1 text-danger">*</span>
                                </label>
                                <select
                                    required={true}
                                    id="type"
                                    className="form-select w-100"
                                    onChange={(event) => {
                                        props.handleDropDownSelect(event);
                                        props.handleChange(event);
                                    }}
                                    value={props.payload.type}
                                    name="type"
                                >
                                    <option value="">Select</option>
                                    <option value={"MultiSelect"}>MultiSelect</option>
                                    <option value={"OneAnswer"}>One Answer</option>
                                    <option value={"TrueFalse"}>True/False</option>
                                    <option value={"OneWord"}>One Word</option>
                                </select>
                            </div>
                            <div className="ms-2 d-flex flex-row">
                                {props.selectedQuestionType === "TrueFalse" ||
                                props.selectedQuestionType === "OneWord" ? (
                                    ""
                                ) : (
                                    <button
                                        type="button"
                                        className="mt-4 btnAdd"
                                        style={{width: "120px"}}
                                        onClick={props.handleAddOption}
                                    >
                                        Add Options
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                    {props.selectedQuestionType === "OneWord" ? (
                        <div className="w-75">
                            <label htmlFor={"oneWordAnswer"}>
                                Options
                                <span className="ms-1 text-danger mb-2">*</span>
                            </label>
                            <input
                                id={"oneWordAnswer"}
                                className="form-control mt-2 mb-3"
                                type="text"
                                name={"oneWordAnswer"}
                                placeholder={`Option`}
                                value={props.payload.oneWordAnswer}
                                onChange={(e) => props.handleChange(e)}
                                required
                            />
                        </div>
                    ) : (
                        <DropdownSelect
                            options={props.options}
                            selectedQuestionType={props.selectedQuestionType}
                            handleOptionTextChange={props.handleOptionTextChange}
                            handleOptionCorrectChange={props.handleOptionCorrectChange}
                            handleOptionCorrectChanges={props.handleOptionCorrectChanges}
                            handleRemoveOption={props.handleRemoveOption}
                        />
                    )}
                    <div className='d-flex align-items-center justify-content-end mt-2'>
                        <div>
                            <div>
                                {comprehensionId ?
                                    <button
                                        onClick={handleCreateQuestion}
                                        className={"btn btn-sm mb-2 createQuestionButton text-white"}
                                        type={"button"}
                                        disabled={!comprehensionId || deepEqual(props.payload, props.initialPayload) || ifFormEmpty()}
                                        ref={inputRef}
                                    >
                                        {selectedQuestionId !== -1 ? 'Edit/Update Ques' : 'Save/Add Ques.'}
                                    </button>
                                    :
                                    <button
                                        onClick={handleUpdateQuestions}
                                        className={"btn btn-sm mb-2 createQuestionButton text-white"}
                                        type={"button"}
                                        disabled={!props.questionId || deepEqual(props.payload, props.initialPayload) || ifFormEmpty()}
                                        ref={inputRef}
                                    >
                                        {props.questionId ? 'Edit/Update Ques' : 'Save/Add Ques.'}
                                    </button>
                                }
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className='d-flex align-items-center'>
                {location.pathname === '/teacher/audit' && (props.flaggedQuestionId !== undefined && props.flaggedQuestionId !== 0)
                    ? (
                        <ResolveQuestion flaggedQuestionId={props.flaggedQuestionId ?? null}
                                         flaggedQuestionIds={props.flaggedIds}
                                         onHide={() => props.onSuccess()}/>)
                    :
                    (props.flaggedIds && props.flaggedIds.length > 0) &&
                    props.allFlaggedQuestionIds.includes(selectedQuestionId as number) ?
                        <ResolveQuestion flaggedQuestionId={props.flaggedQuestionId ?? null}
                                         flaggedQuestionIds={props.flaggedIds}
                                         onHide={() => props.onSuccess()}/> :
                        ''
                }
            </div>
        </div>
    );
};
