export interface Question{
    id: number,
    text?:string,
    image?:string,
    imageText?:string,
    imageUrl?:string,
    options?:Option[],
    topics?:Topic[],
    createdData?:string,
    updatedDate?:string,
    active?:boolean,
    type?:QuestionType,
    explanation?:string,
    imageCid?:string,
    difficultyLevel?:string,
    oneWordAnswer?:string
}
export interface Option {
    id:number,
    text:string,
    correct:boolean
    url?:string
}
export interface Topic{
    id:number,
    name:string
}
export type answer = {
    type?:string,
    questionId: number,
    answer?:string,
    selectedOption?:number,
    selectedOptions?:number[]
}

export type StudentTestResponse={
    id:number,
    resultId:number,
    total:number,
    correct:number,
    wrong:number,
    skipped:number,
    totalMarks:number,
    obtainedMarks:number
    analysis:responseAnalysis[];
}
export type responseAnalysis={
    question:Question,
    status:string,
    difficultyLevel:string,
    attemptedOptions:number[],
    attemptedOption:number
    correctOption:number,
    timeTaken:any,
    attemptedAnswer?:string
}
export enum QuizMode {
    ASSIGNMENT="ASSIGNMENT",
    TEST = 'TEST',
    PRACTISE = 'PRACTICE'
}
export enum ViewType{
    REVIEW='REVIEW'
}
export enum QuestionType {
    MultiSelect = "MultiSelect",
    OneAnswer = "OneAnswer",
    TrueFalse = "TrueFalse",
    OneWord = "OneWord",
}
export interface PostDataSelect {
    type: string;
    selectedOptions?: Option[];
    answer?: string;
    question:Question;
}
export  type PostData =PostDataSelect