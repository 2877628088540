import axios from 'axios';
import {Token} from "../dto/Token";
import {LoginPayload} from "../dto/Payload";
import { API_BASE_URL } from "../common/AppConstants";
import {StudentLoginUsingId} from "../modules/student/screen/login/LoginStudentUserId";
import {selfLoginPayload, ValidateName} from "../modules/student/screen/login/selfRegistration/SelfLogin";


export enum StudentApi {
    SEND_OTP,
    LOGIN
}
const StudentApisPaths = {
    [StudentApi.SEND_OTP]: '/auth/send-otp',
    [StudentApi.LOGIN]: '/auth/login'
}
export const useStudentApi = () => {

    const sendOtp = (mobile: string) => {
        return axios.post(API_BASE_URL+StudentApisPaths[StudentApi.SEND_OTP], {username: mobile});
    }
    const login = (payload: LoginPayload) => {
        return axios.post<Token>(API_BASE_URL+StudentApisPaths[StudentApi.LOGIN], payload);
    }
    const selfLogin = (payload: selfLoginPayload) =>{
        return axios.post(API_BASE_URL+`/auth/register`,payload);
    }
    const userIdPasswordLogin = (payload : StudentLoginUsingId) => {
        return axios.post(API_BASE_URL+`/auth/login` , payload);
    }
    const validateUsername = (payload:ValidateName) => {
        return axios.post(API_BASE_URL+`/validate`, payload);
    }
    return {sendOtp, login , selfLogin , userIdPasswordLogin, validateUsername};
}