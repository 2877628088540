import {CourseBatch} from "../../../../dto/CourseBatch";
import {useNavigate} from "react-router-dom";
import {StudentCourseCard} from "./card/StudentCourseCard";
import './card/studentCard.scss';
import './StudentEnrolledCourses.scss';
import React, {useEffect, useState} from "react";
import {EmptyState} from "../../../../components/emptyState/EmptyState";

interface StudentEnrolledCoursesProps {
    courseBatches: CourseBatch[],
    onSuccess: () => void
    selectedTab: string
    selectedCourse?: (courseType: string) => void
}

export enum CourseType {
    PAID = 'PAID',
    FREE_TRIAL = 'FREE_TRIAL'
}

export const StudentEnrolledCourses = (props: StudentEnrolledCoursesProps) => {
    const [selectedOption, setSelectedOption] = useState<CourseType>(CourseType.PAID);
    const navigate = useNavigate();

    useEffect(() => {
        props.selectedCourse && props.selectedCourse(selectedOption)
    }, [selectedOption]);

    const handleOptionClick = (option: CourseType) => {
        setSelectedOption(option);
        props.selectedCourse && props.selectedCourse(option)
    };

    return <div className={`container`}>
        <div className={`row`}>
            <div className='mt-4'>
                <div className="enrolled_wrap">
                    <div className=' flex-row align-items-center'>
                        <div>
                            <h5><b>Enrolled Courses</b></h5>
                        </div>
                        <div className='d-flex cursorPointer'>
                            <span
                                onClick={() => handleOptionClick(CourseType.PAID)}
                                className={` text-center m-1  ${selectedOption === CourseType.PAID ? ' text-white' : ' text-grey'}`}
                                style={{
                                    backgroundColor: selectedOption === CourseType.PAID ? '#3498DB' : 'white',
                                    borderRadius: '5px',
                                    border: selectedOption === CourseType.PAID ? '#3498DB' : '1px solid rgba(33, 33, 33, 0.90)',
                                    padding: '5px 15px',
                                    fontSize: '15px'
                                }}>
                                Subscribed
                            </span>
                            <span
                                onClick={() => handleOptionClick(CourseType.FREE_TRIAL)}
                                className={` text-center m-1 ${selectedOption === CourseType.FREE_TRIAL ? ' text-white' : ' text-grey'}`}
                                style={{
                                    backgroundColor: selectedOption === CourseType.FREE_TRIAL ? '#3498DB' : 'white',
                                    borderRadius: '5px',
                                    border: selectedOption === CourseType.FREE_TRIAL ? '#3498DB' : '1px solid #6F6F6F',
                                    padding: '5px 15px ',
                                    fontSize: '15px'
                                }}>
                                Free Trial
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div className={'d-grid mx-auto thr_grid mt-4'}>
                {props.courseBatches.length > 0 ?
                    props.courseBatches?.map((courseBatch) => (
                        <StudentCourseCard key={courseBatch.id}
                                           onSuccess={props.onSuccess}
                                           courseBatch={courseBatch}
                                           onClick={() => {
                                               navigate(`course-batch/${courseBatch.id}`);
                                           }}
                                           selectedTab={props.selectedTab}
                                           selectedOption={selectedOption}
                        />
                    )) : <div className={'d-flex justify-content-center'}>
                        <EmptyState/>
                    </div>
                }

            </div>
        </div>
    </div>
}