import React, {useRef, useState} from "react";
import {MathX} from "../../../../../../components/math/MathX";
import {AllToasts} from "../../../../../../components/toast/AllToasts";
import {useTeacherApiClient} from "../../../../apiClients/TeacherApiClient";
import './DropDownSelect.css'

interface Option {
    text: string;
    correct: boolean;
    imageCid?: string;
    url?: string
}

interface DropdownSelectProps {
    options: Option[];
    selectedQuestionType: string;
    handleOptionTextChange: (index: number, text: string) => void;
    handleOptionCorrectChange: (index: number, correct: boolean) => void;
    handleOptionCorrectChanges: (index: number, correct: boolean) => void;
    handleRemoveOption?: (index: number) => void;
}

const DropdownSelect: React.FC<DropdownSelectProps> = ({
                                                           options,
                                                           selectedQuestionType,
                                                           handleOptionTextChange,
                                                           handleOptionCorrectChange,
                                                           handleRemoveOption,
                                                           handleOptionCorrectChanges
                                                       }) => {
    const [uploadStatus, setUploadStatus] = useState<string | null>(null);
    const [imageURLs, setImageURLs] = useState<string[]>(new Array(options.length).fill(''));
    const teacherApiClient = useTeacherApiClient();
    const fileInputRefs = useRef<any[]>(Array.from({length: options.length}, () => null));

    const handleFiles = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (!file) {
            return;
        }
        const formData = new FormData();
        formData.append('file', file);
        const reader = new FileReader();
        reader.onload = (e) => {
            const imageSrc = e.target?.result as string;
            const updatedOptions = [...options];
            updatedOptions[index].url = imageSrc;
            handleOptionImageChange(index, imageSrc, file);
        };
        reader.readAsDataURL(file);
    };

    const handleOptionImageChange = (index: number, image: string, file: File) => {
        const updatedImageURLs = [...imageURLs];
        updatedImageURLs[index] = image;
        setImageURLs(updatedImageURLs);
        handleOptionTextChange(index, '');
        handleOptionCorrectChange(index, false);
        setUploadStatus('Uploading...');
        teacherApiClient.uploadImage(file)
            .then((res) => {
                const uploadedFileCid = res.data.cid;
                const updatedOptions = [...options];
                updatedOptions[index].imageCid = uploadedFileCid;
                setUploadStatus('Upload successful');
                AllToasts.success("Image Uploaded Successfully");
            })
            .catch((err) => {
                console.error('Error uploading file:', err);
                setUploadStatus('Error uploading file');
                AllToasts.failure("Image Failed to Upload");
            });
    };

    const removeImage = (index: number) => {
        const updatedOptions = [...options];
        updatedOptions[index].url = undefined;
        handleOptionTextChange(index, '');
        const updatedImageURLs = [...imageURLs];
        updatedImageURLs[index] = '';
        fileInputRefs.current[index].value = '';
        setImageURLs(updatedImageURLs);
    };

    const handleUploadButtonClick = (index: number) => {
        if (fileInputRefs.current && fileInputRefs.current[index]) {
            fileInputRefs.current[index].click();
        }
    };

    return (
        <div>
            {options.map((option, index) => (
                <div key={`${index}-${option.correct}`} className=' '>
                    <div className='d-flex flex-row align-items-center'>
                        <div className='w-75'>
                            <label htmlFor={`options${index}`} className=''>
                                Options {index + 1}
                                <span className='ms-1 text-danger mb-1'>*</span>
                            </label>
                            <MathX>
                                {option.url ? (
                                        <div className="d-flex mb-2 ">
                                            <div className='image-options'>
                                                <img className='img-fluid option-Image' src={option.url} alt={`Option ${index + 1}`}/>
                                                <i onClick={() => removeImage(index)}
                                                   className='bi bi-x-lg fs-6 cross-icon'/>
                                            </div>

                                        </div>
                                    ) :
                                    <div className={'d-flex align-items-center justify-content-center'}>
                                        <input id={`options${index}`} className="form-control mt-2 mb-3"
                                               type="text" name={'options'} placeholder={`Option ${index + 1}`}
                                               value={option.text}
                                               onChange={(e) => handleOptionTextChange(index, e.target.value)}
                                               required/>
                                        <button
                                            type="button"
                                            className="btn mb-2"
                                            onClick={() => handleUploadButtonClick(index)}
                                        >
                                            <i className='bi bi-upload'/>
                                        </button>
                                    </div>
                                }
                            </MathX>
                        </div>
                        <div className='d-flex flex-row ms-4 align-items-center mt-2'>
                            {(selectedQuestionType === 'TrueFalse' || selectedQuestionType === 'OneAnswer') ? (
                                <input id={`correctOption${index}`} type='radio'
                                       className='checkbox-style'
                                       checked={option.correct} name={`radioOption`} onChange={() =>
                                    handleOptionCorrectChanges(index, !option.correct)}
                                />
                            ) : (
                                <input
                                    id={`correctOption${index}`} type='checkbox'
                                    className='checkbox-style'
                                    checked={option.correct} name={`checkboxOption`}
                                    onChange={() => handleOptionCorrectChange(index, !option.correct)}/>

                            )}
                            <div>
                                <button
                                    type="button"
                                    className="close"
                                    onClick={() => handleRemoveOption && handleRemoveOption(index)}
                                >
                                    <span aria-hidden="true" className='text-danger fs-3'>&times;</span>
                                </button>
                                <input
                                    ref={(ref) => (fileInputRefs.current[index] = ref)}
                                    type="file"
                                    accept="image/*"
                                    onChange={(e) => handleFiles(index, e)}
                                    style={{display: 'none'}}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default DropdownSelect;
